'use client';
import {  FC, ReactNode, createContext, useState } from 'react';
import { Modal } from '@unique/component-library';

type ModalContextProps = {
  openModal: (component: ReactNode) => void;
  closeModal: () => void;
};

export const ModalContext = createContext<ModalContextProps>({
  openModal: () => {},
  closeModal: () => {},
});

type ModalProviderProps = {
  children: ReactNode;
};

export const ModalProvider: FC<ModalProviderProps> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [component, setComponent] = useState<ReactNode | null>(null);

  const openModal = (component: ReactNode) => {
    setComponent(component);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ModalContext.Provider value={{ openModal, closeModal }}>
        {children}
        {/* Black background */}
        <div
          tabIndex={-1}
          className={`pointer-events-none fixed z-50 opacity-0 transition-opacity ${
            isOpen ? 'pointer-events-auto opacity-100' : ''
          }`}
        >
          {component && (
            <Modal
              shouldShow={isOpen}
              handleClose={() => {
                setIsOpen(false);
              }}
              classNames="!px-0 sm:!w-[600px] lg:!w-[840px] !max-w-[840px]"
              wrapperClassNames="!max-w-[840px]"
            >
              { component }
            </Modal>
          )}
        </div>
      </ModalContext.Provider>
    </>
  );
};

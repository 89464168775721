'use client';

import {
  Header,
  HeaderMenuOptions,
  Navigation,
  ScrollDirection,
  ToplevelNavItemProps,
  useScrollDirection,
} from '@unique/component-library';
import { IconLogo as UniqueLogo } from '@unique/icons';
import { Allotment } from 'allotment';
import cn from 'classnames';
import Image from 'next/image';
import {
  FC,
  MouseEvent,
  PropsWithChildren,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAuth } from 'react-oidc-context';
import { isLinkElement } from '../helpers/isLinkElement';
import { useScreens } from '../hooks/useScreens';
import { ClientThemeContext } from '../providers/ClientThemeProvider';
import { ScrollWrapper } from './ScrollWrapper';

const SECOND_LEVEL_NAV_CONTAINER_ID = 'second-level-nav-container';

const DEFAULT_LOGO_NAVBAR_ALIGNMENT = 'mx-auto justify-center';

interface LayoutContextProps {
  isHeaderVisible?: boolean;
  setIsHeaderVisible?: (isVisible: boolean) => void;
  isMenuExpanded?: boolean;
  setIsMenuExpanded?: (isExpanded: boolean) => void;
  setTopLevelNavItems?: (items: ToplevelNavItemProps[]) => void;
  topLevelNavItems?: ToplevelNavItemProps[];
  setSecondLevelNavMenu?: (menu: ReactNode) => void;
  secondLevelNavMenu?: ReactNode;
  setSecondLevelFooter?: (menu: ReactNode) => void;
  secondLevelFooter?: ReactNode;
  setHeaderItems?: (headerItems: ReactNode[]) => void;
  splitPaneContent?: ReactNode;
  setSplitPaneContent?: (splitPaneContent: ReactNode) => void;
  setHideLogoOnDesktop?: (hide: boolean) => void;
  shouldShowTermsAndConditions?: boolean;
  setShouldShowTermsAndConditions?: (shouldShow: boolean) => void;
  secondLevelNavContainerId: string;
  shouldShowNetPromoterScore?: boolean;
  setShouldShowNetPromoterScore?: (shouldShow: boolean) => void;
}

export const LayoutContext = createContext<LayoutContextProps>({
  secondLevelNavContainerId: SECOND_LEVEL_NAV_CONTAINER_ID,
});

type DefaultLayoutProps = {
  mainClassname?: string;
  selfUrl?: string;
  basePath?: string;
};

export const DefaultLayout: FC<PropsWithChildren<DefaultLayoutProps>> = ({
  children,
  mainClassname = '',
  selfUrl,
  basePath,
}) => {
  const { user } = useAuth();
  const [headerItems, setHeaderItems] = useState<ReactNode[]>([]);
  const [topLevelNavItems, setTopLevelNavItems] = useState<ToplevelNavItemProps[]>([]);
  const [secondLevelNavMenu, setSecondLevelNavMenu] = useState<ReactNode>(null);
  const [secondLevelFooter, setSecondLevelFooter] = useState<ReactNode>(null);
  const [splitPaneContent, setSplitPaneContent] = useState<ReactNode>(null);
  const [hideLogoOnDesktop, setHideLogoOnDesktop] = useState(false);
  const { logoNavbar, logoHeader, settings } = useContext(ClientThemeContext);
  const splitPaneContentRef = useRef(splitPaneContent);
  const { isDesktop } = useScreens();
  const [isMenuExpanded, setIsMenuExpanded] = useState(isDesktop);
  const [shouldShowTermsAndConditions, setShouldShowTermsAndConditions] = useState(false);
  const [shouldShowNetPromoterScore, setShouldShowNetPromoterScore] = useState(false);

  // Header is visible by default
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  useEffect(() => {
    splitPaneContentRef.current = splitPaneContent;
  }, [splitPaneContent]);

  // save last visited page
  useEffect(() => {
    sessionStorage.setItem('lastVisited', window.location.pathname);
    setIsHeaderVisible(true);
  }, [window.location.pathname]);
  
  // Listen to scroll event to hide header when scrolling down
  const { scrollDirection } = useScrollDirection();
  useEffect(() => {
    // When scrolling down we hide header
    if (scrollDirection === ScrollDirection.Down) {
      setIsHeaderVisible(false);
    } else {
      setIsHeaderVisible(true);
    }
  }, [scrollDirection]);

  // Show menu on desktop by default. Hide on tablet & mobile
  useEffect(() => {
    setIsMenuExpanded(isDesktop);
  }, [isDesktop]);

  const getLogo = (logoUrl?: string, className = 'max-w-[144px]', isHeader = true) => {
    const logoClassName = cn({
      'h-auto max-w-[144px] min-h-[32px] w-auto object-contain aspect-auto': true,
      'max-h-[80px]': isHeader,
      'max-h-[50px] my-[8px] m-auto': !isHeader,
    });

    // TODO: Do we want to fallback to the unique logo here?
    if (!logoUrl)
      return (
        <UniqueLogo
          className={`flex ${settings?.logoNavbarAlignment ?? DEFAULT_LOGO_NAVBAR_ALIGNMENT} my-auto`}
        />
      );
    return (
      <div className={`my-1 flex h-full w-full ${className}`}>
        <Image
          alt="logo"
          src={logoUrl}
          priority={true}
          width={0}
          height={0}
          sizes="100vw"
          className={logoClassName}
        />
      </div>
    );
  };

  // Actual authentication checks are performed higher up. This is just to
  // satisfy Typescript.
  if (!user) throw new Error('Missing user in JWT');
  if (!user.profile.email) throw new Error('Missing email in JWT');

  const handleClickSecondLevelItemMobile = (e: MouseEvent<HTMLDivElement>) => {
    // only close menu if a link was clicked
    if (isLinkElement(e.target)) {
      setIsMenuExpanded(false);
    }
  };

  return (
    <LayoutContext.Provider
      value={{
        setIsHeaderVisible,
        isHeaderVisible,
        setTopLevelNavItems,
        topLevelNavItems,
        setSecondLevelNavMenu,
        secondLevelNavMenu,
        setSecondLevelFooter,
        secondLevelFooter,
        isMenuExpanded,
        setIsMenuExpanded,
        setHeaderItems,
        splitPaneContent,
        setSplitPaneContent,
        setHideLogoOnDesktop,
        shouldShowTermsAndConditions,
        setShouldShowTermsAndConditions,
        shouldShowNetPromoterScore,
        setShouldShowNetPromoterScore,
        secondLevelNavContainerId: SECOND_LEVEL_NAV_CONTAINER_ID,
      }}
    >
      <div className="fixed bottom-0 left-0 right-0 top-0 flex overflow-hidden">
        <Navigation
          isExpanded={isMenuExpanded}
          items={topLevelNavItems}
          logo={getLogo(
            logoNavbar,
            `max-w-[144px] ${settings?.logoNavbarAlignment ?? DEFAULT_LOGO_NAVBAR_ALIGNMENT}`,
            true,
          )}
          hasSecondLevel={!!secondLevelNavMenu}
          handleClickToggleMenuButton={(isExpanded) => {
            setIsMenuExpanded(!!isExpanded);
          }}
          handleClickItem={(e) => (!isDesktop ? handleClickSecondLevelItemMobile(e) : undefined)}
          {...(secondLevelFooter && { secondLevelFooter })}
        >
          {secondLevelNavMenu && secondLevelNavMenu}
        </Navigation>
        <Allotment>
          {/* Main Content - Left side */}
          <Allotment.Pane
            {...(splitPaneContent ? { minSize: isDesktop ? 540 : 320 } : { preferredSize: '100%' })}
          >
            <ScrollWrapper
              id="scrollbar-wrapper"
              className={cn({
                'absolute bottom-0 left-0 right-0 top-0 flex flex-1 shrink flex-col overflow-auto overscroll-none':
                  true,
                [mainClassname]: true,
              })}
            >
              {topLevelNavItems.length > 0 && (
                <>
                  <Header
                    logo={
                      <div className="text-on-secondary flex">
                        {getLogo(logoHeader, 'max-w-[144px] min-w-[125px]', false)}
                      </div>
                    }
                    hideLogoOnDesktop={hideLogoOnDesktop}
                    menu={
                      <HeaderMenuOptions
                        userName={user.profile.name ?? ''}
                        userEmail={user.profile.email}
                        basePath={basePath}
                        selfUrl={selfUrl}
                      />
                    }
                  >
                    {!!headerItems.length && headerItems.map((item) => item)}
                  </Header>
                </>
              )}
              <div className="grow">{children}</div>
            </ScrollWrapper>
          </Allotment.Pane>
          {/* Split pane - on the right side by default */}
          <Allotment.Pane
            visible={!!splitPaneContent}
            minSize={isDesktop ? 400 : 320}
            preferredSize="650px"
          >
            {splitPaneContent}
          </Allotment.Pane>
        </Allotment>
      </div>
    </LayoutContext.Provider>
  );
};

import { FC } from 'react';
import { IconProps } from '.';
import { IconSentimentNegative, IconSentimentNeutral, IconSentimentPositive } from './';

export enum IconSentimentVariant {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NEUTRAL = 'neutral',
}

type IconSentimentProps = IconProps & {
  variant?: IconSentimentVariant;
};

export const IconSentiment: FC<IconSentimentProps> = ({
  width = '16px',
  height = '16px',
  variant = IconSentimentVariant.POSITIVE,
}) => {
  switch (variant) {
    case IconSentimentVariant.NEUTRAL:
      return <IconSentimentNeutral width={width} height={height} />;
    case IconSentimentVariant.NEGATIVE:
      return <IconSentimentNegative width={width} height={height} />;
    // Default is positive
    default:
      return <IconSentimentPositive width={width} height={height} />;
  }
};

export const defaultPhrases: string[] = [
  // Product and Tool Names
  'Jira',
  'Confluence',
  'Slack',
  'Microsoft Teams',
  'Trello',
  'Asana',
  'Salesforce',
  'Zendesk',
  'HubSpot',

  // Team Roles and Names
  'Scrum Master',
  'DevOps Engineer',
  'Full Stack Developer',
  'QA Analyst',

  // Frequently Misrecognized Words
  'Kanban',
  'Scrum',
  'SaaS',
  'CI/CD Pipeline',

  // Custom Company-Specific Terms

  // Abbreviations and Acronyms
  'API',
  'HTTP',
  'SSL',
  'UI/UX',
  'OKRs',
  'KPI',

  // Miscellaneous
];

module.exports = {
  content: [],
  theme: {
    transitionDuration: {
      DEFAULT: "300ms",
      500: "500ms",
      5000: "5000ms",
    },
    backgroundSize: {
      auto: "auto",
      cover: "cover",
      contain: "contain",
      100: "100% 100%",
    },
    backgroundColor: {
      primary: {
        cta: "var(--color-primary-cta)",
        variant: "var(--color-primary-variant)",
      },
      secondary: {
        DEFAULT: "var(--color-secondary)",
        variant: "var(--color-secondary-variant)",
      },
      background: {
        DEFAULT: "var(--color-background)",
        variant: "var(--color-background-variant)",
      },
      attention: {
        DEFAULT: "var(--color-attention)",
        variant: "var(--color-attention-variant)",
      },
      surface: "var(--color-surface)",
      info: "var(--color-info)",
      control: "var(--color-control)",
      error: {
        light: "var(--color-error-light)",
        dark: "var(--color-error-dark)",
      },
      success: {
        light: "var(--color-success-light)",
        dark: "var(--color-success-dark)",
      },
      on: {
        background: {
          main: "var(--color-on-background-main)",
          dimmed: "var(--color-on-background-dimmed)",
        },
        control: {
          main: "var(--color-on-control-main)",
          dimmed: "var(--color-on-control-dimmed)",
        },
      },
      transparent: "transparent",
    },
    textColor: {
      primary: {
        cta: "var(--color-primary-cta)",
        variant: "var(--color-primary-variant)",
      },
      surface: "var(--color-surface)",
      secondary: {
        DEFAULT: "var(--color-secondary)",
      },
      background: {
        DEFAULT: "var(--color-background)",
        variant: "var(--color-background-variant)",
      },
      success: {
        dark: "var(--color-success-dark)",
      },
      info: "var(--color-info)",
      control: "var(--color-control)",
      error: {
        light: "var(--color-error-light)",
        dark: "var(--color-error-dark)",
      },
      attention: {
        DEFAULT: "var(--color-attention)",
        variant: "var(--color-attention-variant)",
      },
      on: {
        primary: "var(--color-on-primary)",
        secondary: "var(--color-on-secondary)",
        background: {
          dimmed: "var(--color-on-background-dimmed)",
          main: "var(--color-on-background-main)",
        },
        attention: {
          DEFAULT: "var(--color-on-attention)",
          variant: "var(--color-on-attention-variant)",
        },
        surface: "var(--color-on-surface)",
        info: "var(--color-on-info)",
        control: {
          main: "var(--color-on-control-main)",
          dimmed: "var(--color-on-control-dimmed)",
        },
        error: {
          light: "var(--color-on-error-light)",
          dark: "var(--color-on-error-dark)",
        },
        success: {
          light: "var(--color-on-success-light)",
          dark: "var(--color-on-success-dark)",
        },
      },
    },
    borderColor: {
      primary: {
        cta: "var(--color-primary-cta)",
      },
      info: "var(--color-info)",
      secondary: {
        DEFAULT: "var(--color-secondary)",
        variant: "var(--color-secondary-variant)",
      },
      background: {
        DEFAULT: "var(--color-background)",
        variant: "var(--color-background-variant)",
      },
      surface: "var(--color-surface)",
      control: "var(--color-control)",
      error: {
        light: "var(--color-error-light)",
        dark: "var(--color-error-dark)",
      },
      success: {
        light: "var(--color-success-light)",
        dark: "var(--color-success-dark)",
      },
      attention: "var(--color-attention)",
      on: {
        background: {
          dimmed: "var(--color-on-background-dimmed)",
        },
        control: {
          main: "var(--color-on-control-main)",
          dimmed: "var(--color-on-control-dimmed)",
        },
        surface: "var(--color-on-surface)",
      },
      transparent: "transparent",
    },
    colors: {
      gray: {
        100: "#e7e7e7",
        200: "#cfcfcf",
        300: "#afaeae",
        400: "#838383",
        500: "#575656",
        600: "#383737",
        700: "#000000",
      },
    },
    fontSize: {
      "4xs": ".55rem",
      "2xs": ".625rem",
      xxs: ".65rem",
      xs: ".75rem",
      sm: ".875rem",
      tiny: ".875rem",
      base: "1rem", // 16px
      lg: "1.125rem",
      xl: "1.25rem",
      "2xl": "1.5rem",
      "3xl": "1.875rem",
      "4xl": "2.25rem",
      "5xl": "2.75rem",
      "6xl": "4rem",
      "7xl": "5rem",
    },
    extend: {
      spacing: {
        "9/16": "56.25%",
        350: "350px",
        250: "250px",
      },
      transitionDuration: {
        0: "0ms",
        100: "100ms",
      },
      transitionProperty: {
        box: "height, max-height, opacity",
      },
      zIndex: {
        "-1": "-1",
        1: "1",
        35: "35", // used for mobile search on meeting page
        25: "25", // used for loading icon when loading a page
        50: "50", // used for sticky meeting header
        51: "51", // used for second level navigation
        55: "55", // used for top level navigation and portals
      },
      scale: {
        "-1": "-1",
      },
      animation: {
        "ping-slow": "ping 1.5s linear infinite",
        "reverse-spin": "reverse-spin 3s linear infinite",
      },
      keyframes: {
        "reverse-spin": {
          from: {
            transform: "rotate(360deg)",
          },
        },
      },
      fontFamily: {
        code: [
          '"Fira Code"',
          '"Fira Mono"',
          "Menlo",
          "Consolas",
          '"DejaVu Sans Mono"',
          "monospace",
        ],
      },
      typography: (theme) => ({
        DEFAULT: {
          css: {
            color: theme("colors.gray.200"),
            a: {
              color: theme("var(--color-secondary)"),
              "&:hover": {
                color: theme("var(--color-secondary-variant)"),
              },
              code: { color: theme("var(--color-on-background-dimmed)") },
            },
            h1: {
              fontWeight: "700",
              letterSpacing: theme("letterSpacing.tight"),
              color: theme("colors.gray.900"),
            },
            h2: {
              fontWeight: "700",
              letterSpacing: theme("letterSpacing.tight"),
              color: theme("colors.gray.900"),
            },
            h3: {
              fontWeight: "600",
              color: theme("colors.gray.900"),
            },
            "h4,h5,h6": {
              color: theme("colors.gray.900"),
            },
            code: {
              color: theme("colors.pink.500"),
              backgroundColor: theme("colors.gray.100"),
              paddingLeft: "4px",
              paddingRight: "4px",
              paddingTop: "2px",
              paddingBottom: "2px",
              borderRadius: "0.25rem",
            },
            "code:before": {
              content: "none",
            },
            "code:after": {
              content: "none",
            },
            hr: { borderColor: theme("colors.gray.200") },
            "ol li:before": {
              fontWeight: "600",
              color: theme("colors.gray.500"),
            },
            "ul li:before": {
              backgroundColor: theme("colors.gray.500"),
            },
            strong: { color: theme("colors.gray.600") },
            blockquote: {
              color: theme("colors.gray.900"),
              borderLeftColor: theme("colors.gray.200"),
            },
          },
        },
      }),
      minWidth: {
        75: "75px",
        300: "300px",
      },
      maxWidth: {
        170: "170px",
        320: "320px",
      },
      minHeight: {
        "33vh": "33vh",
        630: "630px",
        1000: "1000px",
      },
      maxHeight: {
        "20vh": "20vh",
        "38vh": "38vh",
        "65vh": "65vh",
        880: "880px",
        1000: "1000px",
        2500: "2500px",
        none: "none",
        custom: "calc(100vh - 100px)",
        "50vh": "calc(50vh - 200px)",
        "moment-list": "calc(100vh - 430px)",
        "transcript-list": "calc(100vh - 100px)",
        "reports-generator": "calc(100vh - 300px)",
      },
      flex: {
        "0-auto": "0 0 auto",
        "1-0-auto": "1 0 auto",
      },
      width: {
        350: "350px",
        250: "250px",
      },
      left: {
        350: "350px",
        250: "250px",
      },
      screens: {
        "2xs": { min: "481px", max: "640px" },
        "3xs": { min: "320px", max: "480px" },
      },
      boxShadow: {
        strong: "0px 5px 10px 2px rgba(0, 0, 0, 0.25);",
        footer: "0px -4px 8px 0px rgba(0, 0, 0, 0.16);",
      },
    },
  },
  variants: {
    extend: {
      tableLayout: ["hover", "focus"],
      padding: ["hover"],
      boxShadow: ["hover"],
      animation: ["hover", "focus"],
    },
  },
  plugins: [],
};

import { FC } from 'react';
import cn from 'classnames';
import { IconFlag, IconWarning, IconComplianceCheck, IconLoading } from '@unique/icons';

export enum AssessmentBadgeVariant {
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
}

type AssessmentBadgeProps = {
  variant: AssessmentBadgeVariant;
  label: string;
};

export const AssessmentBadge: FC<AssessmentBadgeProps> = ({ variant, label }) => {
  return (
    <div
      className={cn({
        'body-2 flex w-max gap-x-1.5 rounded-[8px] px-2.5 py-0.5': true,
        'bg-error-dark text-on-error-dark': variant === AssessmentBadgeVariant.ERROR,
        'bg-success-dark text-on-success-dark': variant === AssessmentBadgeVariant.SUCCESS,
        'bg-attention text-on-attention': variant === AssessmentBadgeVariant.WARNING,
        'border-background-variant text-on-background-dimmed border':
          variant === AssessmentBadgeVariant.LOADING,
      })}
    >
      <div className="flex shrink-0 items-center">
        {variant === AssessmentBadgeVariant.ERROR && <IconFlag />}
        {variant === AssessmentBadgeVariant.WARNING && <IconWarning width="15px" />}
        {variant === AssessmentBadgeVariant.SUCCESS && <IconComplianceCheck />}
        {variant === AssessmentBadgeVariant.LOADING && (
          <div className="relative mr-3">
            <IconLoading width="12px" className="left-0 -top-3" />
          </div>
        )}
      </div>
      {label}
    </div>
  );
};

'use client';
import { FC, KeyboardEventHandler, ReactNode, Ref, useContext, useEffect, useState } from 'react';
import { ExploreSpaceButton, ExploreSpaceButtonVariant } from './ExploreSpaceButton';
import { LayoutContext, useScreens, useToast } from '@unique/shared-library';
import cn from 'classnames';
import {
  ButtonIcon,
  ButtonSize,
  ButtonVariant,
  Drawer,
  DrawerProvider,
  InputField,
  MenuItem,
} from '@unique/component-library';
import { IconMeatballsMenu, IconSpace } from '@unique/icons';
import { useNavigate } from 'react-router-dom';
import { CHAT_TITLE_MAX_LENGTH } from '@/lib/constants/chatTitleMaxLength';

type SpaceHeaderProps = {
  title: string;
  isTitleEditable?: boolean;
  onTitleChange?: (newTitle: string) => void;
  statusBadge?: ReactNode;
};

export const SpaceHeader: FC<SpaceHeaderProps> = ({
  title,
  isTitleEditable = false,
  onTitleChange,
  statusBadge,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { isMenuExpanded } = useContext(LayoutContext);
  const { isDesktop } = useScreens();
  const navigate = useNavigate();
  const [inputRef, setInputRef] = useState(null);

  const { showErrorToast } = useToast();

  const [spaceTitle, setSpaceTitle] = useState('');

  useEffect(() => {
    if (!title) {
      setSpaceTitle('Untitled Space');
      return;
    }
    setSpaceTitle(title);
  }, [title]);

  const [editTitleMode, setEditTitleMode] = useState(false);

  const handleClickExploreSpaces = () => {
    navigate('/space/explore');
  };

  const handleRenameSpace = () => {
    if (spaceTitle === title) {
      setEditTitleMode(false);
      return;
    }
    if (spaceTitle.trim() === '') {
      showErrorToast('Chat title cannot be empty');
      setSpaceTitle(title);
      return;
    }

    onTitleChange?.(spaceTitle);

    setEditTitleMode(false);
  };

  const handleKeyDownChatInput: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      handleRenameSpace();
      inputRef.current?.blur();
    }
  };

  return (
    <DrawerProvider>
      <div className="flex min-w-0 flex-1 items-center">
        <>
          {!editTitleMode ? (
            <div className="flex items-center">
              <div
                className={cn({
                  'text-on-surface relative my-4 flex-1 shrink-0 gap-x-4 self-center truncate whitespace-nowrap px-5 font-bold':
                    true,
                  'subtitle-1': !isDesktop,
                  'title-s': isDesktop,
                })}
                onClick={() => {
                  isTitleEditable && setEditTitleMode(true);
                }}
              >
                {spaceTitle}
              </div>
              {statusBadge && statusBadge}
            </div>
          ) : (
            <InputField
              name="title"
              classes="border-transparent hover:!border-primary-cta ml-5"
              inputWrapperClasses="relative"
              value={spaceTitle}
              autoFocus
              handleChange={(event) => {
                setSpaceTitle(event.target.value);
              }}
              onBlur={handleRenameSpace}
              onKeyDown={handleKeyDownChatInput}
              setRef={(ref: Ref<HTMLInputElement | null>) => {
                setInputRef(ref);
              }}
              maxLength={CHAT_TITLE_MAX_LENGTH}
            />
          )}
        </>
        {/* meatballs menu including chat delete action is only visible on mobile */}
        <div className="ml-auto pr-1.5 sm:hidden">
          <ButtonIcon
            icon={<IconMeatballsMenu />}
            variant={ButtonVariant.SECONDARY}
            buttonSize={ButtonSize.SMALL}
            onClick={() => {
              setIsDrawerOpen(true);
            }}
          />
        </div>
      </div>
      <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <div className="mt-8">
          <MenuItem
            icon={<IconSpace />}
            title="Explore Spaces"
            handleClick={handleClickExploreSpaces}
          />
        </div>
      </Drawer>
      {/* explore space button is only visible on desktop & tablet when navigation is collapsed */}
      <div
        className={cn({
          'ml-auto hidden self-center overflow-hidden whitespace-nowrap pr-4 transition-opacity delay-200 duration-200 ease-in-out sm:block':
            true,
          'w-auto opacity-100': !isMenuExpanded,
          'pointer-events-none w-0 opacity-0': isMenuExpanded,
        })}
      >
        <div className="overflow-hidden">
          <ExploreSpaceButton
            variant={ExploreSpaceButtonVariant.DARK}
            onClick={() => {
              navigate('/space/explore');
            }}
          />
        </div>
      </div>
    </DrawerProvider>
  );
};

'use client';
import {
  getUseAddDueDiligenceMetaDataKey,
  getUseUpdateMagicTableSheetName,
  useAddDueDiligenceMetaData,
  useContentByChatQuery,
  usePaginatedContentQuery,
  useUpdateMagicTableSheetName,
} from '@/lib/swr/hooks';
import { ButtonIcon, ButtonVariant, InputField, Spinner, Tooltip } from '@unique/component-library';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import FileAccordion from './FileAccordion';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@/store';
import { isIngestingContent, REFRESH_INTERVAL_INGESTION } from '@unique/shared-library';

type Props = {
  assistantId: string;
};

const CreateTableButton = ({
  isDisabled,
  icon,
  onClick,
}: {
  isDisabled?: boolean;
  icon?: ReactNode;
  onClick?: () => void;
}) => (
  <ButtonIcon variant={ButtonVariant.PRIMARY} onClick={onClick} icon={icon} disabled={isDisabled}>
    Create Table
  </ButtonIcon>
);

const CreateDueDiligenceBox: FC<Props> = ({ assistantId }) => {
  const navigate = useNavigate();
  const sourceFileIds = useAppSelector((state) => state.persistedDueDiligence.sourceFileIds);
  const [fileName, setFileName] = useState<string>('Untitled Sheet');
  const questionFileId =
    useAppSelector((state) => state.persistedDueDiligence.questionFileIds)[0] || '';
  const { chatId, dueDiligenceId, sheetId } = useAppSelector(
    (state) => state.persistedDueDiligence.dueDiligenceDetails,
  );

  const { trigger: addDueDiligenceMetaData } = useAddDueDiligenceMetaData(
    getUseAddDueDiligenceMetaDataKey(),
    {
      onSuccess: () => {
        updateSheetName({ sheetId, name: fileName });
      },
    },
  );

  const [isIngestingQuestion, setIsIngestingQuestion] = useState(false);

  const { data: chatContent, isLoading: isLoadingChatContent } = useContentByChatQuery(
    {
      chatId,
    },
    { refreshInterval: isIngestingQuestion ? REFRESH_INTERVAL_INGESTION : 0 },
  );

  const { data: sourceContent, isLoading: isLoadingSourceContent } = usePaginatedContentQuery(
    { where: { id: { in: sourceFileIds } } },
    { revalidateOnFocus: false },
  );

  const { data: importQuestionContent, isLoading: isImportingQuestionContent } =
    usePaginatedContentQuery(
      questionFileId ? { where: { id: { equals: questionFileId } } } : null,
      { revalidateOnFocus: false },
    );

  const { trigger: updateSheetName, isMutating: updatingSheetName } = useUpdateMagicTableSheetName(
    getUseUpdateMagicTableSheetName(),
    {
      onSuccess: () => {
        navigate(`/space/${assistantId}/${sheetId}`);
      },
    },
  );

  const questionsContent = useMemo(() => {
    if (importQuestionContent?.paginatedContent?.nodes?.length) {
      return importQuestionContent?.paginatedContent?.nodes;
    }
    const questionContent = chatContent?.contentByChat?.find(
      (content) => content.id === questionFileId,
    );
    return questionContent ? [questionContent] : [];
  }, [importQuestionContent, chatContent?.contentByChat]);

  const questionsIngestionInProgress = questionsContent?.some((content) =>
    isIngestingContent(content.ingestionState),
  );

  const handleSubmit = () => {
    addDueDiligenceMetaData({
      dueDiligenceId,
      questionFileIds: questionFileId,
      sourceFileIds,
    });
  };

  useEffect(() => {
    setIsIngestingQuestion(questionsIngestionInProgress);
  }, [questionsIngestionInProgress]);

  const isCreateButtonDisabled = updatingSheetName || !fileName || !sheetId;

  const numOfSources = sourceContent?.paginatedContent?.nodes?.length || 0;

  return (
    <div className="mx-auto mt-10 flex max-w-7xl justify-center px-5">
      <div className="flex w-full max-w-3xl">
        <div className="flex w-full flex-col gap-5">
          <div className="flex w-full gap-5">
            <NumberBox digit={1} />
            <FileAccordion
              buttonTitle="Questions"
              content={questionsContent}
              isLoading={isLoadingChatContent || isImportingQuestionContent}
            />
          </div>
          <div className="flex w-full gap-5">
            <NumberBox digit={2} />
            <FileAccordion
              buttonTitle={`${numOfSources} Source${numOfSources > 1 ? 's' : ''}`}
              content={sourceContent?.paginatedContent?.nodes}
              isLoading={isLoadingSourceContent}
            />
          </div>
          <div className="flex w-full gap-2">
            <div className="flex items-start gap-5">
              <NumberBox digit={3} />
              <div className="flex flex-col gap-2">
                <p className="text-on-background-main font-extrabold">Sheet Name</p>
                <div className="w-full sm:w-[500px] md:w-[710px]">
                  <InputField
                    autoFocus
                    name="fileName"
                    classes="w-full"
                    value={fileName}
                    handleChange={(e) => setFileName(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 flex w-full justify-end">
            {/* Show disabled button with tooltip if ingestion is in progress */}
            {questionsIngestionInProgress ? (
              <Tooltip
                wrapper={<CreateTableButton isDisabled={questionsIngestionInProgress} />}
                arrowPosition="none"
                className="w-[260px]"
              >
                This action will be possible once all the uploaded files have been ingested.
              </Tooltip>
            ) : (
              <CreateTableButton
                isDisabled={isCreateButtonDisabled}
                icon={updatingSheetName ? <Spinner size={16} wrapperClasses="pt-1" /> : null}
                onClick={handleSubmit}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateDueDiligenceBox;

const NumberBox = ({ digit }: { digit: number }) => {
  return (
    <div className="bg-primary-cta text-on-primary flex h-10 w-10 items-center justify-center rounded-md font-extrabold">
      {digit}
    </div>
  );
};

import { logger } from '@unique/next-commons/logger';
import { messagesSlice } from '../messagesSlice';
import { getSdk, Message, SortOrder } from '@/@generated/graphql';
import { clientContextValues, Service } from '@unique/next-commons/swr';

const log = logger.child({
  package: 'chat',
  namespace: 'redux:actions:fetchMessages',
});

export const fetchMessages = (chatId, take, skip, includeDebugInfo, includeAssessment) => {
  return async (dispatch) => {
    const { addMessages, setPagination } = messagesSlice.actions;
    try {
      const sdkScope = getSdk(clientContextValues.clients[Service.NODE_CHAT]);
      log.info(`fetch PaginatedMessage: ${chatId}, ${take}, ${skip}`);

      const paginatedMessage = await sdkScope.PaginatedMessage({
        chatId,
        take,
        skip,
        orderBy: [{ createdAt: SortOrder.Desc }],
        includeDebugInfo,
        includeAssessment,
      });
      if (paginatedMessage?.paginatedMessage?.totalCount) {
        dispatch(
          setPagination({ chatId, totalCount: paginatedMessage?.paginatedMessage?.totalCount }),
        );
      }
      return dispatch(addMessages(paginatedMessage?.paginatedMessage?.nodes as Message[]));
    } catch (error) {
      log.error(error.response.errors[0].message);
      return Promise.reject(error.response.errors[0]);
    }
  };
};

import { GraphQLClient } from 'graphql-request';
import { GraphQLClientRequestHeaders } from 'graphql-request/build/cjs/types';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};

export type AnalyticsOrder = {
  __typename?: 'AnalyticsOrder';
  companyId: Scalars['String'];
  configuration: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  state: AnalyticsOrderState;
  stateUpdatedAt: Scalars['DateTime'];
  type: AnalyticsType;
  updatedAt: Scalars['DateTime'];
};

export type AnalyticsOrderOrderByWithRelationInput = {
  configuration?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  stateUpdatedAt?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum AnalyticsOrderScalarFieldEnum {
  CompanyId = 'companyId',
  Configuration = 'configuration',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  Id = 'id',
  State = 'state',
  StateUpdatedAt = 'stateUpdatedAt',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export enum AnalyticsOrderState {
  Created = 'CREATED',
  Done = 'DONE',
  Error = 'ERROR',
  Running = 'RUNNING'
}

export type AnalyticsOrderWhereInput = {
  AND?: InputMaybe<Array<AnalyticsOrderWhereInput>>;
  NOT?: InputMaybe<Array<AnalyticsOrderWhereInput>>;
  OR?: InputMaybe<Array<AnalyticsOrderWhereInput>>;
  configuration?: InputMaybe<JsonFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  state?: InputMaybe<EnumAnalyticsOrderStateFilter>;
  stateUpdatedAt?: InputMaybe<DateTimeFilter>;
  type?: InputMaybe<EnumAnalyticsTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AnalyticsOrderWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum AnalyticsType {
  ActiveUser = 'ACTIVE_USER',
  ChatInteraction = 'CHAT_INTERACTION',
  ChatInteractionDetailed = 'CHAT_INTERACTION_DETAILED',
  ReferenceStat = 'REFERENCE_STAT',
  UserChatExport = 'USER_CHAT_EXPORT'
}

export type Assistant = {
  __typename?: 'Assistant';
  _count: AssistantCount;
  access?: Maybe<Array<Scalars['String']>>;
  alert?: Maybe<Scalars['String']>;
  assistantAccess: Array<AssistantAccessDto>;
  chatUpload: ChatUpload;
  company: Company;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  defaultForCompanyId?: Maybe<Scalars['String']>;
  examples?: Maybe<Array<Scalars['String']>>;
  explanation?: Maybe<Scalars['String']>;
  fallbackModule: Scalars['String'];
  goals?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  inputLimit?: Maybe<Scalars['Int']>;
  inputPlaceholder?: Maybe<Scalars['String']>;
  isExternal: Scalars['Boolean'];
  languageModel?: Maybe<Scalars['String']>;
  modules?: Maybe<Array<Module>>;
  name: Scalars['String'];
  prompts?: Maybe<Array<Prompt>>;
  scopeRules?: Maybe<Array<ScopeRule>>;
  settings?: Maybe<Scalars['JSON']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userPrompts: Array<UserPrompt>;
};

export type AssistantAccessCreateDto = {
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  type: Scalars['String'];
};

export type AssistantAccessDto = {
  __typename?: 'AssistantAccessDto';
  entityId: Scalars['String'];
  entityType: Scalars['String'];
  id: Scalars['String'];
  type: Scalars['String'];
};

export type AssistantCount = {
  __typename?: 'AssistantCount';
  chats: Scalars['Int'];
  modules: Scalars['Int'];
  prompts: Scalars['Int'];
  scopeRules: Scalars['Int'];
  userPrompts: Scalars['Int'];
};

export type AssistantCreateInput = {
  access?: InputMaybe<Array<Scalars['String']>>;
  alert?: InputMaybe<Scalars['String']>;
  chatUpload?: InputMaybe<ChatUpload>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  examples?: InputMaybe<Array<Scalars['String']>>;
  explanation?: InputMaybe<Scalars['String']>;
  fallbackModule: Scalars['String'];
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  inputLimit?: InputMaybe<Scalars['Int']>;
  inputPlaceholder?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  languageModel?: InputMaybe<Scalars['String']>;
  modules?: InputMaybe<ModuleCreateNestedManyWithoutAssistantInput>;
  name: Scalars['String'];
  prompts?: InputMaybe<PromptCreateNestedManyWithoutAssistantInput>;
  scopeRules?: InputMaybe<ScopeRuleCreateNestedManyWithoutAssistantInput>;
  settings?: InputMaybe<Scalars['JSON']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPrompts?: InputMaybe<UserPromptCreateNestedManyWithoutAssistantInput>;
};

export type AssistantCreateManyCompanyInput = {
  access?: InputMaybe<Array<Scalars['String']>>;
  alert?: InputMaybe<Scalars['String']>;
  chatUpload?: InputMaybe<ChatUpload>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  defaultForCompanyId?: InputMaybe<Scalars['String']>;
  examples?: InputMaybe<Array<Scalars['String']>>;
  explanation?: InputMaybe<Scalars['String']>;
  fallbackModule: Scalars['String'];
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  inputLimit?: InputMaybe<Scalars['Int']>;
  inputPlaceholder?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  languageModel?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  settings?: InputMaybe<Scalars['JSON']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AssistantCreateManyCompanyInputEnvelope = {
  data: Array<AssistantCreateManyCompanyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AssistantCreateNestedManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<AssistantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssistantCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<AssistantCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<AssistantCreateManyCompanyInputEnvelope>;
};

export type AssistantCreateNestedOneWithoutDefaultForCompanyInput = {
  connect?: InputMaybe<AssistantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AssistantCreateOrConnectWithoutDefaultForCompanyInput>;
  create?: InputMaybe<AssistantCreateWithoutDefaultForCompanyInput>;
};

export type AssistantCreateOrConnectWithoutCompanyInput = {
  create: AssistantCreateWithoutCompanyInput;
  where: AssistantWhereUniqueInput;
};

export type AssistantCreateOrConnectWithoutDefaultForCompanyInput = {
  create: AssistantCreateWithoutDefaultForCompanyInput;
  where: AssistantWhereUniqueInput;
};

export type AssistantCreateWithoutCompanyInput = {
  access?: InputMaybe<Array<Scalars['String']>>;
  alert?: InputMaybe<Scalars['String']>;
  chatUpload?: InputMaybe<ChatUpload>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  examples?: InputMaybe<Array<Scalars['String']>>;
  explanation?: InputMaybe<Scalars['String']>;
  fallbackModule: Scalars['String'];
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  inputLimit?: InputMaybe<Scalars['Int']>;
  inputPlaceholder?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  languageModel?: InputMaybe<Scalars['String']>;
  modules?: InputMaybe<ModuleCreateNestedManyWithoutAssistantInput>;
  name: Scalars['String'];
  prompts?: InputMaybe<PromptCreateNestedManyWithoutAssistantInput>;
  scopeRules?: InputMaybe<ScopeRuleCreateNestedManyWithoutAssistantInput>;
  settings?: InputMaybe<Scalars['JSON']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPrompts?: InputMaybe<UserPromptCreateNestedManyWithoutAssistantInput>;
};

export type AssistantCreateWithoutDefaultForCompanyInput = {
  access?: InputMaybe<Array<Scalars['String']>>;
  alert?: InputMaybe<Scalars['String']>;
  chatUpload?: InputMaybe<ChatUpload>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  examples?: InputMaybe<Array<Scalars['String']>>;
  explanation?: InputMaybe<Scalars['String']>;
  fallbackModule: Scalars['String'];
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  inputLimit?: InputMaybe<Scalars['Int']>;
  inputPlaceholder?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  languageModel?: InputMaybe<Scalars['String']>;
  modules?: InputMaybe<ModuleCreateNestedManyWithoutAssistantInput>;
  name: Scalars['String'];
  prompts?: InputMaybe<PromptCreateNestedManyWithoutAssistantInput>;
  scopeRules?: InputMaybe<ScopeRuleCreateNestedManyWithoutAssistantInput>;
  settings?: InputMaybe<Scalars['JSON']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPrompts?: InputMaybe<UserPromptCreateNestedManyWithoutAssistantInput>;
};

export type AssistantListRelationFilter = {
  every?: InputMaybe<AssistantWhereInput>;
  none?: InputMaybe<AssistantWhereInput>;
  some?: InputMaybe<AssistantWhereInput>;
};

export type AssistantOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AssistantOrderByWithRelationInput = {
  access?: InputMaybe<SortOrder>;
  alert?: InputMaybe<SortOrder>;
  chatUpload?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  defaultForCompanyId?: InputMaybe<SortOrder>;
  examples?: InputMaybe<SortOrder>;
  explanation?: InputMaybe<SortOrder>;
  fallbackModule?: InputMaybe<SortOrder>;
  goals?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inputLimit?: InputMaybe<SortOrder>;
  inputPlaceholder?: InputMaybe<SortOrder>;
  isExternal?: InputMaybe<SortOrder>;
  languageModel?: InputMaybe<SortOrder>;
  modules?: InputMaybe<ModuleOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  prompts?: InputMaybe<PromptOrderByRelationAggregateInput>;
  scopeRules?: InputMaybe<ScopeRuleOrderByRelationAggregateInput>;
  settings?: InputMaybe<SortOrder>;
  subtitle?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userPrompts?: InputMaybe<UserPromptOrderByRelationAggregateInput>;
};

export type AssistantRelationFilter = {
  is?: InputMaybe<AssistantWhereInput>;
  isNot?: InputMaybe<AssistantWhereInput>;
};

export enum AssistantScalarFieldEnum {
  Access = 'access',
  Alert = 'alert',
  ChatUpload = 'chatUpload',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  DefaultForCompanyId = 'defaultForCompanyId',
  Examples = 'examples',
  Explanation = 'explanation',
  FallbackModule = 'fallbackModule',
  Goals = 'goals',
  Id = 'id',
  InputLimit = 'inputLimit',
  InputPlaceholder = 'inputPlaceholder',
  IsExternal = 'isExternal',
  LanguageModel = 'languageModel',
  Name = 'name',
  Settings = 'settings',
  Subtitle = 'subtitle',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export type AssistantScalarWhereInput = {
  AND?: InputMaybe<Array<AssistantScalarWhereInput>>;
  NOT?: InputMaybe<Array<AssistantScalarWhereInput>>;
  OR?: InputMaybe<Array<AssistantScalarWhereInput>>;
  access?: InputMaybe<StringNullableListFilter>;
  alert?: InputMaybe<StringNullableFilter>;
  chatUpload?: InputMaybe<EnumChatUploadFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  defaultForCompanyId?: InputMaybe<StringNullableFilter>;
  examples?: InputMaybe<StringNullableListFilter>;
  explanation?: InputMaybe<StringNullableFilter>;
  fallbackModule?: InputMaybe<StringFilter>;
  goals?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<StringFilter>;
  inputLimit?: InputMaybe<IntNullableFilter>;
  inputPlaceholder?: InputMaybe<StringNullableFilter>;
  isExternal?: InputMaybe<BoolFilter>;
  languageModel?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  settings?: InputMaybe<JsonNullableFilter>;
  subtitle?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AssistantUpdateInput = {
  access?: InputMaybe<Array<Scalars['String']>>;
  alert?: InputMaybe<Scalars['String']>;
  chatUpload?: InputMaybe<ChatUpload>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  examples?: InputMaybe<Array<Scalars['String']>>;
  explanation?: InputMaybe<Scalars['String']>;
  fallbackModule?: InputMaybe<Scalars['String']>;
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  inputLimit?: InputMaybe<Scalars['Int']>;
  inputPlaceholder?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  languageModel?: InputMaybe<Scalars['String']>;
  modules?: InputMaybe<ModuleUpdateManyWithoutAssistantNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  prompts?: InputMaybe<PromptUpdateManyWithoutAssistantNestedInput>;
  scopeRules?: InputMaybe<ScopeRuleUpdateManyWithoutAssistantNestedInput>;
  settings?: InputMaybe<Scalars['JSON']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPrompts?: InputMaybe<UserPromptUpdateManyWithoutAssistantNestedInput>;
};

export type AssistantUpdateManyMutationInput = {
  access?: InputMaybe<Array<Scalars['String']>>;
  alert?: InputMaybe<Scalars['String']>;
  chatUpload?: InputMaybe<ChatUpload>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  examples?: InputMaybe<Array<Scalars['String']>>;
  explanation?: InputMaybe<Scalars['String']>;
  fallbackModule?: InputMaybe<Scalars['String']>;
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  inputLimit?: InputMaybe<Scalars['Int']>;
  inputPlaceholder?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  languageModel?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Scalars['JSON']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AssistantUpdateManyWithWhereWithoutCompanyInput = {
  data: AssistantUpdateManyMutationInput;
  where: AssistantScalarWhereInput;
};

export type AssistantUpdateManyWithoutCompanyNestedInput = {
  connect?: InputMaybe<Array<AssistantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AssistantCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<AssistantCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<AssistantCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<AssistantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AssistantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AssistantWhereUniqueInput>>;
  set?: InputMaybe<Array<AssistantWhereUniqueInput>>;
  update?: InputMaybe<Array<AssistantUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: InputMaybe<Array<AssistantUpdateManyWithWhereWithoutCompanyInput>>;
  upsert?: InputMaybe<Array<AssistantUpsertWithWhereUniqueWithoutCompanyInput>>;
};

export type AssistantUpdateOneWithoutDefaultForCompanyNestedInput = {
  connect?: InputMaybe<AssistantWhereUniqueInput>;
  connectOrCreate?: InputMaybe<AssistantCreateOrConnectWithoutDefaultForCompanyInput>;
  create?: InputMaybe<AssistantCreateWithoutDefaultForCompanyInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<AssistantUpdateWithoutDefaultForCompanyInput>;
  upsert?: InputMaybe<AssistantUpsertWithoutDefaultForCompanyInput>;
};

export type AssistantUpdateWithWhereUniqueWithoutCompanyInput = {
  data: AssistantUpdateWithoutCompanyInput;
  where: AssistantWhereUniqueInput;
};

export type AssistantUpdateWithoutCompanyInput = {
  access?: InputMaybe<Array<Scalars['String']>>;
  alert?: InputMaybe<Scalars['String']>;
  chatUpload?: InputMaybe<ChatUpload>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  examples?: InputMaybe<Array<Scalars['String']>>;
  explanation?: InputMaybe<Scalars['String']>;
  fallbackModule?: InputMaybe<Scalars['String']>;
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  inputLimit?: InputMaybe<Scalars['Int']>;
  inputPlaceholder?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  languageModel?: InputMaybe<Scalars['String']>;
  modules?: InputMaybe<ModuleUpdateManyWithoutAssistantNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  prompts?: InputMaybe<PromptUpdateManyWithoutAssistantNestedInput>;
  scopeRules?: InputMaybe<ScopeRuleUpdateManyWithoutAssistantNestedInput>;
  settings?: InputMaybe<Scalars['JSON']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPrompts?: InputMaybe<UserPromptUpdateManyWithoutAssistantNestedInput>;
};

export type AssistantUpdateWithoutDefaultForCompanyInput = {
  access?: InputMaybe<Array<Scalars['String']>>;
  alert?: InputMaybe<Scalars['String']>;
  chatUpload?: InputMaybe<ChatUpload>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  examples?: InputMaybe<Array<Scalars['String']>>;
  explanation?: InputMaybe<Scalars['String']>;
  fallbackModule?: InputMaybe<Scalars['String']>;
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  inputLimit?: InputMaybe<Scalars['Int']>;
  inputPlaceholder?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  languageModel?: InputMaybe<Scalars['String']>;
  modules?: InputMaybe<ModuleUpdateManyWithoutAssistantNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  prompts?: InputMaybe<PromptUpdateManyWithoutAssistantNestedInput>;
  scopeRules?: InputMaybe<ScopeRuleUpdateManyWithoutAssistantNestedInput>;
  settings?: InputMaybe<Scalars['JSON']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userPrompts?: InputMaybe<UserPromptUpdateManyWithoutAssistantNestedInput>;
};

export type AssistantUpsertWithWhereUniqueWithoutCompanyInput = {
  create: AssistantCreateWithoutCompanyInput;
  update: AssistantUpdateWithoutCompanyInput;
  where: AssistantWhereUniqueInput;
};

export type AssistantUpsertWithoutDefaultForCompanyInput = {
  create: AssistantCreateWithoutDefaultForCompanyInput;
  update: AssistantUpdateWithoutDefaultForCompanyInput;
};

export type AssistantWhereInput = {
  AND?: InputMaybe<Array<AssistantWhereInput>>;
  NOT?: InputMaybe<Array<AssistantWhereInput>>;
  OR?: InputMaybe<Array<AssistantWhereInput>>;
  access?: InputMaybe<StringNullableListFilter>;
  alert?: InputMaybe<StringNullableFilter>;
  chatUpload?: InputMaybe<EnumChatUploadFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  defaultForCompanyId?: InputMaybe<StringNullableFilter>;
  examples?: InputMaybe<StringNullableListFilter>;
  explanation?: InputMaybe<StringNullableFilter>;
  fallbackModule?: InputMaybe<StringFilter>;
  goals?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<StringFilter>;
  inputLimit?: InputMaybe<IntNullableFilter>;
  inputPlaceholder?: InputMaybe<StringNullableFilter>;
  isExternal?: InputMaybe<BoolFilter>;
  languageModel?: InputMaybe<StringNullableFilter>;
  modules?: InputMaybe<ModuleListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  prompts?: InputMaybe<PromptListRelationFilter>;
  scopeRules?: InputMaybe<ScopeRuleListRelationFilter>;
  settings?: InputMaybe<JsonNullableFilter>;
  subtitle?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userPrompts?: InputMaybe<UserPromptListRelationFilter>;
};

export type AssistantWhereUniqueInput = {
  defaultForCompanyId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type Benchmark = {
  __typename?: 'Benchmark';
  BenchmarkEntry?: Maybe<Array<BenchmarkEntry>>;
  _count: BenchmarkCount;
  abortedAt?: Maybe<Scalars['DateTime']>;
  benchmarkContext?: Maybe<Scalars['JSON']>;
  company: Company;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  input: Scalars['JSON'];
  lastHeartbeatAt?: Maybe<Scalars['DateTime']>;
  output: Scalars['JSON'];
  status: BenchmarkStatus;
  updatedAt: Scalars['DateTime'];
};

export type BenchmarkCount = {
  __typename?: 'BenchmarkCount';
  BenchmarkEntry: Scalars['Int'];
};

export type BenchmarkCreateManyCompanyInput = {
  abortedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  input: Scalars['JSON'];
  lastHeartbeatAt?: InputMaybe<Scalars['DateTime']>;
  output: Scalars['JSON'];
  status?: InputMaybe<BenchmarkStatus>;
};

export type BenchmarkCreateManyCompanyInputEnvelope = {
  data: Array<BenchmarkCreateManyCompanyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BenchmarkCreateNestedManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<BenchmarkWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenchmarkCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<BenchmarkCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<BenchmarkCreateManyCompanyInputEnvelope>;
};

export type BenchmarkCreateOrConnectWithoutCompanyInput = {
  create: BenchmarkCreateWithoutCompanyInput;
  where: BenchmarkWhereUniqueInput;
};

export type BenchmarkCreateWithoutCompanyInput = {
  abortedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  input: Scalars['JSON'];
  lastHeartbeatAt?: InputMaybe<Scalars['DateTime']>;
  output: Scalars['JSON'];
  status?: InputMaybe<BenchmarkStatus>;
};

export type BenchmarkEntry = {
  __typename?: 'BenchmarkEntry';
  benchmarkId: Scalars['String'];
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  requeued: Scalars['Int'];
  status: BenchmarkEntryStatus;
  updatedAt: Scalars['DateTime'];
};

export enum BenchmarkEntryStatus {
  Done = 'DONE',
  Error = 'ERROR',
  Queued = 'QUEUED',
  ReQueued = 'RE_QUEUED',
  Running = 'RUNNING'
}

export type BenchmarkListRelationFilter = {
  every?: InputMaybe<BenchmarkWhereInput>;
  none?: InputMaybe<BenchmarkWhereInput>;
  some?: InputMaybe<BenchmarkWhereInput>;
};

export type BenchmarkOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BenchmarkScalarWhereInput = {
  AND?: InputMaybe<Array<BenchmarkScalarWhereInput>>;
  NOT?: InputMaybe<Array<BenchmarkScalarWhereInput>>;
  OR?: InputMaybe<Array<BenchmarkScalarWhereInput>>;
  abortedAt?: InputMaybe<DateTimeNullableFilter>;
  companyId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  input?: InputMaybe<JsonFilter>;
  lastHeartbeatAt?: InputMaybe<DateTimeNullableFilter>;
  output?: InputMaybe<JsonFilter>;
  status?: InputMaybe<EnumBenchmarkStatusFilter>;
};

export enum BenchmarkStatus {
  Done = 'DONE',
  Error = 'ERROR',
  NotAvailable = 'NOT_AVAILABLE',
  Pending = 'PENDING',
  Running = 'RUNNING'
}

export type BenchmarkStatusResponse = {
  __typename?: 'BenchmarkStatusResponse';
  benchmarkId?: Maybe<Scalars['String']>;
  done: Scalars['Float'];
  error: Scalars['Float'];
  filename: Scalars['String'];
  status: Scalars['String'];
  total: Scalars['Float'];
};

export type BenchmarkUpdateManyMutationInput = {
  abortedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<Scalars['JSON']>;
  lastHeartbeatAt?: InputMaybe<Scalars['DateTime']>;
  output?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<BenchmarkStatus>;
};

export type BenchmarkUpdateManyWithWhereWithoutCompanyInput = {
  data: BenchmarkUpdateManyMutationInput;
  where: BenchmarkScalarWhereInput;
};

export type BenchmarkUpdateManyWithoutCompanyNestedInput = {
  connect?: InputMaybe<Array<BenchmarkWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BenchmarkCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<BenchmarkCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<BenchmarkCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<BenchmarkWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BenchmarkScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BenchmarkWhereUniqueInput>>;
  set?: InputMaybe<Array<BenchmarkWhereUniqueInput>>;
  update?: InputMaybe<Array<BenchmarkUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: InputMaybe<Array<BenchmarkUpdateManyWithWhereWithoutCompanyInput>>;
  upsert?: InputMaybe<Array<BenchmarkUpsertWithWhereUniqueWithoutCompanyInput>>;
};

export type BenchmarkUpdateWithWhereUniqueWithoutCompanyInput = {
  data: BenchmarkUpdateWithoutCompanyInput;
  where: BenchmarkWhereUniqueInput;
};

export type BenchmarkUpdateWithoutCompanyInput = {
  abortedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<Scalars['JSON']>;
  lastHeartbeatAt?: InputMaybe<Scalars['DateTime']>;
  output?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<BenchmarkStatus>;
};

export type BenchmarkUpsertWithWhereUniqueWithoutCompanyInput = {
  create: BenchmarkCreateWithoutCompanyInput;
  update: BenchmarkUpdateWithoutCompanyInput;
  where: BenchmarkWhereUniqueInput;
};

export type BenchmarkWhereInput = {
  AND?: InputMaybe<Array<BenchmarkWhereInput>>;
  NOT?: InputMaybe<Array<BenchmarkWhereInput>>;
  OR?: InputMaybe<Array<BenchmarkWhereInput>>;
  abortedAt?: InputMaybe<DateTimeNullableFilter>;
  company?: InputMaybe<CompanyRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  input?: InputMaybe<JsonFilter>;
  lastHeartbeatAt?: InputMaybe<DateTimeNullableFilter>;
  output?: InputMaybe<JsonFilter>;
  status?: InputMaybe<EnumBenchmarkStatusFilter>;
};

export type BenchmarkWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type Chat = {
  __typename?: 'Chat';
  MagicTableSheet?: Maybe<Array<MagicTableSheet>>;
  ShortTermMemory?: Maybe<Array<ShortTermMemory>>;
  _count: ChatCount;
  assistant?: Maybe<Assistant>;
  assistantId?: Maybe<Scalars['String']>;
  benchmarkEntries?: Maybe<Array<BenchmarkEntry>>;
  companyId: Scalars['String'];
  context: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deleted: Scalars['Boolean'];
  /** @DtoReadOnly */
  expiredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  messages?: Maybe<Array<Message>>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type ChatCount = {
  __typename?: 'ChatCount';
  MagicTableSheet: Scalars['Int'];
  ShortTermMemory: Scalars['Int'];
  benchmarkEntries: Scalars['Int'];
  messages: Scalars['Int'];
};

export type ChatOrderByWithRelationInput = {
  MagicTableSheet?: InputMaybe<MagicTableSheetOrderByRelationAggregateInput>;
  assistant?: InputMaybe<AssistantOrderByWithRelationInput>;
  companyId?: InputMaybe<SortOrder>;
  context?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deleted?: InputMaybe<SortOrder>;
  expiredAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  messages?: InputMaybe<MessageOrderByRelationAggregateInput>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ChatRelationFilter = {
  is?: InputMaybe<ChatWhereInput>;
  isNot?: InputMaybe<ChatWhereInput>;
};

export enum ChatScalarFieldEnum {
  AssistantId = 'assistantId',
  CompanyId = 'companyId',
  Context = 'context',
  CreatedAt = 'createdAt',
  Deleted = 'deleted',
  ExpiredAt = 'expiredAt',
  Id = 'id',
  Title = 'title',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export enum ChatUpload {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type ChatWhereInput = {
  AND?: InputMaybe<Array<ChatWhereInput>>;
  MagicTableSheet?: InputMaybe<MagicTableSheetListRelationFilter>;
  NOT?: InputMaybe<Array<ChatWhereInput>>;
  OR?: InputMaybe<Array<ChatWhereInput>>;
  assistant?: InputMaybe<AssistantRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  context?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deleted?: InputMaybe<BoolFilter>;
  expiredAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  messages?: InputMaybe<MessageListRelationFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ChatWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Chunk = {
  __typename?: 'Chunk';
  /** @DtoReadOnly */
  contentId: Scalars['String'];
  /** @DtoReadOnly */
  createdAt: Scalars['DateTime'];
  embedding?: Maybe<Scalars['JSON']>;
  embeddingsFirst10: Array<Scalars['Float']>;
  endPage?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  model?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  startPage?: Maybe<Scalars['Int']>;
  /** @DtoReadOnly */
  text: Scalars['String'];
  /** @DtoReadOnly */
  updatedAt: Scalars['DateTime'];
  /**
   * @DtoReadOnly
   * @DtoEntityHidden
   */
  vectorId: Scalars['String'];
};

export type ChunkCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  embedding?: InputMaybe<Scalars['JSON']>;
  endPage?: InputMaybe<Scalars['Int']>;
  fingerprint: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  startPage?: InputMaybe<Scalars['Int']>;
  text: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChunkOrderByWithRelationInput = {
  contentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  embedding?: InputMaybe<SortOrder>;
  endPage?: InputMaybe<SortOrder>;
  fingerprint?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  startPage?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ChunkScalarFieldEnum {
  ContentId = 'contentId',
  CreatedAt = 'createdAt',
  Embedding = 'embedding',
  EndPage = 'endPage',
  Fingerprint = 'fingerprint',
  Id = 'id',
  Model = 'model',
  Order = 'order',
  StartPage = 'startPage',
  Text = 'text',
  UpdatedAt = 'updatedAt',
  VectorId = 'vectorId'
}

export type ChunkUpdateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  embedding?: InputMaybe<Scalars['JSON']>;
  endPage?: InputMaybe<Scalars['Int']>;
  fingerprint?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  startPage?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ChunkWhereInput = {
  AND?: InputMaybe<Array<ChunkWhereInput>>;
  NOT?: InputMaybe<Array<ChunkWhereInput>>;
  OR?: InputMaybe<Array<ChunkWhereInput>>;
  contentId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  embedding?: InputMaybe<JsonNullableFilter>;
  endPage?: InputMaybe<IntNullableFilter>;
  fingerprint?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  model?: InputMaybe<StringNullableFilter>;
  order?: InputMaybe<IntNullableFilter>;
  startPage?: InputMaybe<IntNullableFilter>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ChunkWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Color = {
  __typename?: 'Color';
  createdAt?: Maybe<Scalars['DateTime']>;
  hexValue: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  theme?: Maybe<Theme>;
  themeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColorCreateManyThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  hexValue: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ColorCreateManyThemeInputEnvelope = {
  data: Array<ColorCreateManyThemeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ColorCreateNestedManyWithoutThemeInput = {
  connect?: InputMaybe<Array<ColorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ColorCreateOrConnectWithoutThemeInput>>;
  create?: InputMaybe<Array<ColorCreateWithoutThemeInput>>;
  createMany?: InputMaybe<ColorCreateManyThemeInputEnvelope>;
};

export type ColorCreateOrConnectWithoutThemeInput = {
  create: ColorCreateWithoutThemeInput;
  where: ColorWhereUniqueInput;
};

export type ColorCreateWithoutThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  hexValue: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ColorScalarWhereInput = {
  AND?: InputMaybe<Array<ColorScalarWhereInput>>;
  NOT?: InputMaybe<Array<ColorScalarWhereInput>>;
  OR?: InputMaybe<Array<ColorScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  hexValue?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  themeId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type ColorUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  hexValue?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ColorUpdateManyWithWhereWithoutThemeInput = {
  data: ColorUpdateManyMutationInput;
  where: ColorScalarWhereInput;
};

export type ColorUpdateManyWithoutThemeNestedInput = {
  connect?: InputMaybe<Array<ColorWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ColorCreateOrConnectWithoutThemeInput>>;
  create?: InputMaybe<Array<ColorCreateWithoutThemeInput>>;
  createMany?: InputMaybe<ColorCreateManyThemeInputEnvelope>;
  delete?: InputMaybe<Array<ColorWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ColorScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ColorWhereUniqueInput>>;
  set?: InputMaybe<Array<ColorWhereUniqueInput>>;
  update?: InputMaybe<Array<ColorUpdateWithWhereUniqueWithoutThemeInput>>;
  updateMany?: InputMaybe<Array<ColorUpdateManyWithWhereWithoutThemeInput>>;
  upsert?: InputMaybe<Array<ColorUpsertWithWhereUniqueWithoutThemeInput>>;
};

export type ColorUpdateWithWhereUniqueWithoutThemeInput = {
  data: ColorUpdateWithoutThemeInput;
  where: ColorWhereUniqueInput;
};

export type ColorUpdateWithoutThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  hexValue?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ColorUpsertWithWhereUniqueWithoutThemeInput = {
  create: ColorCreateWithoutThemeInput;
  update: ColorUpdateWithoutThemeInput;
  where: ColorWhereUniqueInput;
};

export type ColorWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Company = {
  __typename?: 'Company';
  Benchmark?: Maybe<Array<Benchmark>>;
  ModuleTemplate?: Maybe<Array<ModuleTemplate>>;
  TermsAndConditions?: Maybe<Array<TermsAndConditions>>;
  _count: CompanyCount;
  acronyms?: Maybe<Scalars['JSON']>;
  assistants?: Maybe<Array<Assistant>>;
  configuration: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  defaultAssistant?: Maybe<Assistant>;
  id: Scalars['ID'];
  theme?: Maybe<Theme>;
  themeId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  users?: Maybe<Array<User>>;
};

export type CompanyCount = {
  __typename?: 'CompanyCount';
  Benchmark: Scalars['Int'];
  ModuleTemplate: Scalars['Int'];
  TermsAndConditions: Scalars['Int'];
  assistants: Scalars['Int'];
  users: Scalars['Int'];
};

export type CompanyCreateManyThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyCreateManyThemeInputEnvelope = {
  data: Array<CompanyCreateManyThemeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyCreateNestedManyWithoutThemeInput = {
  connect?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CompanyCreateOrConnectWithoutThemeInput>>;
  create?: InputMaybe<Array<CompanyCreateWithoutThemeInput>>;
  createMany?: InputMaybe<CompanyCreateManyThemeInputEnvelope>;
};

export type CompanyCreateNestedOneWithoutModuleTemplateInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutModuleTemplateInput>;
  create?: InputMaybe<CompanyCreateWithoutModuleTemplateInput>;
};

export type CompanyCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<CompanyCreateWithoutUsersInput>;
};

export type CompanyCreateOrConnectWithoutModuleTemplateInput = {
  create: CompanyCreateWithoutModuleTemplateInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateOrConnectWithoutThemeInput = {
  create: CompanyCreateWithoutThemeInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateOrConnectWithoutUsersInput = {
  create: CompanyCreateWithoutUsersInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyCreateWithoutModuleTemplateInput = {
  Benchmark?: InputMaybe<BenchmarkCreateNestedManyWithoutCompanyInput>;
  TermsAndConditions?: InputMaybe<TermsAndConditionsCreateNestedManyWithoutCompanyInput>;
  acronyms?: InputMaybe<Scalars['JSON']>;
  assistants?: InputMaybe<AssistantCreateNestedManyWithoutCompanyInput>;
  configuration?: InputMaybe<Scalars['JSON']>;
  defaultAssistant?: InputMaybe<AssistantCreateNestedOneWithoutDefaultForCompanyInput>;
  id: Scalars['String'];
};

export type CompanyCreateWithoutThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutCompanyInput>;
};

export type CompanyCreateWithoutUsersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<ThemeCreateNestedOneWithoutCompanyInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyMeta = {
  __typename?: 'CompanyMeta';
  collectionName: Scalars['String'];
  companyId: Scalars['ID'];
  embeddingModel?: Maybe<Scalars['String']>;
};

export type CompanyOrderByWithRelationInput = {
  Benchmark?: InputMaybe<BenchmarkOrderByRelationAggregateInput>;
  ModuleTemplate?: InputMaybe<ModuleTemplateOrderByRelationAggregateInput>;
  TermsAndConditions?: InputMaybe<TermsAndConditionsOrderByRelationAggregateInput>;
  acronyms?: InputMaybe<SortOrder>;
  assistants?: InputMaybe<AssistantOrderByRelationAggregateInput>;
  configuration?: InputMaybe<SortOrder>;
  defaultAssistant?: InputMaybe<AssistantOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
};

export type CompanyRelationFilter = {
  is?: InputMaybe<CompanyWhereInput>;
  isNot?: InputMaybe<CompanyWhereInput>;
};

export type CompanyScalarWhereInput = {
  AND?: InputMaybe<Array<CompanyScalarWhereInput>>;
  NOT?: InputMaybe<Array<CompanyScalarWhereInput>>;
  OR?: InputMaybe<Array<CompanyScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  themeId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type CompanyUpdateInput = {
  Benchmark?: InputMaybe<BenchmarkUpdateManyWithoutCompanyNestedInput>;
  ModuleTemplate?: InputMaybe<ModuleTemplateUpdateManyWithoutCompanyNestedInput>;
  TermsAndConditions?: InputMaybe<TermsAndConditionsUpdateManyWithoutCompanyNestedInput>;
  acronyms?: InputMaybe<Scalars['JSON']>;
  assistants?: InputMaybe<AssistantUpdateManyWithoutCompanyNestedInput>;
  configuration?: InputMaybe<Scalars['JSON']>;
  defaultAssistant?: InputMaybe<AssistantUpdateOneWithoutDefaultForCompanyNestedInput>;
  id?: InputMaybe<Scalars['String']>;
};

export type CompanyUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyUpdateManyWithWhereWithoutThemeInput = {
  data: CompanyUpdateManyMutationInput;
  where: CompanyScalarWhereInput;
};

export type CompanyUpdateManyWithoutThemeNestedInput = {
  connect?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CompanyCreateOrConnectWithoutThemeInput>>;
  create?: InputMaybe<Array<CompanyCreateWithoutThemeInput>>;
  createMany?: InputMaybe<CompanyCreateManyThemeInputEnvelope>;
  delete?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CompanyScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  set?: InputMaybe<Array<CompanyWhereUniqueInput>>;
  update?: InputMaybe<Array<CompanyUpdateWithWhereUniqueWithoutThemeInput>>;
  updateMany?: InputMaybe<Array<CompanyUpdateManyWithWhereWithoutThemeInput>>;
  upsert?: InputMaybe<Array<CompanyUpsertWithWhereUniqueWithoutThemeInput>>;
};

export type CompanyUpdateOneRequiredWithoutModuleTemplateNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutModuleTemplateInput>;
  create?: InputMaybe<CompanyCreateWithoutModuleTemplateInput>;
  update?: InputMaybe<CompanyUpdateWithoutModuleTemplateInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutModuleTemplateInput>;
};

export type CompanyUpdateOneRequiredWithoutUsersNestedInput = {
  connect?: InputMaybe<CompanyWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CompanyCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<CompanyCreateWithoutUsersInput>;
  update?: InputMaybe<CompanyUpdateWithoutUsersInput>;
  upsert?: InputMaybe<CompanyUpsertWithoutUsersInput>;
};

export type CompanyUpdateWithWhereUniqueWithoutThemeInput = {
  data: CompanyUpdateWithoutThemeInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyUpdateWithoutModuleTemplateInput = {
  Benchmark?: InputMaybe<BenchmarkUpdateManyWithoutCompanyNestedInput>;
  TermsAndConditions?: InputMaybe<TermsAndConditionsUpdateManyWithoutCompanyNestedInput>;
  acronyms?: InputMaybe<Scalars['JSON']>;
  assistants?: InputMaybe<AssistantUpdateManyWithoutCompanyNestedInput>;
  configuration?: InputMaybe<Scalars['JSON']>;
  defaultAssistant?: InputMaybe<AssistantUpdateOneWithoutDefaultForCompanyNestedInput>;
  id?: InputMaybe<Scalars['String']>;
};

export type CompanyUpdateWithoutThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserUpdateManyWithoutCompanyNestedInput>;
};

export type CompanyUpdateWithoutUsersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<ThemeUpdateOneWithoutCompanyNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyUpsertWithWhereUniqueWithoutThemeInput = {
  create: CompanyCreateWithoutThemeInput;
  update: CompanyUpdateWithoutThemeInput;
  where: CompanyWhereUniqueInput;
};

export type CompanyUpsertWithoutModuleTemplateInput = {
  create: CompanyCreateWithoutModuleTemplateInput;
  update: CompanyUpdateWithoutModuleTemplateInput;
};

export type CompanyUpsertWithoutUsersInput = {
  create: CompanyCreateWithoutUsersInput;
  update: CompanyUpdateWithoutUsersInput;
};

export type CompanyWhereInput = {
  AND?: InputMaybe<Array<CompanyWhereInput>>;
  Benchmark?: InputMaybe<BenchmarkListRelationFilter>;
  ModuleTemplate?: InputMaybe<ModuleTemplateListRelationFilter>;
  NOT?: InputMaybe<Array<CompanyWhereInput>>;
  OR?: InputMaybe<Array<CompanyWhereInput>>;
  TermsAndConditions?: InputMaybe<TermsAndConditionsListRelationFilter>;
  acronyms?: InputMaybe<JsonNullableFilter>;
  assistants?: InputMaybe<AssistantListRelationFilter>;
  configuration?: InputMaybe<JsonFilter>;
  defaultAssistant?: InputMaybe<AssistantRelationFilter>;
  id?: InputMaybe<StringFilter>;
};

export type CompanyWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Content = {
  __typename?: 'Content';
  _count: ContentCount;
  appliedIngestionConfig?: Maybe<Scalars['JSON']>;
  byteSize: Scalars['Int'];
  /**
   * @DtoReadOnly
   * @DtoEntityHidden
   */
  chunks?: Maybe<Array<Chunk>>;
  /** @DtoReadOnly */
  createdAt: Scalars['DateTime'];
  /** @DtoReadOnly */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** @DtoReadOnly */
  expiredAt?: Maybe<Scalars['DateTime']>;
  /** @DtoReadOnly */
  expiresAt?: Maybe<Scalars['DateTime']>;
  expiresInDays?: Maybe<Scalars['Float']>;
  externalFileOwner?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  ingestionConfig?: Maybe<Scalars['JSON']>;
  ingestionProgress: Scalars['Int'];
  /** @DtoEntityHidden */
  ingestionState: IngestionState;
  /** @DtoEntityHidden */
  ingestionStateUpdatedAt?: Maybe<Scalars['DateTime']>;
  internallyStoredAt?: Maybe<Scalars['DateTime']>;
  key: Scalars['String'];
  /** @IsJSON */
  metadata?: Maybe<Scalars['JSON']>;
  mimeType: Scalars['String'];
  /** @DtoReadOnly */
  ownerId: Scalars['String'];
  ownerType: OwnerType;
  pdfPreviewWriteUrl: Scalars['String'];
  previewPdfFileName?: Maybe<Scalars['String']>;
  readUrl: Scalars['String'];
  sasUrl: Scalars['String'];
  /**
   * @DtoReadOnly
   * @DtoEntityHidden
   */
  source: Source;
  title?: Maybe<Scalars['String']>;
  /** @DtoReadOnly */
  updatedAt: Scalars['DateTime'];
  /** @IsUrl */
  url?: Maybe<Scalars['String']>;
  writeUrl: Scalars['String'];
};

export type ContentCount = {
  __typename?: 'ContentCount';
  chunks: Scalars['Int'];
};

export type ContentCreateInput = {
  byteSize?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  externalFileOwner?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  ingestionConfig?: InputMaybe<Scalars['JSON']>;
  ingestionProgress?: InputMaybe<Scalars['Int']>;
  internallyStoredAt?: InputMaybe<Scalars['DateTime']>;
  key: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSON']>;
  mimeType: Scalars['String'];
  ownerType: OwnerType;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type ContentOrderByWithRelationInput = {
  byteSize?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  expiredAt?: InputMaybe<SortOrder>;
  expiresAt?: InputMaybe<SortOrder>;
  externalFileOwner?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ingestionConfig?: InputMaybe<SortOrder>;
  ingestionProgress?: InputMaybe<SortOrder>;
  internallyStoredAt?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  metadata?: InputMaybe<SortOrder>;
  mimeType?: InputMaybe<SortOrder>;
  ownerType?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export enum ContentScalarFieldEnum {
  AppliedIngestionConfig = 'appliedIngestionConfig',
  ByteSize = 'byteSize',
  CollectionName = 'collectionName',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  ExpiredAt = 'expiredAt',
  ExpiresAt = 'expiresAt',
  ExternalFileOwner = 'externalFileOwner',
  Id = 'id',
  IngestionConfig = 'ingestionConfig',
  IngestionProgress = 'ingestionProgress',
  IngestionState = 'ingestionState',
  IngestionStateUpdatedAt = 'ingestionStateUpdatedAt',
  InternallyStoredAt = 'internallyStoredAt',
  Key = 'key',
  Metadata = 'metadata',
  MimeType = 'mimeType',
  OwnerId = 'ownerId',
  OwnerType = 'ownerType',
  PreviewPdfFileName = 'previewPdfFileName',
  SourceId = 'sourceId',
  Title = 'title',
  UpdatedAt = 'updatedAt',
  Url = 'url',
  Version = 'version'
}

export type ContentUpdateInput = {
  byteSize?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  externalFileOwner?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  ingestionConfig?: InputMaybe<Scalars['JSON']>;
  ingestionProgress?: InputMaybe<Scalars['Int']>;
  internallyStoredAt?: InputMaybe<Scalars['DateTime']>;
  key?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  mimeType?: InputMaybe<Scalars['String']>;
  ownerType?: InputMaybe<OwnerType>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type ContentWhereInput = {
  AND?: InputMaybe<Array<ContentWhereInput>>;
  NOT?: InputMaybe<Array<ContentWhereInput>>;
  OR?: InputMaybe<Array<ContentWhereInput>>;
  byteSize?: InputMaybe<IntFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  expiredAt?: InputMaybe<DateTimeNullableFilter>;
  expiresAt?: InputMaybe<DateTimeNullableFilter>;
  externalFileOwner?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  ingestionConfig?: InputMaybe<JsonNullableFilter>;
  ingestionProgress?: InputMaybe<IntFilter>;
  ingestionState?: InputMaybe<EnumIngestionStateFilter>;
  ingestionStateUpdatedAt?: InputMaybe<DateTimeNullableFilter>;
  internallyStoredAt?: InputMaybe<DateTimeNullableFilter>;
  key?: InputMaybe<StringFilter>;
  metadata?: InputMaybe<JsonNullableFilter>;
  mimeType?: InputMaybe<StringFilter>;
  ownerId?: InputMaybe<StringFilter>;
  ownerType?: InputMaybe<EnumOwnerTypeFilter>;
  source?: InputMaybe<SourceRelationFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type ContentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DueDiligence = {
  __typename?: 'DueDiligence';
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  /** @DtoReadOnly */
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  magicTableSheet: MagicTableSheet;
  magicTableSheetId: Scalars['String'];
  questionFileIds?: Maybe<Array<Scalars['String']>>;
  questionTexts?: Maybe<Array<Scalars['String']>>;
  sourceFileIds?: Maybe<Array<Scalars['String']>>;
  updatedAt: Scalars['DateTime'];
};

export type DueDiligenceActionResponse = {
  __typename?: 'DueDiligenceActionResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type DueDiligenceOrderByWithRelationInput = {
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  magicTableSheet?: InputMaybe<MagicTableSheetOrderByWithRelationInput>;
  magicTableSheetId?: InputMaybe<SortOrder>;
  questionFileIds?: InputMaybe<SortOrder>;
  questionTexts?: InputMaybe<SortOrder>;
  sourceFileIds?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DueDiligenceRelationFilter = {
  is?: InputMaybe<DueDiligenceWhereInput>;
  isNot?: InputMaybe<DueDiligenceWhereInput>;
};

export enum DueDiligenceScalarFieldEnum {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  DeletedAt = 'deletedAt',
  Id = 'id',
  MagicTableSheetId = 'magicTableSheetId',
  QuestionFileIds = 'questionFileIds',
  QuestionTexts = 'questionTexts',
  SourceFileIds = 'sourceFileIds',
  UpdatedAt = 'updatedAt'
}

export type DueDiligenceWhereInput = {
  AND?: InputMaybe<Array<DueDiligenceWhereInput>>;
  NOT?: InputMaybe<Array<DueDiligenceWhereInput>>;
  OR?: InputMaybe<Array<DueDiligenceWhereInput>>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  magicTableSheet?: InputMaybe<MagicTableSheetRelationFilter>;
  magicTableSheetId?: InputMaybe<StringFilter>;
  questionFileIds?: InputMaybe<StringNullableListFilter>;
  questionTexts?: InputMaybe<StringNullableListFilter>;
  sourceFileIds?: InputMaybe<StringNullableListFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DueDiligenceWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  magicTableSheetId?: InputMaybe<Scalars['String']>;
};

export type EnumAnalyticsOrderStateFilter = {
  equals?: InputMaybe<AnalyticsOrderState>;
  in?: InputMaybe<Array<AnalyticsOrderState>>;
  not?: InputMaybe<NestedEnumAnalyticsOrderStateFilter>;
  notIn?: InputMaybe<Array<AnalyticsOrderState>>;
};

export type EnumAnalyticsTypeFilter = {
  equals?: InputMaybe<AnalyticsType>;
  in?: InputMaybe<Array<AnalyticsType>>;
  not?: InputMaybe<NestedEnumAnalyticsTypeFilter>;
  notIn?: InputMaybe<Array<AnalyticsType>>;
};

export type EnumBenchmarkStatusFilter = {
  equals?: InputMaybe<BenchmarkStatus>;
  in?: InputMaybe<Array<BenchmarkStatus>>;
  not?: InputMaybe<NestedEnumBenchmarkStatusFilter>;
  notIn?: InputMaybe<Array<BenchmarkStatus>>;
};

export type EnumChatUploadFilter = {
  equals?: InputMaybe<ChatUpload>;
  in?: InputMaybe<Array<ChatUpload>>;
  not?: InputMaybe<NestedEnumChatUploadFilter>;
  notIn?: InputMaybe<Array<ChatUpload>>;
};

export type EnumIngestionStateFilter = {
  equals?: InputMaybe<IngestionState>;
  in?: InputMaybe<Array<IngestionState>>;
  not?: InputMaybe<NestedEnumIngestionStateFilter>;
  notIn?: InputMaybe<Array<IngestionState>>;
};

export type EnumMagicTableActorTypeFilter = {
  equals?: InputMaybe<MagicTableActorType>;
  in?: InputMaybe<Array<MagicTableActorType>>;
  not?: InputMaybe<NestedEnumMagicTableActorTypeFilter>;
  notIn?: InputMaybe<Array<MagicTableActorType>>;
};

export type EnumNotificationBannerTypeFilter = {
  equals?: InputMaybe<NotificationBannerType>;
  in?: InputMaybe<Array<NotificationBannerType>>;
  not?: InputMaybe<NestedEnumNotificationBannerTypeFilter>;
  notIn?: InputMaybe<Array<NotificationBannerType>>;
};

export type EnumOwnerTypeFilter = {
  equals?: InputMaybe<OwnerType>;
  in?: InputMaybe<Array<OwnerType>>;
  not?: InputMaybe<NestedEnumOwnerTypeFilter>;
  notIn?: InputMaybe<Array<OwnerType>>;
};

export type EnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type EnumSourceKindFilter = {
  equals?: InputMaybe<SourceKind>;
  in?: InputMaybe<Array<SourceKind>>;
  not?: InputMaybe<NestedEnumSourceKindFilter>;
  notIn?: InputMaybe<Array<SourceKind>>;
};

export type ExternalLanguageModel = {
  __typename?: 'ExternalLanguageModel';
  _count: ExternalLanguageModelCount;
  apiVersion: Scalars['String'];
  authHeaderName?: Maybe<Scalars['String']>;
  authHeaderPrefix?: Maybe<Scalars['String']>;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deploymentName: Scalars['String'];
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  internalDescription: Scalars['String'];
  languageModelGroups?: Maybe<Array<LanguageModelGroup>>;
  maxTokens: Scalars['Int'];
  model: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  version: Scalars['String'];
};

export type ExternalLanguageModelCount = {
  __typename?: 'ExternalLanguageModelCount';
  languageModelGroups: Scalars['Int'];
};

export type ExternalLanguageModelCreateInput = {
  apiVersion: Scalars['String'];
  authHeaderName?: InputMaybe<Scalars['String']>;
  authHeaderPrefix?: InputMaybe<Scalars['String']>;
  authHeaderValue?: InputMaybe<Scalars['String']>;
  deploymentName: Scalars['String'];
  endpoint: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  internalDescription: Scalars['String'];
  maxTokens: Scalars['Int'];
  model: Scalars['String'];
  version: Scalars['String'];
};

export type ExternalLanguageModelOrderByWithRelationInput = {
  apiVersion?: InputMaybe<SortOrder>;
  authHeaderName?: InputMaybe<SortOrder>;
  authHeaderPrefix?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deploymentName?: InputMaybe<SortOrder>;
  endpoint?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  internalDescription?: InputMaybe<SortOrder>;
  maxTokens?: InputMaybe<SortOrder>;
  model?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  version?: InputMaybe<SortOrder>;
};

export enum ExternalLanguageModelScalarFieldEnum {
  ApiVersion = 'apiVersion',
  AuthHeaderName = 'authHeaderName',
  AuthHeaderPrefix = 'authHeaderPrefix',
  AuthHeaderValue = 'authHeaderValue',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  DeploymentName = 'deploymentName',
  Endpoint = 'endpoint',
  Id = 'id',
  InternalDescription = 'internalDescription',
  MaxTokens = 'maxTokens',
  Model = 'model',
  UpdatedAt = 'updatedAt',
  Version = 'version'
}

export type ExternalLanguageModelUpdateInput = {
  apiVersion?: InputMaybe<Scalars['String']>;
  authHeaderName?: InputMaybe<Scalars['String']>;
  authHeaderPrefix?: InputMaybe<Scalars['String']>;
  authHeaderValue?: InputMaybe<Scalars['String']>;
  deploymentName?: InputMaybe<Scalars['String']>;
  endpoint?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  internalDescription?: InputMaybe<Scalars['String']>;
  maxTokens?: InputMaybe<Scalars['Int']>;
  model?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
};

export type ExternalLanguageModelWhereInput = {
  AND?: InputMaybe<Array<ExternalLanguageModelWhereInput>>;
  NOT?: InputMaybe<Array<ExternalLanguageModelWhereInput>>;
  OR?: InputMaybe<Array<ExternalLanguageModelWhereInput>>;
  apiVersion?: InputMaybe<StringFilter>;
  authHeaderName?: InputMaybe<StringNullableFilter>;
  authHeaderPrefix?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deploymentName?: InputMaybe<StringFilter>;
  endpoint?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  internalDescription?: InputMaybe<StringFilter>;
  maxTokens?: InputMaybe<IntFilter>;
  model?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  version?: InputMaybe<StringFilter>;
};

export type ExternalLanguageModelWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Feedback = {
  __typename?: 'Feedback';
  additionalInfo?: Maybe<Scalars['JSON']>;
  /** @DtoReadOnly */
  expiredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  message: Message;
  messageId: Scalars['String'];
  positive: Scalars['Boolean'];
  text?: Maybe<Scalars['String']>;
};

export type FeedbackCreateNestedOneWithoutMessageInput = {
  connect?: InputMaybe<FeedbackWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FeedbackCreateOrConnectWithoutMessageInput>;
  create?: InputMaybe<FeedbackCreateWithoutMessageInput>;
};

export type FeedbackCreateOrConnectWithoutMessageInput = {
  create: FeedbackCreateWithoutMessageInput;
  where: FeedbackWhereUniqueInput;
};

export type FeedbackCreateWithoutMessageInput = {
  additionalInfo?: InputMaybe<Scalars['JSON']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  positive?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
};

export type FeedbackOrderByWithRelationInput = {
  additionalInfo?: InputMaybe<SortOrder>;
  expiredAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<MessageOrderByWithRelationInput>;
  messageId?: InputMaybe<SortOrder>;
  positive?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
};

export type FeedbackRelationFilter = {
  is?: InputMaybe<FeedbackWhereInput>;
  isNot?: InputMaybe<FeedbackWhereInput>;
};

export type FeedbackUpdateOneWithoutMessageNestedInput = {
  connect?: InputMaybe<FeedbackWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FeedbackCreateOrConnectWithoutMessageInput>;
  create?: InputMaybe<FeedbackCreateWithoutMessageInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<FeedbackUpdateWithoutMessageInput>;
  upsert?: InputMaybe<FeedbackUpsertWithoutMessageInput>;
};

export type FeedbackUpdateWithoutMessageInput = {
  additionalInfo?: InputMaybe<Scalars['JSON']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  positive?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
};

export type FeedbackUpsertWithoutMessageInput = {
  create: FeedbackCreateWithoutMessageInput;
  update: FeedbackUpdateWithoutMessageInput;
};

export type FeedbackWhereInput = {
  AND?: InputMaybe<Array<FeedbackWhereInput>>;
  NOT?: InputMaybe<Array<FeedbackWhereInput>>;
  OR?: InputMaybe<Array<FeedbackWhereInput>>;
  additionalInfo?: InputMaybe<JsonNullableFilter>;
  expiredAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<MessageRelationFilter>;
  messageId?: InputMaybe<StringFilter>;
  positive?: InputMaybe<BoolFilter>;
  text?: InputMaybe<StringNullableFilter>;
};

export type FeedbackWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  messageId?: InputMaybe<Scalars['String']>;
};

export type Font = {
  __typename?: 'Font';
  createdAt?: Maybe<Scalars['DateTime']>;
  family: Scalars['String'];
  fileTypes?: Maybe<Array<Scalars['String']>>;
  files?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  style: Scalars['String'];
  theme?: Maybe<Theme>;
  themeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  weight: Scalars['String'];
};

export type FontCreateManyThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  family: Scalars['String'];
  fileTypes?: InputMaybe<Array<Scalars['String']>>;
  files?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  style: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight: Scalars['String'];
};

export type FontCreateManyThemeInputEnvelope = {
  data: Array<FontCreateManyThemeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FontCreateNestedManyWithoutThemeInput = {
  connect?: InputMaybe<Array<FontWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FontCreateOrConnectWithoutThemeInput>>;
  create?: InputMaybe<Array<FontCreateWithoutThemeInput>>;
  createMany?: InputMaybe<FontCreateManyThemeInputEnvelope>;
};

export type FontCreateOrConnectWithoutThemeInput = {
  create: FontCreateWithoutThemeInput;
  where: FontWhereUniqueInput;
};

export type FontCreateWithoutThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  family: Scalars['String'];
  fileTypes?: InputMaybe<Array<Scalars['String']>>;
  files?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  style: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight: Scalars['String'];
};

export type FontFamily = {
  __typename?: 'FontFamily';
  createdAt?: Maybe<Scalars['DateTime']>;
  family: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  theme?: Maybe<Theme>;
  themeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FontFamilyCreateManyThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  family: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FontFamilyCreateManyThemeInputEnvelope = {
  data: Array<FontFamilyCreateManyThemeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FontFamilyCreateNestedManyWithoutThemeInput = {
  connect?: InputMaybe<Array<FontFamilyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FontFamilyCreateOrConnectWithoutThemeInput>>;
  create?: InputMaybe<Array<FontFamilyCreateWithoutThemeInput>>;
  createMany?: InputMaybe<FontFamilyCreateManyThemeInputEnvelope>;
};

export type FontFamilyCreateOrConnectWithoutThemeInput = {
  create: FontFamilyCreateWithoutThemeInput;
  where: FontFamilyWhereUniqueInput;
};

export type FontFamilyCreateWithoutThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  family: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FontFamilyScalarWhereInput = {
  AND?: InputMaybe<Array<FontFamilyScalarWhereInput>>;
  NOT?: InputMaybe<Array<FontFamilyScalarWhereInput>>;
  OR?: InputMaybe<Array<FontFamilyScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  family?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  themeId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type FontFamilyUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  family?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FontFamilyUpdateManyWithWhereWithoutThemeInput = {
  data: FontFamilyUpdateManyMutationInput;
  where: FontFamilyScalarWhereInput;
};

export type FontFamilyUpdateManyWithoutThemeNestedInput = {
  connect?: InputMaybe<Array<FontFamilyWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FontFamilyCreateOrConnectWithoutThemeInput>>;
  create?: InputMaybe<Array<FontFamilyCreateWithoutThemeInput>>;
  createMany?: InputMaybe<FontFamilyCreateManyThemeInputEnvelope>;
  delete?: InputMaybe<Array<FontFamilyWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FontFamilyScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FontFamilyWhereUniqueInput>>;
  set?: InputMaybe<Array<FontFamilyWhereUniqueInput>>;
  update?: InputMaybe<Array<FontFamilyUpdateWithWhereUniqueWithoutThemeInput>>;
  updateMany?: InputMaybe<Array<FontFamilyUpdateManyWithWhereWithoutThemeInput>>;
  upsert?: InputMaybe<Array<FontFamilyUpsertWithWhereUniqueWithoutThemeInput>>;
};

export type FontFamilyUpdateWithWhereUniqueWithoutThemeInput = {
  data: FontFamilyUpdateWithoutThemeInput;
  where: FontFamilyWhereUniqueInput;
};

export type FontFamilyUpdateWithoutThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  family?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FontFamilyUpsertWithWhereUniqueWithoutThemeInput = {
  create: FontFamilyCreateWithoutThemeInput;
  update: FontFamilyUpdateWithoutThemeInput;
  where: FontFamilyWhereUniqueInput;
};

export type FontFamilyWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type FontScalarWhereInput = {
  AND?: InputMaybe<Array<FontScalarWhereInput>>;
  NOT?: InputMaybe<Array<FontScalarWhereInput>>;
  OR?: InputMaybe<Array<FontScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  family?: InputMaybe<StringFilter>;
  fileTypes?: InputMaybe<StringNullableListFilter>;
  files?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<StringFilter>;
  style?: InputMaybe<StringFilter>;
  themeId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
  weight?: InputMaybe<StringFilter>;
};

export type FontUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  family?: InputMaybe<Scalars['String']>;
  fileTypes?: InputMaybe<Array<Scalars['String']>>;
  files?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  style?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight?: InputMaybe<Scalars['String']>;
};

export type FontUpdateManyWithWhereWithoutThemeInput = {
  data: FontUpdateManyMutationInput;
  where: FontScalarWhereInput;
};

export type FontUpdateManyWithoutThemeNestedInput = {
  connect?: InputMaybe<Array<FontWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FontCreateOrConnectWithoutThemeInput>>;
  create?: InputMaybe<Array<FontCreateWithoutThemeInput>>;
  createMany?: InputMaybe<FontCreateManyThemeInputEnvelope>;
  delete?: InputMaybe<Array<FontWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FontScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FontWhereUniqueInput>>;
  set?: InputMaybe<Array<FontWhereUniqueInput>>;
  update?: InputMaybe<Array<FontUpdateWithWhereUniqueWithoutThemeInput>>;
  updateMany?: InputMaybe<Array<FontUpdateManyWithWhereWithoutThemeInput>>;
  upsert?: InputMaybe<Array<FontUpsertWithWhereUniqueWithoutThemeInput>>;
};

export type FontUpdateWithWhereUniqueWithoutThemeInput = {
  data: FontUpdateWithoutThemeInput;
  where: FontWhereUniqueInput;
};

export type FontUpdateWithoutThemeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  family?: InputMaybe<Scalars['String']>;
  fileTypes?: InputMaybe<Array<Scalars['String']>>;
  files?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  style?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight?: InputMaybe<Scalars['String']>;
};

export type FontUpsertWithWhereUniqueWithoutThemeInput = {
  create: FontCreateWithoutThemeInput;
  update: FontUpdateWithoutThemeInput;
  where: FontWhereUniqueInput;
};

export type FontWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum IngestionState {
  CheckingIntegrity = 'CHECKING_INTEGRITY',
  Failed = 'FAILED',
  FailedCreatingChunks = 'FAILED_CREATING_CHUNKS',
  FailedEmbedding = 'FAILED_EMBEDDING',
  FailedGettingFile = 'FAILED_GETTING_FILE',
  FailedImage = 'FAILED_IMAGE',
  FailedMalwareFound = 'FAILED_MALWARE_FOUND',
  FailedParsing = 'FAILED_PARSING',
  FailedRedelivered = 'FAILED_REDELIVERED',
  FailedTooLessContent = 'FAILED_TOO_LESS_CONTENT',
  Finished = 'FINISHED',
  IngestionChunking = 'INGESTION_CHUNKING',
  IngestionEmbedding = 'INGESTION_EMBEDDING',
  IngestionReading = 'INGESTION_READING',
  MalwareScanning = 'MALWARE_SCANNING',
  Queued = 'QUEUED',
  RebuildingMetadata = 'REBUILDING_METADATA',
  RecreatingVecetordbIndex = 'RECREATING_VECETORDB_INDEX',
  Retrying = 'RETRYING',
  ReEmbedding = 'RE_EMBEDDING',
  ReIngesting = 'RE_INGESTING'
}

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type JsonNullableFilter = {
  array_contains?: InputMaybe<Scalars['JSON']>;
  array_ends_with?: InputMaybe<Scalars['JSON']>;
  array_starts_with?: InputMaybe<Scalars['JSON']>;
  equals?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
  path?: InputMaybe<Array<Scalars['String']>>;
  string_contains?: InputMaybe<Scalars['String']>;
  string_ends_with?: InputMaybe<Scalars['String']>;
  string_starts_with?: InputMaybe<Scalars['String']>;
};

export type LanguageModelGroup = {
  __typename?: 'LanguageModelGroup';
  _count: LanguageModelGroupCount;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  groupName: Scalars['String'];
  id: Scalars['ID'];
  languageModels?: Maybe<Array<ExternalLanguageModel>>;
  updatedAt: Scalars['DateTime'];
};

export type LanguageModelGroupCompanyIdGroupNameCompoundUniqueInput = {
  groupName: Scalars['String'];
};

export type LanguageModelGroupCount = {
  __typename?: 'LanguageModelGroupCount';
  languageModels: Scalars['Int'];
};

export type LanguageModelGroupCreateInput = {
  groupName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
};

export type LanguageModelGroupOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  groupName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum LanguageModelGroupScalarFieldEnum {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  GroupName = 'groupName',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type LanguageModelGroupUpdateInput = {
  groupName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type LanguageModelGroupWhereInput = {
  AND?: InputMaybe<Array<LanguageModelGroupWhereInput>>;
  NOT?: InputMaybe<Array<LanguageModelGroupWhereInput>>;
  OR?: InputMaybe<Array<LanguageModelGroupWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  groupName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LanguageModelGroupWhereUniqueInput = {
  companyId_groupName?: InputMaybe<LanguageModelGroupCompanyIdGroupNameCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']>;
};

export type MagicTableActionResponse = {
  __typename?: 'MagicTableActionResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export enum MagicTableActorType {
  Assistant = 'ASSISTANT',
  System = 'SYSTEM',
  Tool = 'TOOL',
  User = 'USER'
}

export type MagicTableArtifact = {
  __typename?: 'MagicTableArtifact';
  artifactState: Scalars['String'];
  artifactType: Scalars['String'];
  companyId: Scalars['String'];
  contentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  /** @DtoReadOnly */
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  magicTableSheet: MagicTableSheet;
  magicTableSheetId: Scalars['String'];
  mimeType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type MagicTableArtifactListRelationFilter = {
  every?: InputMaybe<MagicTableArtifactWhereInput>;
  none?: InputMaybe<MagicTableArtifactWhereInput>;
  some?: InputMaybe<MagicTableArtifactWhereInput>;
};

export type MagicTableArtifactOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

/** Types of artifacts that can be generated */
export enum MagicTableArtifactType {
  FullReport = 'FULL_REPORT',
  Questions = 'QUESTIONS'
}

export type MagicTableArtifactWhereInput = {
  AND?: InputMaybe<Array<MagicTableArtifactWhereInput>>;
  NOT?: InputMaybe<Array<MagicTableArtifactWhereInput>>;
  OR?: InputMaybe<Array<MagicTableArtifactWhereInput>>;
  artifactState?: InputMaybe<StringFilter>;
  artifactType?: InputMaybe<StringFilter>;
  companyId?: InputMaybe<StringFilter>;
  contentId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  magicTableSheetId?: InputMaybe<StringFilter>;
  mimeType?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MagicTableCell = {
  __typename?: 'MagicTableCell';
  _count: MagicTableCellCount;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  data: Scalars['String'];
  /** @DtoReadOnly */
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  magicTableColumn: MagicTableColumn;
  magicTableColumnId: Scalars['String'];
  magicTableLogEntry?: Maybe<Array<MagicTableLogEntry>>;
  magicTableRow: MagicTableRow;
  magicTableRowId: Scalars['String'];
  magicTableSheet: MagicTableSheet;
  magicTableSheetId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MagicTableCellCount = {
  __typename?: 'MagicTableCellCount';
  magicTableLogEntry: Scalars['Int'];
};

export type MagicTableColumn = {
  __typename?: 'MagicTableColumn';
  _count: MagicTableColumnCount;
  columnOrder: Scalars['Int'];
  columnWidth?: Maybe<Scalars['Int']>;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  /** @DtoReadOnly */
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  magicTableCell?: Maybe<Array<MagicTableCell>>;
  magicTableSheet: MagicTableSheet;
  magicTableSheetId: Scalars['String'];
  suppressSizeToFit: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type MagicTableColumnCount = {
  __typename?: 'MagicTableColumnCount';
  magicTableCell: Scalars['Int'];
};

export type MagicTableColumnCreateInput = {
  columnWidth?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  suppressSizeToFit?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MagicTableColumnMetaDataGraphQlObjectType = {
  __typename?: 'MagicTableColumnMetaDataGraphQlObjectType';
  field?: Maybe<Scalars['String']>;
  suppressSizeToFit?: Maybe<Scalars['Boolean']>;
  width?: Maybe<Scalars['Float']>;
};

export type MagicTableLogEntry = {
  __typename?: 'MagicTableLogEntry';
  actorType: MagicTableActorType;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  /** @DtoReadOnly */
  deletedAt?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  magicTableCell?: Maybe<MagicTableCell>;
  magicTableCellId?: Maybe<Scalars['String']>;
  magicTableSheet: MagicTableSheet;
  magicTableSheetId: Scalars['String'];
  messageId?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MagicTableLogEntryListRelationFilter = {
  every?: InputMaybe<MagicTableLogEntryWhereInput>;
  none?: InputMaybe<MagicTableLogEntryWhereInput>;
  some?: InputMaybe<MagicTableLogEntryWhereInput>;
};

export type MagicTableLogEntryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MagicTableLogEntryWhereInput = {
  AND?: InputMaybe<Array<MagicTableLogEntryWhereInput>>;
  NOT?: InputMaybe<Array<MagicTableLogEntryWhereInput>>;
  OR?: InputMaybe<Array<MagicTableLogEntryWhereInput>>;
  actorType?: InputMaybe<EnumMagicTableActorTypeFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  details?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<StringFilter>;
  magicTableCellId?: InputMaybe<StringNullableFilter>;
  magicTableSheetId?: InputMaybe<StringFilter>;
  messageId?: InputMaybe<StringNullableFilter>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MagicTableRow = {
  __typename?: 'MagicTableRow';
  _count: MagicTableRowCount;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  /** @DtoReadOnly */
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  magicTableCell?: Maybe<Array<MagicTableCell>>;
  magicTableSheet: MagicTableSheet;
  magicTableSheetId: Scalars['String'];
  rowOrder: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type MagicTableRowCount = {
  __typename?: 'MagicTableRowCount';
  magicTableCell: Scalars['Int'];
};

export type MagicTableSheet = {
  __typename?: 'MagicTableSheet';
  _count: MagicTableSheetCount;
  chat?: Maybe<Chat>;
  chatId?: Maybe<Scalars['String']>;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  /** @DtoReadOnly */
  deletedAt?: Maybe<Scalars['DateTime']>;
  dueDiligence?: Maybe<DueDiligence>;
  id: Scalars['ID'];
  magicTableArtifact?: Maybe<Array<MagicTableArtifact>>;
  magicTableCell?: Maybe<Array<MagicTableCell>>;
  magicTableColumn?: Maybe<Array<MagicTableColumn>>;
  magicTableLogEntry?: Maybe<Array<MagicTableLogEntry>>;
  magicTableRow?: Maybe<Array<MagicTableRow>>;
  name: Scalars['String'];
  state: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MagicTableSheetCount = {
  __typename?: 'MagicTableSheetCount';
  magicTableArtifact: Scalars['Int'];
  magicTableCell: Scalars['Int'];
  magicTableColumn: Scalars['Int'];
  magicTableLogEntry: Scalars['Int'];
  magicTableRow: Scalars['Int'];
};

export type MagicTableSheetListRelationFilter = {
  every?: InputMaybe<MagicTableSheetWhereInput>;
  none?: InputMaybe<MagicTableSheetWhereInput>;
  some?: InputMaybe<MagicTableSheetWhereInput>;
};

export type MagicTableSheetOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MagicTableSheetOrderByWithRelationInput = {
  chat?: InputMaybe<ChatOrderByWithRelationInput>;
  chatId?: InputMaybe<SortOrder>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  dueDiligence?: InputMaybe<DueDiligenceOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  magicTableArtifact?: InputMaybe<MagicTableArtifactOrderByRelationAggregateInput>;
  magicTableLogEntry?: InputMaybe<MagicTableLogEntryOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  state?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MagicTableSheetRelationFilter = {
  is?: InputMaybe<MagicTableSheetWhereInput>;
  isNot?: InputMaybe<MagicTableSheetWhereInput>;
};

export enum MagicTableSheetScalarFieldEnum {
  ChatId = 'chatId',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Name = 'name',
  State = 'state',
  UpdatedAt = 'updatedAt'
}

/** States that the Magic Table Sheet can be in */
export enum MagicTableSheetState {
  Idle = 'IDLE',
  Processing = 'PROCESSING',
  StoppedByUser = 'STOPPED_BY_USER'
}

export type MagicTableSheetWhereInput = {
  AND?: InputMaybe<Array<MagicTableSheetWhereInput>>;
  NOT?: InputMaybe<Array<MagicTableSheetWhereInput>>;
  OR?: InputMaybe<Array<MagicTableSheetWhereInput>>;
  chat?: InputMaybe<ChatRelationFilter>;
  chatId?: InputMaybe<StringNullableFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  dueDiligence?: InputMaybe<DueDiligenceRelationFilter>;
  id?: InputMaybe<StringFilter>;
  magicTableArtifact?: InputMaybe<MagicTableArtifactListRelationFilter>;
  magicTableLogEntry?: InputMaybe<MagicTableLogEntryListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  state?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MagicTableSheetWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Message = {
  __typename?: 'Message';
  ShortTermMemory?: Maybe<Array<ShortTermMemory>>;
  _count: MessageCount;
  assessment?: Maybe<Array<MessageAssessment>>;
  chat?: Maybe<Chat>;
  chatId: Scalars['String'];
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  debugInfo?: Maybe<Scalars['JSON']>;
  /** @DtoReadOnly */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** @DtoReadOnly */
  expiredAt?: Maybe<Scalars['DateTime']>;
  feedback?: Maybe<Feedback>;
  gptRequest?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  isBenchmarkingMessage: Scalars['Boolean'];
  messages?: Maybe<Array<Message>>;
  originalText?: Maybe<Scalars['String']>;
  previousMessage?: Maybe<Message>;
  references?: Maybe<Array<Reference>>;
  role: Role;
  startedStreamingAt?: Maybe<Scalars['DateTime']>;
  stoppedStreamingAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  translationOneToOne?: Maybe<TranslationOneToOne>;
  updatedAt: Scalars['DateTime'];
  usedForLastPrompt: Scalars['Boolean'];
  usedTokens: Scalars['Int'];
  userId?: Maybe<Scalars['String']>;
};

export type MessageAssessment = {
  __typename?: 'MessageAssessment';
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  explanation?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isVisible: Scalars['Boolean'];
  label?: Maybe<Scalars['String']>;
  message: Message;
  messageId: Scalars['String'];
  status: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type MessageAssessmentCreateInput = {
  explanation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type MessageAssessmentCreateManyMessageInput = {
  explanation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type MessageAssessmentCreateManyMessageInputEnvelope = {
  data: Array<MessageAssessmentCreateManyMessageInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MessageAssessmentCreateNestedManyWithoutMessageInput = {
  connect?: InputMaybe<Array<MessageAssessmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MessageAssessmentCreateOrConnectWithoutMessageInput>>;
  create?: InputMaybe<Array<MessageAssessmentCreateWithoutMessageInput>>;
  createMany?: InputMaybe<MessageAssessmentCreateManyMessageInputEnvelope>;
};

export type MessageAssessmentCreateOrConnectWithoutMessageInput = {
  create: MessageAssessmentCreateWithoutMessageInput;
  where: MessageAssessmentWhereUniqueInput;
};

export type MessageAssessmentCreateWithoutMessageInput = {
  explanation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type MessageAssessmentListRelationFilter = {
  every?: InputMaybe<MessageAssessmentWhereInput>;
  none?: InputMaybe<MessageAssessmentWhereInput>;
  some?: InputMaybe<MessageAssessmentWhereInput>;
};

export type MessageAssessmentMessageIdTypeCompoundUniqueInput = {
  type: Scalars['String'];
};

export type MessageAssessmentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MessageAssessmentScalarWhereInput = {
  AND?: InputMaybe<Array<MessageAssessmentScalarWhereInput>>;
  NOT?: InputMaybe<Array<MessageAssessmentScalarWhereInput>>;
  OR?: InputMaybe<Array<MessageAssessmentScalarWhereInput>>;
  explanation?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<StringFilter>;
  type?: InputMaybe<StringNullableFilter>;
};

export type MessageAssessmentUpdateInput = {
  explanation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type MessageAssessmentUpdateManyMutationInput = {
  explanation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type MessageAssessmentUpdateManyWithWhereWithoutMessageInput = {
  data: MessageAssessmentUpdateManyMutationInput;
  where: MessageAssessmentScalarWhereInput;
};

export type MessageAssessmentUpdateManyWithoutMessageNestedInput = {
  connect?: InputMaybe<Array<MessageAssessmentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MessageAssessmentCreateOrConnectWithoutMessageInput>>;
  create?: InputMaybe<Array<MessageAssessmentCreateWithoutMessageInput>>;
  createMany?: InputMaybe<MessageAssessmentCreateManyMessageInputEnvelope>;
  delete?: InputMaybe<Array<MessageAssessmentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MessageAssessmentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MessageAssessmentWhereUniqueInput>>;
  set?: InputMaybe<Array<MessageAssessmentWhereUniqueInput>>;
  update?: InputMaybe<Array<MessageAssessmentUpdateWithWhereUniqueWithoutMessageInput>>;
  updateMany?: InputMaybe<Array<MessageAssessmentUpdateManyWithWhereWithoutMessageInput>>;
  upsert?: InputMaybe<Array<MessageAssessmentUpsertWithWhereUniqueWithoutMessageInput>>;
};

export type MessageAssessmentUpdateWithWhereUniqueWithoutMessageInput = {
  data: MessageAssessmentUpdateWithoutMessageInput;
  where: MessageAssessmentWhereUniqueInput;
};

export type MessageAssessmentUpdateWithoutMessageInput = {
  explanation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type MessageAssessmentUpsertWithWhereUniqueWithoutMessageInput = {
  create: MessageAssessmentCreateWithoutMessageInput;
  update: MessageAssessmentUpdateWithoutMessageInput;
  where: MessageAssessmentWhereUniqueInput;
};

export type MessageAssessmentWhereInput = {
  AND?: InputMaybe<Array<MessageAssessmentWhereInput>>;
  NOT?: InputMaybe<Array<MessageAssessmentWhereInput>>;
  OR?: InputMaybe<Array<MessageAssessmentWhereInput>>;
  explanation?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  label?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<StringFilter>;
  type?: InputMaybe<StringNullableFilter>;
};

export type MessageAssessmentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  messageId_type?: InputMaybe<MessageAssessmentMessageIdTypeCompoundUniqueInput>;
};

export type MessageCount = {
  __typename?: 'MessageCount';
  ShortTermMemory: Scalars['Int'];
  assessment: Scalars['Int'];
  messages: Scalars['Int'];
  references: Scalars['Int'];
};

export type MessageCreateInput = {
  assessment?: InputMaybe<MessageAssessmentCreateNestedManyWithoutMessageInput>;
  completedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  feedback?: InputMaybe<FeedbackCreateNestedOneWithoutMessageInput>;
  id?: InputMaybe<Scalars['String']>;
  isBenchmarkingMessage?: InputMaybe<Scalars['Boolean']>;
  messages?: InputMaybe<MessageCreateNestedManyWithoutPreviousMessageInput>;
  originalText?: InputMaybe<Scalars['String']>;
  previousMessage?: InputMaybe<MessageCreateNestedOneWithoutMessagesInput>;
  role: Role;
  text?: InputMaybe<Scalars['String']>;
  translationOneToOne?: InputMaybe<TranslationOneToOneCreateNestedOneWithoutMessageInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MessageCreateManyPreviousMessageInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isBenchmarkingMessage?: InputMaybe<Scalars['Boolean']>;
  originalText?: InputMaybe<Scalars['String']>;
  role: Role;
  text?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MessageCreateManyPreviousMessageInputEnvelope = {
  data: Array<MessageCreateManyPreviousMessageInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MessageCreateNestedManyWithoutPreviousMessageInput = {
  connect?: InputMaybe<Array<MessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MessageCreateOrConnectWithoutPreviousMessageInput>>;
  create?: InputMaybe<Array<MessageCreateWithoutPreviousMessageInput>>;
  createMany?: InputMaybe<MessageCreateManyPreviousMessageInputEnvelope>;
};

export type MessageCreateNestedOneWithoutMessagesInput = {
  connect?: InputMaybe<MessageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MessageCreateOrConnectWithoutMessagesInput>;
  create?: InputMaybe<MessageCreateWithoutMessagesInput>;
};

export type MessageCreateOrConnectWithoutMessagesInput = {
  create: MessageCreateWithoutMessagesInput;
  where: MessageWhereUniqueInput;
};

export type MessageCreateOrConnectWithoutPreviousMessageInput = {
  create: MessageCreateWithoutPreviousMessageInput;
  where: MessageWhereUniqueInput;
};

export type MessageCreateWithoutMessagesInput = {
  assessment?: InputMaybe<MessageAssessmentCreateNestedManyWithoutMessageInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  feedback?: InputMaybe<FeedbackCreateNestedOneWithoutMessageInput>;
  id?: InputMaybe<Scalars['String']>;
  isBenchmarkingMessage?: InputMaybe<Scalars['Boolean']>;
  originalText?: InputMaybe<Scalars['String']>;
  previousMessage?: InputMaybe<MessageCreateNestedOneWithoutMessagesInput>;
  role: Role;
  text?: InputMaybe<Scalars['String']>;
  translationOneToOne?: InputMaybe<TranslationOneToOneCreateNestedOneWithoutMessageInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MessageCreateWithoutPreviousMessageInput = {
  assessment?: InputMaybe<MessageAssessmentCreateNestedManyWithoutMessageInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  feedback?: InputMaybe<FeedbackCreateNestedOneWithoutMessageInput>;
  id?: InputMaybe<Scalars['String']>;
  isBenchmarkingMessage?: InputMaybe<Scalars['Boolean']>;
  messages?: InputMaybe<MessageCreateNestedManyWithoutPreviousMessageInput>;
  originalText?: InputMaybe<Scalars['String']>;
  role: Role;
  text?: InputMaybe<Scalars['String']>;
  translationOneToOne?: InputMaybe<TranslationOneToOneCreateNestedOneWithoutMessageInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MessageListRelationFilter = {
  every?: InputMaybe<MessageWhereInput>;
  none?: InputMaybe<MessageWhereInput>;
  some?: InputMaybe<MessageWhereInput>;
};

export type MessageOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MessageOrderByWithRelationInput = {
  assessment?: InputMaybe<MessageAssessmentOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  expiredAt?: InputMaybe<SortOrder>;
  feedback?: InputMaybe<FeedbackOrderByWithRelationInput>;
  id?: InputMaybe<SortOrder>;
  isBenchmarkingMessage?: InputMaybe<SortOrder>;
  messages?: InputMaybe<MessageOrderByRelationAggregateInput>;
  originalText?: InputMaybe<SortOrder>;
  previousMessage?: InputMaybe<MessageOrderByWithRelationInput>;
  role?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  translationOneToOne?: InputMaybe<TranslationOneToOneOrderByWithRelationInput>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type MessageRelationFilter = {
  is?: InputMaybe<MessageWhereInput>;
  isNot?: InputMaybe<MessageWhereInput>;
};

export enum MessageScalarFieldEnum {
  ChatId = 'chatId',
  CompletedAt = 'completedAt',
  CreatedAt = 'createdAt',
  DebugInfo = 'debugInfo',
  DeletedAt = 'deletedAt',
  ExpiredAt = 'expiredAt',
  GptRequest = 'gptRequest',
  Id = 'id',
  IsBenchmarkingMessage = 'isBenchmarkingMessage',
  OriginalText = 'originalText',
  PreviousMessageId = 'previousMessageId',
  Role = 'role',
  StartedStreamingAt = 'startedStreamingAt',
  StoppedStreamingAt = 'stoppedStreamingAt',
  Text = 'text',
  UpdatedAt = 'updatedAt',
  UsedForLastPrompt = 'usedForLastPrompt',
  UsedTokens = 'usedTokens',
  UserId = 'userId'
}

export type MessageScalarWhereInput = {
  AND?: InputMaybe<Array<MessageScalarWhereInput>>;
  NOT?: InputMaybe<Array<MessageScalarWhereInput>>;
  OR?: InputMaybe<Array<MessageScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  expiredAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isBenchmarkingMessage?: InputMaybe<BoolFilter>;
  originalText?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  text?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type MessageUpdateInput = {
  assessment?: InputMaybe<MessageAssessmentUpdateManyWithoutMessageNestedInput>;
  completedAt?: InputMaybe<Scalars['DateTime']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  feedback?: InputMaybe<FeedbackUpdateOneWithoutMessageNestedInput>;
  id?: InputMaybe<Scalars['String']>;
  isBenchmarkingMessage?: InputMaybe<Scalars['Boolean']>;
  messages?: InputMaybe<MessageUpdateManyWithoutPreviousMessageNestedInput>;
  originalText?: InputMaybe<Scalars['String']>;
  previousMessage?: InputMaybe<MessageUpdateOneWithoutMessagesNestedInput>;
  role?: InputMaybe<Role>;
  stoppedStreamingAt?: InputMaybe<Scalars['DateTime']>;
  text?: InputMaybe<Scalars['String']>;
  translationOneToOne?: InputMaybe<TranslationOneToOneUpdateOneWithoutMessageNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MessageUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isBenchmarkingMessage?: InputMaybe<Scalars['Boolean']>;
  originalText?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Role>;
  text?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MessageUpdateManyWithWhereWithoutPreviousMessageInput = {
  data: MessageUpdateManyMutationInput;
  where: MessageScalarWhereInput;
};

export type MessageUpdateManyWithoutPreviousMessageNestedInput = {
  connect?: InputMaybe<Array<MessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MessageCreateOrConnectWithoutPreviousMessageInput>>;
  create?: InputMaybe<Array<MessageCreateWithoutPreviousMessageInput>>;
  createMany?: InputMaybe<MessageCreateManyPreviousMessageInputEnvelope>;
  delete?: InputMaybe<Array<MessageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MessageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MessageWhereUniqueInput>>;
  set?: InputMaybe<Array<MessageWhereUniqueInput>>;
  update?: InputMaybe<Array<MessageUpdateWithWhereUniqueWithoutPreviousMessageInput>>;
  updateMany?: InputMaybe<Array<MessageUpdateManyWithWhereWithoutPreviousMessageInput>>;
  upsert?: InputMaybe<Array<MessageUpsertWithWhereUniqueWithoutPreviousMessageInput>>;
};

export type MessageUpdateOneWithoutMessagesNestedInput = {
  connect?: InputMaybe<MessageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MessageCreateOrConnectWithoutMessagesInput>;
  create?: InputMaybe<MessageCreateWithoutMessagesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<MessageUpdateWithoutMessagesInput>;
  upsert?: InputMaybe<MessageUpsertWithoutMessagesInput>;
};

export type MessageUpdateWithWhereUniqueWithoutPreviousMessageInput = {
  data: MessageUpdateWithoutPreviousMessageInput;
  where: MessageWhereUniqueInput;
};

export type MessageUpdateWithoutMessagesInput = {
  assessment?: InputMaybe<MessageAssessmentUpdateManyWithoutMessageNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  feedback?: InputMaybe<FeedbackUpdateOneWithoutMessageNestedInput>;
  id?: InputMaybe<Scalars['String']>;
  isBenchmarkingMessage?: InputMaybe<Scalars['Boolean']>;
  originalText?: InputMaybe<Scalars['String']>;
  previousMessage?: InputMaybe<MessageUpdateOneWithoutMessagesNestedInput>;
  role?: InputMaybe<Role>;
  text?: InputMaybe<Scalars['String']>;
  translationOneToOne?: InputMaybe<TranslationOneToOneUpdateOneWithoutMessageNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MessageUpdateWithoutPreviousMessageInput = {
  assessment?: InputMaybe<MessageAssessmentUpdateManyWithoutMessageNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  expiredAt?: InputMaybe<Scalars['DateTime']>;
  feedback?: InputMaybe<FeedbackUpdateOneWithoutMessageNestedInput>;
  id?: InputMaybe<Scalars['String']>;
  isBenchmarkingMessage?: InputMaybe<Scalars['Boolean']>;
  messages?: InputMaybe<MessageUpdateManyWithoutPreviousMessageNestedInput>;
  originalText?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Role>;
  text?: InputMaybe<Scalars['String']>;
  translationOneToOne?: InputMaybe<TranslationOneToOneUpdateOneWithoutMessageNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type MessageUpsertWithWhereUniqueWithoutPreviousMessageInput = {
  create: MessageCreateWithoutPreviousMessageInput;
  update: MessageUpdateWithoutPreviousMessageInput;
  where: MessageWhereUniqueInput;
};

export type MessageUpsertWithoutMessagesInput = {
  create: MessageCreateWithoutMessagesInput;
  update: MessageUpdateWithoutMessagesInput;
};

export type MessageWhereInput = {
  AND?: InputMaybe<Array<MessageWhereInput>>;
  NOT?: InputMaybe<Array<MessageWhereInput>>;
  OR?: InputMaybe<Array<MessageWhereInput>>;
  assessment?: InputMaybe<MessageAssessmentListRelationFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  expiredAt?: InputMaybe<DateTimeNullableFilter>;
  feedback?: InputMaybe<FeedbackRelationFilter>;
  id?: InputMaybe<StringFilter>;
  isBenchmarkingMessage?: InputMaybe<BoolFilter>;
  messages?: InputMaybe<MessageListRelationFilter>;
  originalText?: InputMaybe<StringNullableFilter>;
  previousMessage?: InputMaybe<MessageRelationFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  startedStreamingAt?: InputMaybe<DateTimeNullableFilter>;
  stoppedStreamingAt?: InputMaybe<DateTimeNullableFilter>;
  text?: InputMaybe<StringNullableFilter>;
  translationOneToOne?: InputMaybe<TranslationOneToOneRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type MessageWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Module = {
  __typename?: 'Module';
  assistant: Assistant;
  assistantId: Scalars['String'];
  configuration: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isExternal: Scalars['Boolean'];
  moduleTemplate?: Maybe<ModuleTemplate>;
  moduleTemplateId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  toolDefinition?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['DateTime'];
  weight: Scalars['Int'];
};

export type ModuleCreateInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  moduleTemplate?: InputMaybe<ModuleTemplateCreateNestedOneWithoutModulesInput>;
  name: Scalars['String'];
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleCreateManyAssistantInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  moduleTemplateId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleCreateManyAssistantInputEnvelope = {
  data: Array<ModuleCreateManyAssistantInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ModuleCreateManyModuleTemplateInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleCreateManyModuleTemplateInputEnvelope = {
  data: Array<ModuleCreateManyModuleTemplateInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ModuleCreateNestedManyWithoutAssistantInput = {
  connect?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleCreateOrConnectWithoutAssistantInput>>;
  create?: InputMaybe<Array<ModuleCreateWithoutAssistantInput>>;
  createMany?: InputMaybe<ModuleCreateManyAssistantInputEnvelope>;
};

export type ModuleCreateNestedManyWithoutModuleTemplateInput = {
  connect?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleCreateOrConnectWithoutModuleTemplateInput>>;
  create?: InputMaybe<Array<ModuleCreateWithoutModuleTemplateInput>>;
  createMany?: InputMaybe<ModuleCreateManyModuleTemplateInputEnvelope>;
};

export type ModuleCreateOrConnectWithoutAssistantInput = {
  create: ModuleCreateWithoutAssistantInput;
  where: ModuleWhereUniqueInput;
};

export type ModuleCreateOrConnectWithoutModuleTemplateInput = {
  create: ModuleCreateWithoutModuleTemplateInput;
  where: ModuleWhereUniqueInput;
};

export type ModuleCreateWithoutAssistantInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  moduleTemplate?: InputMaybe<ModuleTemplateCreateNestedOneWithoutModulesInput>;
  name: Scalars['String'];
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleCreateWithoutModuleTemplateInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleListRelationFilter = {
  every?: InputMaybe<ModuleWhereInput>;
  none?: InputMaybe<ModuleWhereInput>;
  some?: InputMaybe<ModuleWhereInput>;
};

export type ModuleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ModuleOrderByWithRelationInput = {
  configuration?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isExternal?: InputMaybe<SortOrder>;
  moduleTemplate?: InputMaybe<ModuleTemplateOrderByWithRelationInput>;
  moduleTemplateId?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  toolDefinition?: InputMaybe<SortOrder>;
  weight?: InputMaybe<SortOrder>;
};

export enum ModuleScalarFieldEnum {
  AssistantId = 'assistantId',
  Configuration = 'configuration',
  CreatedAt = 'createdAt',
  Description = 'description',
  Id = 'id',
  IsExternal = 'isExternal',
  ModuleTemplateId = 'moduleTemplateId',
  Name = 'name',
  ToolDefinition = 'toolDefinition',
  UpdatedAt = 'updatedAt',
  Weight = 'weight'
}

export type ModuleScalarWhereInput = {
  AND?: InputMaybe<Array<ModuleScalarWhereInput>>;
  NOT?: InputMaybe<Array<ModuleScalarWhereInput>>;
  OR?: InputMaybe<Array<ModuleScalarWhereInput>>;
  configuration?: InputMaybe<JsonFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isExternal?: InputMaybe<BoolFilter>;
  moduleTemplateId?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  toolDefinition?: InputMaybe<JsonNullableFilter>;
  weight?: InputMaybe<IntFilter>;
};

export type ModuleTemplate = {
  __typename?: 'ModuleTemplate';
  _count: ModuleTemplateCount;
  company: Company;
  companyId: Scalars['String'];
  configuration: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isExternal: Scalars['Boolean'];
  modules?: Maybe<Array<Module>>;
  name: Scalars['String'];
  templateName: Scalars['String'];
  toolDefinition: Scalars['JSON'];
  updatedAt: Scalars['DateTime'];
  weight: Scalars['Int'];
};

export type ModuleTemplateCount = {
  __typename?: 'ModuleTemplateCount';
  modules: Scalars['Int'];
};

export type ModuleTemplateCreateManyCompanyInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  templateName: Scalars['String'];
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleTemplateCreateManyCompanyInputEnvelope = {
  data: Array<ModuleTemplateCreateManyCompanyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ModuleTemplateCreateNestedOneWithoutModulesInput = {
  connect?: InputMaybe<ModuleTemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModuleTemplateCreateOrConnectWithoutModulesInput>;
  create?: InputMaybe<ModuleTemplateCreateWithoutModulesInput>;
};

export type ModuleTemplateCreateOrConnectWithoutCompanyInput = {
  create: ModuleTemplateCreateWithoutCompanyInput;
  where: ModuleTemplateWhereUniqueInput;
};

export type ModuleTemplateCreateOrConnectWithoutModulesInput = {
  create: ModuleTemplateCreateWithoutModulesInput;
  where: ModuleTemplateWhereUniqueInput;
};

export type ModuleTemplateCreateWithoutCompanyInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  modules?: InputMaybe<ModuleCreateNestedManyWithoutModuleTemplateInput>;
  name: Scalars['String'];
  templateName: Scalars['String'];
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleTemplateCreateWithoutModulesInput = {
  company: CompanyCreateNestedOneWithoutModuleTemplateInput;
  configuration?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  templateName: Scalars['String'];
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleTemplateListRelationFilter = {
  every?: InputMaybe<ModuleTemplateWhereInput>;
  none?: InputMaybe<ModuleTemplateWhereInput>;
  some?: InputMaybe<ModuleTemplateWhereInput>;
};

export type ModuleTemplateOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ModuleTemplateOrderByWithRelationInput = {
  company?: InputMaybe<CompanyOrderByWithRelationInput>;
  configuration?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isExternal?: InputMaybe<SortOrder>;
  modules?: InputMaybe<ModuleOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  templateName?: InputMaybe<SortOrder>;
  toolDefinition?: InputMaybe<SortOrder>;
  weight?: InputMaybe<SortOrder>;
};

export type ModuleTemplateRelationFilter = {
  is?: InputMaybe<ModuleTemplateWhereInput>;
  isNot?: InputMaybe<ModuleTemplateWhereInput>;
};

export enum ModuleTemplateScalarFieldEnum {
  CompanyId = 'companyId',
  Configuration = 'configuration',
  CreatedAt = 'createdAt',
  Id = 'id',
  IsExternal = 'isExternal',
  Name = 'name',
  TemplateName = 'templateName',
  ToolDefinition = 'toolDefinition',
  UpdatedAt = 'updatedAt',
  Weight = 'weight'
}

export type ModuleTemplateScalarWhereInput = {
  AND?: InputMaybe<Array<ModuleTemplateScalarWhereInput>>;
  NOT?: InputMaybe<Array<ModuleTemplateScalarWhereInput>>;
  OR?: InputMaybe<Array<ModuleTemplateScalarWhereInput>>;
  configuration?: InputMaybe<JsonFilter>;
  id?: InputMaybe<StringFilter>;
  isExternal?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  templateName?: InputMaybe<StringFilter>;
  toolDefinition?: InputMaybe<JsonFilter>;
  weight?: InputMaybe<IntFilter>;
};

export type ModuleTemplateUpdateInput = {
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutModuleTemplateNestedInput>;
  configuration?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  modules?: InputMaybe<ModuleUpdateManyWithoutModuleTemplateNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleTemplateUpdateManyMutationInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleTemplateUpdateManyWithWhereWithoutCompanyInput = {
  data: ModuleTemplateUpdateManyMutationInput;
  where: ModuleTemplateScalarWhereInput;
};

export type ModuleTemplateUpdateManyWithoutCompanyNestedInput = {
  connect?: InputMaybe<Array<ModuleTemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleTemplateCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<ModuleTemplateCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<ModuleTemplateCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<ModuleTemplateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ModuleTemplateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ModuleTemplateWhereUniqueInput>>;
  set?: InputMaybe<Array<ModuleTemplateWhereUniqueInput>>;
  update?: InputMaybe<Array<ModuleTemplateUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: InputMaybe<Array<ModuleTemplateUpdateManyWithWhereWithoutCompanyInput>>;
  upsert?: InputMaybe<Array<ModuleTemplateUpsertWithWhereUniqueWithoutCompanyInput>>;
};

export type ModuleTemplateUpdateOneWithoutModulesNestedInput = {
  connect?: InputMaybe<ModuleTemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ModuleTemplateCreateOrConnectWithoutModulesInput>;
  create?: InputMaybe<ModuleTemplateCreateWithoutModulesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ModuleTemplateUpdateWithoutModulesInput>;
  upsert?: InputMaybe<ModuleTemplateUpsertWithoutModulesInput>;
};

export type ModuleTemplateUpdateWithWhereUniqueWithoutCompanyInput = {
  data: ModuleTemplateUpdateWithoutCompanyInput;
  where: ModuleTemplateWhereUniqueInput;
};

export type ModuleTemplateUpdateWithoutCompanyInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  modules?: InputMaybe<ModuleUpdateManyWithoutModuleTemplateNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleTemplateUpdateWithoutModulesInput = {
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutModuleTemplateNestedInput>;
  configuration?: InputMaybe<Scalars['JSON']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  templateName?: InputMaybe<Scalars['String']>;
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleTemplateUpsertWithWhereUniqueWithoutCompanyInput = {
  create: ModuleTemplateCreateWithoutCompanyInput;
  update: ModuleTemplateUpdateWithoutCompanyInput;
  where: ModuleTemplateWhereUniqueInput;
};

export type ModuleTemplateUpsertWithoutModulesInput = {
  create: ModuleTemplateCreateWithoutModulesInput;
  update: ModuleTemplateUpdateWithoutModulesInput;
};

export type ModuleTemplateWhereInput = {
  AND?: InputMaybe<Array<ModuleTemplateWhereInput>>;
  NOT?: InputMaybe<Array<ModuleTemplateWhereInput>>;
  OR?: InputMaybe<Array<ModuleTemplateWhereInput>>;
  company?: InputMaybe<CompanyRelationFilter>;
  configuration?: InputMaybe<JsonFilter>;
  id?: InputMaybe<StringFilter>;
  isExternal?: InputMaybe<BoolFilter>;
  modules?: InputMaybe<ModuleListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  templateName?: InputMaybe<StringFilter>;
  toolDefinition?: InputMaybe<JsonFilter>;
  weight?: InputMaybe<IntFilter>;
};

export type ModuleTemplateWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ModuleUpdateInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  moduleTemplate?: InputMaybe<ModuleTemplateUpdateOneWithoutModulesNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleUpdateManyMutationInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleUpdateManyWithWhereWithoutAssistantInput = {
  data: ModuleUpdateManyMutationInput;
  where: ModuleScalarWhereInput;
};

export type ModuleUpdateManyWithWhereWithoutModuleTemplateInput = {
  data: ModuleUpdateManyMutationInput;
  where: ModuleScalarWhereInput;
};

export type ModuleUpdateManyWithoutAssistantNestedInput = {
  connect?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleCreateOrConnectWithoutAssistantInput>>;
  create?: InputMaybe<Array<ModuleCreateWithoutAssistantInput>>;
  createMany?: InputMaybe<ModuleCreateManyAssistantInputEnvelope>;
  delete?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ModuleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  set?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  update?: InputMaybe<Array<ModuleUpdateWithWhereUniqueWithoutAssistantInput>>;
  updateMany?: InputMaybe<Array<ModuleUpdateManyWithWhereWithoutAssistantInput>>;
  upsert?: InputMaybe<Array<ModuleUpsertWithWhereUniqueWithoutAssistantInput>>;
};

export type ModuleUpdateManyWithoutModuleTemplateNestedInput = {
  connect?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleCreateOrConnectWithoutModuleTemplateInput>>;
  create?: InputMaybe<Array<ModuleCreateWithoutModuleTemplateInput>>;
  createMany?: InputMaybe<ModuleCreateManyModuleTemplateInputEnvelope>;
  delete?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ModuleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  set?: InputMaybe<Array<ModuleWhereUniqueInput>>;
  update?: InputMaybe<Array<ModuleUpdateWithWhereUniqueWithoutModuleTemplateInput>>;
  updateMany?: InputMaybe<Array<ModuleUpdateManyWithWhereWithoutModuleTemplateInput>>;
  upsert?: InputMaybe<Array<ModuleUpsertWithWhereUniqueWithoutModuleTemplateInput>>;
};

export type ModuleUpdateWithWhereUniqueWithoutAssistantInput = {
  data: ModuleUpdateWithoutAssistantInput;
  where: ModuleWhereUniqueInput;
};

export type ModuleUpdateWithWhereUniqueWithoutModuleTemplateInput = {
  data: ModuleUpdateWithoutModuleTemplateInput;
  where: ModuleWhereUniqueInput;
};

export type ModuleUpdateWithoutAssistantInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  moduleTemplate?: InputMaybe<ModuleTemplateUpdateOneWithoutModulesNestedInput>;
  name?: InputMaybe<Scalars['String']>;
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleUpdateWithoutModuleTemplateInput = {
  configuration?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isExternal?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  toolDefinition?: InputMaybe<Scalars['JSON']>;
  weight?: InputMaybe<Scalars['Int']>;
};

export type ModuleUpsertWithWhereUniqueWithoutAssistantInput = {
  create: ModuleCreateWithoutAssistantInput;
  update: ModuleUpdateWithoutAssistantInput;
  where: ModuleWhereUniqueInput;
};

export type ModuleUpsertWithWhereUniqueWithoutModuleTemplateInput = {
  create: ModuleCreateWithoutModuleTemplateInput;
  update: ModuleUpdateWithoutModuleTemplateInput;
  where: ModuleWhereUniqueInput;
};

export type ModuleWhereInput = {
  AND?: InputMaybe<Array<ModuleWhereInput>>;
  NOT?: InputMaybe<Array<ModuleWhereInput>>;
  OR?: InputMaybe<Array<ModuleWhereInput>>;
  assistant?: InputMaybe<AssistantRelationFilter>;
  configuration?: InputMaybe<JsonFilter>;
  description?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isExternal?: InputMaybe<BoolFilter>;
  moduleTemplate?: InputMaybe<ModuleTemplateRelationFilter>;
  moduleTemplateId?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  toolDefinition?: InputMaybe<JsonNullableFilter>;
  weight?: InputMaybe<IntFilter>;
};

export type ModuleWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  abortBenchmark: BenchmarkStatusResponse;
  addDueDilgenceMetaData: DueDiligenceActionResponse;
  agreedToTermsAndConditions: TermsAndConditionsAgreement;
  analyticsOrderDelete: AnalyticsOrder;
  analyticsOrderStart: AnalyticsOrder;
  chatCreate: Chat;
  chatDeleteAll: Array<Chat>;
  chatDeleteByChatIds: Array<Chat>;
  chatUpdateTitle: Chat;
  checkVectorDataIntegrity: Scalars['Boolean'];
  chunkCreate: Chunk;
  chunkCreateMany: Array<Chunk>;
  chunkDeleteByContent: Scalars['Boolean'];
  chunkUpdate: Chunk;
  companyMetaUpdate: CompanyMeta;
  companyUpdateData: Company;
  contentDelete: Scalars['Boolean'];
  contentDeleteByContentIds: Array<Content>;
  contentDeleteByKey: Scalars['Boolean'];
  contentDeleteByOwnerId: Scalars['Boolean'];
  contentServiceUpdate: Content;
  contentUpdate: Content;
  contentUpsert: Content;
  contentUpsertByChat: Content;
  createAssistant: Assistant;
  createDefaultModuleTemplate: Scalars['Boolean'];
  createDueDiligence: DueDiligence;
  createModule: Module;
  createModuleFromTemplate: Module;
  createModuleTemplate: ModuleTemplate;
  createNotificationBanner: NotificationBanner;
  createPrompt: Prompt;
  createTermsAndConditions: TermsAndConditions;
  createTheme: Theme;
  deleteAssistant: Assistant;
  deleteChat: Chat;
  deleteDueDiligence: DueDiligenceActionResponse;
  deleteFont: Font;
  deleteMagicTableColumn: MagicTableActionResponse;
  deleteMagicTableRow: MagicTableActionResponse;
  deleteModule: Scalars['Boolean'];
  deleteModuleTemplate: Scalars['Boolean'];
  deleteNotificationBanner: NotificationBanner;
  externalLanguageModelCreate: ExternalLanguageModel;
  externalLanguageModelDelete: ExternalLanguageModel;
  externalLanguageModelUpdate: ExternalLanguageModel;
  forceIngestionState: Scalars['Boolean'];
  generateMagicTableArtifact: MagicTableActionResponse;
  ignoreNetPromoterScoreRating: NetPromoterScoreActivity;
  ingestionEventProcessor: Content;
  languageModelGroupCreate: LanguageModelGroup;
  languageModelGroupDelete: LanguageModelGroup;
  languageModelGroupUpdate: LanguageModelGroup;
  markAllForRebuidingMetadata: Scalars['Boolean'];
  markAllForReindexing: Scalars['Boolean'];
  markAllForReingestion: Scalars['Boolean'];
  markAllForVectorDataIntegrityCheck: Scalars['Boolean'];
  markForReembedding: Scalars['Boolean'];
  markNotificationBannerAsRead: NotificationBannerDismissal;
  messageAssessmentCreate: MessageAssessment;
  messageAssessmentUpdate: MessageAssessment;
  messageCreate: Message;
  messageCreateBySystem: Message;
  messageCreateSync: Array<Message>;
  messageStopStreaming: Message;
  messageUpdateData: Message;
  persistMagicTableColumnMetaData: MagicTableActionResponse;
  reIndexVectorDB: Scalars['Boolean'];
  rebuildMetadata: Scalars['Boolean'];
  reembedFiles: Scalars['Boolean'];
  reingestFiles: Scalars['Boolean'];
  scopeRuleCreateMany: Array<ScopeRule>;
  submitNetPromoterScoreRating: NetPromoterScoreRating;
  undoDeleteChat: Chat;
  updateAssistant: Assistant;
  updateDefaultAssistant: Assistant;
  updateMagicTableCell: MagicTableActionResponse;
  updateMagicTableSheet: MagicTableSheet;
  updateModule: Module;
  updateModuleTemplate: ModuleTemplate;
  updateNotificationBanner: NotificationBanner;
  updateTheme: Theme;
  userPromptCreate: UserPrompt;
  userPromptCreateMany: Array<UserPrompt>;
  userPromptDelete: UserPrompt;
  userPromptDeleteAllUserOwnedPrompts: Scalars['Int'];
  userPromptDeleteMany: Scalars['Int'];
  userPromptUpdate: UserPrompt;
};


export type MutationAbortBenchmarkArgs = {
  benchmarkId: Scalars['String'];
};


export type MutationAddDueDilgenceMetaDataArgs = {
  dueDiligenceId: Scalars['String'];
  questionFileIds?: InputMaybe<Array<Scalars['String']>>;
  questionTexts?: InputMaybe<Array<Scalars['String']>>;
  sourceFileIds?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationAgreedToTermsAndConditionsArgs = {
  termsAndConditionsId: Scalars['String'];
};


export type MutationAnalyticsOrderDeleteArgs = {
  orderId: Scalars['String'];
};


export type MutationAnalyticsOrderStartArgs = {
  orderId: Scalars['String'];
};


export type MutationChatCreateArgs = {
  assistantId?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationChatDeleteByChatIdsArgs = {
  chatIds: Array<Scalars['String']>;
};


export type MutationChatUpdateTitleArgs = {
  chatId: Scalars['String'];
  title: Scalars['String'];
};


export type MutationCheckVectorDataIntegrityArgs = {
  waitAfterRounds?: InputMaybe<Scalars['Int']>;
  waitInMs?: InputMaybe<Scalars['Int']>;
};


export type MutationChunkCreateArgs = {
  contentId: Scalars['String'];
  input: ChunkCreateInput;
};


export type MutationChunkCreateManyArgs = {
  contentId: Scalars['String'];
  input: Array<ChunkCreateInput>;
};


export type MutationChunkDeleteByContentArgs = {
  id: Scalars['String'];
};


export type MutationChunkUpdateArgs = {
  chunkId: Scalars['String'];
  contentId: Scalars['String'];
  input: ChunkUpdateInput;
};


export type MutationCompanyMetaUpdateArgs = {
  embeddingModel?: InputMaybe<Scalars['String']>;
};


export type MutationCompanyUpdateDataArgs = {
  input: CompanyUpdateInput;
};


export type MutationContentDeleteArgs = {
  id: Scalars['String'];
};


export type MutationContentDeleteByContentIdsArgs = {
  contentIds: Array<Scalars['String']>;
};


export type MutationContentDeleteByKeyArgs = {
  baseUrl?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  ownerType: Scalars['String'];
  scopeId?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};


export type MutationContentDeleteByOwnerIdArgs = {
  ownerIds: Array<Scalars['String']>;
  shouldSoftDeleteContent?: InputMaybe<Scalars['Boolean']>;
};


export type MutationContentServiceUpdateArgs = {
  contentId: Scalars['String'];
  input: ContentUpdateInput;
  previewPdfFileName?: InputMaybe<Scalars['String']>;
};


export type MutationContentUpdateArgs = {
  contentId: Scalars['String'];
  input: ContentUpdateInput;
  ownerId?: InputMaybe<Scalars['String']>;
};


export type MutationContentUpsertArgs = {
  baseUrl?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['String']>;
  fileUrl?: InputMaybe<Scalars['String']>;
  input: ContentCreateInput;
  scopeId?: InputMaybe<Scalars['String']>;
  sourceKind?: InputMaybe<Scalars['String']>;
  sourceName?: InputMaybe<Scalars['String']>;
  sourceOwnerType?: InputMaybe<Scalars['String']>;
  storeInternally?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
};


export type MutationContentUpsertByChatArgs = {
  chatId: Scalars['String'];
  fileUrl?: InputMaybe<Scalars['String']>;
  input: ContentCreateInput;
  storeInternally?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateAssistantArgs = {
  assistantAccessCreateInput?: InputMaybe<Array<AssistantAccessCreateDto>>;
  input: AssistantCreateInput;
};


export type MutationCreateDefaultModuleTemplateArgs = {
  defautlModuleTemplateName: Scalars['String'];
};


export type MutationCreateDueDiligenceArgs = {
  assistantId: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationCreateModuleArgs = {
  assistantId: Scalars['String'];
  input: ModuleCreateInput;
  moduleTemplateId?: InputMaybe<Scalars['String']>;
};


export type MutationCreateModuleFromTemplateArgs = {
  assistantId: Scalars['String'];
  templateId: Scalars['String'];
};


export type MutationCreateModuleTemplateArgs = {
  input: ModuleTemplateCreateWithoutCompanyInput;
};


export type MutationCreateNotificationBannerArgs = {
  input: NotificationBannerCreateInput;
};


export type MutationCreatePromptArgs = {
  assistantId: Scalars['String'];
  input: PromptCreateInput;
};


export type MutationCreateTermsAndConditionsArgs = {
  termsAndConditions: Scalars['String'];
};


export type MutationCreateThemeArgs = {
  input: ThemeCreateInput;
};


export type MutationDeleteAssistantArgs = {
  id: Scalars['String'];
};


export type MutationDeleteChatArgs = {
  chatId: Scalars['String'];
};


export type MutationDeleteDueDiligenceArgs = {
  dueDiligenceId: Scalars['String'];
};


export type MutationDeleteFontArgs = {
  fontId: Scalars['String'];
  themeId: Scalars['String'];
};


export type MutationDeleteMagicTableColumnArgs = {
  columnOrder: Scalars['Float'];
  sheetId: Scalars['String'];
};


export type MutationDeleteMagicTableRowArgs = {
  rowOrder: Scalars['Float'];
  sheetId: Scalars['String'];
};


export type MutationDeleteModuleArgs = {
  moduleId: Scalars['String'];
};


export type MutationDeleteModuleTemplateArgs = {
  moduleTemplateId: Scalars['String'];
};


export type MutationDeleteNotificationBannerArgs = {
  notificationBannerId: Scalars['String'];
};


export type MutationExternalLanguageModelCreateArgs = {
  groupName: Scalars['String'];
  input: ExternalLanguageModelCreateInput;
};


export type MutationExternalLanguageModelDeleteArgs = {
  id: Scalars['String'];
};


export type MutationExternalLanguageModelUpdateArgs = {
  id: Scalars['String'];
  input: ExternalLanguageModelUpdateInput;
};


export type MutationForceIngestionStateArgs = {
  ingestionState: IngestionState;
  where?: InputMaybe<ContentWhereInput>;
};


export type MutationGenerateMagicTableArtifactArgs = {
  artifactTypes: Array<MagicTableArtifactType>;
  sheetId: Scalars['String'];
};


export type MutationIngestionEventProcessorArgs = {
  contentId: Scalars['String'];
  event: Scalars['String'];
};


export type MutationLanguageModelGroupCreateArgs = {
  input: LanguageModelGroupCreateInput;
};


export type MutationLanguageModelGroupDeleteArgs = {
  groupName: Scalars['String'];
};


export type MutationLanguageModelGroupUpdateArgs = {
  groupName: Scalars['String'];
  input: LanguageModelGroupUpdateInput;
};


export type MutationMarkAllForRebuidingMetadataArgs = {
  where?: InputMaybe<ContentWhereInput>;
};


export type MutationMarkAllForReindexingArgs = {
  where?: InputMaybe<ContentWhereInput>;
};


export type MutationMarkAllForReingestionArgs = {
  where?: InputMaybe<ContentWhereInput>;
};


export type MutationMarkAllForVectorDataIntegrityCheckArgs = {
  where?: InputMaybe<ContentWhereInput>;
};


export type MutationMarkForReembeddingArgs = {
  where?: InputMaybe<ContentWhereInput>;
};


export type MutationMarkNotificationBannerAsReadArgs = {
  notificationBannerId: Scalars['String'];
};


export type MutationMessageAssessmentCreateArgs = {
  input: MessageAssessmentCreateInput;
  messageId: Scalars['String'];
};


export type MutationMessageAssessmentUpdateArgs = {
  input: MessageAssessmentUpdateInput;
  messageId: Scalars['String'];
};


export type MutationMessageCreateArgs = {
  assistantId?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['String']>;
  input: MessageCreateInput;
  moduleSelectionOnly?: InputMaybe<Scalars['Boolean']>;
  syncMode?: InputMaybe<Scalars['Boolean']>;
  translationInput?: InputMaybe<TranslationOneToOneCreateInput>;
};


export type MutationMessageCreateBySystemArgs = {
  chatId: Scalars['String'];
  input: MessageCreateInput;
};


export type MutationMessageCreateSyncArgs = {
  assistantId?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['String']>;
  input: MessageCreateInput;
};


export type MutationMessageStopStreamingArgs = {
  chatId: Scalars['String'];
  messageId: Scalars['String'];
};


export type MutationMessageUpdateDataArgs = {
  chatId: Scalars['String'];
  input: MessageUpdateInput;
  messageId: Scalars['String'];
};


export type MutationPersistMagicTableColumnMetaDataArgs = {
  columnOrder: Scalars['Float'];
  metaData: MagicTableColumnCreateInput;
  sheetId: Scalars['String'];
};


export type MutationRebuildMetadataArgs = {
  waitAfterRounds?: InputMaybe<Scalars['Int']>;
  waitInMs?: InputMaybe<Scalars['Int']>;
};


export type MutationReembedFilesArgs = {
  waitAfterRounds?: InputMaybe<Scalars['Int']>;
  waitInMs?: InputMaybe<Scalars['Int']>;
};


export type MutationReingestFilesArgs = {
  waitAfterRounds?: InputMaybe<Scalars['Int']>;
  waitInMs?: InputMaybe<Scalars['Int']>;
};


export type MutationScopeRuleCreateManyArgs = {
  assistantId: Scalars['String'];
  data: Array<ScopeRuleCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSubmitNetPromoterScoreRatingArgs = {
  input: NetPromoterScoreRatingCreateInput;
};


export type MutationUndoDeleteChatArgs = {
  chatId: Scalars['String'];
};


export type MutationUpdateAssistantArgs = {
  assistantAccessCreateInput?: InputMaybe<Array<AssistantAccessCreateDto>>;
  assistantAccessIdsToDelete?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  input: AssistantUpdateInput;
};


export type MutationUpdateDefaultAssistantArgs = {
  newDefaultAssistantId: Scalars['String'];
};


export type MutationUpdateMagicTableCellArgs = {
  columnOrder: Scalars['Float'];
  data: Scalars['String'];
  rowOrder: Scalars['Float'];
  sheetId: Scalars['String'];
};


export type MutationUpdateMagicTableSheetArgs = {
  name?: InputMaybe<Scalars['String']>;
  sheetId: Scalars['String'];
  state?: InputMaybe<MagicTableSheetState>;
};


export type MutationUpdateModuleArgs = {
  input: ModuleUpdateInput;
  moduleId: Scalars['String'];
};


export type MutationUpdateModuleTemplateArgs = {
  input: ModuleTemplateUpdateInput;
  moduleTemplateId: Scalars['String'];
};


export type MutationUpdateNotificationBannerArgs = {
  input: NotificationBannerUpdateInput;
  notificationBannerId: Scalars['String'];
};


export type MutationUpdateThemeArgs = {
  input: ThemeUpdateInput;
  themeId: Scalars['String'];
};


export type MutationUserPromptCreateArgs = {
  assistantId: Scalars['String'];
  input: UserPromptCreateInput;
};


export type MutationUserPromptCreateManyArgs = {
  areOwnedByUser: Scalars['Boolean'];
  assistantId: Scalars['String'];
  data: Array<UserPromptCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUserPromptDeleteArgs = {
  assistantId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationUserPromptDeleteAllUserOwnedPromptsArgs = {
  assistantId: Scalars['String'];
  where?: InputMaybe<UserPromptWhereInput>;
};


export type MutationUserPromptDeleteManyArgs = {
  areOwnedByUser: Scalars['Boolean'];
  assistantId: Scalars['String'];
  where?: InputMaybe<UserPromptWhereInput>;
};


export type MutationUserPromptUpdateArgs = {
  assistantId: Scalars['String'];
  id: Scalars['String'];
  input: UserPromptUpdateInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumAnalyticsOrderStateFilter = {
  equals?: InputMaybe<AnalyticsOrderState>;
  in?: InputMaybe<Array<AnalyticsOrderState>>;
  not?: InputMaybe<NestedEnumAnalyticsOrderStateFilter>;
  notIn?: InputMaybe<Array<AnalyticsOrderState>>;
};

export type NestedEnumAnalyticsTypeFilter = {
  equals?: InputMaybe<AnalyticsType>;
  in?: InputMaybe<Array<AnalyticsType>>;
  not?: InputMaybe<NestedEnumAnalyticsTypeFilter>;
  notIn?: InputMaybe<Array<AnalyticsType>>;
};

export type NestedEnumBenchmarkStatusFilter = {
  equals?: InputMaybe<BenchmarkStatus>;
  in?: InputMaybe<Array<BenchmarkStatus>>;
  not?: InputMaybe<NestedEnumBenchmarkStatusFilter>;
  notIn?: InputMaybe<Array<BenchmarkStatus>>;
};

export type NestedEnumChatUploadFilter = {
  equals?: InputMaybe<ChatUpload>;
  in?: InputMaybe<Array<ChatUpload>>;
  not?: InputMaybe<NestedEnumChatUploadFilter>;
  notIn?: InputMaybe<Array<ChatUpload>>;
};

export type NestedEnumIngestionStateFilter = {
  equals?: InputMaybe<IngestionState>;
  in?: InputMaybe<Array<IngestionState>>;
  not?: InputMaybe<NestedEnumIngestionStateFilter>;
  notIn?: InputMaybe<Array<IngestionState>>;
};

export type NestedEnumMagicTableActorTypeFilter = {
  equals?: InputMaybe<MagicTableActorType>;
  in?: InputMaybe<Array<MagicTableActorType>>;
  not?: InputMaybe<NestedEnumMagicTableActorTypeFilter>;
  notIn?: InputMaybe<Array<MagicTableActorType>>;
};

export type NestedEnumNotificationBannerTypeFilter = {
  equals?: InputMaybe<NotificationBannerType>;
  in?: InputMaybe<Array<NotificationBannerType>>;
  not?: InputMaybe<NestedEnumNotificationBannerTypeFilter>;
  notIn?: InputMaybe<Array<NotificationBannerType>>;
};

export type NestedEnumOwnerTypeFilter = {
  equals?: InputMaybe<OwnerType>;
  in?: InputMaybe<Array<OwnerType>>;
  not?: InputMaybe<NestedEnumOwnerTypeFilter>;
  notIn?: InputMaybe<Array<OwnerType>>;
};

export type NestedEnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type NestedEnumSourceKindFilter = {
  equals?: InputMaybe<SourceKind>;
  in?: InputMaybe<Array<SourceKind>>;
  not?: InputMaybe<NestedEnumSourceKindFilter>;
  notIn?: InputMaybe<Array<SourceKind>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NetPromoterScoreActivity = {
  __typename?: 'NetPromoterScoreActivity';
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  type: NetPromoterScoreActivityType;
  updatedAt: Scalars['DateTime'];
};

export enum NetPromoterScoreActivityType {
  Ignored = 'IGNORED',
  Rated = 'RATED'
}

export type NetPromoterScoreRating = {
  __typename?: 'NetPromoterScoreRating';
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  feedback?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  score: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type NetPromoterScoreRatingCreateInput = {
  companyId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: Scalars['String'];
  feedback?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  score: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type NetPromoterScoreTriggerCheckResponse = {
  __typename?: 'NetPromoterScoreTriggerCheckResponse';
  delayInSecBeforeQuestionDisplay?: Maybe<Scalars['Float']>;
  message: Scalars['String'];
  question?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type NotificationBanner = {
  __typename?: 'NotificationBanner';
  _count: NotificationBannerCount;
  companyId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dismissals?: Maybe<Array<NotificationBannerDismissal>>;
  dismissibleUntil?: Maybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isDismissible: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  linkText?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  notificationTo?: Maybe<Array<Scalars['String']>>;
  startDate: Scalars['DateTime'];
  title: Scalars['String'];
  type: NotificationBannerType;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};

export type NotificationBannerCount = {
  __typename?: 'NotificationBannerCount';
  dismissals: Scalars['Int'];
};

export type NotificationBannerCreateInput = {
  companyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dismissals?: InputMaybe<NotificationBannerDismissalCreateNestedManyWithoutBannerInput>;
  dismissibleUntil?: InputMaybe<Scalars['DateTime']>;
  endDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDismissible?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  linkText?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  notificationTo?: InputMaybe<Array<Scalars['String']>>;
  startDate: Scalars['DateTime'];
  title: Scalars['String'];
  type: NotificationBannerType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type NotificationBannerDismissal = {
  __typename?: 'NotificationBannerDismissal';
  banner: NotificationBanner;
  bannerId: Scalars['String'];
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  dismissedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type NotificationBannerDismissalCreateManyBannerInput = {
  companyId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dismissedAt: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type NotificationBannerDismissalCreateManyBannerInputEnvelope = {
  data: Array<NotificationBannerDismissalCreateManyBannerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type NotificationBannerDismissalCreateNestedManyWithoutBannerInput = {
  connect?: InputMaybe<Array<NotificationBannerDismissalWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationBannerDismissalCreateOrConnectWithoutBannerInput>>;
  create?: InputMaybe<Array<NotificationBannerDismissalCreateWithoutBannerInput>>;
  createMany?: InputMaybe<NotificationBannerDismissalCreateManyBannerInputEnvelope>;
};

export type NotificationBannerDismissalCreateOrConnectWithoutBannerInput = {
  create: NotificationBannerDismissalCreateWithoutBannerInput;
  where: NotificationBannerDismissalWhereUniqueInput;
};

export type NotificationBannerDismissalCreateWithoutBannerInput = {
  companyId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dismissedAt: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type NotificationBannerDismissalListRelationFilter = {
  every?: InputMaybe<NotificationBannerDismissalWhereInput>;
  none?: InputMaybe<NotificationBannerDismissalWhereInput>;
  some?: InputMaybe<NotificationBannerDismissalWhereInput>;
};

export type NotificationBannerDismissalOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type NotificationBannerDismissalScalarWhereInput = {
  AND?: InputMaybe<Array<NotificationBannerDismissalScalarWhereInput>>;
  NOT?: InputMaybe<Array<NotificationBannerDismissalScalarWhereInput>>;
  OR?: InputMaybe<Array<NotificationBannerDismissalScalarWhereInput>>;
  bannerId?: InputMaybe<StringFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dismissedAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type NotificationBannerDismissalUpdateManyMutationInput = {
  companyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dismissedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type NotificationBannerDismissalUpdateManyWithWhereWithoutBannerInput = {
  data: NotificationBannerDismissalUpdateManyMutationInput;
  where: NotificationBannerDismissalScalarWhereInput;
};

export type NotificationBannerDismissalUpdateManyWithoutBannerNestedInput = {
  connect?: InputMaybe<Array<NotificationBannerDismissalWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<NotificationBannerDismissalCreateOrConnectWithoutBannerInput>>;
  create?: InputMaybe<Array<NotificationBannerDismissalCreateWithoutBannerInput>>;
  createMany?: InputMaybe<NotificationBannerDismissalCreateManyBannerInputEnvelope>;
  delete?: InputMaybe<Array<NotificationBannerDismissalWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<NotificationBannerDismissalScalarWhereInput>>;
  disconnect?: InputMaybe<Array<NotificationBannerDismissalWhereUniqueInput>>;
  set?: InputMaybe<Array<NotificationBannerDismissalWhereUniqueInput>>;
  update?: InputMaybe<Array<NotificationBannerDismissalUpdateWithWhereUniqueWithoutBannerInput>>;
  updateMany?: InputMaybe<Array<NotificationBannerDismissalUpdateManyWithWhereWithoutBannerInput>>;
  upsert?: InputMaybe<Array<NotificationBannerDismissalUpsertWithWhereUniqueWithoutBannerInput>>;
};

export type NotificationBannerDismissalUpdateWithWhereUniqueWithoutBannerInput = {
  data: NotificationBannerDismissalUpdateWithoutBannerInput;
  where: NotificationBannerDismissalWhereUniqueInput;
};

export type NotificationBannerDismissalUpdateWithoutBannerInput = {
  companyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dismissedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type NotificationBannerDismissalUpsertWithWhereUniqueWithoutBannerInput = {
  create: NotificationBannerDismissalCreateWithoutBannerInput;
  update: NotificationBannerDismissalUpdateWithoutBannerInput;
  where: NotificationBannerDismissalWhereUniqueInput;
};

export type NotificationBannerDismissalWhereInput = {
  AND?: InputMaybe<Array<NotificationBannerDismissalWhereInput>>;
  NOT?: InputMaybe<Array<NotificationBannerDismissalWhereInput>>;
  OR?: InputMaybe<Array<NotificationBannerDismissalWhereInput>>;
  banner?: InputMaybe<NotificationBannerRelationFilter>;
  bannerId?: InputMaybe<StringFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dismissedAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type NotificationBannerDismissalWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type NotificationBannerOrderByWithRelationInput = {
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  dismissals?: InputMaybe<NotificationBannerDismissalOrderByRelationAggregateInput>;
  dismissibleUntil?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isActive?: InputMaybe<SortOrder>;
  isDismissible?: InputMaybe<SortOrder>;
  link?: InputMaybe<SortOrder>;
  linkText?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  notificationTo?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type NotificationBannerRelationFilter = {
  is?: InputMaybe<NotificationBannerWhereInput>;
  isNot?: InputMaybe<NotificationBannerWhereInput>;
};

export enum NotificationBannerScalarFieldEnum {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  DismissibleUntil = 'dismissibleUntil',
  EndDate = 'endDate',
  Id = 'id',
  IsActive = 'isActive',
  IsDismissible = 'isDismissible',
  Link = 'link',
  LinkText = 'linkText',
  Message = 'message',
  NotificationTo = 'notificationTo',
  StartDate = 'startDate',
  Title = 'title',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export enum NotificationBannerType {
  Announcement = 'ANNOUNCEMENT',
  Info = 'INFO',
  Maintenance = 'MAINTENANCE',
  Outage = 'OUTAGE'
}

export type NotificationBannerUpdateInput = {
  companyId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dismissals?: InputMaybe<NotificationBannerDismissalUpdateManyWithoutBannerNestedInput>;
  dismissibleUntil?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isDismissible?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  linkText?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  notificationTo?: InputMaybe<Array<Scalars['String']>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<NotificationBannerType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type NotificationBannerWhereInput = {
  AND?: InputMaybe<Array<NotificationBannerWhereInput>>;
  NOT?: InputMaybe<Array<NotificationBannerWhereInput>>;
  OR?: InputMaybe<Array<NotificationBannerWhereInput>>;
  companyId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dismissals?: InputMaybe<NotificationBannerDismissalListRelationFilter>;
  dismissibleUntil?: InputMaybe<DateTimeNullableFilter>;
  endDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isActive?: InputMaybe<BoolFilter>;
  isDismissible?: InputMaybe<BoolFilter>;
  link?: InputMaybe<StringNullableFilter>;
  linkText?: InputMaybe<StringNullableFilter>;
  message?: InputMaybe<StringFilter>;
  notificationTo?: InputMaybe<StringNullableListFilter>;
  startDate?: InputMaybe<DateTimeFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumNotificationBannerTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type NotificationBannerWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type OversizedContent = {
  __typename?: 'OversizedContent';
  chunkid: Scalars['String'];
  count: Scalars['Float'];
  id: Scalars['String'];
  key: Scalars['String'];
};

export enum OwnerType {
  Chat = 'CHAT',
  Company = 'COMPANY',
  Scope = 'SCOPE',
  User = 'USER'
}

export type PaginatedContent = {
  __typename?: 'PaginatedContent';
  contentContributors: Array<PaginatedContentContributor>;
  contentOwners: Array<PaginatedContentOwner>;
  nodes?: Maybe<Array<Content>>;
  totalCount: Scalars['Int'];
};

export type PaginatedContentContributor = {
  __typename?: 'PaginatedContentContributor';
  contentId: Scalars['String'];
  contributor: Scalars['String'];
};

export type PaginatedContentOwner = {
  __typename?: 'PaginatedContentOwner';
  ownerId: Scalars['String'];
  ownerName: Scalars['String'];
};

export type PaginatedMessage = {
  __typename?: 'PaginatedMessage';
  nodes?: Maybe<Array<Message>>;
  totalCount: Scalars['Int'];
};

export type Prompt = {
  __typename?: 'Prompt';
  assistant: Assistant;
  assistantId: Scalars['String'];
  constraints?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['DateTime'];
  goals?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  prefix: Scalars['String'];
  suffix: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PromptCreateInput = {
  constraints?: InputMaybe<Array<Scalars['String']>>;
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  prefix: Scalars['String'];
  suffix: Scalars['String'];
};

export type PromptCreateManyAssistantInput = {
  constraints?: InputMaybe<Array<Scalars['String']>>;
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  prefix: Scalars['String'];
  suffix: Scalars['String'];
};

export type PromptCreateManyAssistantInputEnvelope = {
  data: Array<PromptCreateManyAssistantInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PromptCreateNestedManyWithoutAssistantInput = {
  connect?: InputMaybe<Array<PromptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromptCreateOrConnectWithoutAssistantInput>>;
  create?: InputMaybe<Array<PromptCreateWithoutAssistantInput>>;
  createMany?: InputMaybe<PromptCreateManyAssistantInputEnvelope>;
};

export type PromptCreateOrConnectWithoutAssistantInput = {
  create: PromptCreateWithoutAssistantInput;
  where: PromptWhereUniqueInput;
};

export type PromptCreateWithoutAssistantInput = {
  constraints?: InputMaybe<Array<Scalars['String']>>;
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  prefix: Scalars['String'];
  suffix: Scalars['String'];
};

export type PromptListRelationFilter = {
  every?: InputMaybe<PromptWhereInput>;
  none?: InputMaybe<PromptWhereInput>;
  some?: InputMaybe<PromptWhereInput>;
};

export type PromptOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PromptScalarWhereInput = {
  AND?: InputMaybe<Array<PromptScalarWhereInput>>;
  NOT?: InputMaybe<Array<PromptScalarWhereInput>>;
  OR?: InputMaybe<Array<PromptScalarWhereInput>>;
  constraints?: InputMaybe<StringNullableListFilter>;
  goals?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  prefix?: InputMaybe<StringFilter>;
  suffix?: InputMaybe<StringFilter>;
};

export type PromptUpdateManyMutationInput = {
  constraints?: InputMaybe<Array<Scalars['String']>>;
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  prefix?: InputMaybe<Scalars['String']>;
  suffix?: InputMaybe<Scalars['String']>;
};

export type PromptUpdateManyWithWhereWithoutAssistantInput = {
  data: PromptUpdateManyMutationInput;
  where: PromptScalarWhereInput;
};

export type PromptUpdateManyWithoutAssistantNestedInput = {
  connect?: InputMaybe<Array<PromptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromptCreateOrConnectWithoutAssistantInput>>;
  create?: InputMaybe<Array<PromptCreateWithoutAssistantInput>>;
  createMany?: InputMaybe<PromptCreateManyAssistantInputEnvelope>;
  delete?: InputMaybe<Array<PromptWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PromptScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PromptWhereUniqueInput>>;
  set?: InputMaybe<Array<PromptWhereUniqueInput>>;
  update?: InputMaybe<Array<PromptUpdateWithWhereUniqueWithoutAssistantInput>>;
  updateMany?: InputMaybe<Array<PromptUpdateManyWithWhereWithoutAssistantInput>>;
  upsert?: InputMaybe<Array<PromptUpsertWithWhereUniqueWithoutAssistantInput>>;
};

export type PromptUpdateWithWhereUniqueWithoutAssistantInput = {
  data: PromptUpdateWithoutAssistantInput;
  where: PromptWhereUniqueInput;
};

export type PromptUpdateWithoutAssistantInput = {
  constraints?: InputMaybe<Array<Scalars['String']>>;
  goals?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  prefix?: InputMaybe<Scalars['String']>;
  suffix?: InputMaybe<Scalars['String']>;
};

export type PromptUpsertWithWhereUniqueWithoutAssistantInput = {
  create: PromptCreateWithoutAssistantInput;
  update: PromptUpdateWithoutAssistantInput;
  where: PromptWhereUniqueInput;
};

export type PromptWhereInput = {
  AND?: InputMaybe<Array<PromptWhereInput>>;
  NOT?: InputMaybe<Array<PromptWhereInput>>;
  OR?: InputMaybe<Array<PromptWhereInput>>;
  constraints?: InputMaybe<StringNullableListFilter>;
  goals?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  prefix?: InputMaybe<StringFilter>;
  suffix?: InputMaybe<StringFilter>;
};

export type PromptWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  activeNotificationBanner?: Maybe<NotificationBanner>;
  allAssistants: Array<Assistant>;
  allAssistantsByCompanyId: Array<Assistant>;
  allAssistantsByUserId: Array<Assistant>;
  analyticsOrders: Array<AnalyticsOrder>;
  assistantByCompany: Assistant;
  assistantByUser: Assistant;
  assistantModuleTypes: Array<Scalars['String']>;
  assistants: Array<Assistant>;
  chat: Chat;
  chats: Array<Chat>;
  chunk: Array<Chunk>;
  chunkById: Chunk;
  company: Company;
  companyMeta: CompanyMeta;
  content: Array<Content>;
  contentByChat: Array<Content>;
  contentByCompany: Array<Content>;
  contentById: Array<Content>;
  contentByUser: Array<Content>;
  contentWithOversizedChunks: Array<OversizedContent>;
  dueDiligence: Array<DueDiligence>;
  externalLanguageModelFindMany: Array<ExternalLanguageModel>;
  getCurrentStatus: BenchmarkStatusResponse;
  getLatestPrompt: Prompt;
  getMagicTableCellLogEntry: Array<MagicTableLogEntry>;
  getTransformedMagicTableSheet: TransformedMagicTableResponse;
  languageModelGroupFindMany: Array<LanguageModelGroup>;
  latestTermsAndConditions: TermsAndConditions;
  magicTableSheet: MagicTableSheet;
  message: Message;
  messageAssessmentsByChat: Array<MessageAssessment>;
  messages: Array<Message>;
  moduleTemplates: Array<ModuleTemplate>;
  modules: Array<Module>;
  netPromoterScoreTriggerCheck: NetPromoterScoreTriggerCheckResponse;
  notificationBanners: Array<NotificationBanner>;
  paginatedContent: PaginatedContent;
  paginatedContentForRule: PaginatedContent;
  paginatedMessage: PaginatedMessage;
  scopeRulesByAssistant: Array<ScopeRule>;
  statisticsIngestion: StatisticsIngestion;
  termsAndConditions: Array<TermsAndConditions>;
  theme: Theme;
  translationOneToOneForMessage: TranslationOneToOne;
  userPrompt: Array<UserPrompt>;
};


export type QueryAllAssistantsArgs = {
  cursor?: InputMaybe<AssistantWhereUniqueInput>;
  distinct?: InputMaybe<Array<AssistantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AssistantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssistantWhereInput>;
};


export type QueryAllAssistantsByUserIdArgs = {
  cursor?: InputMaybe<AssistantWhereUniqueInput>;
  distinct?: InputMaybe<Array<AssistantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AssistantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  userId: Scalars['String'];
  where?: InputMaybe<AssistantWhereInput>;
};


export type QueryAnalyticsOrdersArgs = {
  cursor?: InputMaybe<AnalyticsOrderWhereUniqueInput>;
  distinct?: InputMaybe<Array<AnalyticsOrderScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AnalyticsOrderOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AnalyticsOrderWhereInput>;
};


export type QueryAssistantByCompanyArgs = {
  assistantId: Scalars['String'];
};


export type QueryAssistantByUserArgs = {
  assistantId: Scalars['String'];
};


export type QueryAssistantsArgs = {
  cursor?: InputMaybe<AssistantWhereUniqueInput>;
  distinct?: InputMaybe<Array<AssistantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AssistantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssistantWhereInput>;
};


export type QueryChatArgs = {
  chatId: Scalars['String'];
};


export type QueryChatsArgs = {
  cursor?: InputMaybe<ChatWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChatScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChatOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChatWhereInput>;
};


export type QueryChunkArgs = {
  chatId?: InputMaybe<Scalars['String']>;
  contentId: Scalars['String'];
  cursor?: InputMaybe<ChunkWhereUniqueInput>;
  distinct?: InputMaybe<Array<ChunkScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ChunkOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChunkWhereInput>;
};


export type QueryChunkByIdArgs = {
  contentId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryContentArgs = {
  accessType?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryContentByChatArgs = {
  accessType?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['String']>;
};


export type QueryContentByCompanyArgs = {
  chatId?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  includeDeletedContent?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryContentByIdArgs = {
  accessType?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['String']>;
  contentIds: Array<Scalars['String']>;
};


export type QueryContentByUserArgs = {
  accessType?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  includeDeletedContent?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryContentWithOversizedChunksArgs = {
  textLength?: InputMaybe<Scalars['Float']>;
};


export type QueryDueDiligenceArgs = {
  cursor?: InputMaybe<DueDiligenceWhereUniqueInput>;
  distinct?: InputMaybe<Array<DueDiligenceScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<DueDiligenceOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DueDiligenceWhereInput>;
};


export type QueryExternalLanguageModelFindManyArgs = {
  cursor?: InputMaybe<ExternalLanguageModelWhereUniqueInput>;
  distinct?: InputMaybe<Array<ExternalLanguageModelScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ExternalLanguageModelOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExternalLanguageModelWhereInput>;
};


export type QueryGetLatestPromptArgs = {
  assistantId: Scalars['String'];
  promptName: Scalars['String'];
};


export type QueryGetMagicTableCellLogEntryArgs = {
  columnOrder: Scalars['Float'];
  rowOrder: Scalars['Float'];
  sheetId: Scalars['String'];
};


export type QueryGetTransformedMagicTableSheetArgs = {
  cursor?: InputMaybe<MagicTableSheetWhereUniqueInput>;
  distinct?: InputMaybe<Array<MagicTableSheetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MagicTableSheetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MagicTableSheetWhereInput>;
};


export type QueryLanguageModelGroupFindManyArgs = {
  cursor?: InputMaybe<LanguageModelGroupWhereUniqueInput>;
  distinct?: InputMaybe<Array<LanguageModelGroupScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LanguageModelGroupOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LanguageModelGroupWhereInput>;
};


export type QueryMagicTableSheetArgs = {
  cursor?: InputMaybe<MagicTableSheetWhereUniqueInput>;
  distinct?: InputMaybe<Array<MagicTableSheetScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MagicTableSheetOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MagicTableSheetWhereInput>;
};


export type QueryMessageArgs = {
  chatId: Scalars['String'];
  messageId: Scalars['String'];
};


export type QueryMessageAssessmentsByChatArgs = {
  chatId: Scalars['String'];
};


export type QueryMessagesArgs = {
  chatId: Scalars['String'];
  cursor?: InputMaybe<MessageWhereUniqueInput>;
  distinct?: InputMaybe<Array<MessageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MessageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MessageWhereInput>;
};


export type QueryModuleTemplatesArgs = {
  cursor?: InputMaybe<ModuleTemplateWhereUniqueInput>;
  distinct?: InputMaybe<Array<ModuleTemplateScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ModuleTemplateOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ModuleTemplateWhereInput>;
};


export type QueryModulesArgs = {
  cursor?: InputMaybe<ModuleWhereUniqueInput>;
  distinct?: InputMaybe<Array<ModuleScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ModuleOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ModuleWhereInput>;
};


export type QueryNotificationBannersArgs = {
  cursor?: InputMaybe<NotificationBannerWhereUniqueInput>;
  distinct?: InputMaybe<Array<NotificationBannerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<NotificationBannerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<NotificationBannerWhereInput>;
};


export type QueryPaginatedContentArgs = {
  accessType?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryPaginatedContentForRuleArgs = {
  rule: Scalars['JSON'];
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryPaginatedMessageArgs = {
  chatId: Scalars['String'];
  cursor?: InputMaybe<MessageWhereUniqueInput>;
  distinct?: InputMaybe<Array<MessageScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MessageOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MessageWhereInput>;
};


export type QueryScopeRulesByAssistantArgs = {
  assistantId: Scalars['String'];
};


export type QueryStatisticsIngestionArgs = {
  accessType?: InputMaybe<Scalars['String']>;
  chatId?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<ContentWhereUniqueInput>;
  distinct?: InputMaybe<Array<ContentScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ContentWhereInput>;
};


export type QueryTermsAndConditionsArgs = {
  cursor?: InputMaybe<TermsAndConditionsWhereUniqueInput>;
  distinct?: InputMaybe<Array<TermsAndConditionsScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TermsAndConditionsOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TermsAndConditionsWhereInput>;
};


export type QueryThemeArgs = {
  themeId?: InputMaybe<Scalars['String']>;
};


export type QueryTranslationOneToOneForMessageArgs = {
  chatId: Scalars['String'];
  messageId: Scalars['String'];
};


export type QueryUserPromptArgs = {
  assistantId: Scalars['String'];
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type Reference = {
  __typename?: 'Reference';
  id: Scalars['ID'];
  message: Message;
  name: Scalars['String'];
  sequenceNumber: Scalars['Int'];
  source: Scalars['String'];
  sourceId: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export enum Role {
  Assistant = 'ASSISTANT',
  System = 'SYSTEM',
  User = 'USER'
}

export type ScopeRule = {
  __typename?: 'ScopeRule';
  assistant: Assistant;
  assistantId: Scalars['String'];
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isAdvanced: Scalars['Boolean'];
  rule: Scalars['JSON'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ScopeRuleCreateManyAssistantInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isAdvanced?: InputMaybe<Scalars['Boolean']>;
  rule: Scalars['JSON'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ScopeRuleCreateManyAssistantInputEnvelope = {
  data: Array<ScopeRuleCreateManyAssistantInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ScopeRuleCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isAdvanced?: InputMaybe<Scalars['Boolean']>;
  rule: Scalars['JSON'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ScopeRuleCreateNestedManyWithoutAssistantInput = {
  connect?: InputMaybe<Array<ScopeRuleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScopeRuleCreateOrConnectWithoutAssistantInput>>;
  create?: InputMaybe<Array<ScopeRuleCreateWithoutAssistantInput>>;
  createMany?: InputMaybe<ScopeRuleCreateManyAssistantInputEnvelope>;
};

export type ScopeRuleCreateOrConnectWithoutAssistantInput = {
  create: ScopeRuleCreateWithoutAssistantInput;
  where: ScopeRuleWhereUniqueInput;
};

export type ScopeRuleCreateWithoutAssistantInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isAdvanced?: InputMaybe<Scalars['Boolean']>;
  rule: Scalars['JSON'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ScopeRuleListRelationFilter = {
  every?: InputMaybe<ScopeRuleWhereInput>;
  none?: InputMaybe<ScopeRuleWhereInput>;
  some?: InputMaybe<ScopeRuleWhereInput>;
};

export type ScopeRuleOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ScopeRuleScalarWhereInput = {
  AND?: InputMaybe<Array<ScopeRuleScalarWhereInput>>;
  NOT?: InputMaybe<Array<ScopeRuleScalarWhereInput>>;
  OR?: InputMaybe<Array<ScopeRuleScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isAdvanced?: InputMaybe<BoolFilter>;
  rule?: InputMaybe<JsonFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScopeRuleUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isAdvanced?: InputMaybe<Scalars['Boolean']>;
  rule?: InputMaybe<Scalars['JSON']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ScopeRuleUpdateManyWithWhereWithoutAssistantInput = {
  data: ScopeRuleUpdateManyMutationInput;
  where: ScopeRuleScalarWhereInput;
};

export type ScopeRuleUpdateManyWithoutAssistantNestedInput = {
  connect?: InputMaybe<Array<ScopeRuleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ScopeRuleCreateOrConnectWithoutAssistantInput>>;
  create?: InputMaybe<Array<ScopeRuleCreateWithoutAssistantInput>>;
  createMany?: InputMaybe<ScopeRuleCreateManyAssistantInputEnvelope>;
  delete?: InputMaybe<Array<ScopeRuleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ScopeRuleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ScopeRuleWhereUniqueInput>>;
  set?: InputMaybe<Array<ScopeRuleWhereUniqueInput>>;
  update?: InputMaybe<Array<ScopeRuleUpdateWithWhereUniqueWithoutAssistantInput>>;
  updateMany?: InputMaybe<Array<ScopeRuleUpdateManyWithWhereWithoutAssistantInput>>;
  upsert?: InputMaybe<Array<ScopeRuleUpsertWithWhereUniqueWithoutAssistantInput>>;
};

export type ScopeRuleUpdateWithWhereUniqueWithoutAssistantInput = {
  data: ScopeRuleUpdateWithoutAssistantInput;
  where: ScopeRuleWhereUniqueInput;
};

export type ScopeRuleUpdateWithoutAssistantInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  isAdvanced?: InputMaybe<Scalars['Boolean']>;
  rule?: InputMaybe<Scalars['JSON']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ScopeRuleUpsertWithWhereUniqueWithoutAssistantInput = {
  create: ScopeRuleCreateWithoutAssistantInput;
  update: ScopeRuleUpdateWithoutAssistantInput;
  where: ScopeRuleWhereUniqueInput;
};

export type ScopeRuleWhereInput = {
  AND?: InputMaybe<Array<ScopeRuleWhereInput>>;
  NOT?: InputMaybe<Array<ScopeRuleWhereInput>>;
  OR?: InputMaybe<Array<ScopeRuleWhereInput>>;
  assistant?: InputMaybe<AssistantRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  isAdvanced?: InputMaybe<BoolFilter>;
  rule?: InputMaybe<JsonFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ScopeRuleWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ShortTermMemory = {
  __typename?: 'ShortTermMemory';
  chat?: Maybe<Chat>;
  chatId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  data: Scalars['JSON'];
  /** @DtoReadOnly */
  expiredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  memoryName: Scalars['String'];
  message?: Maybe<Message>;
  messageId?: Maybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type Source = {
  __typename?: 'Source';
  _count: SourceCount;
  /** @DtoReadOnly */
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  kind: SourceKind;
  name?: Maybe<Scalars['String']>;
  /** @DtoReadOnly */
  ownerId: Scalars['String'];
  /** @DtoReadOnly */
  ownerType: OwnerType;
  /** @DtoReadOnly */
  updatedAt: Scalars['DateTime'];
};

export type SourceCount = {
  __typename?: 'SourceCount';
  content: Scalars['Int'];
};

export enum SourceKind {
  AtlassianConfluenceCloud = 'ATLASSIAN_CONFLUENCE_CLOUD',
  AtlassianConfluenceOnprem = 'ATLASSIAN_CONFLUENCE_ONPREM',
  FileDownload = 'FILE_DOWNLOAD',
  Intranet = 'INTRANET',
  Microsoft_365Sharepoint = 'MICROSOFT_365_SHAREPOINT',
  UniqueBlobStorage = 'UNIQUE_BLOB_STORAGE'
}

export type SourceRelationFilter = {
  is?: InputMaybe<SourceWhereInput>;
  isNot?: InputMaybe<SourceWhereInput>;
};

export type SourceWhereInput = {
  AND?: InputMaybe<Array<SourceWhereInput>>;
  NOT?: InputMaybe<Array<SourceWhereInput>>;
  OR?: InputMaybe<Array<SourceWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  kind?: InputMaybe<EnumSourceKindFilter>;
  name?: InputMaybe<StringNullableFilter>;
  ownerType?: InputMaybe<EnumOwnerTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StatisticsIngestion = {
  __typename?: 'StatisticsIngestion';
  counts: Scalars['JSON'];
};

export type StreamingError = {
  __typename?: 'StreamingError';
  message: Scalars['String'];
  status: Scalars['Int'];
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']>>;
  has?: InputMaybe<Scalars['String']>;
  hasEvery?: InputMaybe<Array<Scalars['String']>>;
  hasSome?: InputMaybe<Array<Scalars['String']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  chatUpdate: Chat;
  magicTableArtifactUpdate: MagicTableArtifact;
  magicTableCellUpdate: MagicTableCell;
  magicTableSheetUpdate: MagicTableSheet;
  messageUpdate: Message;
  streamingError: StreamingError;
};


export type SubscriptionMagicTableArtifactUpdateArgs = {
  sheetId: Scalars['String'];
};


export type SubscriptionMagicTableCellUpdateArgs = {
  sheetId: Scalars['String'];
};


export type SubscriptionMagicTableSheetUpdateArgs = {
  sheetId: Scalars['String'];
};


export type SubscriptionMessageUpdateArgs = {
  chatId: Scalars['String'];
};


export type SubscriptionStreamingErrorArgs = {
  chatId: Scalars['String'];
};

export type TermsAndConditions = {
  __typename?: 'TermsAndConditions';
  TermsAndConditionsAgreement?: Maybe<Array<TermsAndConditionsAgreement>>;
  _count: TermsAndConditionsCount;
  acceptanceStatus: Scalars['String'];
  company: Company;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  id: Scalars['ID'];
  isUniqueTermsAndConditions: Scalars['Boolean'];
  text: Scalars['String'];
};

export type TermsAndConditionsAgreement = {
  __typename?: 'TermsAndConditionsAgreement';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  termsAndConditions: TermsAndConditions;
  termsAndConditionsId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TermsAndConditionsCount = {
  __typename?: 'TermsAndConditionsCount';
  TermsAndConditionsAgreement: Scalars['Int'];
};

export type TermsAndConditionsCreateManyCompanyInput = {
  id?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

export type TermsAndConditionsCreateManyCompanyInputEnvelope = {
  data: Array<TermsAndConditionsCreateManyCompanyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TermsAndConditionsCreateNestedManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<TermsAndConditionsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TermsAndConditionsCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<TermsAndConditionsCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<TermsAndConditionsCreateManyCompanyInputEnvelope>;
};

export type TermsAndConditionsCreateOrConnectWithoutCompanyInput = {
  create: TermsAndConditionsCreateWithoutCompanyInput;
  where: TermsAndConditionsWhereUniqueInput;
};

export type TermsAndConditionsCreateWithoutCompanyInput = {
  id?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};

export type TermsAndConditionsListRelationFilter = {
  every?: InputMaybe<TermsAndConditionsWhereInput>;
  none?: InputMaybe<TermsAndConditionsWhereInput>;
  some?: InputMaybe<TermsAndConditionsWhereInput>;
};

export type TermsAndConditionsOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TermsAndConditionsOrderByWithRelationInput = {
  id?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
};

export enum TermsAndConditionsScalarFieldEnum {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  Id = 'id',
  IsUniqueTermsAndConditions = 'isUniqueTermsAndConditions',
  Text = 'text'
}

export type TermsAndConditionsScalarWhereInput = {
  AND?: InputMaybe<Array<TermsAndConditionsScalarWhereInput>>;
  NOT?: InputMaybe<Array<TermsAndConditionsScalarWhereInput>>;
  OR?: InputMaybe<Array<TermsAndConditionsScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  text?: InputMaybe<StringFilter>;
};

export type TermsAndConditionsUpdateManyMutationInput = {
  id?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type TermsAndConditionsUpdateManyWithWhereWithoutCompanyInput = {
  data: TermsAndConditionsUpdateManyMutationInput;
  where: TermsAndConditionsScalarWhereInput;
};

export type TermsAndConditionsUpdateManyWithoutCompanyNestedInput = {
  connect?: InputMaybe<Array<TermsAndConditionsWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TermsAndConditionsCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<TermsAndConditionsCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<TermsAndConditionsCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<TermsAndConditionsWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TermsAndConditionsScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TermsAndConditionsWhereUniqueInput>>;
  set?: InputMaybe<Array<TermsAndConditionsWhereUniqueInput>>;
  update?: InputMaybe<Array<TermsAndConditionsUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: InputMaybe<Array<TermsAndConditionsUpdateManyWithWhereWithoutCompanyInput>>;
  upsert?: InputMaybe<Array<TermsAndConditionsUpsertWithWhereUniqueWithoutCompanyInput>>;
};

export type TermsAndConditionsUpdateWithWhereUniqueWithoutCompanyInput = {
  data: TermsAndConditionsUpdateWithoutCompanyInput;
  where: TermsAndConditionsWhereUniqueInput;
};

export type TermsAndConditionsUpdateWithoutCompanyInput = {
  id?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
};

export type TermsAndConditionsUpsertWithWhereUniqueWithoutCompanyInput = {
  create: TermsAndConditionsCreateWithoutCompanyInput;
  update: TermsAndConditionsUpdateWithoutCompanyInput;
  where: TermsAndConditionsWhereUniqueInput;
};

export type TermsAndConditionsWhereInput = {
  AND?: InputMaybe<Array<TermsAndConditionsWhereInput>>;
  NOT?: InputMaybe<Array<TermsAndConditionsWhereInput>>;
  OR?: InputMaybe<Array<TermsAndConditionsWhereInput>>;
  id?: InputMaybe<StringFilter>;
  text?: InputMaybe<StringFilter>;
};

export type TermsAndConditionsWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Theme = {
  __typename?: 'Theme';
  _count: ThemeCount;
  colors?: Maybe<Array<Color>>;
  company?: Maybe<Array<Company>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  favicon?: Maybe<Scalars['String']>;
  fontFamilies?: Maybe<Array<FontFamily>>;
  fonts?: Maybe<Array<Font>>;
  helpCenterLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isUniqueTheme?: Maybe<Scalars['Boolean']>;
  logoHeader?: Maybe<Scalars['String']>;
  logoNavbar?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  supportEmail?: Maybe<Scalars['String']>;
  tabName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<Array<User>>;
};

export type ThemeCount = {
  __typename?: 'ThemeCount';
  colors: Scalars['Int'];
  company: Scalars['Int'];
  fontFamilies: Scalars['Int'];
  fonts: Scalars['Int'];
  users: Scalars['Int'];
};

export type ThemeCreateInput = {
  colors?: InputMaybe<ColorCreateNestedManyWithoutThemeInput>;
  company?: InputMaybe<CompanyCreateNestedManyWithoutThemeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favicon?: InputMaybe<Scalars['String']>;
  fontFamilies?: InputMaybe<FontFamilyCreateNestedManyWithoutThemeInput>;
  fonts?: InputMaybe<FontCreateNestedManyWithoutThemeInput>;
  helpCenterLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isUniqueTheme?: InputMaybe<Scalars['Boolean']>;
  logoHeader?: InputMaybe<Scalars['String']>;
  logoNavbar?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Scalars['JSON']>;
  supportEmail?: InputMaybe<Scalars['String']>;
  tabName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutThemeInput>;
};

export type ThemeCreateNestedOneWithoutCompanyInput = {
  connect?: InputMaybe<ThemeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ThemeCreateOrConnectWithoutCompanyInput>;
  create?: InputMaybe<ThemeCreateWithoutCompanyInput>;
};

export type ThemeCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<ThemeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ThemeCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<ThemeCreateWithoutUsersInput>;
};

export type ThemeCreateOrConnectWithoutCompanyInput = {
  create: ThemeCreateWithoutCompanyInput;
  where: ThemeWhereUniqueInput;
};

export type ThemeCreateOrConnectWithoutUsersInput = {
  create: ThemeCreateWithoutUsersInput;
  where: ThemeWhereUniqueInput;
};

export type ThemeCreateWithoutCompanyInput = {
  colors?: InputMaybe<ColorCreateNestedManyWithoutThemeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favicon?: InputMaybe<Scalars['String']>;
  fontFamilies?: InputMaybe<FontFamilyCreateNestedManyWithoutThemeInput>;
  fonts?: InputMaybe<FontCreateNestedManyWithoutThemeInput>;
  helpCenterLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isUniqueTheme?: InputMaybe<Scalars['Boolean']>;
  logoHeader?: InputMaybe<Scalars['String']>;
  logoNavbar?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Scalars['JSON']>;
  supportEmail?: InputMaybe<Scalars['String']>;
  tabName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutThemeInput>;
};

export type ThemeCreateWithoutUsersInput = {
  colors?: InputMaybe<ColorCreateNestedManyWithoutThemeInput>;
  company?: InputMaybe<CompanyCreateNestedManyWithoutThemeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favicon?: InputMaybe<Scalars['String']>;
  fontFamilies?: InputMaybe<FontFamilyCreateNestedManyWithoutThemeInput>;
  fonts?: InputMaybe<FontCreateNestedManyWithoutThemeInput>;
  helpCenterLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isUniqueTheme?: InputMaybe<Scalars['Boolean']>;
  logoHeader?: InputMaybe<Scalars['String']>;
  logoNavbar?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Scalars['JSON']>;
  supportEmail?: InputMaybe<Scalars['String']>;
  tabName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ThemeUpdateInput = {
  colors?: InputMaybe<ColorUpdateManyWithoutThemeNestedInput>;
  company?: InputMaybe<CompanyUpdateManyWithoutThemeNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favicon?: InputMaybe<Scalars['String']>;
  fontFamilies?: InputMaybe<FontFamilyUpdateManyWithoutThemeNestedInput>;
  fonts?: InputMaybe<FontUpdateManyWithoutThemeNestedInput>;
  helpCenterLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isUniqueTheme?: InputMaybe<Scalars['Boolean']>;
  logoHeader?: InputMaybe<Scalars['String']>;
  logoNavbar?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Scalars['JSON']>;
  supportEmail?: InputMaybe<Scalars['String']>;
  tabName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserUpdateManyWithoutThemeNestedInput>;
};

export type ThemeUpdateOneWithoutCompanyNestedInput = {
  connect?: InputMaybe<ThemeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ThemeCreateOrConnectWithoutCompanyInput>;
  create?: InputMaybe<ThemeCreateWithoutCompanyInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ThemeUpdateWithoutCompanyInput>;
  upsert?: InputMaybe<ThemeUpsertWithoutCompanyInput>;
};

export type ThemeUpdateOneWithoutUsersNestedInput = {
  connect?: InputMaybe<ThemeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ThemeCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<ThemeCreateWithoutUsersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ThemeUpdateWithoutUsersInput>;
  upsert?: InputMaybe<ThemeUpsertWithoutUsersInput>;
};

export type ThemeUpdateWithoutCompanyInput = {
  colors?: InputMaybe<ColorUpdateManyWithoutThemeNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favicon?: InputMaybe<Scalars['String']>;
  fontFamilies?: InputMaybe<FontFamilyUpdateManyWithoutThemeNestedInput>;
  fonts?: InputMaybe<FontUpdateManyWithoutThemeNestedInput>;
  helpCenterLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isUniqueTheme?: InputMaybe<Scalars['Boolean']>;
  logoHeader?: InputMaybe<Scalars['String']>;
  logoNavbar?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Scalars['JSON']>;
  supportEmail?: InputMaybe<Scalars['String']>;
  tabName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserUpdateManyWithoutThemeNestedInput>;
};

export type ThemeUpdateWithoutUsersInput = {
  colors?: InputMaybe<ColorUpdateManyWithoutThemeNestedInput>;
  company?: InputMaybe<CompanyUpdateManyWithoutThemeNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  favicon?: InputMaybe<Scalars['String']>;
  fontFamilies?: InputMaybe<FontFamilyUpdateManyWithoutThemeNestedInput>;
  fonts?: InputMaybe<FontUpdateManyWithoutThemeNestedInput>;
  helpCenterLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isUniqueTheme?: InputMaybe<Scalars['Boolean']>;
  logoHeader?: InputMaybe<Scalars['String']>;
  logoNavbar?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<Scalars['JSON']>;
  supportEmail?: InputMaybe<Scalars['String']>;
  tabName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ThemeUpsertWithoutCompanyInput = {
  create: ThemeCreateWithoutCompanyInput;
  update: ThemeUpdateWithoutCompanyInput;
};

export type ThemeUpsertWithoutUsersInput = {
  create: ThemeCreateWithoutUsersInput;
  update: ThemeUpdateWithoutUsersInput;
};

export type ThemeWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type TransformedMagicTableResponse = {
  __typename?: 'TransformedMagicTableResponse';
  magicTableSheet?: Maybe<TransformedMagicTableSheet>;
  message?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type TransformedMagicTableSheet = {
  __typename?: 'TransformedMagicTableSheet';
  chat: Chat;
  columnDefinitions?: Maybe<Array<MagicTableColumnMetaDataGraphQlObjectType>>;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  dueDiligence?: Maybe<DueDiligence>;
  magicTableArtifact?: Maybe<Array<MagicTableArtifact>>;
  name: Scalars['String'];
  ownerId: Scalars['String'];
  rows?: Maybe<Array<Scalars['JSONObject']>>;
  sheetId: Scalars['String'];
  state: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TranslationOneToOne = {
  __typename?: 'TranslationOneToOne';
  contentIdToTranslate?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  message: Message;
  messageId: Scalars['String'];
  translateToLanguage: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TranslationOneToOneCreateInput = {
  contentIdToTranslate?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  translateToLanguage: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TranslationOneToOneCreateNestedOneWithoutMessageInput = {
  connect?: InputMaybe<TranslationOneToOneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TranslationOneToOneCreateOrConnectWithoutMessageInput>;
  create?: InputMaybe<TranslationOneToOneCreateWithoutMessageInput>;
};

export type TranslationOneToOneCreateOrConnectWithoutMessageInput = {
  create: TranslationOneToOneCreateWithoutMessageInput;
  where: TranslationOneToOneWhereUniqueInput;
};

export type TranslationOneToOneCreateWithoutMessageInput = {
  contentIdToTranslate?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  translateToLanguage: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TranslationOneToOneOrderByWithRelationInput = {
  contentIdToTranslate?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  translateToLanguage?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TranslationOneToOneRelationFilter = {
  is?: InputMaybe<TranslationOneToOneWhereInput>;
  isNot?: InputMaybe<TranslationOneToOneWhereInput>;
};

export type TranslationOneToOneUpdateOneWithoutMessageNestedInput = {
  connect?: InputMaybe<TranslationOneToOneWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TranslationOneToOneCreateOrConnectWithoutMessageInput>;
  create?: InputMaybe<TranslationOneToOneCreateWithoutMessageInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<TranslationOneToOneUpdateWithoutMessageInput>;
  upsert?: InputMaybe<TranslationOneToOneUpsertWithoutMessageInput>;
};

export type TranslationOneToOneUpdateWithoutMessageInput = {
  contentIdToTranslate?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  translateToLanguage?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TranslationOneToOneUpsertWithoutMessageInput = {
  create: TranslationOneToOneCreateWithoutMessageInput;
  update: TranslationOneToOneUpdateWithoutMessageInput;
};

export type TranslationOneToOneWhereInput = {
  AND?: InputMaybe<Array<TranslationOneToOneWhereInput>>;
  NOT?: InputMaybe<Array<TranslationOneToOneWhereInput>>;
  OR?: InputMaybe<Array<TranslationOneToOneWhereInput>>;
  contentIdToTranslate?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  translateToLanguage?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TranslationOneToOneWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  company: Company;
  companyId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  theme?: Maybe<Theme>;
  themeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserCreateManyCompanyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  themeId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateManyCompanyInputEnvelope = {
  data: Array<UserCreateManyCompanyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserCreateManyThemeInput = {
  companyId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateManyThemeInputEnvelope = {
  data: Array<UserCreateManyThemeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserCreateNestedManyWithoutCompanyInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<UserCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<UserCreateManyCompanyInputEnvelope>;
};

export type UserCreateNestedManyWithoutThemeInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutThemeInput>>;
  create?: InputMaybe<Array<UserCreateWithoutThemeInput>>;
  createMany?: InputMaybe<UserCreateManyThemeInputEnvelope>;
};

export type UserCreateOrConnectWithoutCompanyInput = {
  create: UserCreateWithoutCompanyInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutThemeInput = {
  create: UserCreateWithoutThemeInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutCompanyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<ThemeCreateNestedOneWithoutUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutThemeInput = {
  company: CompanyCreateNestedOneWithoutUsersInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserPrompt = {
  __typename?: 'UserPrompt';
  assistant: Assistant;
  assistantId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  ownedBy?: Maybe<Scalars['String']>;
  prompt: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type UserPromptCreateInput = {
  id?: InputMaybe<Scalars['String']>;
  ownedBy?: InputMaybe<Scalars['String']>;
  prompt: Scalars['String'];
  title: Scalars['String'];
};

export type UserPromptCreateManyAssistantInput = {
  id?: InputMaybe<Scalars['String']>;
  ownedBy?: InputMaybe<Scalars['String']>;
  prompt: Scalars['String'];
  title: Scalars['String'];
};

export type UserPromptCreateManyAssistantInputEnvelope = {
  data: Array<UserPromptCreateManyAssistantInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserPromptCreateManyInput = {
  assistantId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  prompt: Scalars['String'];
  title: Scalars['String'];
};

export type UserPromptCreateNestedManyWithoutAssistantInput = {
  connect?: InputMaybe<Array<UserPromptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserPromptCreateOrConnectWithoutAssistantInput>>;
  create?: InputMaybe<Array<UserPromptCreateWithoutAssistantInput>>;
  createMany?: InputMaybe<UserPromptCreateManyAssistantInputEnvelope>;
};

export type UserPromptCreateOrConnectWithoutAssistantInput = {
  create: UserPromptCreateWithoutAssistantInput;
  where: UserPromptWhereUniqueInput;
};

export type UserPromptCreateWithoutAssistantInput = {
  id?: InputMaybe<Scalars['String']>;
  ownedBy?: InputMaybe<Scalars['String']>;
  prompt: Scalars['String'];
  title: Scalars['String'];
};

export type UserPromptListRelationFilter = {
  every?: InputMaybe<UserPromptWhereInput>;
  none?: InputMaybe<UserPromptWhereInput>;
  some?: InputMaybe<UserPromptWhereInput>;
};

export type UserPromptOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserPromptScalarWhereInput = {
  AND?: InputMaybe<Array<UserPromptScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserPromptScalarWhereInput>>;
  OR?: InputMaybe<Array<UserPromptScalarWhereInput>>;
  assistantId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  ownedBy?: InputMaybe<StringNullableFilter>;
  prompt?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type UserPromptUpdateInput = {
  id?: InputMaybe<Scalars['String']>;
  prompt?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UserPromptUpdateManyMutationInput = {
  id?: InputMaybe<Scalars['String']>;
  prompt?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UserPromptUpdateManyWithWhereWithoutAssistantInput = {
  data: UserPromptUpdateManyMutationInput;
  where: UserPromptScalarWhereInput;
};

export type UserPromptUpdateManyWithoutAssistantNestedInput = {
  connect?: InputMaybe<Array<UserPromptWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserPromptCreateOrConnectWithoutAssistantInput>>;
  create?: InputMaybe<Array<UserPromptCreateWithoutAssistantInput>>;
  createMany?: InputMaybe<UserPromptCreateManyAssistantInputEnvelope>;
  delete?: InputMaybe<Array<UserPromptWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserPromptScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserPromptWhereUniqueInput>>;
  set?: InputMaybe<Array<UserPromptWhereUniqueInput>>;
  update?: InputMaybe<Array<UserPromptUpdateWithWhereUniqueWithoutAssistantInput>>;
  updateMany?: InputMaybe<Array<UserPromptUpdateManyWithWhereWithoutAssistantInput>>;
  upsert?: InputMaybe<Array<UserPromptUpsertWithWhereUniqueWithoutAssistantInput>>;
};

export type UserPromptUpdateWithWhereUniqueWithoutAssistantInput = {
  data: UserPromptUpdateWithoutAssistantInput;
  where: UserPromptWhereUniqueInput;
};

export type UserPromptUpdateWithoutAssistantInput = {
  id?: InputMaybe<Scalars['String']>;
  prompt?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UserPromptUpsertWithWhereUniqueWithoutAssistantInput = {
  create: UserPromptCreateWithoutAssistantInput;
  update: UserPromptUpdateWithoutAssistantInput;
  where: UserPromptWhereUniqueInput;
};

export type UserPromptWhereInput = {
  AND?: InputMaybe<Array<UserPromptWhereInput>>;
  NOT?: InputMaybe<Array<UserPromptWhereInput>>;
  OR?: InputMaybe<Array<UserPromptWhereInput>>;
  assistantId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  ownedBy?: InputMaybe<StringNullableFilter>;
  prompt?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export type UserPromptWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type UserScalarWhereInput = {
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  themeId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type UserUpdateManyMutationInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserUpdateManyWithWhereWithoutCompanyInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutThemeInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutCompanyNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutCompanyInput>>;
  create?: InputMaybe<Array<UserCreateWithoutCompanyInput>>;
  createMany?: InputMaybe<UserCreateManyCompanyInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutCompanyInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutCompanyInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutCompanyInput>>;
};

export type UserUpdateManyWithoutThemeNestedInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutThemeInput>>;
  create?: InputMaybe<Array<UserCreateWithoutThemeInput>>;
  createMany?: InputMaybe<UserCreateManyThemeInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutThemeInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutThemeInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutThemeInput>>;
};

export type UserUpdateWithWhereUniqueWithoutCompanyInput = {
  data: UserUpdateWithoutCompanyInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutThemeInput = {
  data: UserUpdateWithoutThemeInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutCompanyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  theme?: InputMaybe<ThemeUpdateOneWithoutUsersNestedInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserUpdateWithoutThemeInput = {
  company?: InputMaybe<CompanyUpdateOneRequiredWithoutUsersNestedInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserUpsertWithWhereUniqueWithoutCompanyInput = {
  create: UserCreateWithoutCompanyInput;
  update: UserUpdateWithoutCompanyInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutThemeInput = {
  create: UserCreateWithoutThemeInput;
  update: UserUpdateWithoutThemeInput;
  where: UserWhereUniqueInput;
};

export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type AddDueDilgenceMetaDataMutationVariables = Exact<{
  dueDiligenceId: Scalars['String'];
  questionFileIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  questionTexts?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  sourceFileIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type AddDueDilgenceMetaDataMutation = { __typename?: 'Mutation', addDueDilgenceMetaData: { __typename?: 'DueDiligenceActionResponse', message?: string | null, status: boolean } };

export type AssistantQueryVariables = Exact<{
  assistantId: Scalars['String'];
}>;


export type AssistantQuery = { __typename?: 'Query', assistantByUser: { __typename?: 'Assistant', id: string, name: string, createdAt: any, title?: string | null, explanation?: string | null, alert?: string | null, inputLimit?: number | null, inputPlaceholder?: string | null, chatUpload: ChatUpload, settings?: any | null, access?: Array<string> | null, company: { __typename?: 'Company', id: string, configuration: any }, userPrompts: Array<{ __typename?: 'UserPrompt', id: string, title: string, prompt: string, ownedBy?: string | null }>, modules?: Array<{ __typename?: 'Module', id: string, name: string }> | null } };

export type AssistantsQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<AssistantOrderByWithRelationInput> | AssistantOrderByWithRelationInput>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssistantWhereInput>;
}>;


export type AssistantsQuery = { __typename?: 'Query', assistants: Array<{ __typename?: 'Assistant', id: string, name: string, createdAt: any, settings?: any | null, access?: Array<string> | null }> };

export type AssistantsWithUserPromptsQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<AssistantOrderByWithRelationInput> | AssistantOrderByWithRelationInput>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssistantWhereInput>;
}>;


export type AssistantsWithUserPromptsQuery = { __typename?: 'Query', assistants: Array<{ __typename?: 'Assistant', id: string, name: string, explanation?: string | null, settings?: any | null, access?: Array<string> | null, userPrompts: Array<{ __typename?: 'UserPrompt', id: string, title: string, prompt: string, ownedBy?: string | null }> }> };

export type ChatQueryVariables = Exact<{
  chatId: Scalars['String'];
}>;


export type ChatQuery = { __typename?: 'Query', chat: { __typename?: 'Chat', id: string, title?: string | null, createdAt: any, assistant?: { __typename?: 'Assistant', id: string, access?: Array<string> | null, settings?: any | null, name: string, title?: string | null, modules?: Array<{ __typename?: 'Module', name: string }> | null } | null } };

export type ChatBenchmarkingAbortMutationVariables = Exact<{
  benchmarkId: Scalars['String'];
}>;


export type ChatBenchmarkingAbortMutation = { __typename?: 'Mutation', abortBenchmark: { __typename?: 'BenchmarkStatusResponse', filename: string, status: string, error: number, done: number, total: number, benchmarkId?: string | null } };

export type ChatBenchmarkingStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type ChatBenchmarkingStatusQuery = { __typename?: 'Query', getCurrentStatus: { __typename?: 'BenchmarkStatusResponse', filename: string, status: string, error: number, done: number, total: number, benchmarkId?: string | null } };

export type ChatCreateMutationVariables = Exact<{
  title: Scalars['String'];
  assistantId: Scalars['String'];
}>;


export type ChatCreateMutation = { __typename?: 'Mutation', chatCreate: { __typename?: 'Chat', id: string, title?: string | null, createdAt: any } };

export type ChatDeleteMutationVariables = Exact<{
  chatId: Scalars['String'];
}>;


export type ChatDeleteMutation = { __typename?: 'Mutation', deleteChat: { __typename?: 'Chat', id: string, title?: string | null } };

export type ChatDeleteAllMutationVariables = Exact<{ [key: string]: never; }>;


export type ChatDeleteAllMutation = { __typename?: 'Mutation', chatDeleteAll: Array<{ __typename?: 'Chat', id: string }> };

export type ChatDeleteByChatIdsMutationVariables = Exact<{
  chatIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type ChatDeleteByChatIdsMutation = { __typename?: 'Mutation', chatDeleteByChatIds: Array<{ __typename?: 'Chat', id: string }> };

export type ChatSubscriptionSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ChatSubscriptionSubscription = { __typename?: 'Subscription', chatUpdate: { __typename?: 'Chat', id: string, title?: string | null, createdAt: any } };

export type ChatUpdateTitleMutationVariables = Exact<{
  chatId: Scalars['String'];
  title: Scalars['String'];
}>;


export type ChatUpdateTitleMutation = { __typename?: 'Mutation', chatUpdateTitle: { __typename?: 'Chat', id: string, title?: string | null } };

export type ChatsQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<ChatOrderByWithRelationInput> | ChatOrderByWithRelationInput>;
  take?: InputMaybe<Scalars['Int']>;
}>;


export type ChatsQuery = { __typename?: 'Query', chats: Array<{ __typename?: 'Chat', id: string, title?: string | null, createdAt: any, assistant?: { __typename?: 'Assistant', id: string, access?: Array<string> | null } | null }> };

export type ContentByChatQueryVariables = Exact<{
  chatId?: InputMaybe<Scalars['String']>;
}>;


export type ContentByChatQuery = { __typename?: 'Query', contentByChat: Array<{ __typename?: 'Content', id: string, mimeType: string, ownerType: OwnerType, ownerId: string, key: string, title?: string | null, createdAt: any, url?: string | null, readUrl: string, ingestionState: IngestionState, ingestionStateUpdatedAt?: any | null, internallyStoredAt?: any | null, previewPdfFileName?: string | null, expiresInDays?: number | null, expiredAt?: any | null, source: { __typename?: 'Source', kind: SourceKind } }> };

export type ContentByIdQueryVariables = Exact<{
  contentIds: Array<Scalars['String']> | Scalars['String'];
  chatId: Scalars['String'];
}>;


export type ContentByIdQuery = { __typename?: 'Query', contentById: Array<{ __typename?: 'Content', id: string, mimeType: string, ownerId: string, key: string, title?: string | null, createdAt: any, url?: string | null, readUrl: string, internallyStoredAt?: any | null, ingestionState: IngestionState, ingestionStateUpdatedAt?: any | null, previewPdfFileName?: string | null, expiresInDays?: number | null, expiredAt?: any | null, source: { __typename?: 'Source', kind: SourceKind } }> };

export type ContentUpsertByChatMutationVariables = Exact<{
  input: ContentCreateInput;
  fileUrl?: InputMaybe<Scalars['String']>;
  chatId: Scalars['String'];
  storeInternally?: InputMaybe<Scalars['Boolean']>;
}>;


export type ContentUpsertByChatMutation = { __typename?: 'Mutation', contentUpsertByChat: { __typename?: 'Content', id: string, key: string, byteSize: number, mimeType: string, ownerType: OwnerType, ownerId: string, writeUrl: string, readUrl: string, createdAt: any, internallyStoredAt?: any | null } };

export type CreateDueDiligenceMutationVariables = Exact<{
  assistantId: Scalars['String'];
}>;


export type CreateDueDiligenceMutation = { __typename?: 'Mutation', createDueDiligence: { __typename?: 'DueDiligence', id: string, magicTableSheet: { __typename?: 'MagicTableSheet', id: string, chatId?: string | null } } };

export type DeleteDueDiligenceMutationVariables = Exact<{
  dueDiligenceId: Scalars['String'];
}>;


export type DeleteDueDiligenceMutation = { __typename?: 'Mutation', deleteDueDiligence: { __typename?: 'DueDiligenceActionResponse', message?: string | null, status: boolean } };

export type DueDiligenceQueryVariables = Exact<{
  cursor?: InputMaybe<DueDiligenceWhereUniqueInput>;
}>;


export type DueDiligenceQuery = { __typename?: 'Query', dueDiligence: Array<{ __typename?: 'DueDiligence', id: string, magicTableSheet: { __typename?: 'MagicTableSheet', id: string, name: string, createdAt: any, updatedAt: any } }> };

export type GenerateMagicTableArtifactMutationVariables = Exact<{
  artifactTypes: Array<MagicTableArtifactType> | MagicTableArtifactType;
  sheetId: Scalars['String'];
}>;


export type GenerateMagicTableArtifactMutation = { __typename?: 'Mutation', generateMagicTableArtifact: { __typename?: 'MagicTableActionResponse', message?: string | null, status: boolean } };

export type GetMagicTableCellLogEntryQueryVariables = Exact<{
  columnOrder: Scalars['Float'];
  rowOrder: Scalars['Float'];
  sheetId: Scalars['String'];
}>;


export type GetMagicTableCellLogEntryQuery = { __typename?: 'Query', getMagicTableCellLogEntry: Array<{ __typename?: 'MagicTableLogEntry', id: string, details?: any | null, text: string, messageId?: string | null, actorType: MagicTableActorType, createdAt: any }> };

export type GetTransformedMagicTableQueryVariables = Exact<{
  cursor?: InputMaybe<MagicTableSheetWhereUniqueInput>;
}>;


export type GetTransformedMagicTableQuery = { __typename?: 'Query', getTransformedMagicTableSheet: { __typename?: 'TransformedMagicTableResponse', message?: string | null, magicTableSheet?: { __typename?: 'TransformedMagicTableSheet', sheetId: string, rows?: Array<any> | null, name: string, state: string, updatedAt?: any | null, chat: { __typename?: 'Chat', id: string }, dueDiligence?: { __typename?: 'DueDiligence', id: string, questionFileIds?: Array<string> | null, sourceFileIds?: Array<string> | null } | null, columnDefinitions?: Array<{ __typename?: 'MagicTableColumnMetaDataGraphQlObjectType', field?: string | null, suppressSizeToFit?: boolean | null, width?: number | null }> | null } | null } };

export type MagicTableArtifactUpdateSubscriptionVariables = Exact<{
  sheetId: Scalars['String'];
}>;


export type MagicTableArtifactUpdateSubscription = { __typename?: 'Subscription', magicTableArtifactUpdate: { __typename?: 'MagicTableArtifact', id: string, name?: string | null, artifactType: string, contentId?: string | null, updatedAt: any } };

export type MagicTableCellUpdateSubscriptionVariables = Exact<{
  sheetId: Scalars['String'];
}>;


export type MagicTableCellUpdateSubscription = { __typename?: 'Subscription', magicTableCellUpdate: { __typename?: 'MagicTableCell', id: string, data: string, magicTableRow: { __typename?: 'MagicTableRow', id: string, rowOrder: number }, magicTableColumn: { __typename?: 'MagicTableColumn', id: string, columnOrder: number } } };

export type MagicTableSheetQueryVariables = Exact<{
  where?: InputMaybe<MagicTableSheetWhereInput>;
}>;


export type MagicTableSheetQuery = { __typename?: 'Query', magicTableSheet: { __typename?: 'MagicTableSheet', chatId?: string | null } };

export type MagicTableSheetUpdateSubscriptionVariables = Exact<{
  sheetId: Scalars['String'];
}>;


export type MagicTableSheetUpdateSubscription = { __typename?: 'Subscription', magicTableSheetUpdate: { __typename?: 'MagicTableSheet', id: string, state: string, updatedAt: any } };

export type MessageCreateMutationVariables = Exact<{
  chatId?: InputMaybe<Scalars['String']>;
  assistantId?: InputMaybe<Scalars['String']>;
  input: MessageCreateInput;
  translationInput?: InputMaybe<TranslationOneToOneCreateInput>;
}>;


export type MessageCreateMutation = { __typename?: 'Mutation', messageCreate: { __typename?: 'Message', id: string, text?: string | null, role: Role, chatId: string, createdAt: any, messages?: Array<{ __typename?: 'Message', id: string }> | null } };

export type MessageCreateSyncMutationVariables = Exact<{
  assistantId?: InputMaybe<Scalars['String']>;
  input: MessageCreateInput;
}>;


export type MessageCreateSyncMutation = { __typename?: 'Mutation', messageCreateSync: Array<{ __typename?: 'Message', id: string, text?: string | null, role: Role, chatId: string, createdAt: any, previousMessage?: { __typename?: 'Message', id: string } | null, messages?: Array<{ __typename?: 'Message', id: string }> | null }> };

export type MessageStopStreamingMutationVariables = Exact<{
  chatId: Scalars['String'];
  messageId: Scalars['String'];
}>;


export type MessageStopStreamingMutation = { __typename?: 'Mutation', messageStopStreaming: { __typename?: 'Message', id: string, stoppedStreamingAt?: any | null, text?: string | null } };

export type MessageSubscriptionSubscriptionVariables = Exact<{
  chatId: Scalars['String'];
}>;


export type MessageSubscriptionSubscription = { __typename?: 'Subscription', messageUpdate: { __typename?: 'Message', id: string, text?: string | null, role: Role, chatId: string, createdAt: any, startedStreamingAt?: any | null, stoppedStreamingAt?: any | null, completedAt?: any | null, references?: Array<{ __typename?: 'Reference', id: string, name: string, sequenceNumber: number, url?: string | null }> | null, feedback?: { __typename?: 'Feedback', positive: boolean } | null } };

export type MessageUpdateMutationVariables = Exact<{
  chatId: Scalars['String'];
  messageId: Scalars['String'];
  input: MessageUpdateInput;
}>;


export type MessageUpdateMutation = { __typename?: 'Mutation', messageUpdateData: { __typename?: 'Message', id: string, text?: string | null, role: Role, chatId: string, createdAt: any, feedback?: { __typename?: 'Feedback', positive: boolean } | null } };

export type MessagesQueryVariables = Exact<{
  chatId: Scalars['String'];
  orderBy?: InputMaybe<Array<MessageOrderByWithRelationInput> | MessageOrderByWithRelationInput>;
  includeDebugInfo?: Scalars['Boolean'];
  includeAssessment?: Scalars['Boolean'];
}>;


export type MessagesQuery = { __typename?: 'Query', messages: Array<{ __typename?: 'Message', id: string, text?: string | null, originalText?: string | null, role: Role, chatId: string, createdAt: any, stoppedStreamingAt?: any | null, gptRequest?: any | null, debugInfo?: any | null, references?: Array<{ __typename?: 'Reference', id: string, name: string, sequenceNumber: number, url?: string | null }> | null, feedback?: { __typename?: 'Feedback', positive: boolean, text?: string | null, additionalInfo?: any | null } | null, assessment?: Array<{ __typename?: 'MessageAssessment', explanation?: string | null, id: string, isVisible: boolean, label?: string | null, status: string, type?: string | null, messageId: string }> | null }> };

export type PaginatedContentQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ContentOrderByWithRelationInput> | ContentOrderByWithRelationInput>;
  where?: InputMaybe<ContentWhereInput>;
  chatId?: InputMaybe<Scalars['String']>;
}>;


export type PaginatedContentQuery = { __typename?: 'Query', paginatedContent: { __typename?: 'PaginatedContent', totalCount: number, nodes?: Array<{ __typename?: 'Content', id: string, mimeType: string, key: string, title?: string | null, internallyStoredAt?: any | null, ingestionState: IngestionState, ingestionStateUpdatedAt?: any | null }> | null } };

export type PaginatedMessageQueryVariables = Exact<{
  chatId: Scalars['String'];
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MessageOrderByWithRelationInput> | MessageOrderByWithRelationInput>;
  includeDebugInfo?: Scalars['Boolean'];
  includeAssessment?: Scalars['Boolean'];
}>;


export type PaginatedMessageQuery = { __typename?: 'Query', paginatedMessage: { __typename?: 'PaginatedMessage', totalCount: number, nodes?: Array<{ __typename?: 'Message', id: string, text?: string | null, originalText?: string | null, role: Role, chatId: string, createdAt: any, stoppedStreamingAt?: any | null, gptRequest?: any | null, debugInfo?: any | null, references?: Array<{ __typename?: 'Reference', id: string, name: string, sequenceNumber: number, url?: string | null }> | null, feedback?: { __typename?: 'Feedback', positive: boolean, text?: string | null, additionalInfo?: any | null } | null, assessment?: Array<{ __typename?: 'MessageAssessment', explanation?: string | null, id: string, isVisible: boolean, label?: string | null, status: string, type?: string | null, messageId: string }> | null }> | null } };

export type PersistMagicTableColumnMetaDataMutationVariables = Exact<{
  columnOrder: Scalars['Float'];
  metaData: MagicTableColumnCreateInput;
  sheetId: Scalars['String'];
}>;


export type PersistMagicTableColumnMetaDataMutation = { __typename?: 'Mutation', persistMagicTableColumnMetaData: { __typename?: 'MagicTableActionResponse', message?: string | null, status: boolean } };

export type StreamingErrorSubscriptionSubscriptionVariables = Exact<{
  chatId: Scalars['String'];
}>;


export type StreamingErrorSubscriptionSubscription = { __typename?: 'Subscription', streamingError: { __typename?: 'StreamingError', message: string, status: number } };

export type TranslationOneToOneForMessageQueryVariables = Exact<{
  chatId: Scalars['String'];
  messageId: Scalars['String'];
}>;


export type TranslationOneToOneForMessageQuery = { __typename?: 'Query', translationOneToOneForMessage: { __typename?: 'TranslationOneToOne', translateToLanguage: string } };

export type UndoChatDeleteMutationVariables = Exact<{
  chatId: Scalars['String'];
}>;


export type UndoChatDeleteMutation = { __typename?: 'Mutation', undoDeleteChat: { __typename?: 'Chat', id: string, title?: string | null } };

export type UpdateMagicTableCellMutationVariables = Exact<{
  columnOrder: Scalars['Float'];
  data: Scalars['String'];
  rowOrder: Scalars['Float'];
  sheetId: Scalars['String'];
}>;


export type UpdateMagicTableCellMutation = { __typename?: 'Mutation', updateMagicTableCell: { __typename?: 'MagicTableActionResponse', message?: string | null, status: boolean } };

export type UpdateMagicTableSheetMutationVariables = Exact<{
  name: Scalars['String'];
  sheetId: Scalars['String'];
}>;


export type UpdateMagicTableSheetMutation = { __typename?: 'Mutation', updateMagicTableSheet: { __typename?: 'MagicTableSheet', id: string } };

export type UserPromptCreateMutationVariables = Exact<{
  assistantId: Scalars['String'];
  input: UserPromptCreateInput;
}>;


export type UserPromptCreateMutation = { __typename?: 'Mutation', userPromptCreate: { __typename?: 'UserPrompt', assistantId: string, id: string, ownedBy?: string | null, prompt: string, title: string } };

export type UserPromptDeleteAllUserOwnedPromptsMutationVariables = Exact<{
  assistantId: Scalars['String'];
}>;


export type UserPromptDeleteAllUserOwnedPromptsMutation = { __typename?: 'Mutation', userPromptDeleteAllUserOwnedPrompts: number };

export type UserPromptCreateManyMutationVariables = Exact<{
  assistantId: Scalars['String'];
  areOwnedByUser: Scalars['Boolean'];
  data: Array<UserPromptCreateManyInput> | UserPromptCreateManyInput;
}>;


export type UserPromptCreateManyMutation = { __typename?: 'Mutation', userPromptCreateMany: Array<{ __typename?: 'UserPrompt', id: string, prompt: string, title: string }> };

export type AnalyticsOrderDeleteMutationVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type AnalyticsOrderDeleteMutation = { __typename?: 'Mutation', analyticsOrderDelete: { __typename?: 'AnalyticsOrder', updatedAt: any, type: AnalyticsType, stateUpdatedAt: any, state: AnalyticsOrderState, id: string, createdBy: string, createdAt: any, configuration: any, companyId: string } };

export type AnalyticsOrderStartMutationVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type AnalyticsOrderStartMutation = { __typename?: 'Mutation', analyticsOrderStart: { __typename?: 'AnalyticsOrder', id: string, updatedAt: any, type: AnalyticsType, stateUpdatedAt: any, state: AnalyticsOrderState, createdBy: string, createdAt: any, configuration: any, companyId: string } };

export type AnalyticsOrdersQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<AnalyticsOrderOrderByWithRelationInput> | AnalyticsOrderOrderByWithRelationInput>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type AnalyticsOrdersQuery = { __typename?: 'Query', analyticsOrders: Array<{ __typename?: 'AnalyticsOrder', updatedAt: any, type: AnalyticsType, stateUpdatedAt: any, id: string, state: AnalyticsOrderState, createdBy: string, createdAt: any, configuration: any, companyId: string }> };


export const AddDueDilgenceMetaDataDocument = gql`
    mutation AddDueDilgenceMetaData($dueDiligenceId: String!, $questionFileIds: [String!], $questionTexts: [String!], $sourceFileIds: [String!]) {
  addDueDilgenceMetaData(
    dueDiligenceId: $dueDiligenceId
    questionFileIds: $questionFileIds
    questionTexts: $questionTexts
    sourceFileIds: $sourceFileIds
  ) {
    message
    status
  }
}
    `;
export const AssistantDocument = gql`
    query Assistant($assistantId: String!) {
  assistantByUser(assistantId: $assistantId) {
    id
    name
    createdAt
    title
    explanation
    alert
    inputLimit
    inputPlaceholder
    chatUpload
    settings
    company {
      id
      configuration
    }
    access
    userPrompts {
      id
      title
      prompt
      ownedBy
    }
    modules {
      id
      name
    }
  }
}
    `;
export const AssistantsDocument = gql`
    query Assistants($orderBy: [AssistantOrderByWithRelationInput!], $take: Int, $where: AssistantWhereInput) {
  assistants(orderBy: $orderBy, take: $take, where: $where) {
    id
    name
    createdAt
    settings
    access
  }
}
    `;
export const AssistantsWithUserPromptsDocument = gql`
    query AssistantsWithUserPrompts($orderBy: [AssistantOrderByWithRelationInput!], $take: Int, $where: AssistantWhereInput) {
  assistants(orderBy: $orderBy, take: $take, where: $where) {
    id
    name
    explanation
    settings
    access
    userPrompts {
      id
      title
      prompt
      ownedBy
    }
  }
}
    `;
export const ChatDocument = gql`
    query Chat($chatId: String!) {
  chat(chatId: $chatId) {
    id
    title
    createdAt
    assistant {
      id
      access
      settings
      name
      title
      modules {
        name
      }
    }
  }
}
    `;
export const ChatBenchmarkingAbortDocument = gql`
    mutation ChatBenchmarkingAbort($benchmarkId: String!) {
  abortBenchmark(benchmarkId: $benchmarkId) {
    filename
    status
    error
    done
    total
    benchmarkId
  }
}
    `;
export const ChatBenchmarkingStatusDocument = gql`
    query ChatBenchmarkingStatus {
  getCurrentStatus {
    filename
    status
    error
    done
    total
    benchmarkId
  }
}
    `;
export const ChatCreateDocument = gql`
    mutation ChatCreate($title: String!, $assistantId: String!) {
  chatCreate(title: $title, assistantId: $assistantId) {
    id
    title
    createdAt
  }
}
    `;
export const ChatDeleteDocument = gql`
    mutation ChatDelete($chatId: String!) {
  deleteChat(chatId: $chatId) {
    id
    title
  }
}
    `;
export const ChatDeleteAllDocument = gql`
    mutation ChatDeleteAll {
  chatDeleteAll {
    id
  }
}
    `;
export const ChatDeleteByChatIdsDocument = gql`
    mutation ChatDeleteByChatIds($chatIds: [String!]!) {
  chatDeleteByChatIds(chatIds: $chatIds) {
    id
  }
}
    `;
export const ChatSubscriptionDocument = gql`
    subscription ChatSubscription {
  chatUpdate {
    id
    title
    createdAt
  }
}
    `;
export const ChatUpdateTitleDocument = gql`
    mutation ChatUpdateTitle($chatId: String!, $title: String!) {
  chatUpdateTitle(chatId: $chatId, title: $title) {
    id
    title
  }
}
    `;
export const ChatsDocument = gql`
    query Chats($orderBy: [ChatOrderByWithRelationInput!], $take: Int) {
  chats(orderBy: $orderBy, take: $take) {
    id
    title
    createdAt
    assistant {
      id
      access
    }
  }
}
    `;
export const ContentByChatDocument = gql`
    query ContentByChat($chatId: String) {
  contentByChat(chatId: $chatId) {
    id
    mimeType
    ownerType
    ownerId
    key
    title
    createdAt
    url
    readUrl
    source {
      kind
    }
    ingestionState
    ingestionStateUpdatedAt
    internallyStoredAt
    previewPdfFileName
    expiresInDays
    expiredAt
  }
}
    `;
export const ContentByIdDocument = gql`
    query ContentById($contentIds: [String!]!, $chatId: String!) {
  contentById(contentIds: $contentIds, chatId: $chatId) {
    id
    mimeType
    ownerId
    key
    title
    createdAt
    url
    readUrl
    source {
      kind
    }
    internallyStoredAt
    ingestionState
    ingestionStateUpdatedAt
    previewPdfFileName
    expiresInDays
    expiredAt
  }
}
    `;
export const ContentUpsertByChatDocument = gql`
    mutation ContentUpsertByChat($input: ContentCreateInput!, $fileUrl: String, $chatId: String!, $storeInternally: Boolean) {
  contentUpsertByChat(
    input: $input
    fileUrl: $fileUrl
    chatId: $chatId
    storeInternally: $storeInternally
  ) {
    id
    key
    byteSize
    mimeType
    ownerType
    ownerId
    writeUrl
    readUrl
    createdAt
    internallyStoredAt
  }
}
    `;
export const CreateDueDiligenceDocument = gql`
    mutation CreateDueDiligence($assistantId: String!) {
  createDueDiligence(assistantId: $assistantId) {
    id
    magicTableSheet {
      id
      chatId
    }
  }
}
    `;
export const DeleteDueDiligenceDocument = gql`
    mutation DeleteDueDiligence($dueDiligenceId: String!) {
  deleteDueDiligence(dueDiligenceId: $dueDiligenceId) {
    message
    status
  }
}
    `;
export const DueDiligenceDocument = gql`
    query DueDiligence($cursor: DueDiligenceWhereUniqueInput) {
  dueDiligence(cursor: $cursor) {
    id
    magicTableSheet {
      id
      name
      createdAt
      updatedAt
    }
  }
}
    `;
export const GenerateMagicTableArtifactDocument = gql`
    mutation GenerateMagicTableArtifact($artifactTypes: [MagicTableArtifactType!]!, $sheetId: String!) {
  generateMagicTableArtifact(artifactTypes: $artifactTypes, sheetId: $sheetId) {
    message
    status
  }
}
    `;
export const GetMagicTableCellLogEntryDocument = gql`
    query GetMagicTableCellLogEntry($columnOrder: Float!, $rowOrder: Float!, $sheetId: String!) {
  getMagicTableCellLogEntry(
    columnOrder: $columnOrder
    rowOrder: $rowOrder
    sheetId: $sheetId
  ) {
    id
    details
    text
    messageId
    actorType
    createdAt
  }
}
    `;
export const GetTransformedMagicTableDocument = gql`
    query GetTransformedMagicTable($cursor: MagicTableSheetWhereUniqueInput) {
  getTransformedMagicTableSheet(cursor: $cursor) {
    message
    magicTableSheet {
      sheetId
      rows
      name
      chat {
        id
      }
      dueDiligence {
        id
        questionFileIds
        sourceFileIds
      }
      columnDefinitions {
        field
        suppressSizeToFit
        width
      }
      state
      updatedAt
    }
  }
}
    `;
export const MagicTableArtifactUpdateDocument = gql`
    subscription MagicTableArtifactUpdate($sheetId: String!) {
  magicTableArtifactUpdate(sheetId: $sheetId) {
    id
    name
    artifactType
    contentId
    updatedAt
  }
}
    `;
export const MagicTableCellUpdateDocument = gql`
    subscription MagicTableCellUpdate($sheetId: String!) {
  magicTableCellUpdate(sheetId: $sheetId) {
    id
    data
    magicTableRow {
      id
      rowOrder
    }
    magicTableColumn {
      id
      columnOrder
    }
  }
}
    `;
export const MagicTableSheetDocument = gql`
    query MagicTableSheet($where: MagicTableSheetWhereInput) {
  magicTableSheet(where: $where) {
    chatId
  }
}
    `;
export const MagicTableSheetUpdateDocument = gql`
    subscription MagicTableSheetUpdate($sheetId: String!) {
  magicTableSheetUpdate(sheetId: $sheetId) {
    id
    state
    updatedAt
  }
}
    `;
export const MessageCreateDocument = gql`
    mutation MessageCreate($chatId: String, $assistantId: String, $input: MessageCreateInput!, $translationInput: TranslationOneToOneCreateInput) {
  messageCreate(
    chatId: $chatId
    assistantId: $assistantId
    input: $input
    translationInput: $translationInput
  ) {
    id
    text
    role
    chatId
    createdAt
    messages {
      id
    }
  }
}
    `;
export const MessageCreateSyncDocument = gql`
    mutation MessageCreateSync($assistantId: String, $input: MessageCreateInput!) {
  messageCreateSync(assistantId: $assistantId, input: $input) {
    id
    text
    role
    chatId
    createdAt
    previousMessage {
      id
    }
    messages {
      id
    }
  }
}
    `;
export const MessageStopStreamingDocument = gql`
    mutation MessageStopStreaming($chatId: String!, $messageId: String!) {
  messageStopStreaming(chatId: $chatId, messageId: $messageId) {
    id
    stoppedStreamingAt
    text
  }
}
    `;
export const MessageSubscriptionDocument = gql`
    subscription MessageSubscription($chatId: String!) {
  messageUpdate(chatId: $chatId) {
    id
    text
    role
    chatId
    createdAt
    startedStreamingAt
    stoppedStreamingAt
    completedAt
    references {
      id
      name
      sequenceNumber
      url
    }
    feedback {
      positive
    }
  }
}
    `;
export const MessageUpdateDocument = gql`
    mutation MessageUpdate($chatId: String!, $messageId: String!, $input: MessageUpdateInput!) {
  messageUpdateData(chatId: $chatId, messageId: $messageId, input: $input) {
    id
    text
    role
    chatId
    createdAt
    feedback {
      positive
    }
  }
}
    `;
export const MessagesDocument = gql`
    query Messages($chatId: String!, $orderBy: [MessageOrderByWithRelationInput!], $includeDebugInfo: Boolean! = false, $includeAssessment: Boolean! = false) {
  messages(chatId: $chatId, orderBy: $orderBy) {
    id
    text
    originalText
    role
    chatId
    createdAt
    references {
      id
      name
      sequenceNumber
      url
    }
    stoppedStreamingAt
    feedback {
      positive
      text
      additionalInfo
    }
    gptRequest @include(if: $includeDebugInfo)
    debugInfo @include(if: $includeDebugInfo)
    assessment @include(if: $includeAssessment) {
      explanation
      id
      isVisible
      label
      status
      type
      messageId
    }
  }
}
    `;
export const PaginatedContentDocument = gql`
    query PaginatedContent($skip: Int, $take: Int, $orderBy: [ContentOrderByWithRelationInput!], $where: ContentWhereInput, $chatId: String) {
  paginatedContent(
    skip: $skip
    take: $take
    orderBy: $orderBy
    where: $where
    chatId: $chatId
  ) {
    nodes {
      id
      mimeType
      key
      title
      internallyStoredAt
      ingestionState
      ingestionStateUpdatedAt
    }
    totalCount
  }
}
    `;
export const PaginatedMessageDocument = gql`
    query PaginatedMessage($chatId: String!, $take: Int, $skip: Int, $orderBy: [MessageOrderByWithRelationInput!], $includeDebugInfo: Boolean! = false, $includeAssessment: Boolean! = false) {
  paginatedMessage(chatId: $chatId, take: $take, skip: $skip, orderBy: $orderBy) {
    nodes {
      id
      text
      originalText
      role
      chatId
      createdAt
      references {
        id
        name
        sequenceNumber
        url
      }
      stoppedStreamingAt
      feedback {
        positive
        text
        additionalInfo
      }
      gptRequest @include(if: $includeDebugInfo)
      debugInfo @include(if: $includeDebugInfo)
      assessment @include(if: $includeAssessment) {
        explanation
        id
        isVisible
        label
        status
        type
        messageId
      }
    }
    totalCount
  }
}
    `;
export const PersistMagicTableColumnMetaDataDocument = gql`
    mutation PersistMagicTableColumnMetaData($columnOrder: Float!, $metaData: MagicTableColumnCreateInput!, $sheetId: String!) {
  persistMagicTableColumnMetaData(
    columnOrder: $columnOrder
    metaData: $metaData
    sheetId: $sheetId
  ) {
    message
    status
  }
}
    `;
export const StreamingErrorSubscriptionDocument = gql`
    subscription StreamingErrorSubscription($chatId: String!) {
  streamingError(chatId: $chatId) {
    message
    status
  }
}
    `;
export const TranslationOneToOneForMessageDocument = gql`
    query TranslationOneToOneForMessage($chatId: String!, $messageId: String!) {
  translationOneToOneForMessage(chatId: $chatId, messageId: $messageId) {
    translateToLanguage
  }
}
    `;
export const UndoChatDeleteDocument = gql`
    mutation UndoChatDelete($chatId: String!) {
  undoDeleteChat(chatId: $chatId) {
    id
    title
  }
}
    `;
export const UpdateMagicTableCellDocument = gql`
    mutation UpdateMagicTableCell($columnOrder: Float!, $data: String!, $rowOrder: Float!, $sheetId: String!) {
  updateMagicTableCell(
    columnOrder: $columnOrder
    data: $data
    rowOrder: $rowOrder
    sheetId: $sheetId
  ) {
    message
    status
  }
}
    `;
export const UpdateMagicTableSheetDocument = gql`
    mutation UpdateMagicTableSheet($name: String!, $sheetId: String!) {
  updateMagicTableSheet(name: $name, sheetId: $sheetId) {
    id
  }
}
    `;
export const UserPromptCreateDocument = gql`
    mutation UserPromptCreate($assistantId: String!, $input: UserPromptCreateInput!) {
  userPromptCreate(assistantId: $assistantId, input: $input) {
    assistantId
    id
    ownedBy
    prompt
    title
  }
}
    `;
export const UserPromptDeleteAllUserOwnedPromptsDocument = gql`
    mutation UserPromptDeleteAllUserOwnedPrompts($assistantId: String!) {
  userPromptDeleteAllUserOwnedPrompts(assistantId: $assistantId)
}
    `;
export const UserPromptCreateManyDocument = gql`
    mutation UserPromptCreateMany($assistantId: String!, $areOwnedByUser: Boolean!, $data: [UserPromptCreateManyInput!]!) {
  userPromptCreateMany(
    assistantId: $assistantId
    areOwnedByUser: $areOwnedByUser
    data: $data
  ) {
    id
    prompt
    title
  }
}
    `;
export const AnalyticsOrderDeleteDocument = gql`
    mutation AnalyticsOrderDelete($orderId: String!) {
  analyticsOrderDelete(orderId: $orderId) {
    updatedAt
    type
    stateUpdatedAt
    state
    id
    createdBy
    createdAt
    configuration
    companyId
  }
}
    `;
export const AnalyticsOrderStartDocument = gql`
    mutation AnalyticsOrderStart($orderId: String!) {
  analyticsOrderStart(orderId: $orderId) {
    id
    updatedAt
    type
    stateUpdatedAt
    state
    createdBy
    createdAt
    configuration
    companyId
  }
}
    `;
export const AnalyticsOrdersDocument = gql`
    query AnalyticsOrders($orderBy: [AnalyticsOrderOrderByWithRelationInput!], $take: Int, $skip: Int) {
  analyticsOrders(orderBy: $orderBy, take: $take, skip: $skip) {
    updatedAt
    type
    stateUpdatedAt
    id
    state
    createdBy
    createdAt
    configuration
    companyId
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    AddDueDilgenceMetaData(variables: AddDueDilgenceMetaDataMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddDueDilgenceMetaDataMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddDueDilgenceMetaDataMutation>(AddDueDilgenceMetaDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddDueDilgenceMetaData', 'mutation');
    },
    Assistant(variables: AssistantQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AssistantQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AssistantQuery>(AssistantDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Assistant', 'query');
    },
    Assistants(variables?: AssistantsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AssistantsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AssistantsQuery>(AssistantsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Assistants', 'query');
    },
    AssistantsWithUserPrompts(variables?: AssistantsWithUserPromptsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AssistantsWithUserPromptsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AssistantsWithUserPromptsQuery>(AssistantsWithUserPromptsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AssistantsWithUserPrompts', 'query');
    },
    Chat(variables: ChatQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ChatQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ChatQuery>(ChatDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Chat', 'query');
    },
    ChatBenchmarkingAbort(variables: ChatBenchmarkingAbortMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ChatBenchmarkingAbortMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ChatBenchmarkingAbortMutation>(ChatBenchmarkingAbortDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ChatBenchmarkingAbort', 'mutation');
    },
    ChatBenchmarkingStatus(variables?: ChatBenchmarkingStatusQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ChatBenchmarkingStatusQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ChatBenchmarkingStatusQuery>(ChatBenchmarkingStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ChatBenchmarkingStatus', 'query');
    },
    ChatCreate(variables: ChatCreateMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ChatCreateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ChatCreateMutation>(ChatCreateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ChatCreate', 'mutation');
    },
    ChatDelete(variables: ChatDeleteMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ChatDeleteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ChatDeleteMutation>(ChatDeleteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ChatDelete', 'mutation');
    },
    ChatDeleteAll(variables?: ChatDeleteAllMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ChatDeleteAllMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ChatDeleteAllMutation>(ChatDeleteAllDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ChatDeleteAll', 'mutation');
    },
    ChatDeleteByChatIds(variables: ChatDeleteByChatIdsMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ChatDeleteByChatIdsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ChatDeleteByChatIdsMutation>(ChatDeleteByChatIdsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ChatDeleteByChatIds', 'mutation');
    },
    ChatSubscription(variables?: ChatSubscriptionSubscriptionVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ChatSubscriptionSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<ChatSubscriptionSubscription>(ChatSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ChatSubscription', 'subscription');
    },
    ChatUpdateTitle(variables: ChatUpdateTitleMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ChatUpdateTitleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ChatUpdateTitleMutation>(ChatUpdateTitleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ChatUpdateTitle', 'mutation');
    },
    Chats(variables?: ChatsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ChatsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ChatsQuery>(ChatsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Chats', 'query');
    },
    ContentByChat(variables?: ContentByChatQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ContentByChatQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ContentByChatQuery>(ContentByChatDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ContentByChat', 'query');
    },
    ContentById(variables: ContentByIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ContentByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ContentByIdQuery>(ContentByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ContentById', 'query');
    },
    ContentUpsertByChat(variables: ContentUpsertByChatMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ContentUpsertByChatMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ContentUpsertByChatMutation>(ContentUpsertByChatDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ContentUpsertByChat', 'mutation');
    },
    CreateDueDiligence(variables: CreateDueDiligenceMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateDueDiligenceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateDueDiligenceMutation>(CreateDueDiligenceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateDueDiligence', 'mutation');
    },
    DeleteDueDiligence(variables: DeleteDueDiligenceMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteDueDiligenceMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteDueDiligenceMutation>(DeleteDueDiligenceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteDueDiligence', 'mutation');
    },
    DueDiligence(variables?: DueDiligenceQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DueDiligenceQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DueDiligenceQuery>(DueDiligenceDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DueDiligence', 'query');
    },
    GenerateMagicTableArtifact(variables: GenerateMagicTableArtifactMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GenerateMagicTableArtifactMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<GenerateMagicTableArtifactMutation>(GenerateMagicTableArtifactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GenerateMagicTableArtifact', 'mutation');
    },
    GetMagicTableCellLogEntry(variables: GetMagicTableCellLogEntryQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetMagicTableCellLogEntryQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMagicTableCellLogEntryQuery>(GetMagicTableCellLogEntryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetMagicTableCellLogEntry', 'query');
    },
    GetTransformedMagicTable(variables?: GetTransformedMagicTableQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetTransformedMagicTableQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetTransformedMagicTableQuery>(GetTransformedMagicTableDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetTransformedMagicTable', 'query');
    },
    MagicTableArtifactUpdate(variables: MagicTableArtifactUpdateSubscriptionVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MagicTableArtifactUpdateSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<MagicTableArtifactUpdateSubscription>(MagicTableArtifactUpdateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MagicTableArtifactUpdate', 'subscription');
    },
    MagicTableCellUpdate(variables: MagicTableCellUpdateSubscriptionVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MagicTableCellUpdateSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<MagicTableCellUpdateSubscription>(MagicTableCellUpdateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MagicTableCellUpdate', 'subscription');
    },
    MagicTableSheet(variables?: MagicTableSheetQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MagicTableSheetQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MagicTableSheetQuery>(MagicTableSheetDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MagicTableSheet', 'query');
    },
    MagicTableSheetUpdate(variables: MagicTableSheetUpdateSubscriptionVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MagicTableSheetUpdateSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<MagicTableSheetUpdateSubscription>(MagicTableSheetUpdateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MagicTableSheetUpdate', 'subscription');
    },
    MessageCreate(variables: MessageCreateMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MessageCreateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<MessageCreateMutation>(MessageCreateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MessageCreate', 'mutation');
    },
    MessageCreateSync(variables: MessageCreateSyncMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MessageCreateSyncMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<MessageCreateSyncMutation>(MessageCreateSyncDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MessageCreateSync', 'mutation');
    },
    MessageStopStreaming(variables: MessageStopStreamingMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MessageStopStreamingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<MessageStopStreamingMutation>(MessageStopStreamingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MessageStopStreaming', 'mutation');
    },
    MessageSubscription(variables: MessageSubscriptionSubscriptionVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MessageSubscriptionSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<MessageSubscriptionSubscription>(MessageSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MessageSubscription', 'subscription');
    },
    MessageUpdate(variables: MessageUpdateMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MessageUpdateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<MessageUpdateMutation>(MessageUpdateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MessageUpdate', 'mutation');
    },
    Messages(variables: MessagesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<MessagesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MessagesQuery>(MessagesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Messages', 'query');
    },
    PaginatedContent(variables?: PaginatedContentQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PaginatedContentQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PaginatedContentQuery>(PaginatedContentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PaginatedContent', 'query');
    },
    PaginatedMessage(variables: PaginatedMessageQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PaginatedMessageQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<PaginatedMessageQuery>(PaginatedMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PaginatedMessage', 'query');
    },
    PersistMagicTableColumnMetaData(variables: PersistMagicTableColumnMetaDataMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PersistMagicTableColumnMetaDataMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PersistMagicTableColumnMetaDataMutation>(PersistMagicTableColumnMetaDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'PersistMagicTableColumnMetaData', 'mutation');
    },
    StreamingErrorSubscription(variables: StreamingErrorSubscriptionSubscriptionVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<StreamingErrorSubscriptionSubscription> {
      return withWrapper((wrappedRequestHeaders) => client.request<StreamingErrorSubscriptionSubscription>(StreamingErrorSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'StreamingErrorSubscription', 'subscription');
    },
    TranslationOneToOneForMessage(variables: TranslationOneToOneForMessageQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<TranslationOneToOneForMessageQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TranslationOneToOneForMessageQuery>(TranslationOneToOneForMessageDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'TranslationOneToOneForMessage', 'query');
    },
    UndoChatDelete(variables: UndoChatDeleteMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UndoChatDeleteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UndoChatDeleteMutation>(UndoChatDeleteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UndoChatDelete', 'mutation');
    },
    UpdateMagicTableCell(variables: UpdateMagicTableCellMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateMagicTableCellMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateMagicTableCellMutation>(UpdateMagicTableCellDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateMagicTableCell', 'mutation');
    },
    UpdateMagicTableSheet(variables: UpdateMagicTableSheetMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateMagicTableSheetMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateMagicTableSheetMutation>(UpdateMagicTableSheetDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateMagicTableSheet', 'mutation');
    },
    UserPromptCreate(variables: UserPromptCreateMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserPromptCreateMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserPromptCreateMutation>(UserPromptCreateDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserPromptCreate', 'mutation');
    },
    UserPromptDeleteAllUserOwnedPrompts(variables: UserPromptDeleteAllUserOwnedPromptsMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserPromptDeleteAllUserOwnedPromptsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserPromptDeleteAllUserOwnedPromptsMutation>(UserPromptDeleteAllUserOwnedPromptsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserPromptDeleteAllUserOwnedPrompts', 'mutation');
    },
    UserPromptCreateMany(variables: UserPromptCreateManyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UserPromptCreateManyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserPromptCreateManyMutation>(UserPromptCreateManyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UserPromptCreateMany', 'mutation');
    },
    AnalyticsOrderDelete(variables: AnalyticsOrderDeleteMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AnalyticsOrderDeleteMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AnalyticsOrderDeleteMutation>(AnalyticsOrderDeleteDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AnalyticsOrderDelete', 'mutation');
    },
    AnalyticsOrderStart(variables: AnalyticsOrderStartMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AnalyticsOrderStartMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AnalyticsOrderStartMutation>(AnalyticsOrderStartDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AnalyticsOrderStart', 'mutation');
    },
    AnalyticsOrders(variables?: AnalyticsOrdersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AnalyticsOrdersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<AnalyticsOrdersQuery>(AnalyticsOrdersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AnalyticsOrders', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;
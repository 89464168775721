'use client';

import { FC, useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import { ButtonVariant } from '@unique/component-library/enums/button';
import { ButtonIcon } from '@unique/component-library/src/ButtonIcon';
import DatePicker from '@unique/component-library/src/DatePicker';
import { IconFeedback } from '@unique/icons/IconFeedback';
import { downloadFile, useToast, ToastVariant, LayoutContext } from '@unique/shared-library';
import { format } from 'date-fns';
import { ClientContext, Service } from '@unique/next-commons/swr';

export const ChatFeedback: FC = () => {
  const { setSplitPaneContent } = useContext(LayoutContext);

  const { services } = useContext(ClientContext);
  const chatBackendUrl = services[Service.NODE_CHAT];
  
  const defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate() - 30);

  const [isLoading, setIsLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState(defaultDate);

  const auth = useAuth();
  const { showToast } = useToast();

  useEffect(() => {
    setSplitPaneContent(null);
  }, [setSplitPaneContent]);

  const downloadFeedback = async () => {
    try {
      setIsLoading(true);

      const dateString = format(currentDate, 'yyyy-MM-dd');
      const response = await fetch(chatBackendUrl + '/feedback?startDate=' + dateString, {
        headers: {
          Authorization: `Bearer ${auth.user?.access_token}`,
        },
      });

      response.blob().then((blob) => {
        // Download CSV
        downloadFile(blob, `feedback-${dateString}.csv`);
        showToast({
          message: 'Chat feedback downloaded successfully.',
          variant: ToastVariant.SUCCESS,
        });
      });
    } catch (err) {
      showToast({
        message: 'There was an error while downloading the chat feedback.',
        variant: ToastVariant.ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="text-on-secondary flex h-full flex-col items-center overflow-y-auto pb-20">
      <div className="flex h-full flex-1 flex-col items-center justify-center text-center">
        <div className="title-s text-on-surface flex items-center p-4">Chat Feedback</div>

        <DatePicker
          defaultDate={defaultDate}
          label="Start Date"
          onDateSelected={(date) => setCurrentDate(date)}
          maxDate={new Date()}
        />
        <ButtonIcon
          variant={ButtonVariant.PRIMARY}
          isLoading={isLoading}
          className="mt-4 w-full"
          onClick={downloadFeedback}
        >
          <div className="flex items-center">
            <IconFeedback />
            <span className="subtitle-2 ml-2">Download Feedback</span>
          </div>
        </ButtonIcon>
      </div>
    </div>
  );
};

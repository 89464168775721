import { FC } from 'react';
import { IconProps } from '.';

export const IconSentimentNeutral: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="sentiment_neutral_24dp_FILL1_wght400_GRAD0_opsz24">
        <path
          id="Vector"
          d="M10.3334 7.33301C10.6112 7.33301 10.8473 7.23579 11.0417 7.04134C11.2362 6.8469 11.3334 6.61079 11.3334 6.33301C11.3334 6.05523 11.2362 5.81912 11.0417 5.62467C10.8473 5.43023 10.6112 5.33301 10.3334 5.33301C10.0556 5.33301 9.81948 5.43023 9.62504 5.62467C9.4306 5.81912 9.33337 6.05523 9.33337 6.33301C9.33337 6.61079 9.4306 6.8469 9.62504 7.04134C9.81948 7.23579 10.0556 7.33301 10.3334 7.33301ZM5.66671 7.33301C5.94448 7.33301 6.1806 7.23579 6.37504 7.04134C6.56948 6.8469 6.66671 6.61079 6.66671 6.33301C6.66671 6.05523 6.56948 5.81912 6.37504 5.62467C6.1806 5.43023 5.94448 5.33301 5.66671 5.33301C5.38893 5.33301 5.15282 5.43023 4.95837 5.62467C4.76393 5.81912 4.66671 6.05523 4.66671 6.33301C4.66671 6.61079 4.76393 6.8469 4.95837 7.04134C5.15282 7.23579 5.38893 7.33301 5.66671 7.33301ZM6.00004 10.333H10V9.33301H6.00004V10.333ZM8.00004 14.6663C7.07782 14.6663 6.21115 14.4913 5.40004 14.1413C4.58893 13.7913 3.88337 13.3163 3.28337 12.7163C2.68337 12.1163 2.20837 11.4108 1.85837 10.5997C1.50837 9.78856 1.33337 8.9219 1.33337 7.99967C1.33337 7.07745 1.50837 6.21079 1.85837 5.39967C2.20837 4.58856 2.68337 3.88301 3.28337 3.28301C3.88337 2.68301 4.58893 2.20801 5.40004 1.85801C6.21115 1.50801 7.07782 1.33301 8.00004 1.33301C8.92226 1.33301 9.78893 1.50801 10.6 1.85801C11.4112 2.20801 12.1167 2.68301 12.7167 3.28301C13.3167 3.88301 13.7917 4.58856 14.1417 5.39967C14.4917 6.21079 14.6667 7.07745 14.6667 7.99967C14.6667 8.9219 14.4917 9.78856 14.1417 10.5997C13.7917 11.4108 13.3167 12.1163 12.7167 12.7163C12.1167 13.3163 11.4112 13.7913 10.6 14.1413C9.78893 14.4913 8.92226 14.6663 8.00004 14.6663Z"
          fill="currentColor"
          className="text-attention"
        />
      </g>
    </svg>
  );
};

'use client';

import { FC, ReactNode, createContext, useEffect, useRef, useState } from 'react';

import { animateScroll, Events } from 'react-scroll';

const DELAY_DISABLE_SCROLL = 300; // disable scroll after 600ms without scroll up or prompt update.
const SCROLL_ANIMATION_DURATION = 400; // Duration of a scr
interface scrollOptions {
  to: number;
  behavior?: 'linear' | 'easeOutQuad';
  delayAfterCurrentScroll?: boolean;
}

interface ScrollWrapperContextProps {
  scrollTo?: (options?: scrollOptions) => Promise<void>;
  scrollToBottom?: (options?: { behavior: 'instant' | 'smooth' }) => Promise<void>;
  scrollPercent: number; // Value between 0 and 1
  
}

export const ScrollWrapperContext = createContext<ScrollWrapperContextProps>({
  scrollPercent: 0,
});

interface ScrollWrapperProps {
  children: ReactNode;
  id?: string;
  className?: string;
}

/*
	ScrollWrapper is a component providing vertical scroll. Design for chat.
	- scollToBotton() smooth scroll (load chat or send prompt)
	- scrollToBottomCancelable() instant scroll and cancel if user scroll up (prompt update scenario)
	Methods are shared using the ScrollWrapperContext Provider.
*/
export const ScrollWrapper: FC<ScrollWrapperProps> = ({ id, className, children }) => {
  const ref = useRef<HTMLDivElement>(null);

  // Data are stored as refs to avoid re-render of the component
  // Scrolling events are handled outside of react lifecycle and connecting with those
  // was creating many issues with the scroll position
  const isScrollDisabledRef = useRef<boolean>(false);

  const timestampLastScroll = useRef<number>(0);
  const timeoutDisableScrolling = useRef<NodeJS.Timeout>();

  // Using state object as this is react lifecycle specific and shared as ScrollWrapperContextProps
  const [scrollPercent, setScrollPercent] = useState<number>(0);

  /**
   * This function is used to remove the disable flag after a delay.
   */
  const resetDisableFlagAfterDelay = () => {
    if (timeoutDisableScrolling.current) {
      clearTimeout(timeoutDisableScrolling.current);
    }
    timeoutDisableScrolling.current = setTimeout(() => {
      isScrollDisabledRef.current = false;
    }, DELAY_DISABLE_SCROLL);
  };

  /*
		Listen to scroll event to keep up to date isScrolling and isScrollDisabled
  */
  useEffect(() => {
    const scrollingElement: HTMLDivElement | null = ref.current;

    let ticking = false;
    // Track previous position to detect scroll direction in scrollListener
    let previousPosition: number = 0;

    // Scroll listener to detect if user is scrolling
    const scrollListener = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          // On scroll, ANY SCROLL, we set isScrolling to true
          if (scrollingElement) {
            const scrollTop = Math.ceil(scrollingElement.scrollTop);
            const scrollHeight = Math.ceil(scrollingElement.scrollHeight);
            setScrollPercent(scrollTop / scrollHeight);

            // Detect scrolling up to disable cancelable scrolling (eg. during prompt update)
            if (previousPosition > scrollTop || isScrollDisabledRef.current === true) {
              // Disable scrolling if user scroll up
              isScrollDisabledRef.current = true;
              resetDisableFlagAfterDelay();
            }
            previousPosition = scrollTop;
          }
          ticking = false;
        });
        ticking = true;
      }
    };
    scrollingElement?.addEventListener('scroll', scrollListener);

    return () => {
      scrollingElement?.removeEventListener('scroll', scrollListener);
    };
  }, []);

  /*
		Smooth scroll from any position to bottom. User has to wait until scroll ends
	*/
  const scrollToBottom = (options?: { behavior: 'instant' | 'smooth' }): Promise<void> => {
    if (Date.now() - timestampLastScroll.current < SCROLL_ANIMATION_DURATION) {
      return Promise.resolve();
    }

    const reactScrollOptions = {
      // Scroll to the container
      containerId: id,
      // Duration of the scroll animation
      duration: options?.behavior === 'instant' ? 0 : SCROLL_ANIMATION_DURATION,
      // Smooth scroll
      smooth:  false,
    };

    return new Promise((resolve) => {
      // Event is trigger when scroll is finished, 
      // even if scrolling was 0 pixels of cancelled

      const scrollEnd = () => {
        Events.scrollEvent.remove('end');
        setTimeout(() => {
          resolve();
        }, 100);
      };
      Events.scrollEvent.register('end', scrollEnd);
      animateScroll.scrollToBottom(reactScrollOptions);
    });
  };

  /**
   * Scroll to a specific position in pixel within scrollWrapper.
   * @param options - The options for the scroll.
   */
  const scrollTo = (options?: scrollOptions): Promise<void> => {
    
    // React scroll options
    const reactScrollOptions = {
      // Scroll to the container
      containerId: id,
      // Duration of the scroll animation
      duration: SCROLL_ANIMATION_DURATION,
      // Target position
      to: options?.to ? options.to : 0,
      // Smooth scroll
      smooth: options?.behavior ?? 'easeOutQuad',
    };

    if (isScrollDisabledRef.current) {
      // If scroll is ALREADY disabled by user scrolling up, we reset the flag disabling
      // as code might still be trying to scroll
      resetDisableFlagAfterDelay();
      return Promise.resolve();
    }

    if (Date.now() - timestampLastScroll.current < SCROLL_ANIMATION_DURATION) {
      // if message reach tos creen scroll event is not triggered this solve it
      // If scroll is already in progress, we ignore this message EXCEPT if
      // delayAfterCurrentScroll is set to true in option.
      // Usually when stream end we ask for a last scroll to have clean behavior. 
      if (options?.delayAfterCurrentScroll) {
        setTimeout(() => {
          if (!isScrollDisabledRef.current) {
            animateScroll.scrollTo(reactScrollOptions.to, reactScrollOptions);
          }
        }, SCROLL_ANIMATION_DURATION);
      }
      return Promise.resolve();
    }

    // Ignore scrolling if we are already there.
    if (ref.current?.scrollTop && ref.current?.scrollTop >= reactScrollOptions.to) {
      return Promise.resolve();
    }

    // Scroll to the target position
    timestampLastScroll.current = Date.now();
    
    return new Promise((resolve) => {
      // Event is trigger when scroll is finished, 
      // even if scrolling was 0 pixels of cancelled
      const scrollEnd = () => {
        Events.scrollEvent.remove('end');
        resolve();
      };
      Events.scrollEvent.register('end', scrollEnd);
      animateScroll.scrollTo(reactScrollOptions.to, reactScrollOptions);
    });
  };

  return (
    <ScrollWrapperContext.Provider
      value={{
        scrollPercent,
        scrollTo,
        scrollToBottom,
      }}
    >
      <div ref={ref} id={id} className={className}>
        {children}
      </div>
    </ScrollWrapperContext.Provider>
  );
};

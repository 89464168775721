import { CustomCellRendererProps } from 'ag-grid-react';
import { getCellId } from '@unique/component-library/src/MagicTable/utils/helpers';
import DueDiligenceMarkdownPreview from './DueDiligenceMarkdownPreview';

export const DueDiligenceCellRenderer = (props: CustomCellRendererProps) => {
  const isNumberColumn = props.colDef.headerName === '';
  const isFirstRow = props.node.rowIndex === 0;

  return (
    <CellRenderer
      value={props.value}
      renderAsPlainText={isNumberColumn}
      isFirstRow={isFirstRow}
      cellId={getCellId(props.node.id, props.column.getColId())}
    />
  );
};

const CellRenderer = ({
  value,
  renderAsPlainText,
  isFirstRow,
  cellId,
}: {
  value: string;
  renderAsPlainText: boolean;
  isFirstRow: boolean;
  cellId: string;
}) => {
  return (
    <div className="flex flex-col gap-2" id={cellId}>
      <DueDiligenceMarkdownPreview
        value={value}
        isFirstRow={isFirstRow}
        renderAsPlainText={renderAsPlainText}
        isCellRenderer
      />
    </div>
  );
};

'use client';
import useDueDiligenceQuery, { TransformedMagicTableSheet } from '@/hooks/useDueDiligenceQuery';
import useUpdateCell from '@/hooks/useUpdateCell';
import { useAppDispatch } from '@/store';
import {
  SelectedCellContext,
  setGridReady,
  setSelectedContextCell,
  updateEditingCell,
} from '@/store/slices/dueDiligence';
import { CellEditing, CellEditRequest, MagicTable, useMagicTable } from '@unique/component-library';
import { IconHistory } from '@unique/icons';
import { LayoutContext, useToast } from '@unique/shared-library';
import {
  CellContextMenuEvent,
  ColumnResizedEvent,
  MenuItemDef,
  SideBarDef,
} from 'ag-grid-enterprise';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import { SpaceHeader } from '../Space/SpaceHeader';
import CellHistorySidebar from './CellHistorySidebar';
import DueDiligenceButtons from './DueDiligenceButtons';
import { DueDiligenceCellRenderer } from './DueDiligenceCellRenderer';
import {
  getUsePersistMagicTableColumnMetadata,
  getUseUpdateMagicTableSheetName,
  usePersistMagicTableColumnMetadata,
  useUpdateMagicTableSheetName,
} from '@/lib/swr/hooks';
import DueDiligenceStatusBadge from './DueDiligenceStatusBadge';
import { MagicTableSheetState } from '@/@generated/graphql';

const sideBar: SideBarDef = {
  toolPanels: [
    {
      id: 'cellHistory',
      labelDefault: 'Cell History',
      labelKey: 'cellHistory',
      iconKey: 'cell-history',
      toolPanel: CellHistorySidebar,
      toolPanelParams: {
        title: 'Cell History',
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressRowGroups: false,
      },
      width: 500,
    },
  ],
  position: 'right',
  hiddenByDefault: true,
  defaultToolPanel: 'cellHistory',
};

export default function DueDiligenceComponent({
  spaceId,
  spaceSlug: sheetId,
}: {
  spaceId: string;
  spaceSlug: string;
}) {
  const dispatch = useAppDispatch();

  const { showErrorToast, showSuccessToast } = useToast();

  const { setHeaderItems, setIsMenuExpanded } = useContext(LayoutContext);

  const contextMenuCell = useRef<SelectedCellContext | null>(null);

  const { dueDiligenceData, loadingTableData, requeryDueDiligence } = useDueDiligenceQuery({
    sheetId,
    setIsMenuExpanded,
  });

  const { trigger: updateMetadata } = usePersistMagicTableColumnMetadata(
    getUsePersistMagicTableColumnMetadata(),
  );

  const {
    tableRef,
    stopEditingCell,
    updateCellValues,
    getColumnIndex,
    getColumnAtIndex,
    setSideBarVisible,
    openToolPanel,
    scrollToLastRow,
    exportAsExcel,
  } = useMagicTable({
    tableColDefs: dueDiligenceData?.columnDefinitions || [],
    tableData: dueDiligenceData?.rows?.filter((row) => Number(row.id) > 1) || [], // remove the first pinned row
    isLoading: loadingTableData,
  });

  const { updateCell } = useUpdateCell({
    sheetId,
    updateCellValues,
    getColumnAtIndex,
    requeryDueDiligence,
  });

  const handleCellEditingStart = useCallback((event: CellEditing) => {
    dispatch(updateEditingCell(event));
  }, []);

  const handleCellEditingEnd = useCallback((event: CellEditing) => {
    dispatch(updateEditingCell(event));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(updateEditingCell(null));
    };
  }, []);

  const handleCellEditRequest = ({ rowIndex, columnId, newValue, event }: CellEditRequest) => {
    // The event from the ag-grid Edit component is called "edit"
    // If the event is coming from a different source that is not known by AG-Grid, it is undefined

    if (!event.source) return;

    updateCell({
      columnOrder: getColumnIndex(columnId),
      rowOrder: rowIndex,
      data: newValue,
      sheetId,
    });
  };

  const { trigger: updateSheetName } = useUpdateMagicTableSheetName(
    getUseUpdateMagicTableSheetName(),
    {
      onSuccess() {
        showSuccessToast('Table name updated');
      },
      onError: () => {
        showErrorToast('Failed to update table name');
      },
    },
  );

  const titleChange = (newTitle: string) => {
    updateSheetName({ name: newTitle, sheetId });
  };

  useEffect(() => {
    if (spaceId) {
      setHeaderItems([
        <SpaceHeader
          key={`space-header-${spaceId}`}
          title={dueDiligenceData?.name}
          isTitleEditable
          onTitleChange={titleChange}
          statusBadge={
            <DueDiligenceStatusBadge sheetId={sheetId} requeryDueDiligence={requeryDueDiligence} />
          }
        />,
      ]);
    }
    return () => {
      setHeaderItems([]);
    };
  }, [spaceId, dueDiligenceData]);

  const getContextMenuItems = useCallback((): (string | MenuItemDef)[] => {
    return [
      {
        name: 'View History',
        icon: renderToString(<IconHistory />),
        action: () => {
          setSideBarVisible(true);
          openToolPanel('cellHistory');
          dispatch(setSelectedContextCell(contextMenuCell.current));
        },
      },
    ];
  }, []);

  const onGridReady = useCallback(() => {
    dispatch(setGridReady(true));
  }, []);

  const onCellContextMenu = useCallback(
    (event: CellContextMenuEvent) => {
      const rowOrder = event.rowIndex;
      const columnOrder = getColumnIndex(event.column.getId());
      contextMenuCell.current = {
        rowOrder,
        columnOrder,
        rowId: event.node.data.id,
        columnId: event.column.getId(),
      };
    },
    [getColumnIndex],
  );

  const handleColumnResize = (event: ColumnResizedEvent) => {
    if (event.finished) {
      updateMetadata({
        sheetId,
        columnOrder: getColumnIndex(event.column.getColId()),
        metaData: { columnWidth: event.column.getActualWidth(), suppressSizeToFit: true },
      });
    }
  };

  const pinnedRow = (): TransformedMagicTableSheet['rows'] => {
    const firstRow = dueDiligenceData?.rows?.find((row) => Number(row.id) === 1);
    return firstRow ? [firstRow] : [];
  };

  return (
    <div className="flex h-full flex-col justify-start">
      <DueDiligenceButtons
        stopEditingCell={stopEditingCell}
        dueDiligenceData={dueDiligenceData}
        chatId={dueDiligenceData?.chat?.id}
        requeryDueDiligence={requeryDueDiligence}
        sheetId={sheetId}
        scrollToLastRow={scrollToLastRow}
        exportAsExcel={exportAsExcel}
      />
      <MagicTable
        ref={tableRef}
        cellEditingEnd={handleCellEditingEnd}
        contextMenuItems={getContextMenuItems}
        cellEditingStart={handleCellEditingStart}
        handleCellEditRequest={handleCellEditRequest}
        onGridReady={onGridReady}
        sidebar={sideBar}
        CustomCellRenderer={DueDiligenceCellRenderer}
        onCellContextMenu={onCellContextMenu}
        handleColumnResize={handleColumnResize}
        pinnedTopRowData={pinnedRow()}
        isTableBusy={dueDiligenceData?.state === MagicTableSheetState.Processing || false}
      />
    </div>
  );
}

import { useEffect, useRef } from 'react';
import { VoiceVisualizerProps } from './types';
import { logger } from '@unique/next-commons/logger';

declare global {
  interface Window {
    webkitAudioContext?: typeof AudioContext;
  }
}

const log = logger.child({ package: 'chat', namespace: 'chat:components:VoiceVisualizer' });

export const VoiceVisualizer = ({ isListening, animate, stream }: VoiceVisualizerProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const animationFrameRef = useRef<number>();
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);

  useEffect(() => {
    if (isListening && stream) {
      startVisualization();
      return;
    }

    pauseVisualization();
  }, [isListening, stream]);

  useEffect(() => {
    return () => {
      stopVisualization();
    };
  }, []);

  const startVisualization = async () => {
    try {
      const canvas = canvasRef.current;
      if (!canvas) return;

      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      if (!audioContextRef.current) return;

      analyserRef.current = audioContextRef.current.createAnalyser();
      analyserRef.current.fftSize = 64;

      mediaStreamRef.current = stream;

      const source = audioContextRef.current.createMediaStreamSource(mediaStreamRef.current);
      source.connect(analyserRef.current);

      const animateLoop = () => {
        if (canvas && analyserRef.current) {
          animate(canvas, analyserRef.current);
        }

        animationFrameRef.current = requestAnimationFrame(animateLoop);
      };

      animateLoop();
    } catch (error) {
      log.error('Error accessing the microphone:' + error.message);
    }
  };

  const pauseVisualization = () => {
    if (!animationFrameRef.current) {
      return;
    }

    cancelAnimationFrame(animationFrameRef.current);
    animationFrameRef.current = null; // Clear the reference to indicate it's paused
  };

  const stopVisualization = () => {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }

    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      mediaStreamRef.current = null;
    }

    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }

    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
    }
  };

  useEffect(() => {
    const resizeCanvas = () => {
      const canvas = canvasRef.current;
      const parent = canvas?.parentElement;

      if (!canvas || !parent) {
        return;
      }

      canvas.width = parent.clientWidth;
      canvas.height = parent.clientHeight;
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return <canvas ref={canvasRef} className="block h-full w-full max-w-full overflow-hidden" />;
};

'use client';
import { createContext, useContext, useState, FC, ReactNode, MouseEvent } from 'react';
import { ChatRenameModal } from './ChatRenameModal';
import { Chat } from '@/lib/swr/types';

type ChatRenameContextType = {
  handleClickRename: (event: MouseEvent<HTMLButtonElement>, chat: Partial<Chat>) => void;
};

const ChatRenameContext = createContext<ChatRenameContextType | undefined>(undefined);

export const ChatRenameProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [selectedChatForRename, setSelectedChatForRename] = useState<Partial<Chat> | undefined>(undefined);

  const handleClickRename = (event: MouseEvent<HTMLButtonElement>, chat: Partial<Chat>) => {
    event.preventDefault();
    setSelectedChatForRename(chat);
    setShowRenameModal(true);
  };

  const handleCloseRenameModal = () => {
    setShowRenameModal(false);
    setSelectedChatForRename(undefined);
  };

  return (
    <ChatRenameContext.Provider value={{ handleClickRename }}>
      {children}
      {showRenameModal && selectedChatForRename && (
        <ChatRenameModal chat={selectedChatForRename} onClose={handleCloseRenameModal} />
      )}
    </ChatRenameContext.Provider>
  );
};

export const useChatRename = () => {
  const context = useContext(ChatRenameContext);
  if (!context) {
    throw new Error('useChatRename must be used within a ChatRenameProvider');
  }
  return context;
};

'use client';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import {
  ButtonIcon,
  ButtonVariant,
  InputSelect,
  InputSelectOption,
  Spinner,
} from '@unique/component-library';
import { IconAddColumn, IconArrowDown, IconWordFile } from '@unique/icons';
import Dropdown from '@unique/component-library/src/Dropdown';
import cn from 'classnames';
import {
  getUseGenerateMagicTableArtifact,
  useContentByIdQuery,
  useGenerateMagicTableArtifact,
  useMagicTableArtifactUpdateSubscription,
} from '@/lib/swr/hooks';
import { logger } from '@unique/next-commons/logger';
import { MagicTableArtifact, MagicTableArtifactType } from '@/@generated/graphql';
import { format } from 'date-fns';
import { ContentById } from '@/lib/swr/types';
import { loadFile } from '@unique/next-commons/helpers';
import { useAuth } from 'react-oidc-context';
import { ClientContext, Service } from '@unique/next-commons/swr';
import { serializeError } from 'serialize-error';
import { useToast } from '@unique/shared-library';

type Props = {
  sheetId: string;
  chatId: string;
  exportAsExcel: () => void;
  isDisabled: boolean;
};

const log = logger.child({
  package: 'chat',
  namespace: 'components:duediligence:duediligence-export-button',
});

const ArtifactTypes = {
  [MagicTableArtifactType.FullReport]: 'Full Report - (DOCX)',
  [MagicTableArtifactType.Questions]: 'Questions',
};

const generateOptions = (): { label: string; value: string }[] => {
  const options = [];
  for (const option in MagicTableArtifactType) {
    options.push({
      label: ArtifactTypes[MagicTableArtifactType[option]],
      value: MagicTableArtifactType[option],
    });
  }
  return options;
};

const AG_GRID_EXCEL_EXPORT = 'AG_GRID_EXCEL_EXPORT';

const ExcelExportOption = { label: 'Full Report (XLSX)', value: AG_GRID_EXCEL_EXPORT };

const DueDiligenceExportButton: FC<Props> = ({ sheetId, chatId, exportAsExcel, isDisabled }) => {
  const auth = useAuth();
  const [step, setStep] = useState(1);
  const { services } = useContext(ClientContext);
  const { showErrorToast } = useToast();

  const [artifactGenerationCancelled, setArtifactGenerationCancelled] = useState(false);

  const [artifactData, setArtifactData] = useState<Partial<MagicTableArtifact> | null>(null);

  const buttonSpanRef = useRef<HTMLSpanElement | null>(null);

  const { data: contentData } = useContentByIdQuery(
    artifactData?.contentId
      ? {
          contentIds: [artifactData?.contentId],
          chatId,
        }
      : null,
  );

  const onFileOpenClick = async (contentItem: ContentById) => {
    if (!contentItem) return;
    try {
      await loadFile({
        accessToken: auth.user.access_token,
        ingestionUrl: services[Service.NODE_INGESTION],
        content: contentItem,
        chatId,
        shouldOpen: true,
      });
    } catch (error) {
      log.error(
        { error: serializeError(error) },
        `Can not open file ${contentItem.title || contentItem.key}`,
      );
      showErrorToast(`Can not open file ${contentItem.title || contentItem.key}`);
    }
  };

  const artifactUpdateSubscription = useMagicTableArtifactUpdateSubscription(
    {
      next: (data) => {
        if (artifactGenerationCancelled) {
          setStep(1);
          return;
        }
        if (data && data?.magicTableArtifactUpdate?.id) {
          setArtifactData(data.magicTableArtifactUpdate);
          setStep(3);
        }
      },
      error: (errors) => {
        log.error(`Due Diligence artifact generation error. Error: ${JSON.stringify(errors)}`);
      },
      complete: () => {
        log.info('Due Diligence artifact generation complete');
      },
    },
    { sheetId },
  );

  const { trigger } = useGenerateMagicTableArtifact(getUseGenerateMagicTableArtifact(), {
    onSuccess: () => {
      setArtifactGenerationCancelled(false);
      setStep(2);
    },
  });

  useEffect(() => {
    artifactUpdateSubscription.on('connected', () => {
      log.info('Connected to socket, MagicTableArtifactUpdateSubscription');
    });
    artifactUpdateSubscription.on('closed', () => {
      log.info('Disconnected from socket, MagicTableArtifactUpdateSubscription');
    });
    return () => {
      artifactUpdateSubscription.terminate();
    };
  }, []);

  const onGenerateClicked = (
    artifactType: MagicTableArtifactType | typeof AG_GRID_EXCEL_EXPORT,
  ) => {
    if (artifactType === AG_GRID_EXCEL_EXPORT) {
      exportAsExcel();
      return;
    }
    trigger({ sheetId, artifactTypes: [artifactType] });
  };

  const onCancelClicked = () => {
    setStep(1);
    setArtifactGenerationCancelled(true);
    artifactUpdateSubscription.terminate();
  };

  const onDownloadButtonClicked = () => {
    if (!contentData?.contentById[0]) {
      showErrorToast('No content found to download');
      return;
    }
    onFileOpenClick(contentData?.contentById[0]);
    buttonSpanRef.current.click();
  };

  useEffect(() => {
    return () => {
      setArtifactGenerationCancelled(false);
      setStep(1);
    };
  }, []);

  return (
    <>
      <Dropdown
        dropDownElement={
          <div className="bg-background flex w-full items-center gap-x-2 rounded-md p-3">
            {step === 1 && (
              <ExportFileSelect onGenerateClicked={onGenerateClicked} isDisabled={isDisabled} />
            )}
            {step === 2 && <FileProgress onCancelClicked={onCancelClicked} />}
            {step === 3 && (
              <ExportCompleted
                downloadClicked={onDownloadButtonClicked}
                artifact={artifactData}
                onCreateClicked={() => setStep(1)}
              />
            )}
          </div>
        }
        className="w-[400px]"
      >
        {(isOpen) => (
          <ButtonIcon variant={ButtonVariant.SECONDARY} icon={<IconAddColumn />}>
            <span className="flex items-center gap-x-3" ref={buttonSpanRef}>
              <span className="hidden sm:inline">Export </span>
              <IconArrowDown
                className={cn({
                  'font-bold transition duration-200': true,
                  'rotate-180': isOpen,
                })}
                width="14px"
                height="14px"
              />
            </span>
          </ButtonIcon>
        )}
      </Dropdown>
    </>
  );
};

const ExportFileSelect = ({
  onGenerateClicked,
  isDisabled,
}: {
  onGenerateClicked: (artifactType: MagicTableArtifactType) => void;
  isDisabled: boolean;
}) => {
  const [selectedOption, setSelectedOption] = useState<InputSelectOption>({
    label: ArtifactTypes[MagicTableArtifactType.FullReport],
    value: MagicTableArtifactType.FullReport,
  });

  return (
    <div className="flex w-full flex-col gap-y-2">
      <InputSelect
        id="options"
        options={[...generateOptions(), ExcelExportOption]}
        selectedOption={selectedOption}
        handleSelectOption={(option) => {
          setSelectedOption(option);
        }}
      />
      <ButtonIcon
        className="ml-auto"
        onClick={() => onGenerateClicked(selectedOption.value as MagicTableArtifactType)}
        disabled={isDisabled}
      >
        Generate
      </ButtonIcon>
    </div>
  );
};

const FileProgress = ({ onCancelClicked }: { onCancelClicked: () => void }) => {
  return (
    <div className="flex w-full items-center gap-x-4">
      <Spinner wrapperClasses="pt-2" />
      <div className="space-between flex w-full items-center">
        <div className="flex items-center gap-x-2">
          <IconWordFile />
          <p className="text-on-background-main font-medium">Preparing your file</p>
        </div>
      </div>
      <ButtonIcon className="ml-auto" onClick={onCancelClicked}>
        Cancel
      </ButtonIcon>
    </div>
  );
};

const ExportCompleted = ({
  downloadClicked,
  artifact,
  onCreateClicked,
}: {
  downloadClicked: () => void;
  onCreateClicked: () => void;
  artifact: Partial<MagicTableArtifact>;
}) => {
  return (
    <div className="flex w-full gap-x-3">
      <div className="flex">
        <IconWordFile />
      </div>
      <div className="flex w-full flex-col items-start gap-3">
        <div className="flex items-center">
          <div className="flex flex-col justify-between gap-y-2">
            <p className="text-on-background-main font-medium">{artifact?.name}</p>
            <p className="text-on-control-dimmed text-sm">
              {format(new Date(artifact?.updatedAt), 'dd MMM yy, HH:mm')}
            </p>
          </div>
        </div>
        <div className="flex w-full justify-end gap-x-2">
          <ButtonIcon onClick={onCreateClicked}>Create New</ButtonIcon>
          <ButtonIcon onClick={downloadClicked}>Download</ButtonIcon>
        </div>
      </div>
    </div>
  );
};

export default DueDiligenceExportButton;

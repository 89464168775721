import { useContext } from 'react';
import { ModalContext } from '../providers/ModalProvider';

/**
 * useModal Hook
 *
 * @returns {ModalContext} ModalContext
 *
 * Example:
 *  const { openModal, closeModal } = useModal();
 *  openModal(<Button onClick={closeModal} />);
 */
export const useModal = () => useContext(ModalContext);

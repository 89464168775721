'use client';

import { useChatDelete } from '@/hooks/useChatDelete';
import { CHAT_QUERY_VARIABLES } from '@/lib/constants/chatQueryVariables';
import { useChatsQuery } from '@/lib/swr/hooks';
import {
  PopupMenu,
  SecondLevelNavItem,
} from '@unique/component-library';
import { IconSelect, IconDelete, IconEdit } from '@unique/icons';
import { useLocation, useParams } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import { useChatRename } from './ChatRenameProvider';
import IndeterminateCheckbox from '@unique/component-library/src/IndeterminateCheckbox';
import { ChatMenuHeader } from './ChatMenuHeader';
import { ChatMenuSelectionHeader } from './ChatMenuSelectionHeader';
import { Chat } from '@/@generated/graphql';
import { chatSlice, useAppDispatch, useAppSelector } from '@/store';

type ChatMenuProps = {
  title?: string;
};

export const ChatMenu: FC<ChatMenuProps> = ({ title = 'Chats' }) => {
  const { id } = useParams();
  const {
    data: chatsQuery,
    isLoading,
    error: chatError,
    mutate,
  } = useChatsQuery(CHAT_QUERY_VARIABLES, { revalidateOnFocus: false });
  const { deleteChatIds, deleteAllChats } = useChatDelete();
  const { handleClickRename } = useChatRename();
  const { chats } = useAppSelector(state => state.chat);
  const dispatch = useAppDispatch();
  const { setChats } = chatSlice.actions;

  useEffect(() => {
    if (chatsQuery?.chats) {
      dispatch(setChats(chatsQuery.chats as Partial<Chat>[]));
    }
  }, [chatsQuery?.chats]);

  const [selectedChats, setSelectedChats] = useState<string[]>([]);

  const getIsOnSingleChatPage = (chatId: string) => id === chatId;

  const [isSelectingModeActive, setIsSelectingModeActive] = useState(false);

  
  //Toggle selected Chat save previous selected chat so user can use Shift to select multiple chats
  const [selectedChatIndex, setSelectedChatIndex] = useState(0);
  const toggleSelectedChat = (chatId: string, event?: React.MouseEvent) => {

    const newSelectedChatIndex = chats?.findIndex((chat) => chat.id === chatId);

    // If Shift key is pressed we add to selected chats all chats between the last selected chat and the current chat
    if (event?.shiftKey) {
      // select all chats between the last selected chat and the current chat
      const start = Math.min(selectedChatIndex, newSelectedChatIndex);
      const end =Math.max(selectedChatIndex, newSelectedChatIndex);
      const chatsToSelect = chats?.slice(start, end + 1);
      setSelectedChats((selectedChats) => Array.from(new Set([...selectedChats, ...chatsToSelect.map((chat) => chat.id)])));
      return;
    }
    // Update startPoint for multiple selection
    setSelectedChatIndex(newSelectedChatIndex);

    if (selectedChats.includes(chatId)) {
      setSelectedChats((selectedChats) => selectedChats.filter((selectedChat) => selectedChat !== chatId));
      return;
    }
    setIsSelectingModeActive(true);
    setSelectedChats((selectedChats) => [...selectedChats, chatId]);
  };

  const toggleAllChats = () => {
    setIsSelectingModeActive(true);
    if (selectedChats.length !== (chats?.length || 0)) {
      setSelectedChats((chats || []).map((chat) => chat.id));
      return;
    }
    setSelectedChats([]);
  };

  // If user navigate we reset select mode
  const location = useLocation();

  useEffect(() => {
    setIsSelectingModeActive(false);
    setSelectedChats([]);
  }, [location.pathname]);

  useEffect(() => {
    if (!id?.length || isLoading) return;
    // If a new chat is created (route changes with new id), revalidate chat menu
    const chatExists = chats?.findIndex((chat) => chat.id === id) > -1;
    if (!chatExists) mutate();
  }, [chats, id, mutate, isLoading]);

  return (
    <div className="relative h-full">
      {!isSelectingModeActive ? (
        <ChatMenuHeader 
          title={title} 
          isLoading={isLoading} 
          activateSelectMode={() => setIsSelectingModeActive(true)}
          onSelectAll={toggleAllChats}
          onDeleteAll={() => deleteAllChats({ mutate })}
          disableDeleteAll={chats?.length === 0}
          />
      ) : (
        <ChatMenuSelectionHeader 
          selectedChatIds={selectedChats}
          chats={chats}
          onToggleButtonClick={toggleAllChats}
          onDeleteButtonClick={() => {
            deleteChatIds(selectedChats, { mutate, chats });
            setSelectedChats([]);
            setIsSelectingModeActive(false);
          }}
          onCancelButtonClick={() => {
            setSelectedChats([]);
            setIsSelectingModeActive(false);
          }}
          />
      )}

      {!isLoading && !chats?.length && !chatError && (
        <SecondLevelNavItem
          key="no-chats"
          name="Your past chats will show here"
          isActive={false}
          readOnly={true}
        />
      )}

      {!isLoading && !chatError && (
        <>
          {chats?.map((chat) => (
              <SecondLevelNavItem
                key={chat.id}
                name={chat.title ?? 'Unnamed chat'}
                href={`/${chat.id}`}
                isActive={getIsOnSingleChatPage(chat.id)}
                className="group select-none"
                onClick={isSelectingModeActive && ((event) => {
                  event.preventDefault();
                  toggleSelectedChat(chat.id, event);
                })}
                icon={
                  isSelectingModeActive && (
                    <IndeterminateCheckbox defaultChecked={selectedChats.includes(chat.id)} />
                  )
                }
              >
                {!isSelectingModeActive && (
                  <div className="ml-auto">
                    <PopupMenu
                      buttonClassesClosed="hidden sm:group-hover:flex"
                      buttonClassesOpened="flex ml-2 sm:group-hover:ml-0"
                      menuItems={[
                        {
                          label: 'Select',
                          icon: <IconSelect width="14px" height="14px" />,
                          onClick: (event) => {
                            event.preventDefault();
                            toggleSelectedChat(chat.id);
                          },
                        },
                        {
                          label: 'Rename',
                          icon: <IconEdit width="18px" height="18px" />,
                          onClick: (event) => handleClickRename(event, chat),
                        },
                        {
                          label: 'Delete',
                          icon: <IconDelete />,
                          onClick: (event) => {
                            event.preventDefault();
                            deleteChatIds([chat.id], { mutate, chats });
                          },
                        },
                      ]}
                    />
                  </div>
                )}
              </SecondLevelNavItem>
            ))}
        </>
      )}
    </div>
  );
};

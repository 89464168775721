import { MagicTableRow } from '@unique/shared-library/@generated/graphql';
import { ColDef, ColumnHeaderClickedEvent } from 'ag-grid-enterprise';
import { marked } from 'marked';
import { ReactNode } from 'react';

// ZZ = 702 columns
export const TOTAL_COLUMNS = 20; // last column name is CZ
export const MIN_ROW_HEIGHT = 40;

const fontSize = 14;
const lineHeight = 1.5;
const paragraphOffset = 16;
const minCharsPerLine = 25;

export type DefaultEmptyColumn = {
  headerName: string;
  field: string;
};

export const idColumn: ColDef = {
  field: 'id',
  headerName: '',
  pinned: 'left',
  rowDrag: false,
  maxWidth: 40,
  lockPosition: 'left',
  lockVisible: false,
  wrapText: false,
  autoHeight: false,
  cellClass: 'custom-lock-pinned',
  cellStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F7F7F5',
  },
};

export function generateExcelHeaders(columnCount: number): string[] {
  const headers: string[] = [];

  for (let i = 0; i < columnCount; i++) {
    headers.push(getExcelColumnName(i));
  }

  return headers;
}

function getExcelColumnName(index: number): string {
  let columnName = '';
  let remainder: number;

  while (index >= 0) {
    remainder = index % 26;
    columnName = String.fromCharCode(65 + remainder) + columnName;
    index = Math.floor(index / 26) - 1;
  }

  return columnName;
}

export const generateEmptyColumns = (numberOfColumns = 26): DefaultEmptyColumn[] => {
  const emptyColumns = generateExcelHeaders(numberOfColumns).map((header) => {
    return {
      headerName: header,
      field: header,
    };
  });
  return emptyColumns;
};

export const generateEmptyRows = (rowCount: number, startIndex: number) => {
  const emptyRows = Array.from({ length: rowCount }, (_, index) => {
    const id = index + startIndex;
    const stringId = id.toString();
    return { id: stringId };
  });
  return emptyRows;
};

export const generateEmptyLoadingRows = (
  columnCount = 26,
  loadingComponent: string | ReactNode,
) => {
  const headersGenerated: string[] = [];
  const columns = generateExcelHeaders(columnCount).map((header) => {
    headersGenerated.push(header);
    return {
      headerName: header,
      headerClass: '',
      field: header,
      sortable: false,
      minWidth: 200,
      width: 200,
      editable: false,
      cellRenderer: () => loadingComponent,
    };
  });

  const emptyRows = headersGenerated.map((header, index) => {
    return {
      field: header,
      id: (index + 1).toString(),
    };
  });

  return { columns: columns, rows: emptyRows };
};

/**
 *
 * @param colDefs AGGrid Column definitions
 * @param event the Column header click event
 * @returns a new column definition
 */
export const highlightHeaderColumnCells = (colDefs: ColDef[], event: ColumnHeaderClickedEvent) => {
  let columns: ColDef[] = colDefs;

  const found = removeHighlights(colDefs);

  // Deselect selected column if any
  if (found.length > 0) {
    columns = found;
  }

  const newColDefs = columns.map((colDef) => {
    if (colDef.field === event.column.getId()) {
      // Remove highlight if already present
      if (colDef.cellClass?.toString().includes('highlight-cells')) {
        return {
          ...colDef,
          cellClass: colDef.cellClass.toString().replace('highlight-cells', ''),
        };
      }
      // Add highlight if not present
      return {
        ...colDef,
        cellClass: 'highlight-cells',
      };
    }
    return colDef;
  });
  return newColDefs;
};

/**
 *
 * @param colDefs AGGrid Column definitions
 * @returns a new column definition
 */
export const removeHighlights = (colDefs: ColDef[]) => {
  let newColumns: ColDef[] = [];
  const foundColumns = colDefs.find((colDef) =>
    colDef.cellClass?.toString().includes('highlight-cells'),
  );
  if (foundColumns) {
    newColumns = colDefs.map((colDef) => {
      if (colDef?.cellClass?.toString().includes('highlight-cells')) {
        return {
          ...colDef,
          cellClass: colDef.cellClass.toString().replace('highlight-cells', ''),
        };
      }
      return colDef;
    });
  }
  return newColumns;
};

/**
 * Calculates the height of a div element based on the provided markdown text.
 * @param {string} markdownText - The markdown text to calculate the height for.
 * @param {string} containerWidth - The width of the container that markdown text will be displayed in.
 * @returns {number} The calculated height of the div element.
 */
export const calculateTextHeight = (markdownText: string, containerWidth = 200) => {
  const html = marked(markdownText).toString();

  // Create a new DOMParser instance
  const parser = new DOMParser();

  // Parse the HTML string into a document
  const doc = parser.parseFromString(html, 'text/html');

  // Extract plain text from the document
  const plainText = doc.body.textContent || '';

  // Approximate width of a character in pixels (this can vary based on the font)
  const charWidth = fontSize * 0.6; // 0.6 is a typical average width-to-height ratio for characters

  // Calculate the maximum number of characters per line based on the container width
  const charsPerLine = Math.floor(containerWidth / charWidth);

  // Split the text into lines based on line breaks
  const lines = plainText.split('\n');

  // Calculate the number of lines considering the characters per line
  let totalLines = 0;
  for (let line of lines) {
    // Remove extra spaces and count the number of characters
    line = line.trim();
    if (line) {
      // Calculate the number of lines this line will occupy
      const numLines = Math.ceil(line.length / charsPerLine);
      totalLines += numLines;
    }
  }

  // Calculate the number of paragraphs
  const paragraphs = doc.body.querySelectorAll('p').length;

  // Calculate the height of the div
  const textHeight = totalLines * lineHeight * fontSize;
  const marginHeight = paragraphs * paragraphOffset;
  const divHeight = textHeight + marginHeight;
  return divHeight > MIN_ROW_HEIGHT ? divHeight : MIN_ROW_HEIGHT;
};

export const calculateRowHeight = (row: MagicTableRow) => {
  const longestContent = Object.values(row).reduce((acc, val) => {
    return val.length > acc.length ? val : acc;
  }) as string;
  if (longestContent.length < minCharsPerLine) return MIN_ROW_HEIGHT;
  return calculateTextHeight(longestContent);
};

export const getCellId = (rowId: string, colId: string) => `cell-${rowId}-${colId}`;

export const transformMagicTableData = (
  tableData: MagicTableRow[],
  columnDefs: DefaultEmptyColumn[],
) => {
  // Create a lookup map for fast access to tableData by row ID
  // and Calculate the height of each row based on the longest content in the row
  const tableDataMap = new Map<number, MagicTableRow>(
    tableData?.map((row) => {
      const computedHeight = calculateRowHeight(row);
      const height = computedHeight < MIN_ROW_HEIGHT ? MIN_ROW_HEIGHT : computedHeight;
      return [Number(row.id), { ...row, rowHeight: height }];
    }),
  );

  // Define function to create a row with either actual data or empty fields
  const createRowData = (id: number, data: { [key: string]: string } = {}) => {
    return columnDefs.reduce(
      (acc: { [key: string]: string }, colDef) => {
        acc[colDef.field] = data[colDef.field] || '';
        acc['rowHeight'] = data.rowHeight?.toString() || MIN_ROW_HEIGHT.toString();
        return acc;
      },
      { id: id.toString() },
    );
  };

  // Calculate the maximum row ID
  const maxRowId = Math.max(...tableData.map((row) => Number(row.id)), 0);

  // Generate all rows, filling in gaps with empty rows
  const allRowData = Array.from({ length: maxRowId + 101 }, (_, index) => {
    const rowId = index + 1;
    const rowData = tableDataMap.get(rowId) || {}; // Get data or default to empty object
    return createRowData(rowId, rowData);
  });
  return allRowData;
};

import {
  AssessmentRating,
  getAssessmentBadgeLabel,
  mapEvaluationToAssessmentBadgeVariant,
  mapEvaluationToIconSentimentVariant,
} from '@/helpers/messageAssessment';
import { Message } from '@/lib/swr/types';
import { IconArrowDown, IconSentiment } from '@unique/icons';
import cn from 'classnames';
import { FC, ReactNode, useMemo, useState } from 'react';
import { AssessmentBadge } from './AssessmentBadge';

type MessageAssessmentProps = {
  message: Message;
  additionalComponents?: ReactNode;
};

const MessageAssessment: FC<MessageAssessmentProps> = ({ message, additionalComponents }) => {
  const [showAssessments, setShowAssessments] = useState(false);
  const assessments = message.assessment ?? [];

  const evaluationRating = useMemo(() => {
    // list of ratings containing POSITIVE, NEGATIVE, VERIFIED, UNVERIFIED
    const ratings = assessments.map((assessment) => assessment.label);

    if (ratings.some((rating) => rating === AssessmentRating.NEGATIVE)) {
      return AssessmentRating.NEGATIVE;
    }

    if (ratings.some((rating) => rating === AssessmentRating.UNVERIFIED)) {
      return AssessmentRating.UNVERIFIED;
    }

    // Note: Positive and verified are treated the same
    return AssessmentRating.POSITIVE;
  }, [assessments]);

  const visibleAssessments = useMemo(
    () => assessments.filter((assessment) => assessment.isVisible),
    [assessments],
  );

  if (assessments.length === 0) {
    return null;
  }

  const handleClickShowAssessment = () => {
    setShowAssessments((prev) => !prev);
  };

  return (
    <>
      <div className="flex w-full items-center justify-between">
        <button
          className="bg-background-variant subtitle-2 flex w-max items-center gap-x-2 rounded-[8px] px-2.5 py-1"
          onClick={handleClickShowAssessment}
        >
          <IconSentiment variant={mapEvaluationToIconSentimentVariant(evaluationRating)} />
          AI answer evaluation
          <IconArrowDown
            width="12px"
            className={cn({
              'origin-center transition': true,
              'rotate-180': showAssessments,
            })}
          />
        </button>
        {additionalComponents}
      </div>
      <div
        className={cn({
          'max-h-0 overflow-hidden opacity-0 transition-all duration-300 ease-in-out': true,
          'mb-6 max-h-screen opacity-100': showAssessments,
        })}
      >
        {visibleAssessments.map((assessment) => (
          <div className="mt-6" key={assessment.id}>
            <AssessmentBadge
              variant={mapEvaluationToAssessmentBadgeVariant(assessment)}
              label={getAssessmentBadgeLabel(assessment)}
            />
            <div className="body-2 text-on-background-main mt-2">{assessment.explanation}</div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MessageAssessment;

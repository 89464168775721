import { Chat } from '@/@generated/graphql';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface Stream {
  chatId: string;
  messageId: string;
}

// map of contentId to imageUrl
export interface ChatImageUrls {
  [key: string]: string;
}

export interface ChatState {
  chatImageUrls: ChatImageUrls;
  streams: Stream[];
  chats?: Partial<Chat>[];
}

const initialState: ChatState = {
  chatImageUrls: {},
  streams: [],
  chats: null,
};

export interface setChatImageUrlsInput {
  chatImageUrls: ChatImageUrls;
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChats: (state, action: PayloadAction<Partial<Chat>[] | null>) => {
      state.chats = action.payload;
    },
    setChatImageUrls: (state, action: PayloadAction<setChatImageUrlsInput>) => {
      state.chatImageUrls = action.payload.chatImageUrls;
    },
    // Handle streams
    addStream: (state, action: PayloadAction<Stream>) => {
      state.streams.push(action.payload);
    },
    removeStream: (state, action: PayloadAction<Stream>) => {
      // We clean all streams with the same chatId to avoid weird blocked situation with multiple stream.
      // Only affet the stop button UI for now.
      state.streams = state.streams.filter((s) => action.payload.chatId !== s.chatId);
    },
  },
});

// Action creators are generated for each case reducer function
export * from './actions';
export default chatSlice.reducer;

import { FC } from 'react';
import { IconProps } from '.';

export const IconComplianceCheck: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.21252 8.52503L6.12502 7.45628C5.98752 7.31878 5.81565 7.25003 5.6094 7.25003C5.40315 7.25003 5.22502 7.32503 5.07502 7.47503C4.93752 7.61253 4.86877 7.78753 4.86877 8.00003C4.86877 8.21253 4.93752 8.38753 5.07502 8.52503L6.68752 10.1375C6.83752 10.2875 7.01252 10.3625 7.21252 10.3625C7.41252 10.3625 7.58752 10.2875 7.73752 10.1375L10.925 6.95003C11.075 6.80003 11.1469 6.62503 11.1406 6.42503C11.1344 6.22503 11.0625 6.05003 10.925 5.90003C10.775 5.75003 10.5969 5.6719 10.3906 5.66565C10.1844 5.6594 10.0063 5.73128 9.85627 5.88128L7.21252 8.52503ZM5.11252 15.3125L4.02502 13.475L1.96252 13.025C1.77502 12.9875 1.62502 12.8907 1.51252 12.7344C1.40002 12.5782 1.35627 12.4063 1.38127 12.2188L1.58752 10.1L0.181274 8.48753C0.0562744 8.35003 -0.00622559 8.18753 -0.00622559 8.00003C-0.00622559 7.81253 0.0562744 7.65003 0.181274 7.51253L1.58752 5.90003L1.38127 3.78128C1.35627 3.59378 1.40002 3.4219 1.51252 3.26565C1.62502 3.1094 1.77502 3.01253 1.96252 2.97503L4.02502 2.52503L5.11252 0.687528C5.21252 0.525028 5.35002 0.415653 5.52502 0.359403C5.70002 0.303153 5.87502 0.312528 6.05002 0.387528L8.00002 1.21253L9.95002 0.387528C10.125 0.312528 10.3 0.303153 10.475 0.359403C10.65 0.415653 10.7875 0.525028 10.8875 0.687528L11.975 2.52503L14.0375 2.97503C14.225 3.01253 14.375 3.1094 14.4875 3.26565C14.6 3.4219 14.6438 3.59378 14.6188 3.78128L14.4125 5.90003L15.8188 7.51253C15.9438 7.65003 16.0063 7.81253 16.0063 8.00003C16.0063 8.18753 15.9438 8.35003 15.8188 8.48753L14.4125 10.1L14.6188 12.2188C14.6438 12.4063 14.6 12.5782 14.4875 12.7344C14.375 12.8907 14.225 12.9875 14.0375 13.025L11.975 13.475L10.8875 15.3125C10.7875 15.475 10.65 15.5844 10.475 15.6407C10.3 15.6969 10.125 15.6875 9.95002 15.6125L8.00002 14.7875L6.05002 15.6125C5.87502 15.6875 5.70002 15.6969 5.52502 15.6407C5.35002 15.5844 5.21252 15.475 5.11252 15.3125Z"
        fill="currentColor"
      />
    </svg>
  );
};

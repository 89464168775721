import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PersistedDueDiligenceState {
  questionFileIds: string[];
  sourceFileIds: string[];
  dueDiligenceDetails: {
    sheetId: string;
    chatId: string;
    dueDiligenceId: string;
  };
}

const persistedInitialState: PersistedDueDiligenceState = {
  questionFileIds: [],
  sourceFileIds: [],
  dueDiligenceDetails: {
    sheetId: '',
    chatId: '',
    dueDiligenceId: '',
  },
};

export const persistedDueDiligenceSlice = createSlice({
  name: 'persistedDueDiligence',
  initialState: persistedInitialState,
  reducers: {
    setQuestionFileIds: (state, action: PayloadAction<string[]>) => {
      state.questionFileIds = action.payload;
    },
    setSourceFileIds: (state, action: PayloadAction<string[]>) => {
      state.sourceFileIds = action.payload;
    },
    setDueDiligenceDetails: (
      state,
      action: PayloadAction<PersistedDueDiligenceState['dueDiligenceDetails']>,
    ) => {
      state.dueDiligenceDetails = action.payload;
    },
  },
});

const persistedDueDiligenceReducer = persistedDueDiligenceSlice.reducer;

export const { setQuestionFileIds, setSourceFileIds, setDueDiligenceDetails } =
  persistedDueDiligenceSlice.actions;

export { persistedDueDiligenceReducer };

'use client';

import { Role } from '@/@generated/graphql';
import { escapeHtmlOutsideCodeBlocks } from '@/helpers/escapeHtmlOutsideCodeBlocks';
import { formatSupportEmail } from '@/helpers/formatSupportEmail';
import { extractMessageCodeLanguage, messageHasCode } from '@/helpers/messages';
import { INTERNAL_URL_PREFIX, isInternalUniqueUrl } from '@/helpers/references';
import { Message } from '@/lib/swr/types';
import { useAppSelector } from '@/store';
import {
  CopyToClipboard,
  DefaultMarkdownLink,
  MarkdownImageType,
  MarkdownLinkType,
  MarkdownPreview,
  duration,
} from '@unique/component-library';
import { ClientThemeContext, FeatureFlagContext, useCopyToClipboard } from '@unique/shared-library';
import cn from 'classnames';
import { isEqual } from 'lodash';
import { FC, memo, useContext } from 'react';
import FeedbackButtons from '../FeedbackButtons';
import { ChatMessageDebugFooter } from './ChatMessageDebugFooter';
import {
  ChatPromptButton,
  PROMPT_URL_PREFIX_HTTP,
  PROMPT_URL_PREFIX_HTTPS,
} from './ChatPromptButton';
import { ChatReferences } from './ChatReferences';
import { SavePromptControl } from './SavePromptControl';
import SenderProfile from './SenderProfile';
import MessageAssessment from './Assessment/MessageAssessment';

interface MessageItemProps {
  message: Message;
  allowDebugRead: boolean;
  showPdfHighlighting: boolean;
  redirectInternalStorageOnly: boolean;
  onThumbsClick: (messageId: string, isPositive?: boolean) => void;
  onSavePromptClick: (prompt: string) => void;
  handleSelectPrompt: (prompt: string) => void;
}

const linkContainsPrompt = (href: unknown) => {
  return href && typeof href === 'string' && href.startsWith(PROMPT_URL_PREFIX_HTTPS);
};

// if message has the same props, we don't need to re-render it
// if message is streaming, it will change all the time while streaming so we need to re-render
const messagePropsDidNotChange = (prevProps: MessageItemProps, nextProps: MessageItemProps) => {
  return (
    isEqual(prevProps.message, nextProps.message) &&
    prevProps.allowDebugRead === nextProps.allowDebugRead &&
    prevProps.showPdfHighlighting === nextProps.showPdfHighlighting &&
    prevProps.redirectInternalStorageOnly === nextProps.redirectInternalStorageOnly
  );
};

export const MessageItem: FC<MessageItemProps> = ({
  message,
  allowDebugRead,
  showPdfHighlighting,
  redirectInternalStorageOnly,
  onThumbsClick,
  onSavePromptClick,
  handleSelectPrompt,
}) => {
  const { copiedText, copy } = useCopyToClipboard();
  const { copiedText: copiedCode, copy: copyCode } = useCopyToClipboard();
  const isAssistantMessage = message.role === Role.Assistant;
  const isUserMessage = message.role === Role.User;
  const chatMessageWithoutReferences = message?.text?.replace(/<sup>.*?<\/sup>/g, '');
  const hasCode = messageHasCode(message?.text);
  const hasPrompt =
    message?.text?.includes(PROMPT_URL_PREFIX_HTTPS) ||
    message?.text?.includes(PROMPT_URL_PREFIX_HTTP);
  const codeLang = hasCode ? extractMessageCodeLanguage(message?.text) : '';
  const shouldHaveCopyButton = isAssistantMessage && !hasCode && !hasPrompt;
  const shouldHaveAddPromptButton = isUserMessage;
  const chatImageUrls = useAppSelector((state) => state.chat.chatImageUrls) ?? {};
  const { supportEmail } = useContext(ClientThemeContext);
  const { flags } = useContext(FeatureFlagContext);

  const ChatMessageMarkdownImage: MarkdownImageType = ({ src }) => {
    if (isInternalUniqueUrl(src?.toString())) {
      // extract the content id from src
      const contentId = src?.toString().replace(INTERNAL_URL_PREFIX, '');
      if (chatImageUrls[contentId]) {
        // eslint-disable-next-line @next/next/no-img-element
        return <img src={chatImageUrls[contentId]} className="my-8 max-h-[400px] max-w-[850px]" />;
      } else {
        return null;
      }
    }
    // Ignore external images in the chat
    return null;
  };

  const ChatMessageMarkdownLink: MarkdownLinkType = ({ children, href, target }) => {
    // Check if link contains a prompt and if it does, render a button instead of a link
    // This is to allow the user to select the prompt
    // Also, we show the file name including file type as the button label (e.g. "file.pdf")
    // based on this, we can extract the file type icon (e.g. PDF icon)

    // only allow prompt buttons that include a string as text
    // children can also include ReactNode elements, so we need to check if it's a string
    let promptCta: string;
    if (Array.isArray(children) && typeof children[0] === 'string') {
      promptCta = children[0]?.toString();
    }
    if (typeof children === 'string') {
      promptCta = children?.toString();
    }

    if (
      linkContainsPrompt(href) &&
      handleSelectPrompt &&
      typeof handleSelectPrompt === 'function' &&
      promptCta
    ) {
      return (
        <ChatPromptButton
          handleSelectPrompt={handleSelectPrompt}
          href={href}
          promptCta={promptCta}
        />
      );
    }

    if (href === 'https://expired') {
      return <ChatPromptButton isExpired promptCta={promptCta} />;
    }
    return (
      <DefaultMarkdownLink href={href} target={target}>
        {children}
      </DefaultMarkdownLink>
    );
  };

  const formatMessage = () => {
    if (isUserMessage) {
      // Escape HTML outside code blocks for user message
      return escapeHtmlOutsideCodeBlocks(message.text);
    }
    return formatSupportEmail(message.text, supportEmail);
  };

  const hasAssessments = Array.isArray(message?.assessment) && message.assessment.length > 0;

  return (
    <div className="mx-auto flex w-full max-w-[928px] items-start">
      <SenderProfile isUserMessage={isUserMessage} />
      <div className="w-[calc(100%-48px)] flex-1 rounded-lg pl-2">
        <div
          className={cn({
            'body-1 group relative rounded-[8px] pb-6 pt-2.5 transition': true,
            'hover:bg-background': shouldHaveCopyButton || shouldHaveAddPromptButton,
          })}
        >
          <MarkdownPreview
            text={formatMessage()}
            handleSelectPrompt={handleSelectPrompt}
            copyCode={copyCode}
            copiedCode={copiedCode}
            codeLang={codeLang}
            customComponents={{
              a: ChatMessageMarkdownLink,
              img: ChatMessageMarkdownImage,
            }}
            wrapCode
            enableLatex={flags.FEATURE_FLAG_ENABLE_LATEX_UN_8603}
          />
          {shouldHaveCopyButton && (
            <CopyToClipboard
              text={chatMessageWithoutReferences || ''}
              copiedText={copiedText}
              copy={copy}
            />
          )}
          {shouldHaveAddPromptButton && (
            <SavePromptControl
              prompt={chatMessageWithoutReferences || ''}
              onSavePromptClick={onSavePromptClick}
            />
          )}
        </div>

        {!!message.references?.length && (
          <div className="text-2xs -mx-3 mt-3 flex flex-col flex-wrap items-start px-3 pt-2">
            <div className="text-2xs -mx-3 flex flex-wrap items-start px-3">
              <ChatReferences
                references={message.references}
                showPdfHighlighting={showPdfHighlighting}
                redirectInternalStorageOnly={redirectInternalStorageOnly}
              />
            </div>
          </div>
        )}

        <div className="text-2xs -mx-3 mt-3 flex flex-wrap px-3 pt-2">
          {isUserMessage && (
            <div className="body-2 text-on-background-dimmed ml-auto mt-2 tracking-widest">
              {duration(message.createdAt)} ago
            </div>
          )}
          {isAssistantMessage && (
            <>
              {flags.FEATURE_FLAG_CHAT_ASSESSMENT_UN_9234 && hasAssessments ? (
                <MessageAssessment
                  message={message}
                  additionalComponents={
                    <FeedbackButtons
                      id={message.id}
                      feedback={message.feedback}
                      onThumbsClick={onThumbsClick}
                    />
                  }
                />
              ) : (
                <div className="ml-auto">
                  <FeedbackButtons
                    id={message.id}
                    feedback={message.feedback}
                    onThumbsClick={onThumbsClick}
                  />
                </div>
              )}
            </>
          )}
        </div>
        {allowDebugRead && <ChatMessageDebugFooter message={message} />}
      </div>
    </div>
  );
};
export default memo(MessageItem, messagePropsDidNotChange);

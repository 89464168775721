import { FC } from 'react';
import { IconProps } from '.';

export const IconWarningStop: FC<IconProps> = ({ width = '32px', height = '32px', className = '' }) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path id="Vector" d="M16 24.8889C16.5037 24.8889 16.9259 24.7185 17.2667 24.3778C17.6074 24.037 17.7778 23.6148 17.7778 23.1111C17.7778 22.6074 17.6074 22.1852 17.2667 21.8444C16.9259 21.5037 16.5037 21.3333 16 21.3333C15.4963 21.3333 15.0741 21.5037 14.7333 21.8444C14.3926 22.1852 14.2222 22.6074 14.2222 23.1111C14.2222 23.6148 14.3926 24.037 14.7333 24.3778C15.0741 24.7185 15.4963 24.8889 16 24.8889ZM16 17.7778C16.5037 17.7778 16.9259 17.6074 17.2667 17.2667C17.6074 16.9259 17.7778 16.5037 17.7778 16V8.88889C17.7778 8.38519 17.6074 7.96296 17.2667 7.62222C16.9259 7.28148 16.5037 7.11111 16 7.11111C15.4963 7.11111 15.0741 7.28148 14.7333 7.62222C14.3926 7.96296 14.2222 8.38519 14.2222 8.88889V16C14.2222 16.5037 14.3926 16.9259 14.7333 17.2667C15.0741 17.6074 15.4963 17.7778 16 17.7778ZM10.8 32C10.3259 32 9.87407 31.9111 9.44444 31.7333C9.01482 31.5556 8.63704 31.3037 8.31111 30.9778L1.02222 23.6889C0.696296 23.363 0.444444 22.9852 0.266667 22.5556C0.0888889 22.1259 0 21.6741 0 21.2V10.8C0 10.3259 0.0888889 9.87407 0.266667 9.44444C0.444444 9.01482 0.696296 8.63704 1.02222 8.31111L8.31111 1.02222C8.63704 0.696296 9.01482 0.444444 9.44444 0.266667C9.87407 0.0888889 10.3259 0 10.8 0H21.2C21.6741 0 22.1259 0.0888889 22.5556 0.266667C22.9852 0.444444 23.363 0.696296 23.6889 1.02222L30.9778 8.31111C31.3037 8.63704 31.5556 9.01482 31.7333 9.44444C31.9111 9.87407 32 10.3259 32 10.8V21.2C32 21.6741 31.9111 22.1259 31.7333 22.5556C31.5556 22.9852 31.3037 23.363 30.9778 23.6889L23.6889 30.9778C23.363 31.3037 22.9852 31.5556 22.5556 31.7333C22.1259 31.9111 21.6741 32 21.2 32H10.8Z" fill="currentColor"/>
    </svg>

  );
};

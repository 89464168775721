import cn from 'classnames';
import { Link } from 'react-router-dom';
import { FC, PropsWithChildren, ReactNode, MouseEvent } from 'react';

export interface SecondLevelNavItemProps {
  href?: string;
  name?: string;
  icon?: ReactNode;
  isActive?: boolean;
  className?: string;
  labelClassName?: string;
  readOnly?: boolean;
  shouldOpenInNewTab?: boolean;
  onClick?: (event?: MouseEvent) => void;
}

export const SecondLevelNavItem: FC<PropsWithChildren<SecondLevelNavItemProps>> = ({
  href,
  name,
  icon,
  isActive,
  readOnly,
  children,
  className = '',
  labelClassName = '',
  shouldOpenInNewTab = false,
  onClick,
}) => {
  const linkClasses = cn({
    'group body-2 relative flex min-h-[42px] items-center px-4 transition cursor-pointer text-on-secondary hover:bg-secondary-variant':
      true,
    '!bg-secondary-variant': isActive,
    [className]: true,
    'hover:pr-2': !!children,
    'cursor-default pointer-events-none italic': readOnly,
  });

  const SecondLevelNavItemContent = () => (
    <>
      {isActive && (
        <div className="bg-primary-cta absolute left-0 top-1 z-0 h-5/6 w-1 rounded-r"></div>
      )}
      {icon && (
        <span
          className={cn({
            'pointer-events-none mr-3 opacity-80 transition group-hover:!opacity-100': true,
            '!opacity-100': isActive,
          })}
        >
          {icon}
        </span>
      )}
      {name && (
        <span
          className={cn({
            'body-2 pointer-events-none truncate opacity-80 group-hover:!opacity-100': true,
            '!opacity-100': isActive,
            [labelClassName]: true,
          })}
        >
          {name}
        </span>
      )}
      {children}
    </>
  );

  if (onClick) {
    return (
      <div className={linkClasses} data-navigation-id={name?.toLowerCase()} onClick={onClick}>
        <SecondLevelNavItemContent />
      </div>
    );
  }

  return (
    <Link
      to={href || '#'}
      className={linkClasses}
      data-navigation-id={name?.toLowerCase()}
      {...(shouldOpenInNewTab && { target: '_blank' })}
    >
      <SecondLevelNavItemContent />
    </Link>
  );
};

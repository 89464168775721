function formatLabel(language: string): string {
  const [lang] = language.split('-');
  return lang.toUpperCase();
}

interface LanguageSelectorProps {
  /**
   * An array of language codes in ISO 3166-1 Alpha-2 standard or ISO 639-1 standard.
   */
  languages: string[];
  selectedLanguage: string;
  onChange: (lang: string) => void;
}

export function LanguageSelector({ selectedLanguage, languages, onChange }: LanguageSelectorProps) {
  return (
    <div className="w-full max-w-xs">
      <select
        value={selectedLanguage}
        onChange={(event) => onChange(event.target.value)}
        className="bg-background text-on-background-main w-full min-w-[60px] cursor-pointer rounded-md border-none px-2 py-1 text-sm focus:outline-none"
      >
        {languages?.map((lang) => (
          <option key={lang} value={lang}>
            {formatLabel(lang)}
          </option>
        ))}
      </select>
    </div>
  );
}

import { FC } from 'react';
import { IconProps } from '.';

export const IconFlag: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="flag_18dp_5F6368 1" clipPath="url(#clip0_22777_872)">
        <path
          id="Vector"
          d="M9.9199 3.19961L9.7279 2.23961C9.6559 1.87161 9.3279 1.59961 8.9439 1.59961H3.1999C2.7599 1.59961 2.3999 1.95961 2.3999 2.39961V14.3996C2.3999 14.8396 2.7599 15.1996 3.1999 15.1996C3.6399 15.1996 3.9999 14.8396 3.9999 14.3996V9.59961H8.4799L8.6719 10.5596C8.7439 10.9356 9.0719 11.1996 9.4559 11.1996H13.5999C14.0399 11.1996 14.3999 10.8396 14.3999 10.3996V3.99961C14.3999 3.55961 14.0399 3.19961 13.5999 3.19961H9.9199Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_22777_872">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

'use client';

import {
  ButtonIcon,
  ButtonSize,
  ButtonVariant,
  PopupMenu,
  Spinner,
  SpinnerTheme,
} from '@unique/component-library';
import { IconSelect, IconMeatballsMenu, IconDelete, IconEdit } from '@unique/icons';
import { FC } from 'react';

type ChatMenuHeaderProps = {
  title?: string;
  isLoading?: boolean;
  disableDeleteAll?: boolean;
  activateSelectMode: () => void;
  onSelectAll: () => void;
  onDeleteAll: () => void;
};

export const ChatMenuHeader: FC<ChatMenuHeaderProps> = ({ 
  title = 'Chats', 
  isLoading = false,
  activateSelectMode,
  onSelectAll,
  onDeleteAll,
  disableDeleteAll,
}) => {
  
  return (
    <div className="subtitle-1 flex items-center justify-between pb-4 pl-4 pr-2 pt-4">
      <div className="flex">
        {title}
        {isLoading && (
          <Spinner
            theme={SpinnerTheme.LIGHT}
            wrapperClasses="ml-4 flex items-center"
            size={16}
          />
        )}
      </div>
      {!isLoading && <>
        <div className="flex sm:hidden">
          <ButtonIcon
            icon={<IconEdit width="14px" height="14px" />}
            variant={ButtonVariant.SHAPE}
            buttonSize={ButtonSize.SMALL}
            onClick={activateSelectMode}
          ></ButtonIcon>
        </div>
        <div className="hidden sm:flex">
          <PopupMenu
            buttonVariant={ButtonVariant.SHAPE}
            buttonIcon={<IconMeatballsMenu />}
            menuItems={[
              {
                label: 'Select all',
                icon: <IconSelect width="14px" height="14px" />,
                onClick: () => {
                  onSelectAll();
                },
              },
              {
                label: 'Delete all',
                disabled: disableDeleteAll,
                icon: <IconDelete width="14px" height="14px" />,
                onClick: () => {
                  onDeleteAll();
                },
              },
            ]}
          />
        </div>
      </>}
    </div>
  );
};

import { ReactNode } from 'react';
import { Tooltip } from '@unique/component-library';

type Props = { children: ReactNode; message: string; showTooltip: boolean };

export default function ButtonTooltipWrapper({ children, message, showTooltip }: Props) {
  if (!showTooltip) {
    return children;
  }

  return (
    <Tooltip wrapper={children} arrowPosition="none">
      {message}
    </Tooltip>
  );
}

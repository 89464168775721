/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AddDueDilgenceMetaDataDocument,
  AddDueDilgenceMetaDataMutation,
  AddDueDilgenceMetaDataMutationVariables,
  AnalyticsOrderDeleteDocument,
  AnalyticsOrderDeleteMutation,
  AnalyticsOrderDeleteMutationVariables,
  AnalyticsOrderStartDocument,
  AnalyticsOrderStartMutation,
  AnalyticsOrderStartMutationVariables,
  AnalyticsOrdersDocument,
  AnalyticsOrdersQuery,
  AnalyticsOrdersQueryVariables,
  AssistantDocument,
  AssistantQuery,
  AssistantQueryVariables,
  AssistantsDocument,
  AssistantsQuery,
  AssistantsQueryVariables,
  AssistantsWithUserPromptsDocument,
  AssistantsWithUserPromptsQuery,
  AssistantsWithUserPromptsQueryVariables,
  ChatBenchmarkingAbortDocument,
  ChatBenchmarkingAbortMutation,
  ChatBenchmarkingAbortMutationVariables,
  ChatBenchmarkingStatusDocument,
  ChatBenchmarkingStatusQuery,
  ChatBenchmarkingStatusQueryVariables,
  ChatCreateDocument,
  ChatCreateMutation,
  ChatCreateMutationVariables,
  ChatDeleteByChatIdsDocument,
  ChatDeleteByChatIdsMutation,
  ChatDeleteByChatIdsMutationVariables,
  ChatDeleteDocument,
  ChatDeleteMutation,
  ChatDeleteMutationVariables,
  ChatDocument,
  ChatQuery,
  ChatQueryVariables,
  ChatSubscriptionDocument,
  ChatSubscriptionSubscription,
  ChatSubscriptionSubscriptionVariables,
  ChatUpdateTitleDocument,
  ChatUpdateTitleMutation,
  ChatUpdateTitleMutationVariables,
  ChatsDocument,
  ChatsQuery,
  ChatsQueryVariables,
  ContentByChatDocument,
  ContentByChatQuery,
  ContentByChatQueryVariables,
  ContentByIdDocument,
  ContentByIdQuery,
  ContentByIdQueryVariables,
  ContentUpsertByChatDocument,
  ContentUpsertByChatMutation,
  ContentUpsertByChatMutationVariables,
  CreateDueDiligenceDocument,
  CreateDueDiligenceMutation,
  CreateDueDiligenceMutationVariables,
  DeleteDueDiligenceDocument,
  DeleteDueDiligenceMutation,
  DeleteDueDiligenceMutationVariables,
  DueDiligenceDocument,
  DueDiligenceQuery,
  DueDiligenceQueryVariables,
  GenerateMagicTableArtifactDocument,
  GenerateMagicTableArtifactMutation,
  GenerateMagicTableArtifactMutationVariables,
  GetMagicTableCellLogEntryDocument,
  GetMagicTableCellLogEntryQuery,
  GetMagicTableCellLogEntryQueryVariables,
  GetTransformedMagicTableDocument,
  GetTransformedMagicTableQuery,
  GetTransformedMagicTableQueryVariables,
  MagicTableArtifactUpdateDocument,
  MagicTableArtifactUpdateSubscription,
  MagicTableArtifactUpdateSubscriptionVariables,
  MagicTableCellUpdateDocument,
  MagicTableCellUpdateSubscription,
  MagicTableCellUpdateSubscriptionVariables,
  MagicTableSheetUpdateDocument,
  MagicTableSheetUpdateSubscription,
  MagicTableSheetUpdateSubscriptionVariables,
  MessageCreateDocument,
  MessageCreateMutation,
  MessageCreateMutationVariables,
  MessageCreateSyncDocument,
  MessageCreateSyncMutation,
  MessageCreateSyncMutationVariables,
  MessageSubscriptionDocument,
  MessageSubscriptionSubscription,
  MessageSubscriptionSubscriptionVariables,
  MessageUpdateDocument,
  MessageUpdateMutation,
  MessageUpdateMutationVariables,
  MessagesDocument,
  MessagesQuery,
  MessagesQueryVariables,
  PaginatedContentDocument,
  PaginatedContentQuery,
  PaginatedContentQueryVariables,
  PersistMagicTableColumnMetaDataDocument,
  PersistMagicTableColumnMetaDataMutation,
  PersistMagicTableColumnMetaDataMutationVariables,
  TranslationOneToOneForMessageDocument,
  TranslationOneToOneForMessageQuery,
  TranslationOneToOneForMessageQueryVariables,
  UndoChatDeleteDocument,
  UndoChatDeleteMutation,
  UndoChatDeleteMutationVariables,
  UpdateMagicTableCellDocument,
  UpdateMagicTableCellMutation,
  UpdateMagicTableCellMutationVariables,
  UpdateMagicTableSheetDocument,
  UpdateMagicTableSheetMutation,
  UpdateMagicTableSheetMutationVariables,
  UserPromptCreateDocument,
  UserPromptCreateManyDocument,
  UserPromptCreateManyMutation,
  UserPromptCreateManyMutationVariables,
  UserPromptCreateMutation,
  UserPromptCreateMutationVariables,
  UserPromptDeleteAllUserOwnedPromptsDocument,
  UserPromptDeleteAllUserOwnedPromptsMutation,
  UserPromptDeleteAllUserOwnedPromptsMutationVariables,
} from '@/@generated/graphql';
import { CHAT_TITLE_MAX_LENGTH } from '@/lib/constants/chatTitleMaxLength';
import {
  GraphqlFetcherArgs,
  Service,
  useClientSWR,
  useClientSWRMutation,
  useWebsocketSubscription,
} from '@unique/next-commons/swr';
import { Client, Sink } from 'graphql-ws';
import { SWRConfiguration } from 'swr';
import { SWRMutationConfiguration } from 'swr/mutation';

const truncateChatTitle = (title: string) => {
  if (typeof title !== 'string') return '';
  return title.length > CHAT_TITLE_MAX_LENGTH
    ? `${title.slice(0, CHAT_TITLE_MAX_LENGTH - 3)}...`
    : title;
};

export function createManyUserPromptsMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      UserPromptCreateManyMutation,
      any,
      GraphqlFetcherArgs<any>,
      UserPromptCreateManyMutationVariables
    >
  >,
) {
  return useClientSWRMutation<UserPromptCreateManyMutation, UserPromptCreateManyMutationVariables>(
    key,
    UserPromptCreateManyDocument,
    options,
    Service.NODE_CHAT,
  );
}

export function deleteAllUserOwnedPromptsMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      UserPromptDeleteAllUserOwnedPromptsMutation,
      any,
      GraphqlFetcherArgs<any>,
      UserPromptDeleteAllUserOwnedPromptsMutationVariables
    >
  >,
) {
  return useClientSWRMutation<
    UserPromptDeleteAllUserOwnedPromptsMutation,
    UserPromptDeleteAllUserOwnedPromptsMutationVariables
  >(key, UserPromptDeleteAllUserOwnedPromptsDocument, options, Service.NODE_CHAT);
}

export function useChatQuery(
  variables?: ChatQueryVariables,
  options?: Partial<SWRConfiguration<ChatQuery>>,
) {
  const resp = useClientSWR<ChatQuery, ChatQueryVariables>(
    variables || variables === undefined ? getUseChatQueryKey(variables) : null,
    options,
  );
  let newData = resp?.data;
  if (newData?.chat?.title) {
    newData = {
      ...newData,
      chat: {
        ...newData.chat,
        title: truncateChatTitle(newData.chat.title),
      },
    };
  }
  return { ...resp, data: newData };
}

export function useChatsQuery(
  variables?: ChatsQueryVariables,
  options?: Partial<SWRConfiguration<ChatsQuery>>,
) {
  const resp = useClientSWR<ChatsQuery, ChatsQueryVariables>(
    getUseChatsQueryKey(variables),
    options,
  );
  return resp;
}

export function useMessagesQuery(
  variables?: MessagesQueryVariables,
  options?: Partial<SWRConfiguration<MessagesQuery>>,
) {
  return useClientSWR<MessagesQuery, MessagesQueryVariables>(
    // If variables exist OR are not passed to the function, execute query. Otherwise (e.g. is null), don't execute.
    variables || variables === undefined ? getUseMessagesQueryKey(variables) : null,
    options,
  );
}

export function useAssistantsQuery(
  variables?: AssistantsQueryVariables,
  options?: Partial<SWRConfiguration<AssistantsQuery>>,
) {
  return useClientSWR<AssistantsQuery, AssistantsQueryVariables>(
    getUseAssistantsQueryKey(variables),
    options,
  );
}

export function useAssistantsWithUserPromptsQuery(
  variables?: AssistantsWithUserPromptsQueryVariables,
) {
  return useClientSWR<AssistantsWithUserPromptsQuery, AssistantsWithUserPromptsQueryVariables>(
    getUseAssistantsWithUserPromptsQueryKey(variables),
  );
}

export function useAssistantQuery(
  variables?: AssistantQueryVariables,
  options?: Partial<SWRConfiguration<AssistantQuery>>,
) {
  return useClientSWR<AssistantQuery, AssistantQueryVariables>(
    // If variables exist OR are not passed to the function, execute query. Otherwise (e.g. is null), don't execute.
    variables || variables === undefined ? getUseAssistantQueryKey(variables) : null,
    options,
  );
}

export function useContentByIdQuery(
  variables?: ContentByIdQueryVariables,
  options?: Partial<SWRConfiguration<ContentByIdQuery>>,
) {
  return useClientSWR<ContentByIdQuery, ContentByIdQueryVariables>(
    // If variables exist OR are not passed to the function, execute query. Otherwise (e.g. is null), don't execute.
    variables || variables === undefined ? getUseContentByIdQueryKey(variables) : null,
    options,
    Service.NODE_INGESTION,
  );
}

export function useContentByChatQuery(
  variables?: ContentByChatQueryVariables,
  options?: Partial<SWRConfiguration<ContentByChatQuery>>,
) {
  return useClientSWR<ContentByChatQuery, ContentByChatQueryVariables>(
    // If variables exist OR are not passed to the function, execute query. Otherwise (e.g. is null), don't execute.
    variables || variables === undefined ? getUseContentByChatQueryKey(variables) : null,
    options,
    Service.NODE_INGESTION,
  );
}

export function useChatBenchmarkingStatusQuery(
  options?: Partial<SWRConfiguration<ChatBenchmarkingStatusQuery>>,
) {
  return useClientSWR<ChatBenchmarkingStatusQuery, ChatBenchmarkingStatusQueryVariables>(
    getUseChatBenchmarkingStatusQueryKey(),
    options,
  );
}

export function useTranslationOneToOneForMessageQuery(
  variables?: TranslationOneToOneForMessageQueryVariables,
  options?: Partial<SWRConfiguration<TranslationOneToOneForMessageQuery>>,
) {
  return useClientSWR<
    TranslationOneToOneForMessageQuery,
    TranslationOneToOneForMessageQueryVariables
  >(
    variables || variables === undefined
      ? getUseTranslationOneToOneForMessageQueryKey(variables)
      : null,
    options,
  );
}

export function useChatBenchmarkingAbortMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      ChatBenchmarkingAbortMutation,
      any,
      GraphqlFetcherArgs<any>,
      ChatBenchmarkingAbortMutationVariables
    >
  >,
) {
  return useClientSWRMutation<
    ChatBenchmarkingAbortMutation,
    ChatBenchmarkingAbortMutationVariables
  >(key, ChatBenchmarkingAbortDocument, options);
}

export function useChatCreateMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      ChatCreateMutation,
      any,
      GraphqlFetcherArgs<any>,
      ChatCreateMutationVariables
    >
  >,
) {
  return useClientSWRMutation<ChatCreateMutation, ChatCreateMutationVariables>(
    key,
    ChatCreateDocument,
    options,
  );
}

export function useChatDeleteMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      ChatDeleteMutation,
      any,
      GraphqlFetcherArgs<any>,
      ChatDeleteMutationVariables
    >
  >,
) {
  return useClientSWRMutation<ChatDeleteMutation, ChatDeleteMutationVariables>(
    key,
    ChatDeleteDocument,
    options,
  );
}

export function useChatDeleteManyMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      ChatDeleteByChatIdsMutation,
      any,
      GraphqlFetcherArgs<any>,
      ChatDeleteByChatIdsMutationVariables
    >
  >,
) {
  return useClientSWRMutation<ChatDeleteByChatIdsMutation, ChatDeleteByChatIdsMutationVariables>(
    key,
    ChatDeleteByChatIdsDocument,
    options,
  );
}

export function useChatUpdateTitleMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      ChatUpdateTitleMutation,
      any,
      GraphqlFetcherArgs<any>,
      ChatUpdateTitleMutationVariables
    >
  >,
) {
  return useClientSWRMutation<ChatUpdateTitleMutation, ChatUpdateTitleMutationVariables>(
    key,
    ChatUpdateTitleDocument,
    options,
  );
}

export function useUndoChatDeleteMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      UndoChatDeleteMutation,
      any,
      GraphqlFetcherArgs<any>,
      UndoChatDeleteMutationVariables
    >
  >,
) {
  return useClientSWRMutation<UndoChatDeleteMutation, UndoChatDeleteMutationVariables>(
    key,
    UndoChatDeleteDocument,
    options,
  );
}

export function useChatUpdateSubscription(
  sink: Sink<ChatSubscriptionSubscription>,
  variables?: ChatSubscriptionSubscriptionVariables,
) {
  return useWebsocketSubscription<
    ChatSubscriptionSubscription,
    ChatSubscriptionSubscriptionVariables
  >(ChatSubscriptionDocument, sink, variables);
}

export function useMessagesUpdateSubscription(
  sink: Sink<MessageSubscriptionSubscription>,
  variables?: MessageSubscriptionSubscriptionVariables,
): Client {
  return useWebsocketSubscription<
    MessageSubscriptionSubscription,
    MessageSubscriptionSubscriptionVariables
  >(MessageSubscriptionDocument, sink, variables);
}

export function useMessageCreateMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      MessageCreateMutation,
      any,
      GraphqlFetcherArgs<any>,
      MessageCreateMutationVariables
    >
  >,
) {
  return useClientSWRMutation<MessageCreateMutation, MessageCreateMutationVariables>(
    key,
    MessageCreateDocument,
    options,
  );
}

export function useMessageUpdateMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      MessageUpdateMutation,
      any,
      GraphqlFetcherArgs<any>,
      MessageUpdateMutationVariables
    >
  >,
) {
  return useClientSWRMutation<MessageUpdateMutation, MessageUpdateMutationVariables>(
    key,
    MessageUpdateDocument,
    options,
  );
}

export function useContentUpsertByChatMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      ContentUpsertByChatMutation,
      any,
      GraphqlFetcherArgs<any>,
      ContentUpsertByChatMutationVariables
    >
  >,
) {
  return useClientSWRMutation<ContentUpsertByChatMutation, ContentUpsertByChatMutationVariables>(
    key,
    ContentUpsertByChatDocument,
    options,
    Service.NODE_INGESTION,
  );
}

export function useUserPromptCreateMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      UserPromptCreateMutation,
      any,
      GraphqlFetcherArgs<any>,
      UserPromptCreateMutationVariables
    >
  >,
) {
  return useClientSWRMutation<UserPromptCreateMutation, UserPromptCreateMutationVariables>(
    key,
    UserPromptCreateDocument,
    options,
  );
}

export function getUseChatQueryKey(
  variables?: ChatQueryVariables,
): GraphqlFetcherArgs<ChatQueryVariables> {
  return { query: ChatDocument, variables };
}

export function getUseChatsQueryKey(
  variables?: ChatsQueryVariables,
): GraphqlFetcherArgs<ChatsQueryVariables> {
  return { query: ChatsDocument, variables };
}

export function getUseMessagesQueryKey(
  variables?: MessagesQueryVariables,
): GraphqlFetcherArgs<MessagesQueryVariables> {
  return { query: MessagesDocument, variables };
}

export function getUseAssistantsQueryKey(
  variables?: AssistantsQueryVariables,
): GraphqlFetcherArgs<AssistantsQueryVariables> {
  return { query: AssistantsDocument, variables };
}

export function getUseAssistantsWithUserPromptsQueryKey(
  variables?: AssistantsWithUserPromptsQueryVariables,
): GraphqlFetcherArgs<AssistantsWithUserPromptsQueryVariables> {
  return { query: AssistantsWithUserPromptsDocument, variables };
}

export function getUseAssistantQueryKey(
  variables?: AssistantQueryVariables,
): GraphqlFetcherArgs<AssistantQueryVariables> {
  return { query: AssistantDocument, variables };
}

export function getUseContentByIdQueryKey(
  variables?: ContentByIdQueryVariables,
): GraphqlFetcherArgs<ContentByIdQueryVariables> {
  return { query: ContentByIdDocument, variables };
}

export function getUseContentByChatQueryKey(
  variables?: ContentByChatQueryVariables,
): GraphqlFetcherArgs<ContentByChatQueryVariables> {
  return { query: ContentByChatDocument, variables };
}

export function getUseChatBenchmarkingStatusQueryKey(
  variables?: ChatBenchmarkingStatusQueryVariables,
): GraphqlFetcherArgs<ChatBenchmarkingStatusQueryVariables> {
  return { query: ChatBenchmarkingStatusDocument, variables };
}

export function getUseTranslationOneToOneForMessageQueryKey(
  variables?: TranslationOneToOneForMessageQueryVariables,
): GraphqlFetcherArgs<TranslationOneToOneForMessageQueryVariables> {
  return { query: TranslationOneToOneForMessageDocument, variables };
}

export function useAnalyticsOrdersQuery(
  variables?: AnalyticsOrdersQueryVariables,
  options?: Partial<SWRConfiguration<AnalyticsOrdersQuery>>,
) {
  return useClientSWR<AnalyticsOrdersQuery, AnalyticsOrdersQueryVariables>(
    getUseAnalyticsOrdersQueryKey(variables),
    options,
  );
}

export function getUseAnalyticsOrdersQueryKey(
  variables?: AnalyticsOrdersQueryVariables,
): GraphqlFetcherArgs<AnalyticsOrdersQueryVariables> {
  return { query: AnalyticsOrdersDocument, variables };
}

export function getUseChatBenchmarkingAbortQueryKey(
  variables?: ChatBenchmarkingAbortMutationVariables,
): GraphqlFetcherArgs<ChatBenchmarkingAbortMutationVariables> {
  return { query: ChatBenchmarkingAbortDocument, variables };
}

export function useAnalyticsOrderStartMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      AnalyticsOrderStartMutation,
      any,
      GraphqlFetcherArgs<any>,
      AnalyticsOrderStartMutationVariables
    >
  >,
) {
  return useClientSWRMutation<AnalyticsOrderStartMutation, AnalyticsOrderStartMutationVariables>(
    key,
    AnalyticsOrderStartDocument,
    options,
  );
}

export function useAnalyticsOrderDeleteMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      AnalyticsOrderDeleteMutation,
      any,
      GraphqlFetcherArgs<any>,
      AnalyticsOrderDeleteMutationVariables
    >
  >,
) {
  return useClientSWRMutation<AnalyticsOrderDeleteMutation, AnalyticsOrderDeleteMutationVariables>(
    key,
    AnalyticsOrderDeleteDocument,
    options,
  );
}

export function useMessageCreateSyncMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      MessageCreateSyncMutation,
      any,
      GraphqlFetcherArgs<any>,
      MessageCreateSyncMutationVariables
    >
  >,
) {
  return useClientSWRMutation<MessageCreateSyncMutation, MessageCreateSyncMutationVariables>(
    key,
    MessageCreateSyncDocument,
    options,
  );
}

export function useMagicTableQuery(
  variables: GetTransformedMagicTableQueryVariables,
  options?: Partial<SWRConfiguration<GetTransformedMagicTableQuery>>,
) {
  const response = useClientSWR<
    GetTransformedMagicTableQuery,
    GetTransformedMagicTableQueryVariables
  >(variables.cursor.id ? { query: GetTransformedMagicTableDocument, variables } : null, options);
  return response;
}

export function useDueDiligenceQuery(
  variables?: DueDiligenceQueryVariables,
  options?: Partial<SWRConfiguration<DueDiligenceQuery>>,
) {
  const response = useClientSWR<DueDiligenceQuery, DueDiligenceQueryVariables>(
    { query: DueDiligenceDocument, variables },
    options,
  );
  return response;
}

export function useUpdateMagicTableCellMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      UpdateMagicTableCellMutation,
      any,
      GraphqlFetcherArgs<any>,
      UpdateMagicTableCellMutationVariables
    >
  >,
) {
  return useClientSWRMutation<UpdateMagicTableCellMutation, UpdateMagicTableCellMutationVariables>(
    key,
    UpdateMagicTableCellDocument,
    options,
  );
}

export function getUseMagicTableCellKey(
  variables?: UpdateMagicTableCellMutationVariables,
): GraphqlFetcherArgs<UpdateMagicTableCellMutationVariables> {
  return { query: UpdateMagicTableCellDocument, variables };
}

export function usePaginatedContentQuery(
  variables?: PaginatedContentQueryVariables,
  options?: Partial<SWRConfiguration<PaginatedContentQuery>>,
) {
  return useClientSWR<PaginatedContentQuery, PaginatedContentQueryVariables>(
    variables || variables === undefined ? getUsePaginatedContentQueryKey(variables) : null,
    options,
    Service.NODE_INGESTION,
  );
}

export function getUsePaginatedContentQueryKey(
  variables?: PaginatedContentQueryVariables,
): GraphqlFetcherArgs<PaginatedContentQueryVariables> {
  return { query: PaginatedContentDocument, variables };
}

export function useMagicTableSheetUpdateSubscription(
  sink: Sink<MagicTableSheetUpdateSubscription>,
  variables?: MagicTableSheetUpdateSubscriptionVariables,
) {
  return useWebsocketSubscription<
    MagicTableSheetUpdateSubscription,
    MagicTableSheetUpdateSubscriptionVariables
  >(MagicTableSheetUpdateDocument, sink, variables);
}

export function useMagicTableCellUpdateSubscription(
  sink: Sink<MagicTableCellUpdateSubscription>,
  variables?: MagicTableCellUpdateSubscriptionVariables,
) {
  return useWebsocketSubscription<
    MagicTableCellUpdateSubscription,
    MagicTableCellUpdateSubscriptionVariables
  >(MagicTableCellUpdateDocument, sink, variables);
}

export function useMagicTableCellLogEntry(
  variables?: GetMagicTableCellLogEntryQueryVariables,
  options?: Partial<SWRConfiguration<GetMagicTableCellLogEntryQuery>>,
) {
  const response = useClientSWR<
    GetMagicTableCellLogEntryQuery,
    GetMagicTableCellLogEntryQueryVariables
  >(
    !variables.columnOrder || !variables.rowOrder
      ? null
      : { query: GetMagicTableCellLogEntryDocument, variables },
    options,
  );
  return response;
}

export function useCreateDueDiligence(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      CreateDueDiligenceMutation,
      any,
      GraphqlFetcherArgs<any>,
      CreateDueDiligenceMutationVariables
    >
  >,
) {
  return useClientSWRMutation<CreateDueDiligenceMutation, CreateDueDiligenceMutationVariables>(
    key,
    CreateDueDiligenceDocument,
    options,
  );
}

export function getUseCreateDueDiligenceKey(
  variables?: CreateDueDiligenceMutationVariables,
): GraphqlFetcherArgs<CreateDueDiligenceMutationVariables> {
  return { query: CreateDueDiligenceDocument, variables };
}

export function useAddDueDiligenceMetaData(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      AddDueDilgenceMetaDataMutation,
      any,
      GraphqlFetcherArgs<any>,
      AddDueDilgenceMetaDataMutationVariables
    >
  >,
) {
  return useClientSWRMutation<
    AddDueDilgenceMetaDataMutation,
    AddDueDilgenceMetaDataMutationVariables
  >(key, AddDueDilgenceMetaDataDocument, options);
}

export function getUseAddDueDiligenceMetaDataKey(
  variables?: AddDueDilgenceMetaDataMutationVariables,
): GraphqlFetcherArgs<AddDueDilgenceMetaDataMutationVariables> {
  return { query: AddDueDilgenceMetaDataDocument, variables };
}

export function useUpdateMagicTableSheetName(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      UpdateMagicTableSheetMutation,
      any,
      GraphqlFetcherArgs<any>,
      UpdateMagicTableSheetMutationVariables
    >
  >,
) {
  return useClientSWRMutation<
    UpdateMagicTableSheetMutation,
    UpdateMagicTableSheetMutationVariables
  >(key, UpdateMagicTableSheetDocument, options);
}

export function getUseUpdateMagicTableSheetName(
  variables?: UpdateMagicTableSheetMutationVariables,
): GraphqlFetcherArgs<UpdateMagicTableSheetMutationVariables> {
  return { query: UpdateMagicTableSheetDocument, variables };
}

export function useDeleteDueDiligence(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      DeleteDueDiligenceMutation,
      any,
      GraphqlFetcherArgs<any>,
      DeleteDueDiligenceMutationVariables
    >
  >,
) {
  return useClientSWRMutation<DeleteDueDiligenceMutation, DeleteDueDiligenceMutationVariables>(
    key,
    DeleteDueDiligenceDocument,
    options,
  );
}

export function getUseDeleteDueDiligence(
  variables?: DeleteDueDiligenceMutationVariables,
): GraphqlFetcherArgs<DeleteDueDiligenceMutationVariables> {
  return { query: DueDiligenceDocument, variables };
}

export function usePersistMagicTableColumnMetadata(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      PersistMagicTableColumnMetaDataMutation,
      any,
      GraphqlFetcherArgs<any>,
      PersistMagicTableColumnMetaDataMutationVariables
    >
  >,
) {
  return useClientSWRMutation<
    PersistMagicTableColumnMetaDataMutation,
    PersistMagicTableColumnMetaDataMutationVariables
  >(key, PersistMagicTableColumnMetaDataDocument, options);
}

export function getUsePersistMagicTableColumnMetadata(
  variables?: PersistMagicTableColumnMetaDataMutationVariables,
): GraphqlFetcherArgs<PersistMagicTableColumnMetaDataMutationVariables> {
  return { query: PersistMagicTableColumnMetaDataDocument, variables };
}

export function useMagicTableArtifactUpdateSubscription(
  sink: Sink<MagicTableArtifactUpdateSubscription>,
  variables?: MagicTableArtifactUpdateSubscriptionVariables,
) {
  return useWebsocketSubscription<
    MagicTableArtifactUpdateSubscription,
    MagicTableArtifactUpdateSubscriptionVariables
  >(MagicTableArtifactUpdateDocument, sink, variables);
}

export function useGenerateMagicTableArtifact(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      GenerateMagicTableArtifactMutation,
      any,
      GraphqlFetcherArgs<any>,
      GenerateMagicTableArtifactMutationVariables
    >
  >,
) {
  return useClientSWRMutation<
    GenerateMagicTableArtifactMutation,
    GenerateMagicTableArtifactMutationVariables
  >(key, GenerateMagicTableArtifactDocument, options);
}

export function getUseGenerateMagicTableArtifact(
  variables?: GenerateMagicTableArtifactMutationVariables,
): GraphqlFetcherArgs<GenerateMagicTableArtifactMutationVariables> {
  return { query: GenerateMagicTableArtifactDocument, variables };
}

import { ButtonIcon, ButtonSize, ButtonVariant } from '@unique/component-library';
import { IconAlert, IconClose, IconPause, IconPlay } from '@unique/icons';
import { useEffect, useMemo, useState } from 'react';
import { useSpeechToText } from './hooks/useSpeechToText';
import { MicrosoftSpeechToTextProvider } from './services/speech-to-text/microsoft.adapter';
import { VoiceVisualizer } from './components/VoiceVisualizer/VoiceVisualizer';
import { LanguageSelector } from './components/LanguageSelector';
import { barHistoryAnimation } from './components/VoiceVisualizer/animations/bar-history-animation';
import { logger } from '@unique/next-commons/logger';
import { useScreens } from '@unique/shared-library';
import { LS_VOICE_INPUT_USER_LANG } from './components/types';

const log = logger.child({
  package: 'chat',
  namespace: 'components:chat:inputs.VoiceInput',
});

interface VoiceInputProps {
  defaultLanguage?: string;
  accessToken: string;
  region: string;
  grammarList?: string[];
  onClose?: () => void;
  onChange?: (transcript: string) => void;
  onChangeInterim?: (interimTranscript: string) => void;
  onStatusChange?: (status: VoiceInputStatus) => void;
}

const enum VoiceInputStatus {
  Initializing = 'initializing',
  Listening = 'listening',
  Stopped = 'stopped',
}

export const VoiceInput = ({
  defaultLanguage,
  accessToken,
  region,
  grammarList,
  onChange,
  onChangeInterim,
  onClose,
}: VoiceInputProps) => {
  const { isMobile } = useScreens();
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedlanguage, setSelectedlanguage] = useState<string | undefined>(defaultLanguage);
  const speechToTextProvider = useMemo(() => {
    return accessToken && region && stream
      ? new MicrosoftSpeechToTextProvider(
          accessToken,
          region,
          selectedlanguage,
          stream,
          grammarList,
        )
      : undefined;
  }, [accessToken, region, selectedlanguage, stream]);

  const {
    startListening,
    availableLanguages,
    stopListening,
    isListening,
    transcript,
    interimTranscript,
    clean,
    error,
  } = useSpeechToText(speechToTextProvider);

  const actionButtonLabel = useMemo(() => {
    if (!isListening && !error) {
      return 'Start';
    }

    if (error && !isListening) {
      return 'Retry';
    }

    return 'Stop';
  }, [isListening, error]);
  const handleStopListening = () => {
    stopListening();
    onClose?.();
  };

  // Trigger auto listening when its ready
  useEffect(() => {
    if (!accessToken || !region || !stream) {
      return () => null;
    }

    startListening();
    return () => stopListening();
  }, [accessToken, region, selectedlanguage, stream]);

  // Trigger callback when transcript is updated
  useEffect(() => {
    if (!transcript) {
      return;
    }
    onChange?.(transcript);
  }, [transcript]);

  // Trigger callback when interim transcript is updated
  useEffect(() => {
    if (!interimTranscript) {
      return;
    }
    onChangeInterim?.(interimTranscript);
  }, [interimTranscript]);

  const handleInitStream = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      if (!mediaStream) {
        throw new Error('No media stream returned');
      }

      setStream(mediaStream);
    } catch (err) {
      log.error('Error getting microphone permissions');
    }
  };

  useEffect(() => {
    // INFO: Initialize shared stream
    handleInitStream();

    return () => {
      clean();
    };
  }, []);

  const handleLanguageChange = (lang: string) => {
    localStorage.setItem(LS_VOICE_INPUT_USER_LANG, lang);
    setSelectedlanguage(lang);
  };

  const handleClose = () => {
    setIsLoading(true);
    clean();
    onClose?.();
  };

  const handleStartStop = () => {
    if (!isListening) {
      startListening();
      return;
    }

    setIsLoading(true);
    handleStopListening();
  };

  return (
    <div className="voice-input-container bg-background text-on-background-main h-full w-full rounded-md p-3">
      <div className="flex h-10 w-full items-center justify-between gap-3">
        <div className="inline">
          <LanguageSelector
            selectedLanguage={selectedlanguage ?? availableLanguages?.[0]}
            languages={availableLanguages}
            onChange={handleLanguageChange}
          />
        </div>

        {!!error && (
          <div className="text-on-background-dimmed w-full">
            <ButtonIcon
              variant={ButtonVariant.DANGER}
              buttonSize={ButtonSize.SMALL}
              icon={<IconAlert />}
            >
              {error}
            </ButtonIcon>
          </div>
        )}
        {!error && stream && (
          <VoiceVisualizer
            animate={barHistoryAnimation({
              barsLimit: isMobile ? 30 : 100,
            })}
            isListening={isListening}
            stream={stream}
          />
        )}

        <ButtonIcon
          variant={ButtonVariant.SECONDARY}
          icon={
            isListening ? <IconPause height="16" width="16" /> : <IconPlay height="16" width="16" />
          }
          isLoading={isLoading}
          buttonSize={ButtonSize.SMALL}
          onClick={handleStartStop}
          aria-label={isListening ? 'Pause listening' : 'Start listening'}
        >
          {actionButtonLabel}
        </ButtonIcon>

        {error && (
          <ButtonIcon
            isLoading={isLoading}
            onClick={handleClose}
            buttonSize={ButtonSize.SMALL}
            variant={ButtonVariant.SECONDARY}
            icon={<IconClose />}
          />
        )}
      </div>
    </div>
  );
};

export const referenceNumberRegex = /\[.*?\]/g;

export const referenceRegex = /\[\d+&msg_[a-z0-9]+\] [^:]+(?: : [\d,]+)?/;

export const addSpanToReferencePlaceholders = (text: string) => {
  const parts = text.split(referenceNumberRegex);
  const matches = text.match(referenceNumberRegex) || [];

  return parts
    .map((part, index) => {
      const match = matches[index] ? `<span>${matches[index].slice(1, -1)}</span>` : '';
      return `${part}${match}`;
    })
    .join('');
};

export const addLinkToReferencePlaceholder = (text: string) => {
  const parts = text.split('\n\n');

  const result = parts
    .map((part, index) => {
      if (!part.match(referenceRegex)) return part;
      return `<sub key=${index}>${part.trim()}</sub> \n\n`;
    })
    .join('');

  return addSpanToReferencePlaceholders(result);
};

import { IconInfo, IconWarningStop, IconMaintenance, IconSuccess } from '@unique/icons';
import { FC } from 'react';
import { ButtonIcon, ButtonVariant } from '@unique/component-library';
import { Maybe, NotificationBannerType } from '../@generated/graphql';

export type NotificationProps = {
  message?: string;
  variant?: NotificationBannerType;
  title?: string;
  link?: Maybe<string> | string;
  linkText?: Maybe<string> | string;
  onDismiss?: () => void;
};

const variantStyle = {
  [NotificationBannerType.Outage]: {
    icon: <IconWarningStop width="24px" height="24px" />,
    borderColor: 'border-error-dark',
    bgColor: 'bg-error-light',
    textColor: 'text-error-dark',
  },
  [NotificationBannerType.Info]: {
    icon: <IconInfo width="24px" height="24px" />,
    borderColor: 'border-info',
    bgColor: 'bg-info',
    textColor: 'text-info',
  },
  [NotificationBannerType.Announcement]: {
    icon: <IconSuccess width="24px" height="24px" />,
    borderColor: 'border-success-dark',
    bgColor: 'bg-success-dark',
    textColor: 'text-success-dark',
  },
  [NotificationBannerType.Maintenance]: {
    icon: <IconMaintenance width="24px" height="24px" />,
    borderColor: 'border-attention',
    bgColor: 'bg-attention',
    textColor: 'text-attention',
  },
}

export const Notification: FC<NotificationProps> = ({
  variant = NotificationBannerType.Info,
  title,
  message,
  link,
  linkText,
  onDismiss,
}) => {

  return (
    <div className="max-w-lg min-w-96">
       {/* Alert container --> */}
      <div 
        className={`border-t-4 ${variantStyle[variant].borderColor} bg-surface text-on-surface rounded-lg shadow-xl`}
        role="alert"
      >
         {/* Heading and icon (optional) --> */}
        <div className="relative flex space-x-4 mb-2 pt-4 pl-1 pr-4">
          <div className={`absolute top-0 bottom-0 left-0 right-0 ${variantStyle[variant].bgColor} opacity-10`}></div>
          <div className={`pt-1 ${variantStyle[variant].textColor}`}>
            {variantStyle[variant].icon}
          </div>
          <div className="">
            <h2 className="text-lg font-bold pb-1">{ title }</h2>
            {/* Body text --> */}
            <p className="text-sm mb-4">
              { message }
            </p>
          </div>
        </div>

         {/* Buttons --> */}
        <div className="flex justify-end space-x-3 bg-surface p-4 rounded-b-lg">
          {/* "Learn more" button --> */}
          { !!link && <a href={link} target="_blank" rel="noopener noreferrer"><ButtonIcon variant={ButtonVariant.SECONDARY}>
            { linkText || 'Learn more'}
          </ButtonIcon></a>}
          {/* "Ok, I understand" button --> */}
          <ButtonIcon variant={ButtonVariant.TERTIARY} handleClick={() => {
            onDismiss?.();
          }}>
            Ok, I understand
          </ButtonIcon>
        </div>
      </div>
    </div>
  );
};

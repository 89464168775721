import { ROUTES_IDS } from '@/routes';
import { useMatches } from 'react-router-dom';

/**
 * getCurrentRouteId take as parameters the useMatches value and return the current route id
 * known in the ROUTES_IDS enum
 * @param routes list of routes as defined out of react touer useMatches hook
 * @returns
 */
export const getCurrentRouteId = (): ROUTES_IDS => {

  const matches = useMatches();
  
  let currentRoute = ROUTES_IDS.UNKNOWN;
  // Reverse allow to go from deeper route to the root and return the first
  // route id in case of nested route with ids
  [...matches].reverse().forEach((route) => {
    const knownRoute = Object.entries(ROUTES_IDS).find((r) => r[1] === route.id);
    if (knownRoute && currentRoute == ROUTES_IDS.UNKNOWN) {
      currentRoute = ROUTES_IDS[knownRoute[0]];
    }
  });
  return currentRoute;
};

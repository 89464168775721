'use client';

import { FC, useContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import { AnalyticsOrderState, SortOrder } from '@/@generated/graphql';
import { analyticsDownloadResponseParser } from '@/lib/analytics-response-parser';
import { getColumns } from '@/lib/get-columns';
import { getAnalyticsTableRow, getAnalyticsTableSkeletonRow } from '@/lib/get-row';
import {
  getUseAnalyticsOrdersQueryKey,
  useAnalyticsOrderDeleteMutation,
  useAnalyticsOrderStartMutation,
  useAnalyticsOrdersQuery,
  useAssistantsQuery,
} from '@/lib/swr/hooks';
import { InputSelect, InputSelectOption, Table } from '@unique/component-library';
import { ButtonVariant } from '@unique/component-library/enums/button';
import { ButtonIcon } from '@unique/component-library/src/ButtonIcon';
import DatePicker from '@unique/component-library/src/DatePicker';
import { IconAnalytics } from '@unique/icons';
import { LayoutContext, ToastVariant, downloadFile, useToast } from '@unique/shared-library';
import { format } from 'date-fns';
import { ClientContext, Service } from '@unique/next-commons/swr';

let timer = null;
const numberOfTakingOrders = 20;
const ORDER_REFETCH_TIMEOUT = 5000;

export const ChatAnalytics: FC = () => {
  const { setSplitPaneContent } = useContext(LayoutContext);
  const { services } = useContext(ClientContext);
  const chatBackendUrl = services[Service.NODE_CHAT];
  const defaultStartDate = new Date();
  const defaultEndDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 30);

  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [selectedAssistant, setSelectedAssistant] = useState<InputSelectOption>();
  const [assistantList, setAssistantList] = useState<InputSelectOption[]>();

  const auth = useAuth();
  const { showToast } = useToast();

  const [hasRunningOrder, setHasRunningOrder] = useState(false);
  const [numberOfSkippingOrders, setNumberOfSkippingOrders] = useState(0);
  const analyticsOrdersQueryVariables = {
    orderBy: [{ createdAt: SortOrder.Desc }],
    skip: numberOfSkippingOrders,
    take: numberOfTakingOrders,
  };
  const { data: orders, mutate: fetchingOrders } = useAnalyticsOrdersQuery(
    analyticsOrdersQueryVariables,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onSuccess: (data) => {
        if (
          data &&
          data.analyticsOrders.some((order) => order.state === AnalyticsOrderState.Running)
        ) {
          timer = setTimeout(() => {
            fetchingOrders();
          }, ORDER_REFETCH_TIMEOUT);
          setHasRunningOrder(true);
        } else {
          setHasRunningOrder(false);
        }
      },
      onError: () => {
        showToast({
          message: 'There was an error fetching the analytics orders',
          variant: ToastVariant.ERROR,
        });
        clearTimeout(timer);
        setHasRunningOrder(false);
      },
    },
  );

  useEffect(() => {
    setSplitPaneContent(null);
  }, [setSplitPaneContent]);

  const { trigger: deleteOrder } = useAnalyticsOrderDeleteMutation(
    getUseAnalyticsOrdersQueryKey(analyticsOrdersQueryVariables),
  );

  const { trigger: startOrder } = useAnalyticsOrderStartMutation(
    getUseAnalyticsOrdersQueryKey(analyticsOrdersQueryVariables),
  );

  const loadNextOrders = async () => {
    setNumberOfSkippingOrders((prev) => prev + numberOfTakingOrders);
    await fetchingOrders();
  };
  const loadPreviousOrders = async () => {
    setNumberOfSkippingOrders((prev) => prev - numberOfTakingOrders);
    await fetchingOrders();
  };

  useAssistantsQuery({orderBy: {
    name: SortOrder.Asc
  }}, {
    onSuccess: (data) => {
      setAssistantList([{
        label: 'All Assistants',
        labelRaw: 'All Assistants',
        value: ''
      }, 
      ...data.assistants.map(({ name, id }) => ({
        label: name,
        value: id,
        labelRaw: name,
        isDisabled: false,
      }))]);
    }
  });

  const downloadOrder = async (orderId: string) => {
    try {
      setIsLoading(true);

      const url = `${chatBackendUrl}/analytics/analytics-scheduler/download/${orderId}`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${auth.user?.access_token}`,
          Accept: 'text/csv',
        },
      });

      if (response.status == 403) {
        showToast({
          message: 'You are not authorized to download this data',
          variant: ToastVariant.ERROR,
        });
        return;
      }
      if (!response.ok) {
        showToast({
          message: 'There was an error while downloading the data',
          variant: ToastVariant.ERROR,
        });
      }

      if (response.status === 200) {
        const blob = await response.blob();
        downloadFile(blob, `${orderId}.csv`);
        showToast({
          message: `${orderId} downloaded successfully.`,
          variant: ToastVariant.SUCCESS,
        });
      } else {
        showToast(analyticsDownloadResponseParser(await response.text()));
      }
    } catch (err) {
      showToast({
        message: `There was an error while downloading the ${orderId}.`,
        variant: ToastVariant.ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const startOrderTrigger = (orderId: string) => {
    const payload = {
      orderId,
    };
    startOrder(payload, {
      revalidate: false,
      throwOnError: false,
      onSuccess: async () => {
        await fetchingOrders();
      },
    });
  };

  const deleteOrderTrigger = (orderId: string) => {
    const payload = {
      orderId,
    };
    deleteOrder(payload, {
      revalidate: false,
      throwOnError: false,
      onSuccess: async () => {
        await fetchingOrders();
      },
    });
  };

  useEffect(() => {
    fetchingOrders();

    return () => {
      clearTimeout(timer);
      setHasRunningOrder(false);
    };
  }, [fetchingOrders]);

  const registerOrder = async (endpoint) => {
    try {
      setIsLoading(true);

      const startDateString = format(startDate, 'yyyy-MM-dd');
      const endDateString = format(endDate, 'yyyy-MM-dd');
      const assistantId = ['chat-interactions', 'chat-interactions-detailed'].includes(endpoint) && selectedAssistant?.value ? selectedAssistant?.value : '';
      const url = `${chatBackendUrl}/analytics/${endpoint}?startDate=${startDateString}&endDate=${endDateString}&assistantId=${assistantId}`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${auth.user?.access_token}`,
        },
      });

      if (response.status == 403) {
        showToast({
          message: 'You are not authorized to download this data',
          variant: ToastVariant.ERROR,
        });
        return;
      }
      if (response.status == 400) {
        const data = await response.json();
        showToast({
          message: `The inputs are invalid. ${data.message}`,
          variant: ToastVariant.ERROR,
        });
        return;
      }
      if (!response.ok) {
        showToast({
          message: 'There was an error creating the analytics order',
          variant: ToastVariant.ERROR,
        });
      }
      await fetchingOrders();
    } catch (err) {
      showToast({
        message: 'There was an error while creating the analytics order',
        variant: ToastVariant.ERROR,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="text-on-secondary flex h-full flex-col items-center overflow-y-auto pb-20">
      <div className="flex h-full flex-1 flex-col items-center justify-center text-center">
        <div className="title-s text-on-surface flex items-center p-4">Analytics Orders</div>
        {(!orders || orders.analyticsOrders.length > 0) && (
          <Table
            id="chunk-table"
            columns={getColumns(
              ['CREATED AT', 'ORDER ID', 'ANALYTICS TYPE', 'STATE', 'ACTIONS'],
              ['25%', '25%', '25%', '15%', '10%'],
            )}
            data={
              isLoading || !orders
                ? getAnalyticsTableSkeletonRow()
                : orders.analyticsOrders.map((row) =>
                    getAnalyticsTableRow(
                      row,
                      startOrderTrigger,
                      deleteOrderTrigger,
                      downloadOrder,
                      hasRunningOrder,
                    ),
                  )
            }
            headerClassName="first-of-type:pl-0"
            className="min-w-[800px] md:min-w-full"
            rowClassName="bg-background hover:bg-surface cursor-pointer"
          />
        )}
        {orders && (
          <div className="mt-4 flex w-full items-baseline">
            {numberOfSkippingOrders !== 0 && (
              <ButtonIcon
                variant={ButtonVariant.PRIMARY}
                isLoading={isLoading}
                className="mt-4"
                onClick={() => loadPreviousOrders()}
              >
                <div className="flex items-center">
                  <span className="subtitle-2 ml-2">Load newer orders</span>
                </div>
              </ButtonIcon>
            )}
            {orders.analyticsOrders.length > 0 && (
              <div className="text-on-surface mx-4">
                {' '}
                Order {numberOfSkippingOrders + 1} to{' '}
                {numberOfSkippingOrders + orders.analyticsOrders.length}{' '}
              </div>
            )}
            {orders.analyticsOrders.length === 0 && numberOfSkippingOrders === 0 && (
              <div className="text-on-surface mx-4"> No orders </div>
            )}
            {orders.analyticsOrders.length === 0 && numberOfSkippingOrders !== 0 && (
              <div className="text-on-surface mx-4"> No older orders on this page </div>
            )}
            {orders.analyticsOrders.length === numberOfTakingOrders && (
              <ButtonIcon
                variant={ButtonVariant.PRIMARY}
                isLoading={isLoading}
                className="mt-4"
                onClick={() => loadNextOrders()}
              >
                <div className="flex items-center">
                  <span className="subtitle-2 ml-2">Load older orders</span>
                </div>
              </ButtonIcon>
            )}
          </div>
        )}

        <div className="title-s text-on-surface flex items-center p-4">Chat Analytics</div>

        <DatePicker
          label="Start Date"
          defaultDate={defaultStartDate}
          onDateSelected={(date) => setStartDate(date)}
          maxDate={new Date()}
        />
        <DatePicker
          label="End Date"
          defaultDate={defaultEndDate}
          onDateSelected={(date) => setEndDate(date)}
        />
        <div className='font-bold'>Assistant</div>
        <InputSelect
          id="assistantSelection"
          placeholder="Select Assistant"
          selectedOption={selectedAssistant}
          options={assistantList}
          handleSelectOption={(option) => setSelectedAssistant(option)}
          tabIndex={4}
        />
        <ButtonIcon
          variant={ButtonVariant.PRIMARY}
          isLoading={isLoading}
          className="mt-4 w-full"
          onClick={() => registerOrder('chat-interactions')}
        >
          <div className="flex items-center">
            <IconAnalytics />
            <span className="subtitle-2 ml-2">Request Chat Interactions</span>
          </div>
        </ButtonIcon>

        <ButtonIcon
          variant={ButtonVariant.PRIMARY}
          isLoading={isLoading}
          className="mt-4 w-full"
          onClick={() => registerOrder('active-users')}
        >
          <div className="flex items-center">
            <IconAnalytics />
            <span className="subtitle-2 ml-2">Request Active Users</span>
          </div>
        </ButtonIcon>

        <ButtonIcon
          variant={ButtonVariant.PRIMARY}
          isLoading={isLoading}
          className="mt-4 w-full"
          onClick={() => registerOrder('reference-stat-export')}
        >
          <div className="flex items-center">
            <IconAnalytics />
            <span className="subtitle-2 ml-2">Request Reference Statistics</span>
          </div>
        </ButtonIcon>

        <ButtonIcon
          variant={ButtonVariant.PRIMARY}
          isLoading={isLoading}
          className="mt-4 w-full"
          onClick={() => registerOrder('chat-interactions-detailed')}
        >
          <div className="flex items-center">
            <IconAnalytics />
            <span className="subtitle-2 ml-2">Request Detailed User Interactions (max 14 days)</span>
          </div>
        </ButtonIcon>
      </div>
    </div>
  );
};

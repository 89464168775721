import { FC } from 'react';
import { IconProps } from '.';

export const IconWarning: FC<IconProps> = ({ width = '18px', height = '18px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1090_67666)">
        <path
          d="M1.8898 17.25H16.1102C17.5644 17.25 18.4708 15.7035 17.7438 14.4718L10.6336 2.42375C9.90648 1.19208 8.09352 1.19208 7.36645 2.42375L0.256242 14.4718C-0.47083 15.7035 0.43565 17.25 1.8898 17.25ZM9 10.7676C8.48066 10.7676 8.05575 10.3508 8.05575 9.8415V7.98938C8.05575 7.48005 8.48066 7.06332 9 7.06332C9.51934 7.06332 9.94425 7.48005 9.94425 7.98938V9.8415C9.94425 10.3508 9.51934 10.7676 9 10.7676ZM9.94425 14.4718H8.05575V12.6197H9.94425V14.4718Z"
          fill="currentcolor"
        />
      </g>
      <defs>
        <rect width={width} height={height} fill="white" />
      </defs>
    </svg>
  );
};

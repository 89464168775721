'use client';
import {
  AddDueDilgenceMetaDataDocument,
  MagicTableSheetState,
  Reference,
  SortOrder,
} from '@/@generated/graphql';
import { deleteSearchParams } from '@/helpers/deleteSearchParams';
import { mapReadUrlForReferences, parseContentIdFromInternalUrl } from '@/helpers/references';
import useChatPdfPreview from '@/hooks/useChatPdfPreview';
import { useAddDueDiligenceMetaData, useContentByIdQuery, useMessagesQuery } from '@/lib/swr/hooks';
import { useAppDispatch, useAppSelector } from '@/store';
import { updateEditingCell } from '@/store/slices/dueDiligence';
import { ButtonIcon, ButtonVariant, Textarea } from '@unique/component-library';
import { IconClose, IconFileAdd, IconPaperPlane } from '@unique/icons';
import { FolderPathSelection, ToastVariant, useToast } from '@unique/shared-library';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DueDiligenceAddQuestionButton from './DueDiligenceAddQuestionButton';
import DueDiligenceExportButton from './DueDiligenceExportButton';
import { TransformedMagicTableSheet } from '@/hooks/useDueDiligenceQuery';
import ButtonTooltipWrapper from './ButtonTooltipWrapper';
import DueDiligenceImportQuestionsButton from './DueDiligenceImportQuestionsButton';

type Props = {
  stopEditingCell: () => void;
  dueDiligenceData: TransformedMagicTableSheet;
  chatId: string;
  sheetId: string;
  requeryDueDiligence: () => void;
  scrollToLastRow: () => void;
  exportAsExcel: (fileName: string) => void;
};

const AGENT_BUSY_MESSAGE =
  'Question extraction in progress... This action will be possible once all the questions have been extracted.';

export default function DueDiligenceButtons({
  stopEditingCell,
  dueDiligenceData,
  chatId,
  requeryDueDiligence,
  sheetId,
  scrollToLastRow,
  exportAsExcel,
}: Props) {
  const dueDiligence = useAppSelector((state) => state.dueDiligence);
  const dispatch = useAppDispatch();
  const [contentId, setContentId] = useState('');

  const isAgentBusy =
    dueDiligence?.magicTableSheetUpdates.state === MagicTableSheetState.Processing;

  const magicTableSheetData = dueDiligenceData?.dueDiligence;

  const { showToast } = useToast();

  const [foundReferenceState, setFoundReferenceState] = useState<Reference | null>(null);

  const [searchParams] = useSearchParams();

  const reference = searchParams.get('reference');
  const requestId = searchParams.get('requestId');
  const messageId = searchParams.get('messageId');

  const { handleClickReference, handleClosePdfPreview } = useChatPdfPreview({
    chatId,
    showPdfHighlighting: true,
    redirectInternalStorageOnly: true,
    onClose: () => {
      searchParams.delete('reference');
      deleteSearchParams(['reference', 'requestId', 'messageId']);
    },
  });

  const chatQueryVariables = {
    chatId,
    orderBy: [{ createdAt: SortOrder.Asc }],
  };

  const { data: messages } = useMessagesQuery(chatId ? chatQueryVariables : null, {
    revalidateOnFocus: false,
  });

  const { data: contentData } = useContentByIdQuery(
    !contentId
      ? null
      : {
          contentIds: [contentId],
          chatId,
        },
  );

  useEffect(() => {
    if (!reference) return;
    const foundMessage = messages?.messages?.find((message) => message.id === messageId);

    const foundReference = foundMessage?.references.find(
      (ref) => ref.sequenceNumber === Number(reference),
    );

    if (foundReference) {
      setContentId(parseContentIdFromInternalUrl(foundReference.url));
      setFoundReferenceState(foundReference as Reference);
    }
  }, [reference, foundReferenceState, messages, requestId]);

  useEffect(() => {
    if (!contentData || !contentId) return;
    handleClickReference(mapReadUrlForReferences([foundReferenceState], contentData, false)[0]);
  }, [contentData, contentId, foundReferenceState, requestId]);

  useEffect(() => {
    return () => {
      handleClosePdfPreview();
    };
  }, []);

  const [showQuestionsSelect, setShowQuestionsSelect] = useState(false);

  const [isImportingQuestions, setIsImportingQuestions] = useState(false);

  const { trigger: addDDMetaData } = useAddDueDiligenceMetaData({
    variables: {},
    query: AddDueDilgenceMetaDataDocument,
  });

  const [showFolderPathSelectionModal, setShowFolderPathSelectionModal] = useState(false);

  const clearEditingSelection = () => {
    stopEditingCell();
    dispatch(updateEditingCell(null));
  };

  const classNames = cn({
    relative: true,
    'pl-24': dueDiligence.editingCell,
  });

  const handleSubmitQuestion = (question: string) => {
    addDDMetaData(
      {
        dueDiligenceId: magicTableSheetData?.id,
        questionTexts: [question],
      },
      {
        onSuccess: () => {
          showToast({
            message: 'Question added',
            variant: ToastVariant.SUCCESS,
          });
          scrollToLastRow();
        },
        onError: () => {
          showToast({
            message: 'Could not add question',
            variant: ToastVariant.ERROR,
          });
        },
      },
    );

    setShowQuestionsSelect(false);
  };

  const handleFolderPathSelected = ({ fileIds }) => {
    setShowFolderPathSelectionModal(false);
    addDDMetaData(
      {
        dueDiligenceId: magicTableSheetData?.id,
        ...(isImportingQuestions ? { questionFileIds: fileIds } : { sourceFileIds: fileIds }),
      },
      {
        onSuccess: () => {
          requeryDueDiligence();
          showToast({
            message: isImportingQuestions ? 'Questions imported' : 'Files added',
            variant: ToastVariant.SUCCESS,
          });
        },
        onError: () => {
          showToast({
            message: isImportingQuestions ? 'Could not import questions' : 'Could not add files',
            variant: ToastVariant.ERROR,
          });
        },
      },
    );
  };

  return (
    <div className="mx-5 my-5 max-w-3xl">
      {/* This will be hidden for now until we clear up the requirements and finalize how it should work. */}
      <form className="hidden w-full">
        <div className="relative flex w-full justify-between">
          {dueDiligence.editingCell && (
            <button
              className="bg-background text-on-background-dimmed shadow-background absolute left-2 z-10 my-auto mt-1 flex h-5 w-auto items-center justify-center rounded-md px-2 py-4 text-sm font-bold shadow-sm"
              onClick={clearEditingSelection}
            >
              Cell&nbsp;{`${dueDiligence.editingCell?.columnId}${dueDiligence?.editingCell.rowId}`}
              &nbsp;
              <IconClose />
            </button>
          )}
          <Textarea name="text" placeholder="Ask your table anything" className={classNames} />
          <ButtonIcon
            variant={ButtonVariant.PRIMARY}
            className="absolute right-9 top-0 my-auto mb-2.5 h-8 w-8 border-none"
            icon={<IconPaperPlane />}
          />
        </div>
      </form>
      <div className="mt-3 flex flex-wrap gap-3 md:flex-nowrap">
        <ButtonTooltipWrapper showTooltip={isAgentBusy} message={AGENT_BUSY_MESSAGE}>
          <ButtonIcon
            variant={ButtonVariant.SECONDARY}
            icon={<IconFileAdd />}
            onClick={() => {
              setIsImportingQuestions(false);
              setShowFolderPathSelectionModal(true);
            }}
            className="text-nowrap"
            disabled={isAgentBusy}
          >
            Add Files
          </ButtonIcon>
        </ButtonTooltipWrapper>
        <ButtonTooltipWrapper showTooltip={isAgentBusy} message={AGENT_BUSY_MESSAGE}>
          <DueDiligenceAddQuestionButton
            setShowQuestionsSelect={setShowQuestionsSelect}
            showQuestionsSelect={showQuestionsSelect}
            disabled={isAgentBusy}
            handleSubmitQuestion={handleSubmitQuestion}
          />
        </ButtonTooltipWrapper>
        {!showQuestionsSelect && (
          <>
            <ButtonTooltipWrapper showTooltip={isAgentBusy} message={AGENT_BUSY_MESSAGE}>
              <DueDiligenceImportQuestionsButton
                handleOpenKnowledgeBaseModal={() => {
                  setShowFolderPathSelectionModal(true);
                  setIsImportingQuestions(true);
                }}
                dueDiligenceId={magicTableSheetData?.id}
                disabled={isAgentBusy}
              />
            </ButtonTooltipWrapper>
            <DueDiligenceExportButton
              sheetId={sheetId}
              chatId={chatId}
              isDisabled={isAgentBusy}
              exportAsExcel={() => exportAsExcel(dueDiligenceData?.name)}
            />
          </>
        )}
      </div>
      {showFolderPathSelectionModal && (
        <FolderPathSelection
          handleClose={() => setShowFolderPathSelectionModal(false)}
          handleSubmit={handleFolderPathSelected}
          submitButtonText="Add files"
          isSelectable
          modalTitle="Select Files or Folders to add"
          maxItemsToSelect={isImportingQuestions ? 1 : undefined}
        />
      )}
    </div>
  );
}

import { ChatQuery } from '@/@generated/graphql';
import { DEFAULT_FILE_TYPES_DOCUMENT_TRANSLATOR } from '@/helpers/getAcceptableFileTypes';
import { getSupportedLanguageOptions } from '@/helpers/translationTextHelpers';
import { Assistant, ChatAssistant, ContentByChat } from '@/lib/swr/types';
import { ConfigurationContext } from '@/providers/ConfigurationProvider';
import { updateTranslateToLanguage, useAppDispatch, useAppSelector } from '@/store';
import {
  ButtonIcon,
  ButtonVariant,
  InputSelect,
  InputSelectOption,
  Spinner,
} from '@unique/component-library';
import { IconUploadInChat } from '@unique/icons';
import { FILE_TYPES, isIngestingContent, LayoutContext } from '@unique/shared-library';
import { FC, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ChatDropzone from '../ChatDropzone';
import { SpaceHeader } from '../Space/SpaceHeader';
import { TranslationMode } from './TranslationComponent';
import { TranslationUploadedDocument } from './TranslationUploadedDocument';

type TranslationModeDocumentProps = {
  assistant: Assistant | ChatAssistant;
  currentChat?: ChatQuery['chat'];
  content?: ContentByChat[];
  isLoadingContent?: boolean;
  spaceInfoComponent: JSX.Element;
};

export const TranslationModeDocument: FC<TranslationModeDocumentProps> = ({
  currentChat,
  assistant,
  content,
  isLoadingContent,
  spaceInfoComponent,
}) => {
  const navigate = useNavigate();
  const translateToLanguage = useAppSelector(({ translation }) => translation.translateToLanguage);
  const dispatch = useAppDispatch();
  const { fileTypesDocumentTranslator } = useContext(ConfigurationContext);

  const streams = useAppSelector((state) => state.chat.streams);
  const isStreaming = useMemo(() => {
    return streams.some((stream) => stream.chatId === currentChat?.id);
  }, [streams, currentChat]);

  const { setHeaderItems } = useContext(LayoutContext);

  useEffect(() => {
    if (
      !assistant?.settings?.translationLanguages?.length ||
      translateToLanguage.value ||
      !currentChat
    )
      return;
    const defaultLanguage = getSupportedLanguageOptions(
      assistant.settings?.translationLanguages,
    )[0];
    dispatch(updateTranslateToLanguage(defaultLanguage));
  }, [assistant, translateToLanguage, currentChat]);

  const DefaultHeader = [
    <SpaceHeader key={`space-header-${assistant.id}`} title={assistant.name} />,
  ];

  const handleSelectOption = (selectedOption: InputSelectOption) => {
    dispatch(updateTranslateToLanguage(selectedOption));
    // if there is no translated content yet (e.g. only 1 uploaded content), do not navigate to space
    // when changing the language
    if (content && content?.length <= 1) return;
    setHeaderItems(DefaultHeader);
    navigate(`/space/${assistant.id}?mode=${TranslationMode.DOCUMENT.toLowerCase()}`);
  };

  const hasContent = !!content && content?.length > 0;
  const hasTranslatedContent = !!content && content?.length > 1;

  const getFileTypesToDisplay = () => {
    if (!fileTypesDocumentTranslator) return DEFAULT_FILE_TYPES_DOCUMENT_TRANSLATOR;
    let fileTypesToDisplay = '';
    const fileTypes = fileTypesDocumentTranslator.split(',');
    fileTypes.forEach((fileType, index) => {
      const fileTypeFormatted = fileType.replaceAll(' ', '').toLowerCase();
      if (FILE_TYPES[fileTypeFormatted]) {
        fileTypesToDisplay += `${fileTypeFormatted.toUpperCase()}${fileTypes.length - 1 === index ? '' : ', '}`;
      }
    });
    if (!fileTypesToDisplay) {
      fileTypesToDisplay = DEFAULT_FILE_TYPES_DOCUMENT_TRANSLATOR;
    }
    return fileTypesToDisplay;
  };

  const translationSelectionDisabled = useMemo(() => {
    if (isStreaming) return true;
    return !!content && content?.length > 0 ? isIngestingContent(content[0].ingestionState) : false;
  }, [content, isStreaming]);

  return (
    <>
      {currentChat?.id && !hasTranslatedContent && (
        <div className="hidden justify-between gap-x-5 sm:flex">
          <div className="flex flex-1 justify-between">
            <ButtonIcon
              variant={ButtonVariant.SECONDARY}
              className="pointer-events-none !font-medium"
            >
              Original Text
            </ButtonIcon>
            <ButtonIcon
              variant={ButtonVariant.SECONDARY}
              className="pointer-events-none !font-medium"
            >
              &rarr;
            </ButtonIcon>
          </div>
          <div className="flex-1">
            <div className="w-max">
              <InputSelect
                options={getSupportedLanguageOptions(assistant?.settings?.translationLanguages)}
                handleSelectOption={handleSelectOption}
                selectedOption={translateToLanguage}
                id="translate"
                isDisabled={translationSelectionDisabled}
              />
            </div>
          </div>
        </div>
      )}

      {isLoadingContent ? (
        <div className="border-control mb-2 mt-5 flex h-full min-h-[170px] flex-1 flex-col items-center justify-center gap-3 overflow-y-auto rounded-lg border-2 border-dashed md:min-h-[250px]">
          <Spinner />
        </div>
      ) : (
        <>
          {hasContent ? (
            <div className="border-control mb-2 mt-5 flex h-full min-h-[170px] flex-1 flex-col items-center justify-center gap-3 overflow-y-auto rounded-lg border-2 border-dashed md:min-h-[250px]">
              <TranslationUploadedDocument
                language={translateToLanguage.value}
                assistantId={assistant.id}
              />
            </div>
          ) : (
            <ChatDropzone assistant={assistant} maxFilesOverride={1}>
              <div className="flex h-full flex-row items-center justify-center gap-3 sm:flex-col">
                <div className="flex sm:flex-none">
                  <IconUploadInChat height="80px" width="80px" />
                </div>
                <div className="flex flex-col items-start gap-3 text-left sm:items-center md:text-center">
                  <p className="text-on-background-main font-extrabold">Upload file to translate</p>
                  <p className="text-on-background-dimmed hidden sm:block">
                    {getFileTypesToDisplay()}
                  </p>
                  <ButtonIcon className="bg-primary-cta text-on-primary mb-0 sm:mb-3">
                    Upload File
                  </ButtonIcon>
                </div>
              </div>
            </ChatDropzone>
          )}
          {spaceInfoComponent}
        </>
      )}
    </>
  );
};

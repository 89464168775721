'use client';
import { MagicTableRow } from '@unique/shared-library/@generated/graphql';
import { ColDef, SideBarDef } from 'ag-grid-enterprise';
import { useCallback, useEffect, useRef } from 'react';
import { CustomMagicTableRow, MagicTableRefHandles } from '..';
import Skeleton from '../../Skeleton';
import {
  generateEmptyColumns,
  generateEmptyLoadingRows,
  generateEmptyRows,
  TOTAL_COLUMNS,
  transformMagicTableData,
} from './helpers';

export const useMagicTable = ({
  tableData,
  isLoading,
  tableColDefs,
}: {
  tableColDefs?: ColDef[];
  tableData: MagicTableRow[];
  isLoading: boolean;
}) => {
  const tableRef = useRef<MagicTableRefHandles>(null);
  const hasInitialized = useRef(false);

  useEffect(() => {
    if (isLoading) {
      const { columns, rows } = generateEmptyLoadingRows(26, <Skeleton className="mt-2" />);
      updateColumnDefs(columns);
      updateRowDefs(rows);
      return;
    }
    if (!isLoading && tableData?.length && !hasInitialized.current) {
      hasInitialized.current = true;
      const columnDefs = generateEmptyColumns(TOTAL_COLUMNS);
      if (tableColDefs?.length) {
        const columnDefResult = columnDefs.map((col) => {
          const foundCols = tableColDefs.find((tableCol) => tableCol.field === col.field);
          if (foundCols) {
            return { ...col, ...foundCols };
          }
          return col;
        });
        updateColumnDefs(columnDefResult);
      } else {
        updateColumnDefs(columnDefs);
      }
      const allRowData = transformMagicTableData(tableData, columnDefs);
      updateRowDefs(allRowData);
    }
    if (!tableData?.length) {
      const columnDefs = generateEmptyColumns(TOTAL_COLUMNS);
      const rowDefs = generateEmptyRows(100, 1);
      updateColumnDefs(columnDefs);
      updateRowDefs(rowDefs);
    }
  }, [tableData, isLoading]);

  const updateColumnDefs = (newColDefs: ColDef[]) => {
    tableRef.current?.updateColumnDefs(newColDefs);
  };

  const updateRowDefs = (newRowData: { [x: string]: string }[]) => {
    tableRef.current?.updateRowData(newRowData);
  };

  const stopEditingCell = () => {
    tableRef.current?.stopEditingCell();
  };

  const updateCellValues = (rowNode: number, columnNode: string, newValue: string) => {
    tableRef.current?.updateCellValues(rowNode, columnNode, newValue);
  };

  const getTableData = () => {
    const result = tableRef.current?.getTableData?.() ?? [];
    return result;
  };

  const exportAsExcel = (fileName: string) => {
    tableRef.current?.exportAsExcel(fileName);
  };

  const getLastOccupiedColumn = () => tableRef.current?.getLastOccupiedColumn?.();

  const getLastOccupiedRow = () => tableRef.current?.getLastOccupiedRow?.();

  const getColumnIndex = useCallback((columnId: string) => {
    const result = tableRef.current?.getColumnIndex?.(columnId);
    return result;
  }, []);

  const getColumnAtIndex = useCallback((index: number) => {
    const result = tableRef.current?.getColumnAtIndex?.(index);
    return result;
  }, []);

  const setRowUpdate = useCallback((rowIndex: number, rowData: CustomMagicTableRow[]) => {
    tableRef.current?.setRowUpdate(rowIndex, rowData);
  }, []);

  const setSideBarVisible = (state?: boolean) => {
    tableRef.current?.setSideBarVisible(state);
  };

  const setSideBar = (def: SideBarDef | string | string[] | boolean) => {
    tableRef.current?.setSideBar(def);
  };

  const openToolPanel = (id: string) => {
    tableRef.current?.openToolPanel(id);
  };

  const scrollToRowAtIndex = (rowIndex:number) => {
    tableRef?.current?.scrollToRowAtIndex(rowIndex)
  }

  const scrollToLastRow = () => {
    tableRef?.current?.scrollToLastRow()
  }

  return {
    tableRef,
    updateColumnDefs,
    updateRowDefs,
    getTableData,
    exportAsExcel,
    stopEditingCell,
    updateCellValues,
    getLastOccupiedColumn,
    getColumnIndex,
    getColumnAtIndex,
    setRowUpdate,
    getLastOccupiedRow,
    setSideBarVisible,
    setSideBar,
    openToolPanel,
    scrollToRowAtIndex,
    scrollToLastRow,
  } as const;
};

import {
  getUseAddDueDiligenceMetaDataKey,
  getUseCreateDueDiligenceKey,
  useAddDueDiligenceMetaData,
  useCreateDueDiligence,
} from '@/lib/swr/hooks';
import { useAppSelector } from '@/store';
import { ButtonIcon, ButtonVariant } from '@unique/component-library';
import { useNavigate } from 'react-router-dom';

type Props = {
  assistantId: string;
  source: string;
};

export default function SkipCreateDueDiligenceButton({ assistantId, source }: Props) {
  const navigate = useNavigate();

  const questionFileId =
    useAppSelector((state) => state.persistedDueDiligence.questionFileIds)[0] || '';

  const { trigger: addDueDiligenceMetaData } = useAddDueDiligenceMetaData(
    getUseAddDueDiligenceMetaDataKey(),
  );

  const goToSheet = (sheetId: string) => {
    navigate(`/space/${assistantId}/${sheetId}`);
  };

  const { trigger: createDueDiligence } = useCreateDueDiligence(getUseCreateDueDiligenceKey(), {
    onSuccess: ({ createDueDiligence }) => {
      if (source === 'add-sources') {
        // Send question file to the agent
        addDueDiligenceMetaData({
          dueDiligenceId: createDueDiligence.id,
          questionFileIds: questionFileId,
        });
        goToSheet(createDueDiligence.magicTableSheet.id);
        return;
      }
      if (source === 'import-questions') {
        goToSheet(createDueDiligence.magicTableSheet.id);
      }
    },
  });

  return (
    <div className="flex justify-end">
      <ButtonIcon
        variant={ButtonVariant.TERTIARY}
        onClick={() => createDueDiligence({ assistantId })}
      >
        Skip
      </ButtonIcon>
    </div>
  );
}

import { useState, useEffect, useCallback } from 'react';
import { SpeechToTextProvider } from '../services/speech-to-text/types';

export function useSpeechToText(provider: SpeechToTextProvider) {
  const [availableLanguages, setAvailableLanguages] = useState<string[]>();
  const [interimTranscript, setInterimTranscript] = useState<string>('');
  const [transcript, setTranscript] = useState<string>('');
  const [isListening, setIsListening] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleClean = () => {
    setTranscript('');
    setIsListening(false);
    setAvailableLanguages([]);
    setInterimTranscript('');
    setError(null);
    provider?.clean();
  };

  const startListening = useCallback(async () => {
    try {
      const hasStarted = await provider.startRecognition();
      if (!hasStarted) {
        throw new Error('An error occurrd while starting recognition');
      }
      setIsListening(true);
    } catch (err) {
      setError('An error occurred while starting recognition.');
      setIsListening(false);
    }
  }, [provider]);

  const stopListening = useCallback(async () => {
    try {
      await provider.stopRecognition();
      setIsListening(false);
    } catch (err) {
      setError('An error occurred while stopping recognition.');
    }
  }, [provider]);

  useEffect(() => {
    if (!error) {
      return;
    }

    setIsListening(false);
  }, [error]);

  useEffect(() => {
    if (!provider) {
      return;
    }

    setAvailableLanguages(provider.availableLanguages);

    provider.onTranscript = (newTranscript: string) => {
      setTranscript((prevTranscript) => `${prevTranscript} ${newTranscript}`);
    };

    provider.onInterimTranscript = (newInterimTranscript: string) => {
      setInterimTranscript(newInterimTranscript);
    };

    provider.onError = (err: string) => {
      setError(err);
    };

    provider.initialize();

    // Cleanup on unmount
    return () => {
      stopListening();
      handleClean();
    };
  }, [provider]);

  return {
    transcript,
    interimTranscript,
    isListening,
    startListening,
    stopListening,
    error,
    availableLanguages,
    clean: handleClean,
  };
}

import {
  addLinkToReferencePlaceholder,
  addSpanToReferencePlaceholders,
  referenceNumberRegex,
  referenceRegex,
} from '@/helpers/addLinkToReferencePlaceholder';
import {
  MarkdownHTMLElementType,
  MarkdownParagraphType,
  MarkdownPreview,
} from '@unique/component-library';
import { nanoid } from 'nanoid';
import { isValidElement, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';

type Props = {
  value: string;
  isFirstRow?: boolean;
  renderAsPlainText?: boolean;
  isCellRenderer?: boolean;
};

export default function DueDiligenceMarkdownPreview({
  value,
  isFirstRow,
  renderAsPlainText,
  isCellRenderer,
}: Props) {
  const navigate = useNavigate();
  const { spaceSlug, spaceId } = useParams();

  const textRenderer = useMemo(() => {
    if (!value) return '';
    // This has to come first because the referenceNumberRegex has some similarities with the referenceRegex
    // and we want to prioritize the referenceRegex first to avoid conflicts
    if (value.match(referenceRegex)) {
      return addLinkToReferencePlaceholder(value);
    }
    if (value.match(referenceNumberRegex)) {
      return addSpanToReferencePlaceholders(value);
    }
    return value;
  }, [value]);

  const handleReferenceClicked = useCallback(
    (reference: string, messageId: string) => {
      navigate(
        `/space/${spaceId}/${spaceSlug}?reference=${reference}&messageId=${messageId}&requestId=${nanoid()}`,
      );
    },
    [navigate, spaceId, spaceSlug],
  );

  const MarkdownSpan: MarkdownHTMLElementType = ({ children }) => {
    const [reference, messageId] = children.toString().split('&');
    return (
      <span
        onClick={() => handleReferenceClicked(reference, messageId)}
        className={
          'subtitle-2 bg-attention-variant text-on-attention-variant top-auto mr-1 inline-flex h-4 w-4 cursor-pointer items-center justify-center rounded-md px-2 align-text-top last-of-type:mr-0'
        }
      >
        {reference}
      </span>
    );
  };

  const MarkdownLink: MarkdownHTMLElementType = ({ children }) => {
    const child = isValidElement(children[0])
      ? children[0].props.children
      : (children[0] as string[]);

    if (!child) return <sub className="cursor-pointer text-left text-[14px]">{children}</sub>;

    const [reference, messageId] = child.toString().split('&');

    return (
      <sub
        onClick={() => handleReferenceClicked(reference, messageId)}
        className="cursor-pointer border-b border-dashed text-left text-[14px] hover:border-b-0"
      >
        {children}
      </sub>
    );
  };

  const MarkdownParagraph: MarkdownParagraphType = ({ children }) => (
    <p
      className={cn({
        'text-on-background-main mb-4 px-2': true,
        '!px-0': !isCellRenderer,
      })}
    >
      {children}
    </p>
  );

  if (renderAsPlainText) {
    return <div className="!font-normal">{textRenderer}</div>;
  }

  if (isFirstRow) {
    return <div className="p-2">{textRenderer}</div>;
  }

  return (
    <MarkdownPreview
      text={textRenderer}
      customComponents={{ span: MarkdownSpan, sub: MarkdownLink, p: MarkdownParagraph }}
      className="text-on-background-main"
    />
  );
}

import { MouseEvent, ReactNode, useRef, useState } from 'react';
import cn from 'classnames';
import { MenuItem, useOutsideClick } from '@unique/component-library';

export interface DropdownOptions {
  id: string;
  title: string;
  value?: string;
  icon?: ReactNode;
  externalUrl?: string;
  handleClick?: (ev: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

export default function Dropdown({
  children,
  dropdownOptions,
  onSelect,
  className,
  dropDownElement,
}: {
  children: ReactNode | ((isOpen?: boolean) => ReactNode);
  dropdownOptions?: DropdownOptions[];
  onSelect?: (option: DropdownOptions) => void;
  className?: string;
  dropDownElement?: ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useOutsideClick(dropdownRef, () => setIsOpen(false));

  const dropdownClasses = cn({
    'absolute left-0 z-50 mt-2 origin-top-right rounded-md bg-surface shadow-lg focus:outline-none rounded-md':
      true,
    'hidden transition ease-out duration-100 opacity-0': !isOpen,
    'block transition ease-in duration-75 opacity-100': isOpen,
    [`${className}`]: true,
  });

  return (
    <>
      <div className="relative inline-block text-left" ref={dropdownRef}>
        <div onClick={() => setIsOpen(!isOpen)}>
          {typeof children === 'function' ? children(isOpen) : children}
        </div>
        <div
          className={dropdownClasses}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
        >
          {dropdownOptions && dropdownOptions.length > 0 && (
            <div className="py-1" role="none">
              {dropdownOptions.map((item) => (
                <MenuItem
                  key={item.id}
                  title={item.title}
                  icon={item.icon}
                  externalUrl={item.externalUrl}
                  handleClick={
                    typeof onSelect === 'function'
                      ? () => {
                          onSelect(item);
                          setIsOpen(false);
                        }
                      : item.handleClick
                  }
                />
              ))}
            </div>
          )}
          {!dropdownOptions && dropDownElement}
        </div>
      </div>
    </>
  );
}

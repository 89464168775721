import { FC } from 'react';
import { IconProps } from '.';

export const IconMaintenance: FC<IconProps> = ({ width = '32px', height = '32px' }) => {
  return (
    <svg 
      width={width}
      height={height}
      viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Vector" fill="currentColor" d="M11.0504 22.1007C7.98082 22.1007 5.3717 21.0264 3.22302 18.8777C1.07434 16.729 0 14.1199 0 11.0504C0 10.4365 0.0460432 9.82254 0.13813 9.20863C0.230216 8.59472 0.399041 8.01151 0.644604 7.45899C0.798082 7.15204 0.989928 6.92182 1.22014 6.76835C1.45036 6.61487 1.7036 6.50743 1.97986 6.44604C2.25612 6.38465 2.54005 6.39233 2.83165 6.46906C3.12326 6.5458 3.39185 6.70695 3.63741 6.95252L8.47194 11.7871L11.7871 8.47194L6.95252 3.63741C6.70695 3.39185 6.5458 3.12326 6.46906 2.83165C6.39233 2.54005 6.38465 2.25612 6.44604 1.97986C6.50743 1.7036 6.61487 1.45036 6.76835 1.22014C6.92182 0.989928 7.15204 0.798082 7.45899 0.644604C8.01151 0.399041 8.59472 0.230216 9.20863 0.13813C9.82254 0.0460432 10.4365 0 11.0504 0C14.1199 0 16.729 1.07434 18.8777 3.22302C21.0264 5.3717 22.1007 7.98082 22.1007 11.0504C22.1007 11.7564 22.0393 12.424 21.9165 13.0532C21.7938 13.6825 21.6096 14.3041 21.364 14.918L30.6647 24.1266C31.5549 25.0168 32 26.1065 32 27.3957C32 28.6849 31.5549 29.7746 30.6647 30.6647C29.7746 31.5549 28.6849 32 27.3957 32C26.1065 32 25.0168 31.5396 24.1266 30.6187L14.918 21.364C14.3041 21.6096 13.6825 21.7938 13.0532 21.9165C12.424 22.0393 11.7564 22.1007 11.0504 22.1007Z" />
    </svg>
  );
};

'use client';
import type { TransformedMagicTableSheet } from '@unique/shared-library/@generated/graphql';
import { IconArrowUp, IconHistory } from '@unique/icons';
import {
  BodyScrollEndEvent,
  CellContextMenuEvent,
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  CellEditRequestEvent,
  ColDef,
  Column,
  ColumnResizedEvent,
  GetContextMenuItems,
  GetRowIdParams,
  GridReadyEvent,
  IRowNode,
  MenuModule,
  ModuleRegistry,
  RowClassParams,
  RowHeightParams,
  RowSelectedEvent,
  SideBarDef,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-enterprise/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid
import { AgGridReact, AgGridReactProps, CustomCellRendererProps } from 'ag-grid-react';
import useDebouncedCallback from 'beautiful-react-hooks/useDebouncedCallback';
import lodash from 'lodash';
import {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { renderToString } from 'react-dom/server';
import './styles/ag-theme-unique.css'; // Custom Theme applied to the Data Grid
import {
  calculateTextHeight,
  getCellId,
  idColumn,
  MIN_ROW_HEIGHT,
  TOTAL_COLUMNS,
} from './utils/helpers';
import { ButtonIcon, ButtonVariant } from '../..';

ModuleRegistry.registerModules([MenuModule]);

export type CellEditing = {
  value: string | number;
  columnId: string;
  rowId: number | null;
};

export type CustomMagicTableRow = { [x: string]: string };

export interface MagicTableResult {
  position: number;
  rowData?: {
    columnId: string;
    value: unknown;
  }[];
}

interface MagicTableProps {
  onRowSelected?: (selected: unknown) => void;
  cellEditingStart?: (event: CellEditing) => void;
  cellEditingEnd?: (event: CellEditing) => void;
  className?: string;
  handleCellEditRequest: ({ rowIndex, columnId, newValue, event }: CellEditRequest) => void;
  contextMenuItems?: GetContextMenuItems;
  loading?: boolean;
  onGridReady?: (event: GridReadyEvent) => void;
  sidebar?: SideBarDef;
  onCellContextMenu?: (event: CellContextMenuEvent) => void;
  CustomCellRenderer?: (props: CustomCellRendererProps) => JSX.Element;
  handleColumnResize?: (event: ColumnResizedEvent) => void;
  pinnedTopRowData?: TransformedMagicTableSheet['rows'];
  isTableBusy?: boolean;
}
export interface MagicTableRefHandles {
  updateColumnDefs: (newColDefs: ColDef[]) => void;
  updateRowData: (newRowData: CustomMagicTableRow[]) => void;
  getTableData: () => MagicTableResult[];
  exportAsExcel: (fileName: string) => void;
  stopEditingCell: () => void;
  updateCellValues: (rowNode: number, columnNode: string, newValue: string) => void;
  getLastOccupiedColumn: () => number;
  getColumnIndex: (columnId: string) => number;
  getColumnAtIndex: (index: number) => string;
  setRowUpdate: (rowIndex: number, data: CustomMagicTableRow[]) => void;
  getLastOccupiedRow: () => number;
  setSideBarVisible: (value?: boolean) => void;
  setSideBar: (def: SideBarDef | string | string[] | boolean) => void;
  openToolPanel: (id: string) => void;
  scrollToRowAtIndex: (rowIndex: number) => void;
  scrollToLastRow: () => void;
}

export interface CellEditRequest {
  rowIndex: number;
  columnId: string;
  newValue: string;
  event: CellEditRequestEvent;
}

const defaultColDef: ColDef<unknown> = {
  sortable: false,
  minWidth: 40,
  editable: true,
  wrapText: true,
  autoHeight: false,
  cellClass: 'custom-ag-cell',
  context: false,
  suppressHeaderMenuButton: true,
  suppressHeaderFilterButton: true,
  suppressHeaderContextMenu: true,
  enableCellChangeFlash: true,
  cellEditor: 'agLargeTextCellEditor',
  cellEditorPopup: false,
  cellEditorParams: {
    rows: 100,
    cols: 50,
  },
  cellRenderer: 'customCellRenderer',
};

const Table = forwardRef<MagicTableRefHandles, MagicTableProps & AgGridReactProps<unknown>>(
  (props, ref) => {
    const {
      onRowSelected,
      cellEditingStart,
      cellEditingEnd,
      className = 'h-full',
      handleCellEditRequest,
      contextMenuItems,
      loading,
      onGridReady,
      sidebar,
      onCellContextMenu,
      CustomCellRenderer,
      handleColumnResize,
      pinnedTopRowData,
      isTableBusy,
    } = props;
    const [rowData, setRowData] = useState<unknown[]>([]);

    const agGridRef = useRef<AgGridReact>(null);

    const [colDefs, setColDefs] = useState<ColDef<unknown>[]>([]);

    const [showBackToTop, setShowBackToTop] = useState(false);

    const updateColumnDefs = (newColDefs: ColDef[]) => {
      setColDefs((prevColDefs) => {
        if (lodash.isEqual(prevColDefs, newColDefs)) {
          return prevColDefs;
        }
        return newColDefs;
      });
    };

    const updateRowData = (newRowData: CustomMagicTableRow[]) => {
      setRowData((prevRowData) => {
        if (lodash.isEqual(prevRowData, newRowData)) {
          return prevRowData;
        }
        return newRowData;
      });
    };

    const handleSelect = useCallback((event: RowSelectedEvent) => {
      onRowSelected?.(event.data);
    }, []);

    useImperativeHandle(ref, () => ({
      updateColumnDefs,
      updateRowData,
      getTableData,
      exportAsExcel,
      stopEditingCell,
      updateCellValues,
      getLastOccupiedColumn,
      getColumnIndex,
      getColumnAtIndex,
      setRowUpdate,
      getLastOccupiedRow,
      setSideBarVisible,
      setSideBar,
      openToolPanel,
      scrollToRowAtIndex,
      scrollToLastRow,
    }));

    const setRowUpdate = (rowIndex: number, data: CustomMagicTableRow[]) => {
      const row = agGridRef.current?.api.getDisplayedRowAtIndex(rowIndex);
      if (row) {
        row.setData(data);
      }
    };

    const autoSizeStrategy = useMemo<
      | SizeColumnsToFitGridStrategy
      | SizeColumnsToFitProvidedWidthStrategy
      | SizeColumnsToContentStrategy
    >(() => {
      return {
        type: 'fitGridWidth',
        defaultMinWidth: 200,
      };
    }, []);

    const getCellValue = (event: CellEditingStartedEvent | CellEditingStoppedEvent) => {
      return event.api.getCellValue({ colKey: event.column, rowNode: event.node });
    };

    const onCellEditingStarted = useCallback((event: CellEditingStartedEvent) => {
      const cellValue = getCellValue(event);
      cellEditingStart?.({
        value: cellValue,
        columnId: event.column.getId(),
        rowId: event.rowIndex !== null ? event.rowIndex + 1 : null,
      });
    }, []);

    const onCellEditingStopped = useCallback((event: CellEditingStoppedEvent) => {
      const cellValue = getCellValue(event);
      cellEditingEnd?.({
        value: cellValue,
        columnId: event.column.getId(),
        rowId: event.rowIndex !== null ? event.rowIndex + 1 : null,
      });
    }, []);

    // Get the table data in the JSON format
    const getTableData = (): MagicTableResult[] => {
      const rowCount = agGridRef.current?.api.getDisplayedRowCount() || 0;

      return Array.from({ length: rowCount }, (_, i) => {
        const row = agGridRef.current?.api.getDisplayedRowAtIndex(i)?.data || {};
        const { id, ...rest } = row;

        if (Object.keys(rest).length > 0 && id !== undefined) {
          const rowData = Object.entries(rest).map(([columnId, value]) => ({ columnId, value }));
          return { position: id, rowData };
        }
        return null;
      }).filter(Boolean) as MagicTableResult[];
    };

    // Get the column index of the given column name
    const getColumnIndex = (columnId: string) => {
      const columnDefs = agGridRef.current?.api?.getColumnDefs() || [];
      const index = columnDefs.findIndex((col) => col.headerName === columnId);
      return index - 1;
    };

    // Get the column name at the given index
    const getColumnAtIndex = (index: number) => {
      const columnDefs = agGridRef.current?.api?.getColumnDefs() || [];
      return columnDefs[index + 1]?.headerName || '';
    };

    const exportAsExcel = (fileName: string) => {
      agGridRef.current?.api.exportDataAsExcel({
        fileName: fileName,
        skipColumnHeaders: true,
        shouldRowBeSkipped: (params) => {
          const countEmptyStrings = lodash.filter(
            Object.values(params.node.data),
            (value) => value === '',
          ).length;
          return countEmptyStrings === TOTAL_COLUMNS - 1;
        },
        columnKeys: agGridRef.current?.api
          ?.getColumnDefs()
          ?.slice(1)
          ?.map((col) => (col as ColDef).field)
          ?.filter((field): field is string => field !== undefined),
      });
    };

    const getRowId = useCallback(
      (params: GetRowIdParams) => String((params.data as { id: string }).id),
      [],
    );

    const onCellEditRequest = useCallback((event: CellEditRequestEvent) => {
      const oldData = event.data;
      const field = event.colDef.field;
      const newValue = event.newValue;
      const newData = { ...oldData };
      newData[field!] = event.newValue;

      if (newData[field!] === oldData[field!] || !newValue) return;

      const rowIndex = event.node.sourceRowIndex;
      const columnId = event.colDef.field!;

      const tx = {
        update: [newData],
      };

      event.api.applyTransaction(tx);

      handleCellEditRequest({ rowIndex, columnId, newValue, event });
    }, []);

    const updateCellValues = useCallback(
      (rowNode: number, columnNode: string, newValue: string) => {
        const row = agGridRef.current?.api.getDisplayedRowAtIndex(rowNode);

        if (row) {
          const contentHeight = calculateTextHeight(newValue);
          if ((row?.rowHeight ?? MIN_ROW_HEIGHT) < contentHeight) {
            row.setRowHeight(contentHeight);
            agGridRef.current?.api.onRowHeightChanged();
          }
          row.setDataValue(columnNode, newValue);
        }
      },
      [],
    );

    const stopEditingCell = () => {
      agGridRef.current?.api.stopEditing(true);
    };

    const getLastOccupiedColumn = () => {
      const lastOccupiedColumnIndexes: number[] = [];

      const columnDefs = agGridRef?.current?.api.getColumnDefs() || [];

      // Loop through each row to find the last non-empty column
      agGridRef?.current?.api.forEachNode((node) => {
        const rowData = node.data;
        let lastColumnIndex = -1;

        // Loop through each column in the row data
        columnDefs.forEach((colDef, index) => {
          const value = rowData[colDef.headerName as string];
          if (value !== null && value !== undefined && value !== '') {
            lastColumnIndex = index;
          }
        });

        lastOccupiedColumnIndexes.push(lastColumnIndex);
      });

      // Return the max last occupied index across all rows
      return Math.max(...lastOccupiedColumnIndexes);
    };

    const getLastOccupiedRow = () => {
      let lastEmptyRow = '1';
      let found = false;
      agGridRef.current?.api.forEachNode((node) => {
        const newNode = { ...node.data };

        // remove default row values
        delete newNode.id;
        delete newNode.rowHeight;

        if (Object.values(newNode).every((value) => !value)) {
          if (!found && node.rowIndex) {
            found = true;
            lastEmptyRow = node.data.id;
          }
        }
      });
      return Number(lastEmptyRow) - 1;
    };

    // Sidebar
    const setSideBarVisible = (value?: boolean) => {
      if (!value) {
        agGridRef.current!.api.setSideBarVisible(agGridRef.current!.api.isSideBarVisible());
        return;
      }
      agGridRef.current!.api.setSideBarVisible(value);
    };

    const setSideBar = (def: SideBarDef | string | string[] | boolean) => {
      agGridRef.current!.api.setGridOption('sideBar', def);
    };

    const openToolPanel = (id: string) => {
      agGridRef.current!.api.openToolPanel(id);
    };

    const getRowHeightByDomElement = (rowNode: IRowNode, allColumns: Column[]) => {
      // Only update the row heights if a column was actually resized by the user
      const hasData = Object.values(rowNode.data).some(
        (value) => value !== null && value !== undefined && value !== '',
      );
      if (rowNode?.rowIndex === null || rowNode?.rowIndex === undefined || !hasData) return;
      let maxHeight = MIN_ROW_HEIGHT;
      // Iterate over each column. Why?
      // The allColumns array from agGridRef.current.columnApi.getColumns() contains info about columns (IDs, fields, etc.),
      // but not about which row each column belongs to, as columns are shared across all rows.
      allColumns.forEach((column) => {
        const colId = column.getColId();
        if (!rowNode?.id || !rowNode.data[colId]) return;
        const cellId = getCellId(rowNode.id, colId);
        const cellElement = document.getElementById(cellId);
        // Update the maximum height for the row
        if (cellElement && cellElement.offsetHeight > maxHeight) {
          maxHeight = cellElement.offsetHeight;
        }
      });
      return maxHeight;
    };

    const scrollToRowAtIndex = (rowIndex: number) => {
      agGridRef.current!.api.ensureIndexVisible(rowIndex);
    };

    const scrollToLastRow = () => {
      scrollToRowAtIndex(getLastOccupiedRow() + 1);
    };

    const scrollToTop = () => {
      scrollToRowAtIndex(0);
    };

    const hasTopPinnedRow = () => !!agGridRef.current?.api.getPinnedTopRowCount();

    const handleRowResize = useCallback(() => {
      if (!agGridRef.current) return;
      // Get all columns
      const allColumns = agGridRef.current.api.getColumns();
      if (!allColumns?.length) return;

      // Create an array to store the maximum height for each row
      const rowHeights: number[] = [];

      // Iterate over each row
      agGridRef.current.api.forEachNode((rowNode) => {
        const rowHeight = getRowHeightByDomElement(rowNode, allColumns);
        // Store the maximum height for the row
        if (rowNode?.rowIndex && rowHeight) {
          rowHeights[rowNode.rowIndex] = rowHeight;
        }
      });
      // Set the row heights
      agGridRef.current.api.forEachNode((rowNode) => {
        const shouldSetRowHeight =
          rowNode?.rowIndex &&
          rowHeights[rowNode.rowIndex] > MIN_ROW_HEIGHT &&
          rowHeights[rowNode.rowIndex] !== rowNode.rowHeight;
        const isFirstRow = rowNode.rowIndex === 0;

        if (!shouldSetRowHeight && !isFirstRow) return;

        // we want to hide the first row as it is already pinned (height to 0)
        if (rowNode?.rowIndex === 0 && !rowNode.rowPinned && hasTopPinnedRow()) {
          rowNode.setRowHeight(0);
        } else if (rowNode?.rowIndex && shouldSetRowHeight) {
          rowNode.setRowHeight(rowHeights[rowNode.rowIndex]);
        }
      });
      // Notify the grid to update the row heights
      agGridRef.current.api.onRowHeightChanged();
    }, []);

    const onColumnResized = useDebouncedCallback(
      (event: ColumnResizedEvent) => {
        // Only update the row heights if a column was actually resized by the user
        // Otherwise this calculation runs whenever the window size changes which would impact performance
        if (event.source !== 'uiColumnResized') return;
        handleRowResize();
        handleColumnResize?.(event);
      },
      [],
      150,
    );

    // Resize rows because they might not be loaded into DOM yet when they are not visible on the screen
    const handleBodyScrollEnd = useDebouncedCallback(
      (event: BodyScrollEndEvent) => {
        handleRowResize();

        const { top, direction } = event;
        if (direction === 'vertical' && top >= MIN_ROW_HEIGHT) {
          setShowBackToTop(true);
        }
        if (direction === 'vertical' && top < MIN_ROW_HEIGHT) {
          setShowBackToTop(false);
        }
      },
      [],
      150,
    );

    const getRowHeight = useCallback((params: RowHeightParams): number | undefined | null => {
      return params.data?.rowHeight ? Number(params.data.rowHeight) : MIN_ROW_HEIGHT;
    }, []);

    const getRowClass = useCallback((params: RowClassParams) => {
      const rowIndex = params?.node?.rowIndex || 0;
      const isEvenRow = rowIndex % 2 === 0;
      if (rowIndex === 0 && !params.node.rowPinned && hasTopPinnedRow()) return 'ag-hidden-row';
      if (rowIndex === 0 && !params.node.rowPinned && !hasTopPinnedRow()) return 'ag-row-striped';
      if (rowIndex !== 0) return isEvenRow ? 'ag-row-striped' : '';
      if (params.node.rowPinned) return 'ag-row-pinned';
    }, []);

    const components = useMemo(
      () => ({
        customCellRenderer: CustomCellRenderer,
      }),
      [CustomCellRenderer],
    );

    const rowSelection = useMemo(
      () => ({
        mode: 'multiRow' as const,
        checkboxes: false,
        headerCheckbox: false,
        enableClickSelection: true,
      }),
      [],
    );

    // Resize the row height when the data is fully loaded
    const onRowDataUpdated = () => {
      const hasActualData = rowData.some((data) => {
        return Object.keys(data as object).length > 2;
      });
      if (!hasActualData) return;
      handleRowResize();
    };

    const onColumnHeaderContextMenu = useCallback(() => null, []);

    const memoizedColDefs = useMemo(() => {
      const columns = [idColumn, ...colDefs];

      if (!isTableBusy) {
        return columns;
      }
      return columns.map((col) => ({ ...col, editable: false }));
    }, [colDefs, isTableBusy]);

    return (
      <div className={`ag-theme-quartz ag-theme-unique ${className}`}>
        <AgGridReact
          ref={agGridRef}
          debug={false}
          loading={loading}
          rowData={rowData}
          components={components}
          columnDefs={memoizedColDefs}
          icons={{
            'cell-history': renderToString(<IconHistory />),
          }}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          getRowHeight={getRowHeight}
          autoSizeStrategy={autoSizeStrategy}
          rowSelection={rowSelection}
          onColumnHeaderContextMenu={onColumnHeaderContextMenu}
          onRowSelected={handleSelect}
          readOnlyEdit
          onCellEditingStarted={onCellEditingStarted}
          onCellEditingStopped={onCellEditingStopped}
          getContextMenuItems={contextMenuItems}
          onRowDataUpdated={onRowDataUpdated}
          onCellEditRequest={onCellEditRequest}
          onGridReady={onGridReady}
          sideBar={sidebar}
          onCellContextMenu={onCellContextMenu}
          getRowClass={getRowClass}
          onColumnResized={onColumnResized}
          onBodyScrollEnd={handleBodyScrollEnd}
          pinnedTopRowData={pinnedTopRowData}
        />
        {showBackToTop && (
          <div className="absolute bottom-5 flex w-full items-center justify-center">
            <ButtonIcon
              variant={ButtonVariant.TERTIARY}
              icon={<IconArrowUp />}
              onClick={scrollToTop}
              className="bg-background text-nowrap"
            >
              Back to Top
            </ButtonIcon>
          </div>
        )}
      </div>
    );
  },
);

export const MagicTable = memo(Table);

import { VoiceVisualizerAnimationPlugin } from '../types';

function drawRoundedRect(ctx, x, y, width, height, radius) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y); // Top-left corner
  ctx.lineTo(x + width - radius, y); // Top edge
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius); // Top-right corner
  ctx.lineTo(x + width, y + height - radius); // Right edge
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height); // Bottom-right corner
  ctx.lineTo(x + radius, y + height); // Bottom edge
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius); // Bottom-left corner
  ctx.lineTo(x, y + radius); // Left edge
  ctx.quadraticCurveTo(x, y, x + radius, y); // Top-left corner
  ctx.closePath();
  ctx.fill();
}

interface BarHistoryAnimation {
  barsLimit?: number;
  barColor?: string;
  speed?: number; // Optional speed control for movement
}

export const barHistoryAnimation: VoiceVisualizerAnimationPlugin<BarHistoryAnimation> = (props) => {
  // INFO: The reason why this is here is because we need to wait for tailwind to inject the classes and react to mount properly.
  // Then its available
  const primaryColor = getComputedStyle(document.documentElement)
    .getPropertyValue('--color-primary-cta')
    .trim();

  const barsLimit = props?.barsLimit ?? 100;
  const barColor = props?.barColor ?? primaryColor ?? '#ffffff';
  const history: number[] = Array(barsLimit).fill(0);
  const speed = props?.speed ?? 20; // Speed determines update frequency (higher = faster)

  let frameCount = 0; // Counter to control animation speed
  let frameAccumulator = 0; // Accumulates values between updates
  let frameSamples = 0; // Counts the number of frames between updates

  return (canvas: HTMLCanvasElement, analyser: AnalyserNode) => {
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    frameCount++;

    const dataArray = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(dataArray);

    // Accumulate the current frame's data
    frameAccumulator += dataArray[0];
    frameSamples++;

    // Skip frames to slow down the animation
    const framesToSkip = Math.max(1, Math.round(60 / speed)); // Default: ~60 FPS
    if (frameCount % framesToSkip !== 0) return;

    // Calculate the average value for the skipped frames
    const averageValue = frameAccumulator / frameSamples;

    // Record the average value into the history
    history.push(averageValue);

    // Reset the accumulator for the next interval
    frameAccumulator = 0;
    frameSamples = 0;

    // Limit the history length to max barsLimit elements
    if (history.length > barsLimit) {
      history.shift();
    }

    const barWidth = canvas.width / barsLimit;
    const maxBarHeight = canvas.height;

    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas

    ctx.fillStyle = barColor; // Set bar color

    // Main loop
    for (let i = 0; i < history.length; i++) {
      const barHeight = (history[i] / 255) * maxBarHeight; // Normalize height
      const spacing = 3; // Set the spacing between bars
      const effectiveBarWidth = barWidth - spacing; // Adjust bar width
      const x = i * barWidth; // Position the bar
      const y = (canvas.height - barHeight) / 2; // Center vertically
      const cornerRadius = 3; // Set the corner radius

      // Only draw bars within the visible canvas area
      if (x + effectiveBarWidth > 0 && x < canvas.width) {
        drawRoundedRect(ctx, x, y, effectiveBarWidth, barHeight, cornerRadius); // Draw rounded bar
      }
    }
  };
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconPause: FC<IconProps> = ({ width = '19px', height = '20px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" y="3" width="3" height="14" fill="currentColor" />
      <rect x="12" y="3" width="3" height="14" fill="currentColor" />
    </svg>
  );
};

import { AssistantQuery } from '@/@generated/graphql';
import { SpaceInfo } from '../Space/SpaceInfo';
import { ButtonIcon, InputField } from '@unique/component-library';
import { IconFileAdd } from '@unique/icons';
import { useDueDiligenceQuery } from '@/lib/swr/hooks';
import DueDiligenceList from './DueDiligenceList';
import { useContext, useEffect } from 'react';
import { LayoutContext } from '@unique/shared-library';
import { SpaceHeader } from '../Space/SpaceHeader';
import { useNavigate } from 'react-router-dom';

export default function DueDiligenceHome({
  assistant,
}: {
  assistant: AssistantQuery['assistantByUser'];
}) {
  const { data: dueDiligenceList, isLoading } = useDueDiligenceQuery();
  const { setHeaderItems } = useContext(LayoutContext);
  const navigate = useNavigate();

  useEffect(() => {
    setHeaderItems([<SpaceHeader key={`space-header-${assistant}`} title={'Due Diligence'} />]);
    return () => {
      setHeaderItems([]);
    };
  }, []);

  return (
    <div>
      {assistant?.explanation || assistant?.alert ? (
        <div className="bg-background flex items-center justify-center py-5">
          <div className="mx-auto max-w-[928px] px-3 sm:px-0">
            <SpaceInfo
              explanation={assistant?.explanation}
              alert={assistant?.alert}
              bg="bg-surface"
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className="mx-auto mt-5 max-w-[928px] px-3 sm:px-0">
        <div className="bg-surface">
          <div className="flex w-full items-center justify-between gap-3 px-0 sm:px-2">
            {/* TODO: To be displayed when we have the API from the backend. Ticket:  https://unique-ch.atlassian.net/browse/UN-9392 */}
            <InputField name="search" className="hidden" placeholder="Search sheet" type="text" />
            <ButtonIcon
              onClick={() =>
                navigate(`/space/${assistant?.id}/create-worksheet?step=import-questions`)
              }
              icon={<IconFileAdd />}
              className="bg-primary-cta text-nowrap"
            >
              New Sheet
            </ButtonIcon>
          </div>
          <div className="mt-10">
            <DueDiligenceList dueDiligence={dueDiligenceList?.dueDiligence} isLoading={isLoading} />
          </div>
        </div>
      </div>
    </div>
  );
}

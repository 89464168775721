'use client';

import { FC, ReactNode, createContext, useState, useEffect, useContext } from 'react';
import { Notification } from '../src/Notification';
import { getSdk, NotificationBanner } from '../@generated/graphql';
import { ClientContext, Service } from '@unique/next-commons/swr';
import { logger } from '@unique/next-commons/logger';
import { FeatureFlagContext } from './FeatureFlagProvider';

type NotificationContextProps = {
  showNotification?: (vars: unknown) => void;
};
export const NotificationContext = createContext<NotificationContextProps>({});

type NotificationProviderProps = {
  children: ReactNode;
};

const log = logger.child({ package: 'shared-library', namespace: 'notification-provider' });

export const NotificationProvider: FC<NotificationProviderProps> = ({ children }) => {

  const [isVisible, setIsVisible] = useState(true);
  const clientContext = useContext(ClientContext);

  const [notificationBanners, setNotificationBanners] = useState<Partial<NotificationBanner> | null>(null);

  const { flags } = useContext(FeatureFlagContext);
  const isNotificationBannerEnabled = flags.FEATURE_FLAG_ENABLE_NOTIFICATION_BANNER_UN_9411;
  
  useEffect(() => {
    if (!clientContext?.clients?.[Service.NODE_THEME] || !isNotificationBannerEnabled) return;
    const sdk = getSdk(clientContext.clients[Service.NODE_THEME]);
    sdk.ActiveNotificationBanner().then((data) => {
      setNotificationBanners(data.activeNotificationBanner as Partial<NotificationBanner>);
    }).catch((error) => {
      log.error(error);
    });
  }, [clientContext]);

  const markNotificationBannerAsRead = (notificationBannerId: string) => {
    if (!clientContext?.clients?.[Service.NODE_THEME]) return;
    const sdk = getSdk(clientContext.clients[Service.NODE_THEME]);
    sdk.MarkNotificationBannerAsRead({ notificationBannerId });
  }

  return (
    <NotificationContext.Provider value={{}}>
      {children}
      <div className="fixed right-0 top-0 flex m-4">
        { isVisible && notificationBanners && <Notification
          variant={notificationBanners.type}
          title={notificationBanners.title}
          message={notificationBanners.message}
          link={notificationBanners.link}
          linkText={notificationBanners.linkText}
          onDismiss={() => {
            setIsVisible(false);
            if (notificationBanners.id) {
              markNotificationBannerAsRead(notificationBanners.id);
            }
          }}
          />
        }
      </div>
    </NotificationContext.Provider>
  );
};
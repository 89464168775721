import { FC } from 'react';
import { IconProps } from '.';

export const IconSentimentPositive: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="sentiment_satisfied_24dp_FILL1_wght400_GRAD0_opsz24">
        <path
          id="Vector"
          d="M10.3333 7.33301C10.6111 7.33301 10.8472 7.23579 11.0416 7.04134C11.2361 6.8469 11.3333 6.61079 11.3333 6.33301C11.3333 6.05523 11.2361 5.81912 11.0416 5.62467C10.8472 5.43023 10.6111 5.33301 10.3333 5.33301C10.0555 5.33301 9.81942 5.43023 9.62498 5.62467C9.43053 5.81912 9.33331 6.05523 9.33331 6.33301C9.33331 6.61079 9.43053 6.8469 9.62498 7.04134C9.81942 7.23579 10.0555 7.33301 10.3333 7.33301ZM5.66665 7.33301C5.94442 7.33301 6.18053 7.23579 6.37498 7.04134C6.56942 6.8469 6.66665 6.61079 6.66665 6.33301C6.66665 6.05523 6.56942 5.81912 6.37498 5.62467C6.18053 5.43023 5.94442 5.33301 5.66665 5.33301C5.38887 5.33301 5.15276 5.43023 4.95831 5.62467C4.76387 5.81912 4.66665 6.05523 4.66665 6.33301C4.66665 6.61079 4.76387 6.8469 4.95831 7.04134C5.15276 7.23579 5.38887 7.33301 5.66665 7.33301ZM7.99998 11.6663C8.75553 11.6663 9.44165 11.4525 10.0583 11.0247C10.675 10.5969 11.1222 10.033 11.4 9.33301H10.3C10.0555 9.74412 9.73053 10.0691 9.32498 10.308C8.91942 10.5469 8.47776 10.6663 7.99998 10.6663C7.5222 10.6663 7.08053 10.5469 6.67498 10.308C6.26942 10.0691 5.94442 9.74412 5.69998 9.33301H4.59998C4.87776 10.033 5.32498 10.5969 5.94165 11.0247C6.55831 11.4525 7.24442 11.6663 7.99998 11.6663ZM7.99998 14.6663C7.07776 14.6663 6.21109 14.4913 5.39998 14.1413C4.58887 13.7913 3.88331 13.3163 3.28331 12.7163C2.68331 12.1163 2.20831 11.4108 1.85831 10.5997C1.50831 9.78856 1.33331 8.9219 1.33331 7.99967C1.33331 7.07745 1.50831 6.21079 1.85831 5.39967C2.20831 4.58856 2.68331 3.88301 3.28331 3.28301C3.88331 2.68301 4.58887 2.20801 5.39998 1.85801C6.21109 1.50801 7.07776 1.33301 7.99998 1.33301C8.9222 1.33301 9.78887 1.50801 10.6 1.85801C11.4111 2.20801 12.1166 2.68301 12.7166 3.28301C13.3166 3.88301 13.7916 4.58856 14.1416 5.39967C14.4916 6.21079 14.6666 7.07745 14.6666 7.99967C14.6666 8.9219 14.4916 9.78856 14.1416 10.5997C13.7916 11.4108 13.3166 12.1163 12.7166 12.7163C12.1166 13.3163 11.4111 13.7913 10.6 14.1413C9.78887 14.4913 8.9222 14.6663 7.99998 14.6663Z"
          fill="currentColor"
          className="text-success-dark"
        />
      </g>
    </svg>
  );
};

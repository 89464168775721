import {
  mutateDeleteAllChats,
  useAppDispatch,
} from '@/store';
import { ToastVariant, useModal, useToast } from '@unique/shared-library';
import { useParams, useNavigate } from 'react-router-dom';

import { ROUTES_IDS } from '@/routes';
import { getCurrentRouteId } from '@/lib/get-current-route-id';
import DeleteAllConfirmation from '@/components/Chat/DeleteAllConfirmation';
import { mutateDeleteChatIds } from '@/store/slices/chat/actions/mutateDeleteChatIds';

export function useChatDelete() {
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  
  const { 
    openModal,
    closeModal,
  } = useModal();
  const route_id = getCurrentRouteId();

  /**
   * 
   * @param chatIds 
   * @param options {mutate: () => void}
   * @returns 
   */
  const deleteChatIds = (chatIds: string[], options = null) => {
    const chatsTotalCount = chatIds.length;
    return openModal(
      <DeleteAllConfirmation 
      chatTitle={chatIds.length === 1 ? options?.chats?.find(chat => chat.id === chatIds[0]).title : null}
        chatsTotalCount={chatsTotalCount}
        onConfirmation={async () => {
          // Delete all chats
          try{
            closeModal();
            showToast({
              message: `Deleting ${chatsTotalCount} Chat${chatsTotalCount === 1 ? '' : 's'}...`,
              variant: ToastVariant.LOADING,
              // Loading toast will stay until next toast appear
              duration: Infinity,
            });
            // Send query to provided chats
            await dispatch(mutateDeleteChatIds(chatIds));
            // update local list of chats running new request to backend
            options?.mutate?.();

            // If user is currently displaying any chat, navigate to home
            if (chatIds.includes(id)) {
              navigate('/');
            }

            showToast({
              message: `Successfully deleted ${chatsTotalCount} Chat${chatsTotalCount === 1 ? '' : 's'}`,
              variant: ToastVariant.SUCCESS,
            });
          } catch (error) {
            showToast({
              message: error.message,
              variant: ToastVariant.ERROR,
            });
          };
        }} />
      );
  };
  /**
   * 
   * @param options {mutate: () => void}
   */
  const deleteAllChats = (options) => {
    // Todo, get data from backend
    return openModal(
      <DeleteAllConfirmation 
        isDeletingAllChats={true}
        onConfirmation={async () => {
          // Delete all chats
          try{
            closeModal();
            showToast({
              message: 'Deleting all Chats...',
              variant: ToastVariant.LOADING,
              duration: Infinity,
            });
            // Send query to delete all chats
            await dispatch(mutateDeleteAllChats());
            // If user is currently displaying any chat, navigate to home
            if (route_id === ROUTES_IDS.CHAT) {
              navigate('/');
            }
            // update local list of chats running new request to backend
            options?.mutate?.();
            showToast({
              message: 'Successfully deleted all Chats',
              variant: ToastVariant.SUCCESS,
            });
          } catch (error) {
            showToast({
              message: error.message,
              variant: ToastVariant.ERROR,
            });
          };
        }} />
      );
  };

  return { deleteChatIds, deleteAllChats };
}

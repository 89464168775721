import cn from 'classnames';
import { FC, ReactNode, useContext } from 'react';
import { LayoutContext, useScreens } from '@unique/shared-library';
import IconHamburger from '@unique/icons/IconHamburger';

interface HeaderProps {
  logo: ReactNode;
  hideLogoOnDesktop?: boolean;
  children?: ReactNode;
  menu?: ReactNode;
}

export const Header: FC<HeaderProps> = ({ logo, children, menu, hideLogoOnDesktop }) => {
  const { isHeaderVisible, isMenuExpanded, splitPaneContent, secondLevelNavMenu, setIsMenuExpanded } =
    useContext(LayoutContext);
  const { isMobile } = useScreens();
  const logoClassNames = `m-auto flex h-full justify-center border-background-variant sm:m-0 sm:min-w-[216px] min-h-[58px] items-center sm:min-h-[79px] flex-1 sm:flex-initial ${
    isMenuExpanded ? 'sm:hidden' : 'block'
  } ${splitPaneContent ? 'lg:border-r' : 'sm:border-r'}`;

  const handleClickHamburgerMenu = () => {
    setIsMenuExpanded && setIsMenuExpanded(!isMenuExpanded);
  };

  return (
    <header
      className={cn({
        'border-background-variant bg-surface sticky top-0 z-50 flex w-full items-center border-b transition transition-transform':
          true,
        'justify-between': children,
        'justify-center sm:justify-start': !children,
        '-translate-y-[190px] sm:-translate-y-[120px]': !isHeaderVisible,
      })}
    >
      <div
        className={cn('flex w-full flex-col sm:flex-row sm:justify-between lg:h-20 sm:min-h-[79px]', {
          'h-auto': !!children,
          'h-[58px]': !children,
          'sm:flex': !splitPaneContent,
          'lg:flex': splitPaneContent,
        })}
      >
        {isMobile && <div className="flex min-h-[58px] flex-row lg:px-4">
          <button
            className="text-on-surface mx-4 flex cursor-pointer items-center lg:ml-0 lg:mr-0"
            onClick={handleClickHamburgerMenu}
          >
            <IconHamburger />
          </button>
          <div className={logoClassNames}>{logo}</div>
          <div className="ml-auto">{menu}</div>
        </div>}
        
         {/* only show logo when theres no second level nav. no need to show it if a second level nav exists, because its displayed in there already */}
         {!isMobile && !secondLevelNavMenu && !hideLogoOnDesktop && <div className={logoClassNames}>{logo}</div>}
         {children}
        {!isMobile &&  <div className="ml-auto">{menu}</div>}
      </div> 
    </header>
  );
};

import { Message } from '@/@generated/graphql';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { StrongId } from '@unique/misc';
import { uniqBy } from 'lodash';


export interface MessagesState {
  // Flat list of messages loaded, potentially multiple chats
  messages: Message[];
  // pagination store for each chatId the totalCount retuned by paginatedMessage query
  pagination: Record<StrongId<'chat'>, number>
}

const initialState: MessagesState = {
  messages: [],
  pagination: {},
};

export const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    clearMessages: (state) => {
      state.messages = initialState.messages;
      state.pagination = initialState.pagination;
    },
    addMessages: (state, action: PayloadAction<Message[]>) => {
      // We don't want to add duplicates and remove old version of message based on id
      state.messages = uniqBy(
        [...action.payload, ...state.messages], 
        'id'
      );
    },
    createMessage: (state, action: PayloadAction<Message>) => {
      if (!action.payload.id) {
        return;
      }
      const index = state.messages.findIndex((message) => message.id === action.payload.id);
      if (index !== -1) {
        // We keep original message data as updateMessage might be smaller
        state.messages[index] = {
          ...state.messages[index],
          ...action.payload
        };
      } else {
        state.messages.push(action.payload);
        state.pagination[action.payload.chatId] =  state.pagination[action.payload.chatId]++;
      }
    },
    setPagination: (state, action: PayloadAction<{ chatId: StrongId<'chat'>, totalCount: number }>) => {
      state.pagination[action.payload.chatId] = action.payload.totalCount;
    },
    updateMessage: (state, action: PayloadAction<Message>) => {
      const index = state.messages.findIndex((message) => message.id === action.payload.id);
      if (index !== -1) {
        // We keep original message data as updateMessage might be smaller
        state.messages[index] = {
          ...state.messages[index],
          ...action.payload
        };
      } else {
        state.messages.push(action.payload);
        state.pagination[action.payload.chatId] =  state.pagination[action.payload.chatId]++;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export * from './actions';
export const { clearMessages, addMessages, createMessage, setPagination, updateMessage } = messagesSlice.actions;
export default messagesSlice.reducer;

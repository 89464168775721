'use client';
import { IconImportDocuments, IconUploadInChat } from '@unique/icons';
import { FolderPathSelection } from '@unique/shared-library';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CreateDueDiligenceBox from '../DueDiligence/CreateDueDiligenceBox';
import DueDiligenceAddSource from '../DueDiligence/DueDiligenceAddSource';
import DueDiligenceUploadBox from '../DueDiligence/DueDiligenceUploadBox';
import NavigationStepper, { StepStatus } from '../NavigationStepper';
import {
  setDueDiligenceDetails,
  setQuestionFileIds,
  setSourceFileIds,
  useAppDispatch,
} from '@/store';
import {
  getUseCreateDueDiligenceKey,
  useAssistantQuery,
  useCreateDueDiligence,
} from '@/lib/swr/hooks';
import SkipCreateDueDiligenceButton from './SkipCreateDueDiligenceButton';

const initialSteps = [
  { id: '1', title: 'Import Questions', status: StepStatus.upcoming, href: 'import-questions' },
  { id: '2', title: 'Add Sources', status: StepStatus.upcoming, href: 'add-sources' },
  { id: '3', title: 'Create Table', status: StepStatus.upcoming, href: 'create-table' },
];

export default function CreateDueDiligence() {
  const { spaceId: assistantId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [steps, setSteps] = useState(initialSteps);
  const [folderPathModalTitle, setFolderPathModalTitle] = useState('');
  const [showFolderPathSelectionModal, setShowFolderPathSelectionModal] = useState(false);

  const { trigger: createDueDiligence } = useCreateDueDiligence(getUseCreateDueDiligenceKey(), {
    onSuccess: ({ createDueDiligence }) => {
      dispatch(
        setDueDiligenceDetails({
          chatId: createDueDiligence.magicTableSheet.chatId,
          sheetId: createDueDiligence.magicTableSheet.id,
          dueDiligenceId: createDueDiligence.id,
        }),
      );
      goToNextPage();
    },
  });

  const goToStep = (step: string) => {
    navigate(`/space/${assistantId}/create-worksheet?${createQueryString('step', step)}`);
  };

  const { data: assistantData } = useAssistantQuery({ assistantId });

  useEffect(() => {
    if (!searchParams.get('step')) {
      const newSteps = [...steps];
      newSteps[0].status = StepStatus.current;
      setSteps(newSteps);
      return;
    }
    const newSteps = [...steps];

    // Step 1
    if (searchParams.get('step') === initialSteps[0].href) {
      newSteps[0].status = StepStatus.current;
    }

    // Step 2
    if (searchParams.get('step') === initialSteps[1].href) {
      newSteps[0].status = StepStatus.complete;
      newSteps[1].status = StepStatus.current;
    }

    // Step 3
    if (searchParams.get('step') === initialSteps[2].href) {
      newSteps[0].status = StepStatus.complete;
      newSteps[1].status = StepStatus.complete;
      newSteps[2].status = StepStatus.current;
    }
    setSteps(newSteps);
  }, [searchParams]);

  const createQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set(name, value);

      return params.toString();
    },
    [searchParams],
  );

  const goToNextPage = () => {
    const currentStepIndex = steps.findIndex((step) => searchParams.get('step') === step.href);

    const newSteps = steps.map((step, index) => {
      if (index === currentStepIndex) {
        return { ...step, status: StepStatus.complete };
      }

      if (index === currentStepIndex + 1) {
        return { ...step, status: StepStatus.current };
      }

      return step;
    });

    const currentStep = steps[currentStepIndex + 1];
    goToStep(currentStep.href);
    setSteps(newSteps);
  };

  const onQuestionsUploadSubmitted = (contentIds?: string[]) => {
    if (!contentIds.length) return;
    dispatch(setQuestionFileIds(contentIds));
    goToNextPage();
  };

  const showSourceFileSelectionModal = () => {
    // Add Sources
    setShowFolderPathSelectionModal(true);
    setFolderPathModalTitle('Select Files or Folders to add');
  };

  const handleFolderPathSelected = async ({ fileIds }) => {
    setShowFolderPathSelectionModal(false);
    if (searchParams.get('step') === initialSteps[0].href) {
      // Store the question file ID for later use
      dispatch(setQuestionFileIds(fileIds));

      // Create Due Diligence Table
      createDueDiligence({ assistantId });
    }

    if (searchParams.get('step') === initialSteps[1].href) {
      // save the source file IDs for later use
      dispatch(setSourceFileIds(fileIds));

      goToNextPage();
    }
  };

  const handleShowFolderPathSelection = () => {
    setFolderPathModalTitle('Select 1 File');
    setShowFolderPathSelectionModal(true);
  };

  const currentStep = searchParams.get('step');

  return (
    <>
      <div className="flex flex-col">
        <div className="bg-background flex items-center justify-center py-5">
          <NavigationStepper steps={steps} />
        </div>
        {currentStep === initialSteps[0].href ? (
          <DueDiligenceUploadBox
            onSubmit={onQuestionsUploadSubmitted}
            icon={<IconImportDocuments />}
            title=" Import Due Diligence questions from document"
            description="PDF, DOCx, CSV, XLSX"
            handleShowFolderPathSelection={handleShowFolderPathSelection}
            assistantIngestionConfig={
              assistantData?.assistantByUser?.settings?.ingestionConfig || {}
            }
            assistantId={assistantId}
            skipButton={
              <SkipCreateDueDiligenceButton
                assistantId={assistantId}
                source={initialSteps[0].href}
              />
            }
          />
        ) : null}
        {currentStep === initialSteps[1].href ? (
          <DueDiligenceAddSource
            onSubmit={showSourceFileSelectionModal}
            icon={<IconUploadInChat />}
            title="Add Sources of Company Information"
            description="PDF, DOCx, CSV"
            uploadButtonTitle="Add Files"
            skipButton={
              <SkipCreateDueDiligenceButton
                assistantId={assistantId}
                source={initialSteps[1].href}
              />
            }
          />
        ) : null}
        {currentStep === initialSteps[2].href ? (
          <CreateDueDiligenceBox assistantId={assistantId} />
        ) : null}
      </div>
      {showFolderPathSelectionModal && (
        <FolderPathSelection
          handleClose={() => setShowFolderPathSelectionModal(false)}
          handleSubmit={handleFolderPathSelected}
          {...(currentStep === initialSteps[0].href
            ? { submitButtonText: 'Add file' }
            : { submitButtonText: 'Add files' })}
          isSelectable
          modalTitle={folderPathModalTitle}
          {...(currentStep === initialSteps[0].href && { maxItemsToSelect: 1 })}
        />
      )}
    </>
  );
}

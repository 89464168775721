import { MagicTableSheetState } from '@/@generated/graphql';
import { useMagicTableSheetUpdateSubscription } from '@/lib/swr/hooks';
import {
  defaultMagicTableSheetUpdate,
  MagicTableSheetUpdate,
  setMagicTableSheetUpdates,
  useAppDispatch,
  useAppSelector,
} from '@/store';
import { LoadingText, Spinner } from '@unique/component-library';
import Badge from '@unique/component-library/src/Badge';
import { IconCheckCircle } from '@unique/icons';
import { logger } from '@unique/next-commons/logger';
import { format } from 'date-fns';
import { useEffect } from 'react';

const log = logger.child({
  package: 'chat',
  namespace: 'components:due-diligence-component',
});

type Props = { sheetId: string; requeryDueDiligence: () => void };

export default function DueDiligenceStatusBadge({ sheetId, requeryDueDiligence }: Props) {
  const magicTableSheetUpdates = useAppSelector(
    (state) => state.dueDiligence.magicTableSheetUpdates,
  );

  const dispatch = useAppDispatch();

  const magicTableSheetWS = useMagicTableSheetUpdateSubscription(
    {
      next: (data) => {
        const currentState: MagicTableSheetUpdate = data.magicTableSheetUpdate;
        // Incase we have missing updates and the interval is not able to run before the state changes to Idle
        // we will requery the data from the database to get the latest updates.
        if (
          currentState.state === MagicTableSheetState.Idle &&
          magicTableSheetUpdates.state === MagicTableSheetState.Processing
        ) {
          requeryDueDiligence();
        }
        dispatch(setMagicTableSheetUpdates(currentState));
      },
      error: (error) => {
        log.error(`Error in MagicTableSheetUpdate Subscription, ${error}`);
      },
      complete: () => {
        log.info('MagicTableSheetUpdate subscription complete');
      },
    },
    { sheetId },
  );

  useEffect(() => {
    magicTableSheetWS.on('connected', () => {
      log.info('Connected to socket, MagicTableSheetUpdateSubscription');
    });

    magicTableSheetWS.on('closed', () => {
      log.info('Disconnected from socket, MagicTableSheetUpdateSubscription');
    });

    return () => {
      magicTableSheetWS.terminate();
    };
  }, []);

  // NOTE: This is a temporary fix to missing updates from the socket connection.
  // Sometimes, the socket does not populate all the cells in the table and we are still investigating this issue.
  // Our temporary fix is, Since the table is in processing state, and the user will not be allowed to perform any actions in this state,
  // we are requerying the data from the database to get the latest updates.
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (magicTableSheetUpdates.state === MagicTableSheetState.Processing) {
      interval = setInterval(() => {
        requeryDueDiligence();
      }, 10000);
    }
    return () => {
      clearTimeout(interval);
    };
  }, [magicTableSheetUpdates.state]);

  useEffect(() => {
    return () => {
      dispatch(setMagicTableSheetUpdates(defaultMagicTableSheetUpdate));
    };
  }, []);

  return (
    <div>
      {magicTableSheetUpdates.state === MagicTableSheetState.Idle &&
        magicTableSheetUpdates.updatedAt && (
          <Badge classes="px-2 py-3 flex items-center bg-success-light rounded-lg text-on-success-light text-bold">
            <span className="mr-2">
              <IconCheckCircle />
            </span>
            UPDATED: {format(new Date(magicTableSheetUpdates.updatedAt), "MMM d 'at' h:mma")}
          </Badge>
        )}
      {magicTableSheetUpdates?.state === MagicTableSheetState.Processing && (
        <Badge classes="px-2 py-3 flex items-center bg-info rounded-lg">
          <div className="text-on-info text-bold flex items-center gap-2">
            <Spinner size={16} color="info" />
            <LoadingText>UPDATING</LoadingText>
          </div>
        </Badge>
      )}
    </div>
  );
}

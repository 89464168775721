import { logger } from '@unique/next-commons/logger';
import { clientContextValues, Service } from '@unique/next-commons/swr';
import { getSdk } from '@/@generated/graphql';

const log = logger.child({
  package: 'chat',
  namespace: 'redux:actions:mutateDeleteAllChats',
});

export const mutateDeleteAllChats = () => {
  return async () => {
    try {
      const sdkScope = getSdk(clientContextValues.clients[Service.NODE_CHAT]);
      await sdkScope.ChatDeleteAll();
      return Promise.resolve();
    } catch (error) {
      log.error(error.response.errors[0].message);
      return Promise.reject(error.response.errors[0]);
    }
  };
};

import { FC } from 'react';
import { IconProps } from '.';

export const IconPlay: FC<IconProps> = ({ width = '100%', height = '100%' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
    >
      <polygon points="5,3 15,10 5,17" fill="currentColor" />
    </svg>
  );
};

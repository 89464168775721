'use client';
import { FC } from 'react';
import { ButtonIcon, ButtonVariant } from '@unique/component-library';
import { IconDeleteModal } from '@unique/icons';

type Props = {
  onConfirmation: () => void;
  sheetName: string;
  deletingSheet: boolean;
};

export const DeleteDueDiligenceConfirmationModal: FC<Props> = ({
  onConfirmation,
  sheetName,
  deletingSheet,
}) => {
  return (
    <div className="p-6">
      <div className="text-on-background-main flex flex-col items-center pb-[48px] pl-[24px] pr-[25px] pt-[24px]">
        <div className="pb-8">
          <IconDeleteModal />
        </div>
        <h3 className="mb-[8px] text-center text-[23px] font-extrabold">Delete Sheet</h3>
        <p className="text-md text-center">
          Click on Delete to <strong>permanently</strong> remove <strong>{sheetName}</strong> from
          your history.
          <br />
          <strong>After</strong> confirmation, it will not be possible to recover the deleted sheet.
        </p>
      </div>
      <div className="flex w-full justify-end pr-6">
        <ButtonIcon
          variant={ButtonVariant.DANGER}
          handleClick={onConfirmation}
          isLoading={deletingSheet}
        >
          Delete
        </ButtonIcon>
      </div>
    </div>
  );
};

export default DeleteDueDiligenceConfirmationModal;

import { OwnerType } from '@/@generated/graphql';
import {
  getUseContentByChatQueryKey,
  getUseCreateDueDiligenceKey,
  useContentUpsertByChatMutation,
  useCreateDueDiligence,
} from '@/lib/swr/hooks';
import { ConfigurationContext } from '@/providers/ConfigurationProvider';
import { setDueDiligenceDetails, useAppDispatch } from '@/store';
import {
  ButtonIcon,
  ButtonVariant,
  Dropzone,
  FileTypeIcon,
  ProgressBar,
} from '@unique/component-library';
import {
  SUPPORTED_MIME_PDF_DOCS_CSV,
  SUPPORTED_MIME_TYPES_EXCEL,
  ToastVariant,
  useFileUpload,
  useToast,
} from '@unique/shared-library';
import cn from 'classnames';
import { FC, forwardRef, MouseEvent, ReactNode, useContext, useEffect, useState } from 'react';
import { ErrorCode, FileRejection, FileWithPath } from 'react-dropzone';

type Props = {
  onSubmit: (contentIds?: string[]) => void;
  icon: ReactNode;
  title: string;
  description: string;
  handleShowFolderPathSelection?: () => void;
  skipButton: ReactNode;
  assistantIngestionConfig: Record<string, string>;
  assistantId: string;
};

const DEFAULT_BORDER_COLOR = 'border-control';

const SUPPORTED_UPLOAD_FILES = { ...SUPPORTED_MIME_PDF_DOCS_CSV, ...SUPPORTED_MIME_TYPES_EXCEL };

const DueDiligenceUploadBox: FC<Props> = forwardRef<HTMLElement, Props>(
  (
    {
      onSubmit,
      icon,
      description,
      title,
      handleShowFolderPathSelection,
      skipButton,
      assistantIngestionConfig,
      assistantId,
    },
    ref,
  ) => {
    const maxFiles = 1;
    const { maxFileSize } = useContext(ConfigurationContext);
    const { showErrorToast, showToast } = useToast();
    const [borderColor, setBorderColor] = useState(DEFAULT_BORDER_COLOR);
    const dispatch = useAppDispatch();

    const [acceptedFiles, setAcceptedFiles] = useState(null);
    const [progress, setProgress] = useState(0);
    // UPSERT CONTENT
    const { trigger: upsertContent } = useContentUpsertByChatMutation(
      getUseContentByChatQueryKey({
        chatId: '',
      }),
    );

    const { trigger: createDueDiligence } = useCreateDueDiligence(getUseCreateDueDiligenceKey(), {
      onSuccess: ({ createDueDiligence }) => {
        dispatch(
          setDueDiligenceDetails({
            chatId: createDueDiligence.magicTableSheet.chatId,
            sheetId: createDueDiligence.magicTableSheet.id,
            dueDiligenceId: createDueDiligence.id,
          }),
        );
      },
    });

    // Upload File
    const { files, handleUpload } = useFileUpload<OwnerType>({
      setError: (error) => showToast({ message: error, variant: ToastVariant.ERROR }),
      chatOwnerType: OwnerType.Chat,
      upsertContent,
      ownerType: OwnerType.Chat,
      upsertContentAttributeName: 'contentUpsertByChat',
      storeInternally: true,
      maxFiles: maxFiles,
      maxFileSize: maxFileSize,
      assistantIngestionConfig,
    });

    useEffect(() => {
      // Each update of files will update the progress
      files && files[0] && setProgress(files[0].progress);
    }, [files, acceptedFiles]);

    const handleChatUpload = async (
      acceptedFiles: FileWithPath[],
      fileRejections: FileRejection[],
      chatId: string,
    ) => {
      if (fileRejections.length) {
        const code = fileRejections[0].errors[0].code;
        let message = `Unknown Error (${code})`;
        switch (code) {
          case ErrorCode.FileTooLarge:
            message = `File too large. The current limit is ${maxFileSize / 1024 / 1024} MB per file.`;
            break;
          case ErrorCode.FileInvalidType:
            message = 'Invalid File Type';
            break;
          case ErrorCode.FileTooSmall:
            message = 'File size is too small';
            break;
          case ErrorCode.TooManyFiles:
            message = `Too many files. You can upload a maximum of ${maxFiles} files at once.`;
            break;
        }
        showToast({ message, variant: ToastVariant.ERROR });
        setBorderColor(DEFAULT_BORDER_COLOR);
        return;
      }

      try {
        // Update UI with accepted files, hardcoded to 1 file
        setAcceptedFiles(acceptedFiles);

        const startTime = new Date().getTime();
        const contentIds = await handleUpload(acceptedFiles, fileRejections, {
          newChatId: chatId,
        });
        const stopTime = new Date().getTime();
        // Add timeout 1000 to let the progress bar animation finish
        setTimeout(
          () => {
            // Confirm upload and go to next page
            onSubmit(contentIds);
          },
          Math.max(0, 1000 - (stopTime - startTime)),
        );
      } catch (error) {
        console.error('Upload failed:', error);
        setAcceptedFiles(null);
        showToast({ message: 'Upload failed. Please try again.', variant: ToastVariant.ERROR });
        setBorderColor(DEFAULT_BORDER_COLOR);
        return;
      }
    };

    const handleClickSelectFromKnowledgeBase = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      handleShowFolderPathSelection();
    };

    const onDrop = async (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => {
      const response = await createDueDiligence({ assistantId });
      if (!response.createDueDiligence.id) {
        showErrorToast('Failed to create due diligence');
        return;
      }

      handleChatUpload(
        acceptedFiles,
        fileRejections,
        response.createDueDiligence.magicTableSheet.chatId,
      );
    };

    return (
      <div className="mx-5 flex flex-col justify-center">
        <div className="mx-auto w-full max-w-3xl">
          {acceptedFiles ? (
            <>
              <div
                onDragOver={() => setBorderColor('border-primary-cta')}
                onDragLeave={() => setBorderColor(DEFAULT_BORDER_COLOR)}
                className={cn({
                  'mx-auto mt-10 flex min-h-[480px] w-full items-center justify-center rounded-md border-2 border-dashed':
                    true,
                  [borderColor]: true,
                })}
              >
                <div className="flex flex-col items-center gap-3">
                  <FileTypeIcon dimensions="60px" mimeType={acceptedFiles?.[0]?.type} />
                  <p className="text-md text-on-background-main text-center font-bold text-white md:text-2xl">
                    Uploading {acceptedFiles[0].name}...
                  </p>
                  <ProgressBar title="Uploading" completed={progress} />
                </div>
              </div>
            </>
          ) : (
            <>
              <Dropzone
                ref={ref}
                onDrop={onDrop}
                onError={(e) => console.log(e)}
                accept={SUPPORTED_UPLOAD_FILES}
                maxFiles={maxFiles}
                maxSize={maxFileSize}
                additonalClassname="h-full pb-2"
              >
                <div
                  onDragOver={() => setBorderColor('border-primary-cta')}
                  onDragLeave={() => setBorderColor(DEFAULT_BORDER_COLOR)}
                  className={cn({
                    'mx-auto mt-10 flex min-h-[480px] w-full items-center justify-center rounded-md border-2 border-dashed':
                      true,
                    [borderColor]: true,
                  })}
                >
                  <div className="flex flex-col items-center gap-3">
                    {icon}
                    <p className="text-md text-on-background-main text-center font-bold text-white md:text-2xl">
                      {title}
                    </p>
                    <p className="text-on-background-dimmed text-sm text-white">{description}</p>
                    <ButtonIcon variant={ButtonVariant.PRIMARY}>Upload File</ButtonIcon>
                    <div className="flex w-full items-center gap-x-3 px-5 py-1">
                      <div className="border-control flex-1 border" />
                      <div className="subtitle-2 text-on-control-dimmed">OR</div>
                      <div className="border-control flex-1 border" />
                    </div>
                    <ButtonIcon
                      variant={ButtonVariant.SECONDARY}
                      onClick={handleClickSelectFromKnowledgeBase}
                    >
                      Select from Knowledge Base
                    </ButtonIcon>
                  </div>
                </div>
              </Dropzone>
              {skipButton}
            </>
          )}
        </div>
      </div>
    );
  },
);

export default DueDiligenceUploadBox;
